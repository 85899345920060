import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  smartTagReportStartDate: null,
  smartTagReportEndDate: null,
  showTime: false,
  smartTagReportSelectMachine: [],
  smartTagReportSelectUnit: [],
  smartTagReportSelectTag: [],
  activeClassConsumer: "Consumer",
  smartTagMachineReportStartDate: null,
  smartTagMachineReportEndDate: null,
  smartTagMachineReportStartTime: null,
  smartTagMachineReportEndTime: null,
  smartTagCOCStartDate: null,
  smartTagCOCEndDate: null,
  smartTagPOPStartDate: null,
  smartTagPOPEndDate: null,
  smartTagPOP1StartDate: null,
  smartTagPOP1EndDate: null,
  smartTagCOCTime: false,
  smartTagPOPTime: false,
  smartTagCOCSelectMachine: [],
  smartTagCOCSelectUnit: [],
  smartTagCOCSelectTag: [],
  smartTagPOPSelectMachine: [],
  smartTagPOPSelectUnit: [],
  smartTagPOPSelectTag: [],
  smartTagHIRange : 0,
  smartTagLowRange : 0,
  selectRangeGraph : "Today",


  activeClassConsumer1: "Consumer",
  smartTagMachineReportStartDate1: null,
  smartTagMachineReportEndDate1: null,
  smartTagMachineReportStartTime1: null,
  smartTagMachineReportEndTime1: null,
  smartTagCOCStartDate1: null,
  smartTagCOCEndDate1: null,
  smartTagPOPStartDate1: null,
  smartTagPOPEndDate1: null,
  smartTagPOP1StartDate1: null,
  smartTagPOP1EndDate1: null,
  smartTagCOCTime1: false,
  smartTagPOPTime1: false,
  smartTagCOCSelectMachine1: [],
  smartTagCOCSelectUnit1: [],
  smartTagCOCSelectTag1: [],
  smartTagPOPSelectMachine1: [],
  smartTagPOPSelectUnit1: [],
  smartTagPOPSelectTag1: [],
  COCIntervalTime1: "1",
  POPIntervalTimePop1: "1",
  rangeValue1: "",
  rangValuePOP1: "",
  smartTagtagMachineName : "",

  smarTagReportDevice :[],
  smarTagReportUnit : [],
  smarTagReportTag : [],
  smarTagselectedDevice : [],
  smarTagselectedUnit : [],
  COCIntervalTime: "1",
  POPIntervalTimePop: "1",
  rangeValue: "",
  rangValuePOP: "",
  smartTagGatewayId: null,
  tagName: "",
  tagComment: "",
  minimum: null,
  maximum: null,
  HH : null,
  HI : null,
  LO : null,
  LL : null,
  isCardClicked : false,
  gateWayId: null,
  tagClickebleData:[],
  overViewTagClickebleData:[],
  deviceID : null,
  tagUNIT: "",

  activeClassTab: "Compact",
  tagType : "",
  smartTagCOCSwitch : false,
  smartTagPOPSwitch : false,
  smarTagReportInterval : "1"
};

const smartTagReportSlice = createSlice({
  name: "smartTagReport",
  initialState,
  reducers: {
    setSmartTagReportData(state, { payload }) {
      return { ...state, ...payload };
    },
    clearSmartTagReportData(state) {
      return initialState;
    },
  },
});

export const { setSmartTagReportData, clearSmartTagReportData } =
  smartTagReportSlice.actions;

export default smartTagReportSlice.reducer;
