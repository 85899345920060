import React, { useState } from "react";
import { Draggable, Droppable } from "react-drag-and-drop";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from "react-router-dom";
import { images } from "../../../../../config/images";
import {
  getDeviceAndHicloudTag,
  createBridgeDeviceAndHicloudTag,
  autoAssignData,
} from "../../../service";
import { ToastContainer, toast } from "react-toastify";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import "react-toastify/dist/ReactToastify.css";
import "./Bridge.css";
import { useEffect } from "react";
import Loader from "../../../../../components/Loader/Loader";
import Mqqtdata from "../../../../ProcessDashboard/Mqqtdata";
import TimerIcon from "@mui/icons-material/Timer";
import { useSelector } from "react-redux";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { timeStampCheckStatus } from "../../../../../components/TimeStampCheckStatus/timeStampCheckStatus";


function Bridge() {
  const navigate = useNavigate();
  const [blankAssignDevice, setblankAssignDevice] = useState([]);
  const [hiCloudTagData, setHiCloudTagData] = useState([]);
  const [deviceTag, setDeviceTag] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(59);
  const [showPopup, setShowPopup] = useState(false);
  const [touchData, setTouchData] = useState("");
  const [matchSignalType, setMatchSignalType] = useState(false)

  const [deviceDetail, setDeviceDetail] = useState({
    uid: '',
    device_name: "",
    device_description: "",
  });
  const [lockAndUnlock, setLockAndUnlock] = useState({});
  const [topics, setTopics] = useState("");
  const [mqttTagData, setMqttTagData] = useState("");
  const [autoAssignData1, setAssignData1] = useState({});
  const [activeCount, setActiveCount] = useState([]);
  const [isNotified, setIsNotified] = useState(false);
  const [MqttTime, setMqttTime] = useState("");

  const { user_access } = useSelector((state) => state.userState);
  console.log("lockAndUnlock", mqttTagData, topics);
  const location = useParams();

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds, showPopup]);

  const notifyError = (message) => {
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });
  };

  const notifySuccess = (message) => {
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });
  };

  const handelChangeUnlock = (id) => {
    setLockAndUnlock((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handelChangeSetUnlockData = (value, index) => {
    if (deviceTag.includes(value.device_tag)) {
      // If value already exists in deviceTag, remove it
      const updatedDeviceTag = deviceTag.filter((tag) => tag !== value.device_tag);
      setDeviceTag(updatedDeviceTag);
    } else {
      // If value doesn't exist in deviceTag, add it
      setDeviceTag([...deviceTag, value.device_tag]);
    }

    if (hiCloudTagData && index >= 0 && index < hiCloudTagData.length) {
      const updatedBlankAssignDevice = [...hiCloudTagData];
      updatedBlankAssignDevice[index] = { ...updatedBlankAssignDevice[index], device_tag: null };
      setHiCloudTagData(updatedBlankAssignDevice);
      setLockAndUnlock((prev) => ({ ...prev, [value.device_tag]: !prev[value.device_tag] }));
    }
  };

  const handleDrop = (data, checkdata) => {
    if (mqttTagData == "") {
      return 1;
    }
    if (mqttTagData) {
      const findTagValue = mqttTagData?.[deviceDetail.uid]?.find((f) => f.device_tag == data.e.foo)
      if (findTagValue &&
        ['0', '1'].includes(findTagValue?.tagValue) && !["DI", "DO"].includes(checkdata.signal_type) ||
        !['0', '1'].includes(findTagValue?.tagValue) && ["DI", "DO"].includes(checkdata.signal_type)
      ) {
        setMatchSignalType(true)
        // return true;
      }

    }
    // This method runs when the data drops
    const filterData = deviceTag.filter((e, i) => e !== data.e.foo);
    if (checkdata.dvTag != null) {
      setDeviceTag([...filterData, checkdata.dvTag]);
    } else {
      setDeviceTag(filterData);
    }
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //       if (seconds > 0) {
    //         setSeconds((prevSeconds) => prevSeconds - 1);
    //       } else {
    //         clearInterval(interval);
    //       }
    //     }, 1000);
    
    //     return () => clearInterval(interval);
    //   }, [seconds, showPopup]);

    setHiCloudTagData((prevHiCloud) => {
      const updatedBlankAssignDevice = [...prevHiCloud];
      updatedBlankAssignDevice[data.i] = { ...updatedBlankAssignDevice[data.i], device_tag: data.e.foo };
      return updatedBlankAssignDevice;
    })
    setTouchData("")
  };

  useEffect(() => {
    if (hiCloudTagData.length) {
      submitCreateBridge();
    }
  }, [deviceTag, hiCloudTagData]);

  const getDeviceAndHicloudTagData = async () => {

    // const hicloud_tags = [
    //   { "tag_name": "M6_KWH", "device_tag": "F4MTR_KWH", "signal_type": "AI" },
    //   { "tag_name": "M6_KVAH", "device_tag": "F4MTR_KVAH", "signal_type": "AI" },
    //   { "tag_name": "M6_PF", "device_tag": "F4MTR_PF", "signal_type": "AI" },
    //   { "tag_name": "M6_KVARH", "device_tag": "F4MTR_KVARH", "signal_type": "AI" },
    //   { "tag_name": "M6_VL1L2", "device_tag": "F4MTR_VL1L2", "signal_type": "AI" },
    //   { "tag_name": "M6_VL2L3", "device_tag": "F4MTR_VL2L3", "signal_type": "AI" },
    //   { "tag_name": "M6_VL3L1", "device_tag": "F4MTR_VL3L1", "signal_type": "AI" },
    //   { "tag_name": "M6_I1", "device_tag": "F4MTR_I1", "signal_type": "AI" },
    //   { "tag_name": "M6_I2", "device_tag": "F4MTR_I2", "signal_type": "AI" },
    //   { "tag_name": "M6_I3", "device_tag": "F4MTR_I3", "signal_type": "AI" },
    //   { "tag_name": "M6_KW", "device_tag": null, "signal_type": "AI" },
    //   { "tag_name": "M6_KVA", "device_tag": null, "signal_type": "AI" },
    //   { "tag_name": "M6_RH", "device_tag": null, "signal_type": "AI" },
    //   { "tag_name": "M6_PF1", "device_tag": null, "signal_type": "AI" },
    //   { "tag_name": "M6_PF2", "device_tag": null, "signal_type": "AI" },
    //   { "tag_name": "M6_PF3", "device_tag": null, "signal_type": "AI" },
    //   { "tag_name": "M6_KVAR", "device_tag": null, "signal_type": "AI" },
    //   { "tag_name": "RUN", "device_tag": null, "signal_type": "DI" }
    // ]

    // const device_tags = [
    //   "F4MTR_UBV_VL1L2",
    //   "F4MTR_UBC_I1I2",
    //   "F4MTR_KW",
    //   "F4MTR_UBC_I2I3",
    //   "F4MTR_UBV_I3I1",
    //   "F4HEAT_ON_FB",
    //   "F4MTR_KVA",
    //   "F4MTR_COM_F",
    //   "F4MTR_KVAR",
    //   "F4MTR_UBV_VL2L3",
    //   "F4MTR_UBV_VL3L1",
    //   "F4_HEAT_ONT",
    //   "M6_KVA"
    // ]

    // setHiCloudTagData(hicloud_tags);
    // setDeviceTag(device_tags);
    // setTopics([`Hikar/DeviceUID/` + 'PHA8741D0850FD/EM0010']);


    setShowPopup(true);
    const param = {
      device: location.id,
    };
    try {
      const resp = await getDeviceAndHicloudTag(param);

      if (resp.status == 200 || resp.status == 201) {
        setShowPopup(false);

        setActiveCount(resp.data.payload.active_tag_count);
        setHiCloudTagData(resp.data.payload.hicloud_tag);
        setDeviceTag(resp.data.payload.device_tag);
        setDeviceDetail({
          uid: resp.data.payload.device_detail.uid,
          device_name: resp.data.payload.device_detail.device_name,
          device_description:
            resp.data.payload.device_detail.device_description,
        });
        setTopics([`Hikar/DeviceUID/` + resp.data.payload.device_detail.uid]);
        setTouchData("")
      }
    } catch (error) {
      setShowPopup(false);
      console.log(error);
      //   notifyError(error.response.data.message);
    }
  };

  const convertTimeStampFormat = mqttTagData ? Object.keys(mqttTagData).map((row) =>{
    const findTimeStamp = mqttTagData[row].find((f) => f.device_tag == "TIMESTAMP");
    return ({
      ...findTimeStamp,
      TIMESTAMP: findTimeStamp?.TIMESTAMP || "",
    });
  }) : []
  useEffect(() => {
    const getData = timeStampCheckStatus(convertTimeStampFormat);

    if (!isNotified && Array.isArray(getData) && getData.length > 0) {
      const { device_tag, tagDateAndTimeFormat, tagValue } = getData[0];
      setMqttTime(tagValue);
      const notifyError = (message) =>
        toast.error(message, {
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
          toastClassName: 'custom-toast',
        });

        if (device_tag && tagDateAndTimeFormat && tagValue) {
      
          notifyError(
            <div>
              <p>Device Tag: {device_tag}</p>
              <p>Current Time: {tagDateAndTimeFormat}</p>
              <p>Mqtt Time: {tagValue}</p>
            </div>
          );
          setIsNotified(true); 
        }
    }
  }, [mqttTagData, isNotified]); 

 
  useEffect(() => {
    getDeviceAndHicloudTagData();
  }, []);

  const submitCreateBridge = async () => {
    const tagBridge = hiCloudTagData.reduce((acc, val) => {
      acc[val.tag_name] = val.device_tag;
      return acc;
    }, {});

    const param = {
      device_id: location.id,
      tag: tagBridge,
    };
    const resp = await createBridgeDeviceAndHicloudTag(param);
    if (resp.status == 200 || resp.status == 201) {
      setLockAndUnlock({});
    }
  };

  const submitRedirect = () => {
    navigate(`/deviceManagement/configure/machine/${location.machineId}`);
  };

  const handleAutoAssignData = async () => {
    const params = {
      device_id: location.id,
      device_tag: deviceTag,
      hicloud_tag: hiCloudTagData.filter((f, index) => f.device_tag == null).map((e) => e.tag_name)
    };
    try {
      setLoading(true);
      const resp = await autoAssignData(params);
      if (resp.status == 200 || resp.status == 201) {
        setLoading(false);

        setHiCloudTagData((prevData) => {
          const prev = [...prevData];
          const mapingData = prev.map((row) => {
            const findTag = resp.data.payload.hicloud_tag?.find((f) => f.tag_name == row.tag_name)
            if (findTag) {
              return findTag;
            }
            return row;
          });
          console.log("mapingDatamapingData", mapingData);
          return mapingData;

        });
        setDeviceTag(resp.data.payload.device_tag);

      }
    } catch (error) {
      setLoading(false);
      notifyError("It doesn't assign automatically due to issues with the string.")
      console.log("handleSubmit", error);
    }
  };
  const superAdminAccess = user_access.length
    ? user_access.find(
      (user) => user.access_module?.access_module_name === "Admin Dashboard"
    )
    : null;
  const accessManagementDevice = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
        (f) => f.sub_module_name == "Device Management"
      )
      : null
    : null;

  const handleTostMsg = () => {
    notifyError("You don't have access.");
  };

  const handleTouchStart = (data) => {
    setTouchData(data)
  }

  const handleEndTouch = (checkdata, i) => {
    
    if (touchData) {
      if (mqttTagData == "") {
        return 1;
      }
      if (mqttTagData) {
        const findTagValue = mqttTagData?.[deviceDetail.uid]?.find((f) => f.device_tag == touchData)
        if (findTagValue &&
          ['0', '1'].includes(findTagValue.tagValue) && !["DI", "DO"].includes(checkdata.signal_type) ||
          !['0', '1'].includes(findTagValue.tagValue) && ["DI", "DO"].includes(checkdata.signal_type)
        ) {
          setMatchSignalType(true)
          // return true;
        }

      }
      const filterData = deviceTag.filter((e, i) => e !== touchData);
      if (checkdata.device_tag != null) {
        setDeviceTag([...filterData, checkdata.device_tag]);
      } else {
        setDeviceTag(filterData);
      }
      setHiCloudTagData((prevBlankAssignDevice) => {
        const updatedBlankAssignDevice = [...prevBlankAssignDevice];
        updatedBlankAssignDevice[i] = { ...updatedBlankAssignDevice[i], device_tag: touchData };;
        return updatedBlankAssignDevice;
      });
      setTouchData("")
    }

  }


  return (
    <>
      {topics && <Mqqtdata topics={topics} setMqttTagData={setMqttTagData} />}
      <div>
        
      </div>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <Grid container>
            <Grid item xs={12} lg={10} md={9}>
              <div className="deviceManagementHeader-wrap configure">
                <div className="deviceManagementTitle-wrap">
                  <KeyboardBackspaceIcon
                    onClick={() =>
                      navigate(
                        `/deviceManagement/configure/machine/${location.machineId}`
                      )
                    }
                    className="backArrow"
                  />{" "}
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="deviceManagementTitle"
                    onClick={() =>
                      navigate(
                        `/deviceManagement/configure/machine/${location.machineId}`
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {deviceDetail.device_name}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={2} md={3}>
              {(accessManagementDevice?.is_viewer &&
                accessManagementDevice?.is_editor) ||
                (!accessManagementDevice?.is_viewer &&
                  accessManagementDevice?.is_editor) ? (
                <button
                  className="addDevice-button bridge auto-assign-btn mt-3"
                  style={{ width: "138px" }}
                  onClick={submitRedirect}
                >
                  Submit
                </button>
              ) : (
                <button
                  className="addDevice-button bridge auto-assign-btn mt-3"
                  style={{ width: "138px" }}
                  onClick={handleTostMsg}
                >
                  Submit
                </button>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} lg={10} md={10}>
              <Card className="bridgeDevice-headerContent">
                <Box className="bridgeDevice-detailsWrap">
                  <Box className="bridgeDevice-details">
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      style={{ fontWeight: "600" }}
                    >
                      {deviceDetail.device_name}
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                      {deviceDetail.device_description}
                    </Typography>
                  </Box>
                  <Box className="bridgeDevice-uidDetailswrap">
                    <Box className="bridgeDevice-uidDetails">
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        style={{ fontWeight: "600" }}
                      >
                        UID:
                      </Typography>
                      <Typography variant="caption" gutterBottom>
                        {deviceDetail.uid}
                      </Typography>
                    </Box>
                    <Box className="bridgeDevice-tagDetails">
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        style={{ fontWeight: "600" }}
                      >
                        Active Tags:
                      </Typography>
                      <Typography variant="caption" gutterBottom>
                        {activeCount}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} lg={2} md={2}>
              {(accessManagementDevice?.is_viewer &&
                accessManagementDevice?.is_editor) ||
                (!accessManagementDevice?.is_viewer &&
                  accessManagementDevice?.is_editor) ? (
                <button
                  className="addDevice-button bridge auto-assign-btn mt-3"
                  onClick={handleAutoAssignData}
                >
                  Auto Assign
                  <img src={images.bridgeImg} alt="" />
                </button>
              ) : <button
                className="addDevice-button bridge auto-assign-btn mt-3"
                onClick={handleTostMsg}
              >
                Auto Assign
                <img src={images.bridgeImg} alt="" />
              </button>}
            </Grid>
          </Grid>
          <Grid container>
            {/* =============== Device Tag Start =============== */}
            <Grid item sm={4} md={4} lg={4} xl={4} pt="20px" pr="10px">
              <Card className="devicetagWrap">
                <div className="deviceWrap">
                  <div className="deviceTitleWrap d-flex justify-content-between">
                    <Typography
                      variant="h6"
                      gutterBottom
                      className="deviceTagtitle"
                    >
                      Device Tag
                      <img
                        src={images.informationIcon}
                        alt=""
                        style={{ paddingLeft: "10px", width: "25px" }}
                      />
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className="deviceTagtitle"
                    >
                      Live Value
                    </Typography>
                  </div>
                  {deviceTag.length
                    ? deviceTag.map((e, i) => {
                      const findRealtimeValue =
                        deviceDetail.uid && mqttTagData
                          ? mqttTagData[deviceDetail.uid].find(
                            (f) => f.device_tag == e
                          )
                          : 0;

                      return (
                        <Draggable
                          onTouchStart={(event) => handleTouchStart(e)}
                          type="foo" data={`${e}`}>
                          <div className="device-tags">
                            <Box className="devicetagBox">
                              <Typography
                                variant="body2"
                                gutterBottom
                                className="devicetagBox-text"
                              >
                                {e}
                              </Typography>
                              <DragIndicatorIcon className="dragIcon bridge" />
                            </Box>
                            <Box className="devicetagBox">
                              <Typography
                                variant="body2"
                                gutterBottom
                                className="devicetagBox-text"
                              >
                                {findRealtimeValue
                                  ? findRealtimeValue.tagValue
                                  : ""}
                              </Typography>
                            </Box>
                          </div>
                        </Draggable>
                      );
                    })
                    : []}
                </div>
              </Card>
            </Grid>
            {/* =============== Device Tag End =============== */}
            {/* ===============  HiCloud Tag Start =============== */}
            <Grid item sm={8} md={8} lg={8} xl={8} pt="20px">
              <Card className="hiCloudtagWrap">
                <div className="hiCloudWrap">
                  <Grid container>
                    <Grid item md={8} lg={8} sm={12}>
                      <div className="hiCloudTitleWrap d-flex">
                        <Typography
                          variant="h6"
                          gutterBottom
                          className="hiCloudTitle"
                        >
                          HiCloud
                          <sup
                            style={{ marginTop: "0.2em" }}
                            className="hiCloud-text"
                          >
                            R
                          </sup>
                          Tag{" "}
                          <img
                            src={images.informationIcon}
                            alt=""
                            style={{ paddingLeft: "10px", width: "25px" }}
                          />
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item md={2} lg={2} sm={12}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className="hiCloudTitle mt-3"
                      >
                        Signal Type
                      </Typography>
                    </Grid>
                    <Grid item md={2} lg={2} sm={12}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className="hiCloudTitle mt-3"
                      >
                        Live Value
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item sm={6} md={6} lg={4} xl={4}>
                      {hiCloudTagData.length
                        ? hiCloudTagData.map((e) => {
                          return (
                            <Box className="hiCloudtagBox">
                              <Typography
                                variant="body2"
                                gutterBottom
                                className="hiCloudtagBox-text"
                              >
                                {e.tag_name}
                              </Typography>
                            </Box>
                          );
                        })
                        : []}
                    </Grid>
                    <Grid item sm={6} md={6} lg={4} xl={4}>
                      {hiCloudTagData.length
                        ? hiCloudTagData.map((data, i) => {
                          return (
                            <Droppable
                              types={["foo"]}
                              onDrop={(e) => handleDrop({ e, i }, data)}
                              onTouchEnd={(event) => handleEndTouch(data, i)}
                            >
                              <Box
                                className="hiCloudtagBox-drag"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  className="hiCloudtagBox-text"
                                >
                                  {data.device_tag}
                                </Typography>
                                {data.device_tag != null ? (
                                  lockAndUnlock[data.device_tag] ? (
                                    <img
                                      src={images.lockOpenImg}
                                      alt=""
                                      style={{
                                        width: "16px",
                                        marginLeft: "auto",
                                        marginRight: "10px",
                                      }}
                                      onClick={() =>
                                        handelChangeSetUnlockData(data, i)
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={images.lockImg}
                                      alt=""
                                      style={{
                                        width: "16px",
                                        marginLeft: "auto",
                                        marginRight: "10px",
                                      }}
                                      onClick={() => handelChangeUnlock(data.device_tag)}
                                    />
                                  )
                                ) : (
                                  ""
                                )}
                              </Box>
                            </Droppable>
                          );
                        })
                        : []}
                    </Grid>
                    <Grid item sm={6} md={6} lg={2} xl={2}>
                      {hiCloudTagData.map((e) => {
                        const findRealtimeValue =
                          deviceDetail.uid && mqttTagData
                            ? mqttTagData[deviceDetail.uid].find(
                              (f) => f.device_tag == e.device_tag
                            )
                            : 0;
                        return (
                          <Box
                            className="hiCloudtagBox-signaltype-drag"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "82%",
                              backgroundColor: findRealtimeValue ? ["0", "1"].includes(findRealtimeValue?.tagValue) && ["AI", "AO"].includes(e.signal_type) ?
                                "rgba(255, 0, 0, 0.51)" :
                                !["0", "1"].includes(findRealtimeValue?.tagValue) && ["DI", "DO"].includes(e.signal_type) ? "rgba(255, 0, 0, 0.51)" : "" : ""
                            }}
                          >
                            <Typography
                              variant="body2"
                              gutterBottom
                              className="hiCloudtagBox-text"
                            >
                              {e.signal_type}
                            </Typography>
                          </Box>

                        );
                      })}
                    </Grid>
                    <Grid item sm={6} md={6} lg={2} xl={2}>
                      {hiCloudTagData.length
                        ? hiCloudTagData.map((e) => {
                          const findRealtimeValue =
                            deviceDetail.uid && mqttTagData
                              ? mqttTagData[deviceDetail.uid].find(
                                (f) => f.device_tag == e.device_tag
                              )
                              : 0;

                          return (
                            <Box
                              className="hiCloudtagBox-signaltype-drag-value"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "82%",
                              }}
                            >
                              <Typography
                                variant="body2"
                                gutterBottom
                                className="hiCloudtagBox-text"
                              >
                                {findRealtimeValue
                                  ? findRealtimeValue.tagValue
                                  : ""}
                              </Typography>
                            </Box>
                          );
                        })
                        : null}
                    </Grid>
                  </Grid>
                </div>
                {/* <Box className="configuration-submitBtnWrap">
                  <button className="Bridge submit" onClick={submitRedirect}>
                    Submit
                  </button>
                </Box> */}
              </Card>
            </Grid>
            {/* ===============  HiCloud Tag End =============== */}
          </Grid>
        </Container>
        {showPopup && (
          <>
            <div className="loader-container">
              <Card className="deleteConfirmation-Modal">
                <Typography
                  variant="p"
                  gutterBottom
                  className="deleteConfirmation-title"
                >
                  Waiting 60 sec for device tag data.
                </Typography>
                <Typography
                  variant="h5"
                  gutterBottom
                  className="deleteConfirmation-title mt-2"
                >
                  <TimerIcon style={{ marginTop: "-3px" }} /> 00:
                  {seconds < 10 ? "0" : ""}
                  {seconds}
                </Typography>
              </Card>
            </div>
          </>
        )}

        {matchSignalType && (
          <>
            <div className="loader-container">
              <Card className="deleteConfirmation-Modal">
                <DisabledByDefaultIcon className="deleteConfirmation-icon" onClick={() => setMatchSignalType(false)} />
                <Typography
                  variant="h5"
                  gutterBottom
                  className="deleteConfirmation-title mt-2"
                >
                  Do not match the signal type
                </Typography>
              </Card>
            </div>
          </>
        )}
        {isLoading && <Loader />}
      </div>
    </>
  );
}

export default Bridge;
