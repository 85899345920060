import { Card, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { images } from "../../../../config";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchIcon from "@material-ui/icons/Search";
import CompactView from "./CompactView";
import DetailView from "./DetailView";
import { useParams } from "react-router-dom";
import { getAlarmStatus, getMachineAlarmStatus, getSmartTagGateway } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { setSmartTagReportData } from "../Slice";
import Loader from "../../../../components/Loader/Loader";

const SmartTagOverview = () => {
  const [gateWayData, setGatewayData] = useState([]);
  const [gateWayId, setGatewayId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [alarmStatus, setAlarmStatus] = useState([]);
  const [machineAlarmStatus,setMachineAlarmStatus] = useState([])

  console.log("alarmStatus", alarmStatus);
  const params = useParams();

  const { activeClassTab } = useSelector((state) => state.smartTagReportSlice);
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const dispatch = useDispatch();

  const handleImageClick = () => {
    dispatch(setSmartTagReportData({ activeClassTab: "Compact" }));
  };

  const handleImageDetailClick = () => {
    dispatch(setSmartTagReportData({ activeClassTab: "Detail" }));
  };

  useEffect(() => {
    getAlarmStatusData();
  }, []);

  const getAlarmStatusData = async () => {
    const param = {
      plant_id: params.plantid,
      company_id: params.companyid,
    };
    try {
      const resp = await getAlarmStatus(param);
      if (resp.status == 200 || resp.status == 201) {
        setAlarmStatus(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };
  const getNotificationIconColor = (priority) => {
    switch (priority) {
      case "HIGH":
        return "#e31e24";
      case "MEDIUM":
        return "#ffa500";
      case "LOW":
        return "#ebeb27";
      default:
        return "gray";
    }
  };
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,

    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    handleGetGatway();
  }, []);

  const handleGetGatway = async () => {
    setLoading(true);
    try {
      const param = {
        plant_id: params.plantid,
        company_id: params.companyid,
      };
      const resp = await getSmartTagGateway(param);
      if (resp.status == 200 || resp.status == 201) {
        setLoading(false);
        setGatewayData(resp.data.payload);
      }
    } catch (Error) {
      setLoading(false);
      console.log("Error in  getSmartTagGateway", Error);
    }
  };
  useEffect(() => {
    if (gateWayData.length > 0) {
      setGatewayId(gateWayData[0].id);
    }
  }, [gateWayData]);
  

  const handleGatewayCardClick = (id) => {
    setGatewayId(id);
  };


  useEffect(() => {
    if(gateWayId){
      getAlarmMachineStatusData();
    }
   
  }, [gateWayId]);

  const getAlarmMachineStatusData = async () => {
    const params = {
      gateway_id: gateWayId,
    };
    try {
      const resp = await getMachineAlarmStatus(params);
      if (resp.status == 200 || resp.status == 201) {
        setMachineAlarmStatus(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };
  return (
    <>
      <div className="page-wraper">
        <Slider {...sliderSettings} className="my-slider-class">
          {gateWayData.map((e) => {
            const matchedNotification = alarmStatus.find(
              (notif) => notif.id === e.id
            );

            return (
              <Card
                className="machineoverView-title"
                onClick={() => handleGatewayCardClick(e.id)}
              >
                <div className="d-flex justify-content-between">
                  <div>
                    <h6 style={{ fontSize: "18px" }}>
                      <b>{e.gateway_name}</b>
                    </h6>
                  </div>
                  <div className="d-flex gap-1">
                    <NotificationsIcon
                      style={{
                        color: getNotificationIconColor(
                          matchedNotification?.alarm_priority
                        ),
                      }}
                    />
                    {e.is_sub ? (
                      <img
                        src={images.cloudActiveImg}
                        alt=""
                        className="triangle-image"
                      />
                    ) : (
                      <img
                        src={images.cloudImg}
                        alt=""
                        className="triangle-image"
                      />
                    )}
                  </div>
                </div>
                <div>
                  <div>
                    <p style={{ fontSize: "14px" }} className="mb-1">
                      {e.gateway_description}
                    </p>
                    <p style={{ fontSize: "14px" }} className="mb-1">
                      License :{" "}
                      <span
                        className={
                          e.license_status == "Active"
                            ? "licensestatus-active"
                            : "licensestatus-inactive"
                        }
                      >
                        {e.license_status}
                      </span>
                    </p>
                  </div>
                </div>
              </Card>
            );
          })}
        </Slider>

        <div className="pt-3">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={10} md={10} lg={11} xl={11}>
              <TextField
                variant="outlined"
                style={{ width: "100%", backgroundColor: "white" }}
                // className="search-textfield"
                placeholder="Search tag name"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon />,
                  inputProps: {
                    style: { padding: "9px" },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={1} xl={1}>
              <div className="d-flex gap-2">
                {activeClassTab === "Compact" ? (
                  <>
                    <img
                      src={images.compactViewIcon}
                      alt=""
                      onClick={handleImageClick}
                      style={{ cursor: "pointer" }}
                    />
                    <img
                      src={images.detailViewIcon}
                      alt=""
                      onClick={handleImageDetailClick}
                      style={{ cursor: "pointer" }}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={images.compact}
                      alt=""
                      onClick={handleImageClick}
                      style={{ cursor: "pointer" }}
                    />
                    <img
                      src={images.detail}
                      alt=""
                      onClick={handleImageDetailClick}
                      style={{ cursor: "pointer" }}
                    />
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="pt-2">
          {activeClassTab === "Compact" ? (
            <CompactView
              gateWayId={gateWayId}
              searchQuery={searchQuery}
              setLoading={setLoading}
              getNotificationIconColor ={getNotificationIconColor}
              machineAlarmStatus ={machineAlarmStatus}
            />
          ) : (
            <DetailView
              gateWayId={gateWayId}
              searchQuery={searchQuery}
              setLoading={setLoading}
              getNotificationIconColor ={getNotificationIconColor}
              machineAlarmStatus ={machineAlarmStatus}
            />
          )}
        </div>
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default SmartTagOverview;
