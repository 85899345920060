import { Box, Stack, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpTwoToneIcon from "@mui/icons-material/KeyboardArrowUpTwoTone";
import { images } from "../../../../config";
import { Card, TextField, Typography } from "@material-ui/core";
import {
  pinUnpinGroupData,
  publistData,
  tagInteractionData,
} from "../../services";
import Loader from "../../../../components/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { SoftTagFormula } from "../../softTag/SoftTagFormula";
import { timeStampCheckStatus } from "../../../../components/TimeStampCheckStatus/timeStampCheckStatus";
import { setSmartTagReportData } from "../Slice";
import { useDispatch, useSelector } from "react-redux";


const TagGroup = ({
  getTag,
  searchQuery,
  mqttTagData,
  getTagData,
  getDeviceTagGroup,
  topics
}) => {


  const [tagGroupVisibility, setTagGroupVisibility] = useState(
    Array(10).fill(false) // Adjust the size based on your number of Tag Groups
  );
  const [updateValueModal, setUpdateValueModal] = useState(false);
  const [tagId, setTagId] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [updateValue, setUpdateValue] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [machineSwitchPopup, setMachineSwitchPopup] = useState(false);
  const [switchChecked, setSwitchChecked] = useState({});
  const [tagName, setTagName] = useState("");
  const [switchId, setSwitchId] = useState("");
  const [editTag, setEditTag] = useState(null);
  const [checkData, setCheckData] = useState(false)
  const [isNotified, setIsNotified] = useState(false);
  const [MqttTime, setMqttTime] = useState("");

  console.log("editTag", checkData);

  const param = useParams();
  const { overViewTagClickebleData } = useSelector(
    (state) => state.smartTagReportSlice
  );


  const handleTagGroupClick = (i) => {
    const updatedVisibility = [...tagGroupVisibility];
    updatedVisibility[i] = !updatedVisibility[i];
    setTagGroupVisibility(updatedVisibility);
  };

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });
  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });
    // const convertTimeStampFormat = mqttTagData
    // ? Object.keys(mqttTagData).map((row) => {
    //     const findTimeStamp = mqttTagData[row].find(
    //       (f) => f.device_tag == "TIMESTAMP"
    //     );
    //     // console.log("findTimeStamp",findTimeStamp)

    //     return {
    //       ...findTimeStamp,
    //       TIMESTAMP: findTimeStamp?.TIMESTAMP || "",
    //     };
    //   })
    // : [];
    // useEffect(() => {
    //   const getData = timeStampCheckStatus(convertTimeStampFormat);
  
    //   if (!isNotified && Array.isArray(getData) && getData.length > 0) {
    //     const { device_tag, tagDateAndTimeFormat, tagValue } = getData[0];
    //     setMqttTime(tagValue);
    //     const notifyError = (message) =>
    //       toast.error(message, {
    //         theme: 'colored',
    //         position: toast.POSITION.TOP_RIGHT,
    //         toastClassName: 'custom-toast',
    //       });
  
    //       if (device_tag && tagDateAndTimeFormat && tagValue) {
        
    //         notifyError(
    //           <div>
    //             <p>Device Tag: {device_tag}</p>
    //             <p>Current Time: {tagDateAndTimeFormat}</p>
    //             <p>Mqtt Time: {tagValue}</p>
    //           </div>
    //         );
    //         setIsNotified(true); 
    //       }
    //   }
    // }, [mqttTagData, isNotified]); 
   

  // useEffect(() => {
  //   if (getTag && Object.keys(getTag).length) {
  //     const tagdatalist = Object.keys(getTag)
  //       .flatMap((key) => {
  //         return getTag[key].map((item) => {
  //           return "Hikar/DeviceUID/" + item.device__machine__uid;
  //         });
  //       })
  //       .filter(Boolean); // Remove null values from the array
  //     setTopics(tagdatalist);
  //   }
  // }, [getTag]);

  const handleEditClick = (id, device, tag, tagValue) => {
    setEditTag(tag);
    setUpdateValueModal(true);
    setTagId(id);
    setDeviceId(device);
    setUpdateValue(tagValue)
  };

  const handleUpdateSubmitValue = async () => {

    if (!updateValue) {
      notifyError("Please fill the value");
      return;
    }


    if (updateValue > Number(editTag.high_range)) {
      notifyError(`Value cannot be greater than ${editTag.high_range}`);
      return;
    } else if (updateValue < Number(editTag.low_range)) {
      notifyError(`Value cannot be less than ${editTag.low_range}`);
      return;
    }

    const params = {
      device: deviceId,
      tag: tagId,
      publish_value: updateValue,
    };
    try {
      setLoading(true);
      const resp = await publistData(params);
      if (resp.status == 200 || resp.status == 201) {
        setUpdateValueModal(false);
        setLoading(false);
        console.log("Publish data successfully");
      }
    } catch (error) {
      setLoading(false);
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    if (tagId) {
      addInteractionData();
    }
  }, [tagId]);

  const addInteractionData = async () => {
    const params = {
      plant: param.plantid,
      tag: [tagId],
    };
    try {
      const resp = await tagInteractionData(params);
      if (resp.status == 200 || resp.status == 201) {
        console.log("Interaction tag successfully");
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };
  // const handleSwitchToggle = (id, device,newState) => {
  //   setTagId(id);
  //   setDeviceId(device);
  //   setSwitchChecked(!switchChecked)

  //   if (!machineSwitchPopup) {
  //     setMachineSwitchPopup(true);
  //   }
  // };
  // const handleSwitchToggle = (tagId) => {
  //   setSwitchChecked((prevState) => ({
  //     ...prevState,
  //     [tagId]: !prevState[tagId], // Toggle the state for the given tagId
  //   }));
  // };

  // Define the function to handle switch click
  const handleSwitchClick = (id, device, name, isChecked) => {
    console.log("ischeckData", isChecked)
    setTagId(id);
    setDeviceId(device);
    setTagName(name);
    setMachineSwitchPopup(true);
    setSwitchId(isChecked);
    // setCheckData(ischeckData)
  };

  const handleSubmit = async () => {
    const params = {
      device: deviceId,
      tag: tagId,
      publish_value: switchId == true ? 0 : 1,
    };
    try {
      setLoading(true);
      const resp = await publistData(params);
      if (resp.status === 200 || resp.status === 201) {
        setMachineSwitchPopup(false);
        setLoading(false);
        setSwitchChecked((prevState) => ({
          ...prevState,
          [tagId]: !prevState[tagId],
        }));
        console.log("Published data successfully");
      }
    } catch (error) {
      setLoading(false);
      console.log("handleSubmit", error);
    }
  };
  const handlepinClick = async (groupName, deviceID) => {
    const params = {
      plant: param.plantid,
      device: deviceID,
      tag_group_name: groupName,
    };
    try {
      const resp = await pinUnpinGroupData(params);
      if (resp.status == 200 || resp.status == 201) {
        notifySuccess(resp.data.message);
        getDeviceTagGroup();
      }
    } catch (error) {
      notifyError(error.response.data.message);
      console.log("handlesubmit", error);
    }
  };


  const mqttDataReduce = mqttTagData.reduce((map, item) => {
    map[item.device_tag] = Number(item.tagValue);
    return map;
  }, {});

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleRedirectClick = async (tagId, machineId, tagUnit, tagName,deviceUid, deviceTag) => {
    navigate(`/smarTagLiveGraph/${tagId}/company/${param.companyid}/plant/${param.plantid}`)
    const existingIndex = overViewTagClickebleData.findIndex(
      (item) => item.plantId === param.plantid
    );

    if (existingIndex !== -1) {
      const updatedTagData = overViewTagClickebleData.map((item, index) => {
        if (index === existingIndex) {
          return {
            ...item,
            machineId: machineId,
            tagUnit: tagUnit,
            tag_id: tagId,
            tagName: tagName,
            device_tag:deviceTag,
            device__uid :deviceUid
          };
        }
        return item;
      });

      dispatch(
        setSmartTagReportData({
          overViewTagClickebleData: updatedTagData,
        })
      );
    } else {
      dispatch(
        setSmartTagReportData({
          overViewTagClickebleData: [
            ...overViewTagClickebleData,
            {
              machineId: machineId,
              tagUnit: tagUnit,
              tag_id: tagId,
              plantId: param.plantid,
              tagName: tagName,
              device_tag:deviceTag,
              device__uid :deviceUid
            },
          ],
        })
      );
    }
  };

  return (
    <div>
      {getTag &&
        Object.keys(getTag)
          .filter((groupKey) => {
            const groupData = getTag[groupKey];
            const tags = groupData[Object.keys(groupData)[0]].tags;
            return tags.some((tag) =>
              tag.tag_name.toLowerCase().includes(searchQuery.toLowerCase())
            );
          })
          .map((groupKey) => {
            const groupData = getTag[groupKey];
            const groupName = Object.keys(groupData)[0];
            const tags = groupData[groupName];
            const deviceID = tags.tags[0]?.device;

            // Filter tags within the group based on the search query
            const filteredTags = tags.tags.filter((tag) =>
              tag.tag_name.toLowerCase().includes(searchQuery.toLowerCase())
            );

            return (
              <div
                key={groupKey}
                className={tagGroupVisibility[groupKey] ? "TagGroupfieldborder" : ""}
              >
                <Box className="TagGroupfield mb-2">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6>{groupName}</h6>
                    </div>
                    <div className="d-flex">
                      <div style={{ marginRight: "12px", cursor: "pointer" }}>
                        {tagGroupVisibility[groupKey] ? (
                          <KeyboardArrowUpTwoToneIcon
                            onClick={() => handleTagGroupClick(groupKey)}
                          />
                        ) : (
                          <KeyboardArrowDownIcon
                            onClick={() => handleTagGroupClick(groupKey)}
                          />
                        )}
                      </div>
                      {tags.is_pinned ? (
                        <div>
                          <img
                            src={images.pinArrow}
                            onClick={() => handlepinClick(groupName, deviceID)}
                            alt=""
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      ) : (
                        <div>
                          <img
                            src={images.unpinArrow}
                            onClick={() => handlepinClick(groupName, deviceID)}
                            alt=""
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Box>
                {tagGroupVisibility[groupKey] && (
                  <div className="compactview-box p-2">
                    {filteredTags.map((tag, index) => {
                      let tagValue =
                        mqttTagData.length && mqttTagData.find((f) => f.device_tag == tag.device_tag)?.["tagValue"] || 0;


                      if (tag.is_soft_tag && tag.soft_tag_id__formula && Object.keys(mqttDataReduce).length) {
                        if (SoftTagFormula(tag.soft_tag_id__formula, mqttDataReduce)?.softTagNotGetTag == true) {
                          tagValue = SoftTagFormula(tag.soft_tag_id__formula, mqttDataReduce)?.value
                        } else {
                          tagValue = tagValue;
                        }
                      }

                      let tagValueColor = "black";
                      let tagStatus = "";

                      if (tag.tag_read_write_type === "RD") {
                        if (
                          parseFloat(tagValue) < parseFloat(tag.setpoint_ll) ||
                          parseFloat(tagValue) < parseFloat(tag.setpoint_lo) ||
                          parseFloat(tagValue) > parseFloat(tag.setpoint_hi) ||
                          parseFloat(tagValue) > parseFloat(tag.setpoint_hh)
                        ) {
                          tagValueColor = "#E31E24";
                        } else {
                          console.log("Tag value within range");
                        }
                      }

                      if (tag.tag_read_write_type === "RW" && tag.signal_type === "DO") {
                        if (tagValue == 0) {
                            tagValueColor = "#E31E24";
                            tagStatus = "OFF";
                        } else if (tagValue == 1) {
                            tagValueColor = "#50C878";
                            tagStatus = "ON";
                        }
                    } else if (tag.signal_type === "DO" || tag.signal_type === "DI") {
                        if (["yes"].includes(tag.alert?.toLowerCase())) {
                            if (parseFloat(tagValue) === 0) {
                                tagStatus = tag.low_range;
                                tagValueColor = "#50C878";
                            } else if (parseFloat(tagValue) === 1) {
                                tagStatus = tag.high_range;
                                tagValueColor = "#E31E24";
                            }
                        } else if (["no"].includes(tag.alert?.toLowerCase())) {
                            if (parseFloat(tagValue) === 0) {
                                tagStatus = tag.low_range;
                                tagValueColor = "#AEBCD2";
                            } else if (parseFloat(tagValue) === 1) {
                                tagStatus = tag.high_range;
                                tagValueColor = "#AEBCD2";
                            }
                        } else {
                            if (tagValue == 0) {
                                tagStatus = tag.low_range ? tag.low_range : "ON";
                            } else if (tagValue == 1) {
                                tagStatus = tag.high_range ? tag.high_range : "ON";
                            }
                        }
                    }

                      return (
                        <Box key={index} className="smartTagTile" onClick={() => handleRedirectClick(tag.id,
                          tag.device,
                          tag.tag_unit,
                          tag.tag_name,
                          tag.device__machine__uid,
                          tag.device_tag)}>
                          <div className="p-2">
                            <div>
                              <h6 style={{ fontSize: "15px", fontWeight: 500 }}>
                                {tag.tag_name}
                              </h6>
                              <div className="d-flex justify-content-between">
                                <h6
                                  style={{
                                    color: tagValueColor,
                                    fontWeight: 800,
                                  }}
                                >
                                    {typeof tagValue === "number" && tagValue % 1 !== 0 && tagValue !== 0 && tagValue !== 1
                                    ? tagValue.toFixed(5)
                                    : tagValue === 0 || tagValue === 1
                                    ? tagStatus || tagValue
                                    : tagStatus || tagValue}
                        
                                </h6>
                                {tag.tag_read_write_type === "RW" &&
                                  tag.signal_type === "DO" && (
                                    <Switch
                                      checked={tagValueColor === "#50C878"}
                                      onClick={(e) => {
                                        e.stopPropagation(); // Prevents triggering the Box click
                                        handleSwitchClick(tag.id, tag.device, tag.tag_name, tagValueColor === "#50C878",e.target.checked);
                                      }}
                                      sx={{
                                        "& .MuiSwitch-thumb": {
                                          bgcolor: "#828282",
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#D9D9E5 !important",
                                        },
                                        "& .Mui-checked": {
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#50C878 !important",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#CEE6E1 !important",
                                          },
                                        },
                                      }}
                                      className="switch-toggle"
                                    />
                                  )}
                                {tag.tag_read_write_type === "RW" &&
                                  (tag.signal_type === "AI" || tag.signal_type === "AO") ? (
                                  <img
                                    src={images.editbtn}
                                    alt=""
                                    style={{
                                      height: "20px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevents triggering the Box click
                                      handleEditClick(tag.id, tag.device, tag, tagValue);
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </Box>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}

      {getTagData &&
        Object.keys(getTagData)
          .filter((groupKey) => {
            const groupData = getTagData[groupKey];
            const tags = groupData[Object.keys(groupData)[0]];
            return tags.some((tag) =>
              tag.tag_name.toLowerCase().includes(searchQuery.toLowerCase())
            );
          })
          .map((groupKey) => {
            const groupData = getTagData[groupKey];
            const groupName = Object.keys(groupData)[0];
            const tags = groupData[groupName];

            // Filter tags within the group based on the search query
            const filteredTags = tags.filter((tag) =>
              tag.tag_name.toLowerCase().includes(searchQuery.toLowerCase())
            );

            return (
              <div key={groupKey} className="TagGroupfieldborder">
                <div className="compactview-box p-2">
                  {filteredTags.map((tag, index) => {
                    let tagValue =
                      mqttTagData.length && mqttTagData.find((f) => f.device_tag == tag.device_tag)?.["tagValue"] || 0;


                    if (tag.is_soft_tag && tag.soft_tag_id__formula && Object.keys(mqttDataReduce).length) {
                      if (SoftTagFormula(tag.soft_tag_id__formula, mqttDataReduce)?.softTagNotGetTag == true) {
                        tagValue = SoftTagFormula(tag.soft_tag_id__formula, mqttDataReduce)?.value
                      } else {
                        tagValue = tagValue;
                      }
                    }

                    let tagValueColor = "black";
                    let tagStatus = "";

                    if (tag.tag_read_write_type === "RD") {
                      if (
                        parseFloat(tagValue) < parseFloat(tag.setpoint_ll) ||
                        parseFloat(tagValue) < parseFloat(tag.setpoint_lo) ||
                        parseFloat(tagValue) > parseFloat(tag.setpoint_hi) ||
                        parseFloat(tagValue) > parseFloat(tag.setpoint_hh)
                      ) {
                        tagValueColor = "#E31E24";
                      } else {
                        console.log("Tag value within range");
                      }
                    }

                    if (tag.tag_read_write_type === "RW" && tag.signal_type === "DO") {
                      if (tagValue == 0) {
                          tagValueColor = "#E31E24";
                          tagStatus = "OFF";
                      } else if (tagValue == 1) {
                          tagValueColor = "#50C878";
                          tagStatus = "ON";
                      }
                  } else if (tag.signal_type === "DO" || tag.signal_type === "DI") {
                      if (["yes"].includes(tag.alert?.toLowerCase())) {
                          if (parseFloat(tagValue) === 0) {
                              tagStatus = tag.low_range;
                              tagValueColor = "#50C878";
                          } else if (parseFloat(tagValue) === 1) {
                              tagStatus = tag.high_range;
                              tagValueColor = "#E31E24";
                          }
                      } else if (["no"].includes(tag.alert?.toLowerCase())) {
                          if (parseFloat(tagValue) === 0) {
                              tagStatus = tag.low_range;
                              tagValueColor = "#AEBCD2";
                          } else if (parseFloat(tagValue) === 1) {
                              tagStatus = tag.high_range;
                              tagValueColor = "#AEBCD2";
                          }
                      } else {
                          if (tagValue == 0) {
                              tagStatus = tag.low_range ? tag.low_range : "ON";
                          } else if (tagValue == 1) {
                              tagStatus = tag.high_range ? tag.high_range : "ON";
                          }
                      }
                  }
                    return (
                      <Card key={index} className="smartTagTile" onClick={() => handleRedirectClick(  tag.id,
                        tag.device,
                        tag.tag_unit,
                        tag.tag_name,
                        tag.device__machine__uid,
                        tag.device_tag)}>
                        <div className="p-2">
                          <div>
                            <div>
                              <h6 style={{ fontSize: "15px", fontWeight: 500 }}>
                                {tag.tag_name}
                              </h6>
                            </div>
                            <div className="d-flex justify-content-between">
                              <h6
                                style={{
                                  color: tagValueColor,
                                  fontWeight: 800,
                                }}
                              >
                                {typeof tagValue === "number" && tagValue % 1 !== 0 && tagValue !== 0 && tagValue !== 1
                                    ? tagValue.toFixed(5)
                                    : tagValue === 0 || tagValue === 1
                                    ? tagStatus || tagValue
                                    : tagStatus || tagValue}
                              </h6>
                              {tag.tag_read_write_type === "RW" && tag.signal_type === "DO" && (
                                <Switch
                                  checked={tagValueColor === "#50C878"}
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevents triggering the Box click
                                    handleSwitchClick(tag.id, tag.device, tag.tag_name, tagValueColor === "#50C878");
                                  }}
                                   
                                  sx={{
                                    "& .MuiSwitch-thumb": {
                                      bgcolor: "#828282",
                                    },
                                    "& .MuiSwitch-track": {
                                      bgcolor: "#D9D9E5 !important",
                                    },
                                    "& .Mui-checked": {
                                      "& .MuiSwitch-thumb": {
                                        bgcolor: "#50C878 !important",
                                      },
                                      "& .MuiSwitch-track": {
                                        bgcolor: "#CEE6E1 !important",
                                      },
                                    },
                                  }}
                                  className="switch-toggle"
                                />
                              )}
                              {tag.tag_read_write_type === "RW" &&
                                (tag.signal_type === "AI" || tag.signal_type === "AO") ? (
                                <img
                                  src={images.editbtn}
                                  alt=""
                                  style={{
                                    height: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevents triggering the Box click
                                    handleEditClick(tag.id, tag.device, tag, tagValue);
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </Card>
                    );
                  })}
                </div>
              </div>
            );
          })}

      {updateValueModal &&
        <Card
          className="addDeviceModal-card"
          style={{ width: "440px" }}
        >
          <Box className="addDeviceModal-titleWrap">
            <Typography
              variant="h5"
              gutterBottom
              className="addDeviceModal-title"
            >
              Update Value
            </Typography>
            <img
              src={images.closeIcon}
              alt=""
              style={{
                width: "30px",
                cursor: "pointer",
                backgroundColor: "white",
              }}
              className="closeImg"
              onClick={() => setUpdateValueModal(false)}
            />
          </Box>

          <Box className="addDeviceModal-innerWrap">
            <Stack spacing={2} pt="10px" className="pt-4">
              <TextField
                id="filled-basic"
                name="group_name"
                label="Enter Value"
                variant="filled"
                className="customeTextField deviceManagement"
                type="number" // Set the type to "number" to allow decimal input
                onChange={(e) => setUpdateValue(e.target.value)}
                value={updateValue}
              />
            </Stack>
            <Box className="accountSecurity-cardButtons deviceManagement">
              <button
                className="addDeviceBtn"
                onClick={handleUpdateSubmitValue}
              >
                Update
              </button>
            </Box>
          </Box>
        </Card>
      }
      {machineSwitchPopup && (
        <Card className="deleteConfirmation-Modal">
          <Typography
            variant="h6"
            gutterBottom
            className="deleteConfirmation-title"
          >
            {tagName} {switchId ? "To OFF" : "To ON"} ?
          </Typography>
          <Box className="deleteConfirmation-btnWrap">
            <button
              className="deleteConfirmation-Yesbtn"
              onClick={handleSubmit}
            >
              Yes
            </button>
            <button
              className="deleteConfirmation-Nobtn"
              onClick={() => setMachineSwitchPopup(false)}
            >
              No
            </button>
          </Box>
        </Card>
      )}
      {isLoading && <Loader />}
    </div>
  );
};
export default TagGroup;
