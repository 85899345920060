import { Box, Card, Stack, Switch, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpTwoToneIcon from "@mui/icons-material/KeyboardArrowUpTwoTone";
import { images } from "../../../../config";
import {
  pinUnpinGroupData,
  publistData,
  tagInteractionData,
} from "../../services";
import Loader from "../../../../components/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { typeOf } from "mathjs";
import { SoftTagFormula } from "../../softTag/SoftTagFormula";
import { useDispatch, useSelector } from "react-redux";
import { setSmartTagReportData } from "../Slice";

const TagGroupDetail = ({
  getTag,
  searchQuery,
  getTagData,
  getDeviceTagGroup,
  setMqttTagPreviousvalue,
  mqttTagPreviousvalue,
  sliderRealTimeTagData,
  tagStatus1,
}) => {
  const [tagGroupVisibility, setTagGroupVisibility] = useState(
    Array(10).fill(false) // Adjust the size based on your number of Tag Groups
  );
  // const [prevTagValue, setPrevTagValue] = useState({});
  const [updateValueModal, setUpdateValueModal] = useState(false);
  const [updateValue, setUpdateValue] = useState("");
  const [tagId, setTagId] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [prevTagValue, setPrevTagValue] = useState({});
  const [machineSwitchPopup, setMachineSwitchPopup] = useState(false);
  const [switchChecked, setSwitchChecked] = useState({});
  const [tagName, setTagName] = useState("");
  const [switchId, setSwitchId] = useState("");
  const [editTag, setEditTag] = useState(null);

  const param = useParams();
  const { overViewTagClickebleData } = useSelector(
    (state) => state.smartTagReportSlice
  );

  const handleTagGroupClick = (i) => {
    const updatedVisibility = [...tagGroupVisibility];
    updatedVisibility[i] = !updatedVisibility[i];
    setTagGroupVisibility(updatedVisibility);
  };
  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });
  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleUpdateSubmitValue = async () => {
    if (!updateValue) {
      notifyError("Please fill the value");
    }

    if (updateValue > Number(editTag.high_range)) {
      notifyError(`Value cannot be greater than ${editTag.high_range}`);
      return;
    } else if (updateValue < Number(editTag.low_range)) {
      notifyError(`Value cannot be less than ${editTag.low_range}`);
      return;
    }

    const params = {
      device: deviceId,
      tag: tagId,
      publish_value: updateValue,
    };
    try {
      setLoading(true);
      const resp = await publistData(params);
      if (resp.status == 200 || resp.status == 201) {
        setUpdateValueModal(false);
        setLoading(false);
        console.log("Publish data successfully");
      }
    } catch (error) {
      setLoading(false);
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    if (tagId) {
      addInteractionData();
    }
  }, [tagId]);
  const addInteractionData = async () => {
    const params = {
      plant: param.plantid,
      tag: [tagId],
    };
    try {
      const resp = await tagInteractionData(params);
      if (resp.status == 200 || resp.status == 201) {
        console.log("Interaction tag successfully");
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const handleEditClick = (id, device, tag, tagValue) => {
    console.log("tagtagtagtagtag", tag);
    setEditTag(tag);
    setUpdateValueModal(true);
    setTagId(id);
    setDeviceId(device);
    setUpdateValue(tagValue);
  };

  const handleSwitchClick = (id, device, name, isChecked) => {
    setTagId(id);
    setDeviceId(device);
    setTagName(name);
    setMachineSwitchPopup(true);
    setSwitchId(isChecked);
  };

  const handleSubmit = async () => {
    const params = {
      device: deviceId,
      tag: tagId,
      publish_value: switchId == true ? 0 : 1,
    };
    try {
      setLoading(true);
      const resp = await publistData(params);
      if (resp.status === 200 || resp.status === 201) {
        setMachineSwitchPopup(false);
        setLoading(false);
        setSwitchChecked((prevState) => ({
          ...prevState,
          [tagId]: !prevState[tagId],
        }));
        console.log("Published data successfully");
      }
    } catch (error) {
      setLoading(false);
      console.log("handleSubmit", error);
    }
  };
  const handlepinClick = async (groupName, deviceID) => {
    const params = {
      plant: param.plantid,
      device: deviceID,
      tag_group_name: groupName,
    };
    try {
      const resp = await pinUnpinGroupData(params);
      if (resp.status == 200 || resp.status == 201) {
        notifySuccess(resp.data.message);
        getDeviceTagGroup();
      }
    } catch (error) {
      notifyError(error.response.data.message);
      console.log("handlesubmit", error);
    }
  };

  const mqttDataReduce = sliderRealTimeTagData.reduce((map, item) => {
    map[item.device_tag] = Number(item.tagValue);
    return map;
  }, {});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRedirectClick = async (tagId, machineId, tagUnit, tagName,deviceUid, deviceTag,softTag,softTagFormula) => {
    navigate(`/smarTagLiveGraph/${tagId}/company/${param.companyid}/plant/${param.plantid}`)
    const existingIndex = overViewTagClickebleData.findIndex(
      (item) => item.plantId === param.plantid
    );

    if (existingIndex !== -1) {
      const updatedTagData = overViewTagClickebleData.map((item, index) => {
        if (index === existingIndex) {
          return {
            ...item,
            machineId: machineId,
            tagUnit: tagUnit,
            tag_id: tagId,
            tagName: tagName,
            device_tag:deviceTag,
            device__uid :deviceUid,
            softTag : softTag,
            softTagFormula :softTagFormula,
            color :""
          };
        }
        return item;
      });

      dispatch(
        setSmartTagReportData({
          overViewTagClickebleData: updatedTagData,
        })
      );
    } else {
      dispatch(
        setSmartTagReportData({
          overViewTagClickebleData: [
            ...overViewTagClickebleData,
            {
              machineId: machineId,
              tagUnit: tagUnit,
              tag_id: tagId,
              plantId: param.plantid,
              tagName: tagName,
              device_tag:deviceTag,
              device__uid :deviceUid,
              softTag : softTag,
              softTagFormula :softTagFormula,
              color : ""
            },
          ],
        })
      );
    }
  };

  useEffect(() => {
    const findPlatTag = overViewTagClickebleData?.length
      ? overViewTagClickebleData.find((f) => f.plantId == param.plantid)?.[
          "tag_id"
        ] || false
      : false;
      console.log("findPlatTag",findPlatTag)
    if (findPlatTag) {
      getTagIdApi(findPlatTag);
    }
  }, [overViewTagClickebleData]);

  const getTagIdApi = async (tagId) => {
    const param = {
      tag: tagId,
    };
    try {
      const resp = await getTagData(param);
      if (resp.status == 200 || resp.status == 201) {
        dispatch(
          setSmartTagReportData({
            deviceID: resp.data.payload.tag_config_data[0].device,
          })
        );
        dispatch(
          setSmartTagReportData({
            tagUNIT: resp.data.payload.tag_config_data[0].tag_unit,
          })
        );

        dispatch(
          setSmartTagReportData({
            smartTagHIRange: resp.data.payload.tag_config_data[0].high_range,
          })
        );
        dispatch(
          setSmartTagReportData({
            smartTagLowRange: resp.data.payload.tag_config_data[0].low_range,
          })
        );
        dispatch(
          setSmartTagReportData({
            smartTagtagMachineName:
              resp.data.payload.tag_config_data[0]
                .device__machine__machine_name,
          })
        );
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  return (
    <div>
      {getTag &&
        Object.keys(getTag)
          .filter((groupKey) => {
            const groupData = getTag[groupKey];
            const tags = groupData[Object.keys(groupData)[0]].tags;

            return tags.some((tag) =>
              tag.tag_name.toLowerCase().includes(searchQuery.toLowerCase())
            );
          })
          .map((groupKey) => {
            const groupData = getTag[groupKey];
            const groupName = Object.keys(groupData)[0];
            const tags = groupData[groupName];

            // Filter tags within the group based on the search query
            const filteredTags = tags.tags.filter((tag) =>
              tag.tag_name.toLowerCase().includes(searchQuery.toLowerCase())
            );

            const deviceID = tags.tags[0]?.device;

            return (
              <div
                key={groupKey}
                className={
                  tagGroupVisibility[groupKey] ? "TagGroupfieldborder" : ""
                }
              >
                <Box className="TagGroupfield mb-2">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6>{groupName}</h6>
                    </div>
                    <div className="d-flex">
                      <div style={{ marginRight: "12px", cursor: "pointer" }}>
                        {tagGroupVisibility[groupKey] ? (
                          <KeyboardArrowUpTwoToneIcon
                            onClick={() => handleTagGroupClick(groupKey)}
                          />
                        ) : (
                          <KeyboardArrowDownIcon
                            onClick={() => handleTagGroupClick(groupKey)}
                          />
                        )}
                      </div>
                      {tags.is_pinned ? (
                        <div>
                          <img
                            src={images.pinArrow}
                            onClick={() => handlepinClick(groupName, deviceID)}
                            alt=""
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      ) : (
                        <div>
                          <img
                            src={images.unpinArrow}
                            onClick={() => handlepinClick(groupName, deviceID)}
                            alt=""
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Box>
                {tagGroupVisibility[groupKey] && (
                  <div className="compactview-box p-2">
                    {filteredTags.map((tag, index) => {
                      let tagValueColor = "black";
                      let tagStatus = "";

                      let tagValue = sliderRealTimeTagData.length
                        ? sliderRealTimeTagData.find(
                            (f) =>
                              f.device__uid == tag.device__machine__uid &&
                              f.device_tag == tag.device_tag
                          )?.["tagValue"] || 0.0
                        : 0.0;

                      if (
                        tag.is_soft_tag &&
                        tag.soft_tag_id__formula &&
                        Object.keys(mqttDataReduce).length
                      ) {
                        if (
                          SoftTagFormula(
                            tag.soft_tag_id__formula,
                            mqttDataReduce
                          )?.softTagNotGetTag == true
                        ) {
                          tagValue = SoftTagFormula(
                            tag.soft_tag_id__formula,
                            mqttDataReduce
                          )?.value;
                        } else {
                          tagValue = tagValue;
                        }
                      }

                      const findStatus = tagStatus1.length
                        ? tagStatus1.find((f) => f.dvName == tag.device_tag)
                        : 0;

                      if (
                        tag.tag_read_write_type === "RW" &&
                        tag.signal_type === "DO"
                      ) {
                        if (tagValue == 0) {
                          tagValueColor = "#E31E24";
                          tagStatus = "OFF";
                        } else if (tagValue == 1) {
                          tagValueColor = "#50C878";
                          tagStatus = "ON";
                        }
                      } else if (
                        tag.signal_type === "DO" ||
                        tag.signal_type === "DI"
                      ) {
                        if (["yes"].includes(tag.alert?.toLowerCase())) {
                          if (parseFloat(tagValue) === 0) {
                            tagStatus = tag.low_range;
                            tagValueColor = "#50C878";
                          } else if (parseFloat(tagValue) === 1) {
                            tagStatus = tag.high_range;
                            tagValueColor = "#E31E24";
                          }
                        } else if (["no"].includes(tag.alert?.toLowerCase())) {
                          if (parseFloat(tagValue) === 0) {
                            tagStatus = tag.low_range;
                            tagValueColor = "#AEBCD2";
                          } else if (parseFloat(tagValue) === 1) {
                            tagStatus = tag.high_range;
                            tagValueColor = "#AEBCD2";
                          }
                        } else {
                          if (tagValue == 0) {
                            tagStatus = tag.low_range ? tag.low_range : "ON";
                          } else if (tagValue == 1) {
                            tagStatus = tag.high_range ? tag.high_range : "ON";
                          }
                        }
                      }

                      const shouldShowUnit = ![
                        "ON",
                        "OFF",
                        "Active",
                        "InActive",
                      ].includes(tagStatus);
                 
                      return (
                        <Card
                          className="detailviewgroup-tile"
                          key={index}
                          onClick={() =>
                            handleRedirectClick(
                              tag.id,
                              tag.device,
                              tag.tag_unit,
                              tag.tag_name,
                              tag.device__machine__uid,
                              tag.device_tag,
                              tag.is_soft_tag,
                              tag.soft_tag_id__formula

                            )
                          }
                        >
                          <div className="d-flex justify-content-between">
                            <div>
                              <h6 style={{ fontSize: "15px", height: "12px" }}>
                                {tag.tag_comment}
                              </h6>
                            </div>
                            <div className="d-flex gap-1">
                              {tagValue > parseFloat(tag.setpoint_hh) ? (
                                <>
                                  <div className="triangle-image-container">
                                    <img
                                      src={images.triandleFillIcon}
                                      alt=""
                                      className="triangle-image"
                                    />
                                    <span className="tooltip">HH alarm</span>
                                  </div>
                                  <div className="triangle-image-container">
                                    <img
                                      src={images.triandleFillIcon}
                                      alt=""
                                      className="triangle-image"
                                    />
                                    <span className="tooltip">HH alarm</span>
                                  </div>
                                </>
                              ) : tagValue > parseFloat(tag.setpoint_hi) ? (
                                <div className="triangle-image-container">
                                  <img
                                    src={images.triandleFillIcon}
                                    alt=""
                                    className="triangle-image"
                                  />
                                  <span className="tooltip">HI alarm</span>
                                </div>
                              ) : tagValue < parseFloat(tag.setpoint_ll) ? (
                                <>
                                  <div className="triangle-image-container">
                                    <img
                                      src={images.triangleDownIcon}
                                      alt=""
                                      className="triangle-image"
                                    />
                                    <span className="tooltip">LL alarm</span>
                                  </div>
                                  <div className="triangle-image-container">
                                    <img
                                      src={images.triangleDownIcon}
                                      alt=""
                                      className="triangle-image"
                                    />
                                    <span className="tooltip">LL alarm</span>
                                  </div>
                                </>
                              ) : tagValue < parseFloat(tag.setpoint_lo) ? (
                                <div className="triangle-image-container">
                                  <img
                                    src={images.triangleDownIcon}
                                    alt=""
                                    className="triangle-image"
                                  />
                                  <span className="tooltip">LO alarm</span>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="d-flex justify-content-between">
                            <div
                              className="d-flex"
                              style={{ gap: "4px", marginBottom: "7px" }}
                            >
                              <h4
                                className="pt-3 mb-2"
                                style={{
                                  color: tagValueColor,
                                }}
                              >
                                <b>{typeof tagValue === "number" && tagValue % 1 !== 0 && tagValue !== 0 && tagValue !== 1
                                    ? tagValue.toFixed(5)
                                    : tagValue === 0 || tagValue === 1
                                    ? tagStatus || tagValue
                                    : tagStatus || tagValue}</b>
                              </h4>
                              {!(
                                tag.signal_type === "DO" ||
                                tag.signal_type === "DI"
                              ) && (
                                <p
                                  style={{
                                    fontSize: "11px",
                                    marginBottom: 0,
                                    marginTop: "26px",
                                  }}
                                >
                                  {tag.tag_unit}
                                </p>
                              )}
                            </div>
                            <div>
                              {tag.tag_read_write_type === "RW" &&
                                tag.signal_type === "DO" && (
                                  <Switch
                                    checked={tagValueColor === "#50C878"}
                                    onClick={(e) => {
                                      e.stopPropagation(); // This will prevent redirection
                                      handleSwitchClick(tag.id, tag.device, tag.tag_name, tagValueColor === "#50C878");
                                    }}
                                    sx={{
                                      "& .MuiSwitch-thumb": {
                                        bgcolor: "#828282",
                                      },
                                      "& .MuiSwitch-track": {
                                        bgcolor: "#D9D9E5 !important",
                                      },
                                      "& .Mui-checked": {
                                        "& .MuiSwitch-thumb": {
                                          bgcolor: "#50C878 !important",
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#CEE6E1 !important",
                                        },
                                      },
                                    }}
                                    className="switch-toggle mt-2"
                                  />
                                )}
                              {tag.tag_read_write_type == "RW" ? (
                                tag.signal_type === "AI" ||
                                tag.signal_type === "AO" ? (
                                  <img
                                    src={images.editbtn}
                                    alt=""
                                    style={{
                                      height: "20px",
                                      marginTop: "19px",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation(); // This will prevent redirection
                                      handleEditClick(tag.id, tag.device, tag, tagValue);
                                    }}
                                  />
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="d-flex justify-content-between">
                            <h6 style={{ fontWeight: 400, fontSize: "14px" }}>
                              {tag.tag_name}
                            </h6>
                            {!(
                              tag.signal_type === "DO" ||
                              tag.signal_type === "DI"
                            ) && (
                              <div
                                style={{ marginTop: "-20px", display: "flex" }}
                              >
                                {tag.is_soft_tag && tag.soft_tag_id__formula ? (
                                  tagStatus1
                                    .find(
                                      (status) =>
                                        status.formula ===
                                        tag.soft_tag_id__formula
                                    )
                                    ?.statusValue.toFixed(2) < 0 ? (
                                    <img
                                      src={images.smartTagDownArrow}
                                      alt="Arrow"
                                      style={{ height: "36px" }}
                                    />
                                  ) : tagStatus1
                                      .find(
                                        (status) =>
                                          status.formula ===
                                          tag.soft_tag_id__formula
                                      )
                                      ?.statusValue.toFixed(2) > 0 ? (
                                    <img
                                      src={images.smartTagUpArrow}
                                      alt="Arrow"
                                      style={{ height: "36px" }}
                                    />
                                  ) : null
                                ) : findStatus &&
                                  findStatus.statusValue.toFixed(2) < 0 ? (
                                  <img
                                    src={images.smartTagDownArrow}
                                    alt="Arrow"
                                    style={{ height: "36px" }}
                                  />
                                ) : findStatus &&
                                  findStatus.statusValue.toFixed(2) > 0 ? (
                                  <img
                                    src={images.smartTagUpArrow}
                                    alt="Arrow"
                                    style={{ height: "36px" }}
                                  />
                                ) : null}


                                <h6 className="mt-3">
                                  <b>
                                  {tag.is_soft_tag && tag.soft_tag_id__formula
                                      ? (
                                          tagStatus1.find(
                                            (status) =>
                                              status.formula ==
                                              tag.soft_tag_id__formula
                                          )?.statusValue || 0.0
                                        ).toFixed(2)
                                      : findStatus
                                      ? (findStatus.statusValue || 0.0).toFixed(
                                          2
                                        )
                                      : (0.0).toFixed(2)}
                                    %
                                  </b>
                                </h6>
                              </div>
                            )}
                          </div>
                        </Card>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}

      {getTagData &&
        Object.keys(getTagData)
          .filter((groupKey) => {
            const groupData = getTagData[groupKey];
            const tags = groupData[Object.keys(groupData)[0]];
            // Filter groups that contain tags matching the search query
            return tags.some((tag) =>
              tag.tag_name.toLowerCase().includes(searchQuery.toLowerCase())
            );
          })
          .map((groupKey) => {
            const groupData = getTagData[groupKey];
            const groupName = Object.keys(groupData)[0];
            const tags = groupData[groupName];

            // Filter tags within the group based on the search query
            const filteredTags = tags.filter((tag) =>
              tag.tag_name.toLowerCase().includes(searchQuery.toLowerCase())
            );

            return (
              <div key={groupKey} className="TagGroupfieldborder">
                <div className="compactview-box p-2">
                  {filteredTags.map((tag, index) => {
                    let tagValueColor = "black";
                    let tagStatus = "";

                    let tagValue = sliderRealTimeTagData.length
                      ? sliderRealTimeTagData.find(
                          (f) =>
                            f.device__uid == tag.device__machine__uid &&
                            f.device_tag == tag.device_tag
                        )?.["tagValue"] || 0.0
                      : 0.0;

                    if (
                      tag.is_soft_tag &&
                      tag.soft_tag_id__formula &&
                      Object.keys(mqttDataReduce).length
                    ) {
                      if (
                        SoftTagFormula(tag.soft_tag_id__formula, mqttDataReduce)
                          ?.softTagNotGetTag == true
                      ) {
                        tagValue = SoftTagFormula(
                          tag.soft_tag_id__formula,
                          mqttDataReduce
                        )?.value;
                      } else {
                        tagValue = tagValue;
                      }
                    }

                    if (
                      tag.tag_read_write_type === "RW" &&
                      tag.signal_type === "DO"
                    ) {
                      if (tagValue == 0) {
                        tagValueColor = "#E31E24";
                        tagStatus = "OFF";
                      } else if (tagValue == 1) {
                        tagValueColor = "#50C878";
                        tagStatus = "ON";
                      }
                    } else if (
                      tag.signal_type === "DO" ||
                      tag.signal_type === "DI"
                    ) {
                      if (["yes"].includes(tag.alert?.toLowerCase())) {
                        if (parseFloat(tagValue) === 0) {
                          tagStatus = tag.low_range;
                          tagValueColor = "#50C878";
                        } else if (parseFloat(tagValue) === 1) {
                          tagStatus = tag.high_range;
                          tagValueColor = "#E31E24";
                        }
                      } else if (["no"].includes(tag.alert?.toLowerCase())) {
                        if (parseFloat(tagValue) === 0) {
                          tagStatus = tag.low_range;
                          tagValueColor = "#AEBCD2";
                        } else if (parseFloat(tagValue) === 1) {
                          tagStatus = tag.high_range;
                          tagValueColor = "#AEBCD2";
                        }
                      } else {
                        if (tagValue == 0) {
                          tagStatus = tag.low_range ? tag.low_range : "ON";
                        } else if (tagValue == 1) {
                          tagStatus = tag.high_range ? tag.high_range : "ON";
                        }
                      }
                    }

                    const findStatus = tagStatus1.length
                      ? tagStatus1.find((f) => f.dvName == tag.device_tag)
                      : 0;

                    const shouldShowUnit = ![
                      "ON",
                      "OFF",
                      "Active",
                      "InActive",
                    ].includes(tagStatus);
                    return (
                      <Card
                        className="detailviewgroup-tile"
                        key={index}
                        onClick={() => handleRedirectClick(  tag.id,
                          tag.device,
                          tag.tag_unit,
                          tag.tag_name,
                          tag.device__machine__uid,
                          tag.device_tag,
                          tag.is_soft_tag,
                          tag.soft_tag_id__formula
                        )}
                      >
                        <div className="d-flex justify-content-between">
                          <div>
                            <h6 style={{ fontSize: "15px", height: "12px" }}>
                              {tag.tag_comment}
                            </h6>
                          </div>
                          <div className="d-flex gap-1">
                            {tagValue > parseFloat(tag.setpoint_hh) ? (
                              <>
                                <div className="triangle-image-container">
                                  <img
                                    src={images.triandleFillIcon}
                                    alt=""
                                    className="triangle-image"
                                  />
                                  <span className="tooltip">HH alarm</span>
                                </div>
                                <div className="triangle-image-container">
                                  <img
                                    src={images.triandleFillIcon}
                                    alt=""
                                    className="triangle-image"
                                  />
                                  <span className="tooltip">HH alarm</span>
                                </div>
                              </>
                            ) : tagValue > parseFloat(tag.setpoint_hi) ? (
                              <div className="triangle-image-container">
                                <img
                                  src={images.triandleFillIcon}
                                  alt=""
                                  className="triangle-image"
                                />
                                <span className="tooltip">HI alarm</span>
                              </div>
                            ) : tagValue < parseFloat(tag.setpoint_ll) ? (
                              <>
                                <div className="triangle-image-container">
                                  <img
                                    src={images.triangleDownIcon}
                                    alt=""
                                    className="triangle-image"
                                  />
                                  <span className="tooltip">LL alarm</span>
                                </div>
                                <div className="triangle-image-container">
                                  <img
                                    src={images.triangleDownIcon}
                                    alt=""
                                    className="triangle-image"
                                  />
                                  <span className="tooltip">LL alarm</span>
                                </div>
                              </>
                            ) : tagValue < parseFloat(tag.setpoint_lo) ? (
                              <div className="triangle-image-container">
                                <img
                                  src={images.triangleDownIcon}
                                  alt=""
                                  className="triangle-image"
                                />
                                <span className="tooltip">LO alarm</span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="d-flex justify-content-between">
                          <div
                            className="d-flex"
                            style={{ gap: "4px", marginBottom: "7px" }}
                          >
                            <h4
                              className="pt-3 mb-2"
                              style={{
                                color: tagValueColor,
                              }}
                            >
                              <b>
                              {typeof tagValue === "number" && tagValue % 1 !== 0 && tagValue !== 0 && tagValue !== 1
                                    ? tagValue.toFixed(5)
                                    : tagValue === 0 || tagValue === 1
                                    ? tagStatus || tagValue
                                    : tagStatus || tagValue}
                              </b>
                            </h4>
                            {!(
                              tag.signal_type === "DO" ||
                              tag.signal_type === "DI"
                            ) && (
                              <p
                                style={{
                                  fontSize: "11px",
                                  marginBottom: 0,
                                  marginTop: "26px",
                                }}
                              >
                                {tag.tag_unit}
                              </p>
                            )}
                          </div>
                          <div>
                            {tag.tag_read_write_type === "RW" &&
                              tag.signal_type === "DO" && (
                                <Switch
                                  checked={tagValueColor === "#50C878"}
                                  onClick={() =>
                                    handleSwitchClick(
                                      tag.id,
                                      tag.device,
                                      tag.tag_name,
                                      tagValueColor === "#50C878"
                                    )
                                  }
                                  sx={{
                                    "& .MuiSwitch-thumb": {
                                      bgcolor: "#828282",
                                    },
                                    "& .MuiSwitch-track": {
                                      bgcolor: "#D9D9E5 !important",
                                    },
                                    "& .Mui-checked": {
                                      "& .MuiSwitch-thumb": {
                                        bgcolor: "#50C878 !important",
                                      },
                                      "& .MuiSwitch-track": {
                                        bgcolor: "#CEE6E1 !important",
                                      },
                                    },
                                  }}
                                  className="switch-toggle mt-2"
                                />
                              )}
                            {tag.tag_read_write_type == "RW" ? (
                              tag.signal_type === "AI" ||
                              tag.signal_type === "AO" ? (
                                <img
                                  src={images.editbtn}
                                  alt=""
                                  style={{
                                    height: "20px",
                                    marginTop: "19px",
                                  }}
                                  onClick={() =>
                                    handleEditClick(
                                      tag.id,
                                      tag.device,
                                      tag,
                                      tagValue
                                    )
                                  }
                                />
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="d-flex justify-content-between">
                          <h6 style={{ fontWeight: 400, fontSize: "14px" }}>
                            {tag.tag_name}
                          </h6>
                          {!(
                            tag.signal_type === "DO" || tag.signal_type === "DI"
                          ) && (
                            <div
                              style={{ marginTop: "-20px", display: "flex" }}
                            >
                              {/* {findStatus ? (
                                findStatus.statusValue.toFixed(2) < 0 ? (
                                  <img
                                    src={images.smartTagDownArrow}
                                    alt="Arrow"
                                    style={{ height: "36px" }}
                                  />
                                ) : findStatus.statusValue.toFixed(2) !== 0 ? (
                                  <img
                                    src={images.smartTagUpArrow}
                                    alt="Arrow"
                                    style={{ height: "36px" }}
                                  />
                                ) : null
                              ) : null} */}
  {tag.is_soft_tag && tag.soft_tag_id__formula ? (
                                  tagStatus1
                                    .find(
                                      (status) =>
                                        status.formula ===
                                        tag.soft_tag_id__formula
                                    )
                                    ?.statusValue.toFixed(2) < 0 ? (
                                    <img
                                      src={images.smartTagDownArrow}
                                      alt="Arrow"
                                      style={{ height: "36px" }}
                                    />
                                  ) : tagStatus1
                                      .find(
                                        (status) =>
                                          status.formula ===
                                          tag.soft_tag_id__formula
                                      )
                                      ?.statusValue.toFixed(2) > 0 ? (
                                    <img
                                      src={images.smartTagUpArrow}
                                      alt="Arrow"
                                      style={{ height: "36px" }}
                                    />
                                  ) : null
                                ) : findStatus &&
                                  findStatus.statusValue.toFixed(2) < 0 ? (
                                  <img
                                    src={images.smartTagDownArrow}
                                    alt="Arrow"
                                    style={{ height: "36px" }}
                                  />
                                ) : findStatus &&
                                  findStatus.statusValue.toFixed(2) > 0 ? (
                                  <img
                                    src={images.smartTagUpArrow}
                                    alt="Arrow"
                                    style={{ height: "36px" }}
                                  />
                                ) : null}
                              <h6 className="mt-3">
                                <b>
                                {tag.is_soft_tag && tag.soft_tag_id__formula
                                      ? (
                                          tagStatus1.find(
                                            (status) =>
                                              status.formula ==
                                              tag.soft_tag_id__formula
                                          )?.statusValue || 0.0
                                        ).toFixed(2)
                                      : findStatus
                                      ? (findStatus.statusValue || 0.0).toFixed(
                                          2
                                        )
                                      : (0.0).toFixed(2)}
                                    %
                                </b>
                              </h6>
                            </div>
                          )}
                          {/* <div style={{ marginTop: "-20px", display: "flex" }}>
                            {findStatus ? (
                              findStatus.statusValue.toFixed(2) < 0 ? (
                                <img
                                  src={images.smartTagDownArrow}
                                  alt="Arrow"
                                  style={{ height: "36px" }}
                                />
                              ) : findStatus.statusValue.toFixed(2) != 0 ? (
                                <img
                                  src={images.smartTagUpArrow}
                                  alt="Arrow"
                                  style={{ height: "36px" }}
                                />
                              ) : null
                            ) : null}

                            <h6 className="mt-3">
                              <b>
                                {findStatus
                                  ? findStatus.statusValue.toFixed(2)
                                  : 0.0}
                                %
                              </b>
                            </h6>
                          </div> */}
                        </div>
                      </Card>
                    );
                  })}
                </div>
              </div>
            );
          })}

      {updateValueModal && (
        <Card className="addDeviceModal-card" style={{ width: "440px" }}>
          <Box className="addDeviceModal-titleWrap">
            <Typography
              variant="h5"
              gutterBottom
              className="addDeviceModal-title"
            >
              Update Value
            </Typography>
            <img
              src={images.closeIcon}
              alt=""
              style={{
                width: "30px",
                cursor: "pointer",
                backgroundColor: "white",
              }}
              className="closeImg"
              onClick={() => setUpdateValueModal(false)}
            />
          </Box>

          <Box className="addDeviceModal-innerWrap">
            <Stack spacing={2} pt="10px" className="pt-4">
              <TextField
                id="filled-basic"
                name="group_name"
                label="Enter Value"
                variant="filled"
                className="customeTextField deviceManagement"
                type="number" // Set the type to "number" to allow decimal input
                onChange={(e) => setUpdateValue(e.target.value)}
                value={updateValue}
              />
            </Stack>
            <Box className="accountSecurity-cardButtons deviceManagement">
              <button
                className="addDeviceBtn"
                onClick={handleUpdateSubmitValue}
              >
                Update
              </button>
            </Box>
          </Box>
        </Card>
      )}
      {machineSwitchPopup && (
        <Card className="deleteConfirmation-Modal">
          <Typography
            variant="h6"
            gutterBottom
            className="deleteConfirmation-title"
          >
            {tagName} {switchId ? "To OFF" : "To ON"} ?
          </Typography>
          <Box className="deleteConfirmation-btnWrap">
            <button
              className="deleteConfirmation-Yesbtn"
              onClick={handleSubmit}
            >
              Yes
            </button>
            <button
              className="deleteConfirmation-Nobtn"
              onClick={() => setMachineSwitchPopup(false)}
            >
              No
            </button>
          </Box>
        </Card>
      )}
      {isLoading && <Loader />}
    </div>
  );
};
export default TagGroupDetail;
