import { Box, Grid, MenuItem, Switch, TextField } from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
import { InputAdornment, Paper, Popper } from "@material-ui/core";
import {
  exportSmartTagAnalyticsCOC,
  exportSmartTagAnalyticsPOP,
  getDeviceByDashboard,
  getTagList,
} from "../services";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import "../MachineDashboard/Analytics/MachineAnalytics.css";
import { setSmartTagReportData } from "../MachineDashboard/Slice";
import DeviceListShimmer from "../../../components/Shimmer/DeviceListShimmer/DeviceListShimmer";
import { ToastContainer, toast } from "react-toastify";

const SmartTagList = ({
  time,
  formatStartDate,
  formatEndDate,
  formatStartTime,
  formatEndTime,
  range,
  machines,
  units,
  handleSelectMachine,
  handleSelectTagUnit,
  handleSelectTag,
  tagListData,
  isShimmerdevicelist,
  isShimmerUnitlist,
  isShimmerMachineslist,
  smartTagSelectmachine,
  smartTagSelectTagUnit,
  snartTagSelectTag,
  selectPOPUnitData,
  formatStartDatePOP1,
  formatEndDatePOP1,
  formatStartTimePOP1,
  formatEndTimePOP1,
  SmartTagSelectSwitch,
  handleToggleTime,
  activeTabClass,
  disableOtherUnits,
  interval,
  agg
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [searchTag, setSearchTag] = useState("");
  const [selectedUnit, setSelectedUnit] = useState([]);

  const { smartTagPOPSelectUnit, activeClassConsumer } = useSelector(
    (state) => state.smartTagReportSlice
  );

  console.log(
    "selectPOPUnitData",
    snartTagSelectTag.map((e) => e.tagId)
  );

  const switchColors = [
    "#FEBB22",
    "#002B2A",
    "#C99C00",
    "#EB9E79",
    "#69927A",
    "#872B81",
    "#F06292",
    "#CD4D4A",
    "#123782",
    "#544632",
    "#94655B",
    "#0F4C81",
    "#8B0000",
    "#C60BC8",
    "#7D2264",
    "#BC2F00",
    "#556830",
    "#FCA311",
    "#021116",
    "#808080",
    "#300338",
    "#634C23",
    "#841E62",
    "#F34424",
    "#003333",
    "#020000",
    "#305355",
    "#5C3A31",
    "#D9750C",
    "#0C4562",
    "#001A30",
    "#00838F",
    "#785049",
    "#FF8800",
    "#0B7C8E",
    "#08535E",
    "#0E3504",
    "#C48200",
    "#846748",
    "#075571",
    "#9C27B0",
    "#A97E07",
    "#CE286D",
    "#8409DF",
    "#0B648D",
    "#29445A",
    "#1D733F",
    "#6E02B1",
  ];

  const param = useParams();

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  useEffect(() => {
    setAnchorEl(null);
    setAnchorEl2(null);
  }, []);

  const [openMachine, setOpenMachine] = useState(false);
  const [openUnit, setOpenUnit] = useState(false);

  const handlePropagation = (event) => {
    event.stopPropagation();
  };

  const handleClick = (event) => {
    setOpenMachine((prev) => !prev);
    setAnchorEl(event.currentTarget);
  };

  const handleOutsideClick = (event) => {
    if (anchorEl && !anchorEl.contains(event.target)) {
      setOpenMachine(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [anchorEl]);

  const handleClickUnit = (event) => {
    setOpenUnit((prev) => !prev);
    setAnchorEl2(event.currentTarget);
  };

  const handleOutsideClickUnit = (event) => {
    if (anchorEl2 && !anchorEl2.contains(event.target)) {
      setOpenUnit(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClickUnit);
    return () => {
      document.removeEventListener("click", handleOutsideClickUnit);
    };
  }, [anchorEl2]);

  const isCheckboxTagUnitDisabled = (tagUnit) => {
    return smartTagSelectTagUnit
      ? smartTagSelectTagUnit.length >= 1 &&
          !smartTagSelectTagUnit.some((device) => device.tagUnit === tagUnit)
      : false;
  };

  const handleExportAnalyticsCOCExcel = async () => {
    const params = {
      company: param.companyid,
      plant: param.plantid,
      tag: snartTagSelectTag.map((e) => e.tagId),
      
      interval: interval,
    };
    if(disableOtherUnits){
      params["aggregate"] = agg
    }
    else{
      params["spike_range"]= range
    }

    if (time) {
      params["start_date"] = `${formatStartDate} ${formatStartTime}`;
      params["end_date"] = `${formatEndDate} ${formatEndTime}`;
    } else {
      params["start_date"] = `${formatStartDate} 00:00:00`;
      params["end_date"] = `${formatEndDate} 23:59:59`;
    }
    try {
      const resp = await exportSmartTagAnalyticsCOC(params);
      if (resp.data.success == true) {
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };

  const handleExportAnalyticsPOPExcel = async () => {
    const params = {
      company: param.companyid,
      plant: param.plantid,
      tag: snartTagSelectTag.map((e) => e.tagId),
      interval: interval,

    };
    if(disableOtherUnits){
      params["aggregate"] = agg
    }
    else{
      params["spike_range"]= range
    }
    if (time) {
      params["start_date"] = `${formatStartDate} ${formatStartTime}`;
      params["end_date"] = `${formatEndDate} ${formatEndTime}`;
      params["start_date_1"] = `${formatStartDatePOP1} ${formatStartTimePOP1}`;
      params["end_date_1"] = `${formatEndDatePOP1} ${formatEndTimePOP1}`;
    } else {
      params["start_date"] = `${formatStartDate} 00:00:00`;
      params["end_date"] = `${formatEndDate} 23:59:59`;
      params["start_date_1"] = `${formatStartDatePOP1} 00:00:00`;
      params["end_date_1"] = `${formatEndDatePOP1} 23:59:59`;
    }
    try {
      const resp = await exportSmartTagAnalyticsPOP(params);
      if (resp.data.success == true) {
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };
  return (
    <>
    
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Box className={disableOtherUnits ? "smarTag-grid-box" : "smartTag-boxes"}>
          <Box className="consumer-text-disc">
            <div className="d-flex justify-content-between">
              <div>
                <h6 className="Title">Title</h6>
                <h6>Data Charts</h6>
              </div>
              <div>
                <Switch checked={SmartTagSelectSwitch}
                onChange={handleToggleTime}/>
              </div>
            </div>
          </Box>

          <Box>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "13px 8px",
                borderRadius: "4px",
                width: "100%",
                background: "#f5f5f5",
                cursor: "pointer",
                marginTop: "4px",
              }}
              onClick={handleClick}
            >
              Select Device <ArrowDropDownIcon />
            </div>
          </Box>

          <Popper
            open={openMachine}
            anchorEl={anchorEl}
            placement="bottom-start"
            style={{ width: "23%", maxHeight: "300px", overflow: "auto" }}
            className="machine-dropdown"
          >
            <Paper>
              {!isShimmerMachineslist ? (
                machines?.length ? (
                  machines.map((row) => (
                    <MenuItem
                      className="vms-coc-menuItem"
                      onClick={handlePropagation}
                    >
                      {" "}
                      {row.device__device_name}{" "}
                      <Switch
                        style={{ float: "right" }}
                        checked={
                          smartTagSelectmachine.find(
                            (item) => item.machineId === row.device
                          )
                            ? true
                            : false
                        }
                        onClick={() => handleSelectMachine(row)}
                      />
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem
                    className="vms-coc-menuItem"
                    onClick={handlePropagation}
                  >
                    No Machine Found
                  </MenuItem>
                )
              ) : (
                <DeviceListShimmer itemCount={2} />
              )}
            </Paper>
          </Popper>
          {activeClassConsumer == "Consumer" ? (
            <Box>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "13px 8px",
                  borderRadius: "4px",
                  width: "100%",
                  background: "#f5f5f5",
                  cursor: "pointer",
                  marginTop: "4px",
                }}
                onClick={handleClickUnit}
              >
                Select Unit <ArrowDropDownIcon />
              </div>

              <Popper
                open={openUnit}
                anchorEl={anchorEl2}
                placement="bottom-start"
                style={{ width: "23%", maxHeight: "300px", overflow: "auto" }}
              >
                <Paper>
                  {!isShimmerUnitlist ? (
                    units?.length ? (
                      units.map((row) => (
                        <MenuItem
                          key={row.tag_unit}
                          className="vms-coc-menuItem"
                          onClick={handlePropagation}
                        >
                          {" "}
                          {row.tag_unit}{" "}
                          <Switch
                            style={{ float: "right" }}
                            checked={
                              smartTagSelectTagUnit.find(
                                (item) => item.tagUnit.toLowerCase() === row.tag_unit.toLowerCase()
                              )
                                ? true
                                : false
                            }

                            disabled={
                              (disableOtherUnits && !smartTagSelectTagUnit.find((item) => item.tagUnit.toLowerCase() === row.tag_unit.toLowerCase())) ||
                              (!["kvah", "kvarh", "kwh"].includes(row.tag_unit.toLowerCase()) && disableOtherUnits)
                            }
                            onClick={() => handleSelectTagUnit(row)}
                          />
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem
                        className="vms-coc-menuItem"
                        onClick={handlePropagation}
                      >
                        No Units Found
                      </MenuItem>
                    )
                  ) : (
                    <DeviceListShimmer itemCount={2} />
                  )}
                </Paper>
              
              </Popper>
            </Box>
          ) : (
            <Box>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "13px 8px",
                  borderRadius: "4px",
                  width: "100%",
                  background: "#f5f5f5",
                  cursor: "pointer",
                  marginTop: "4px",
                }}
                onClick={handleClickUnit}
              >
                Select Unit <ArrowDropDownIcon />
              </div>

              <Popper
                open={openUnit}
                anchorEl={anchorEl2}
                placement="bottom-start"
                style={{
                  width: "23%",
                  maxHeight: "300px",
                  overflow: "auto",
                  zIndex: 999,
                }}
              >
                <Paper>
                  {!isShimmerUnitlist ? (
                    units?.length ? (
                      units.map((row) => (
                        <MenuItem
                          key={row.tag_unit}
                          className="vms-coc-menuItem"
                          onClick={handlePropagation}
                        >
                          {" "}
                          {row.tag_unit}{" "}
                          <Switch
                            style={{ float: "right" }}
                            checked={
                              smartTagSelectTagUnit
                                ? smartTagSelectTagUnit.find(
                                    (f) => f.tagUnit == row.tag_unit
                                  )
                                  ? true
                                  : false
                                : false
                            }
                            disabled={
                              disableOtherUnits &&
                              !["kvah", "kvarh", "kwh"].includes(row.tag_unit.toLowerCase()) &&
                              !smartTagSelectTagUnit.find(
                                (item) => item.tagUnit.toLowerCase() === row.tag_unit.toLowerCase()
                              )
                            }
                            onClick={() => handleSelectTagUnit(row)}
                          />
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem
                        className="vms-coc-menuItem"
                        onClick={handlePropagation}
                      >
                        No Units Found
                      </MenuItem>
                    )
                  ) : (
                    <DeviceListShimmer itemCount={3} />
                  )}
                </Paper>
              </Popper>
            </Box>
          )}

          <div style={{ marginTop: "14px", fontWeight: "bold" }}>Tag List</div>

          <TextField
            variant="outlined"
            className="search-textfield Smarttag-search"
            placeholder="Search Unit here"
            value={searchTag}
            onChange={(e) => setSearchTag(e.target.value)}
            InputProps={{
              startAdornment: <SearchIcon />,
              inputProps: {
                style: { padding: "9px" },
              },
            }}
          />

          <div className={disableOtherUnits ? "smarTag-Unit-grid" : "smartTag-report-taglist-unit"}>
            <Box className="Grid-text1 mt-2">
              {!isShimmerdevicelist ? (
                tagListData
                  ?.filter(
                    (e) =>
                      e.tag_name &&
                      e.tag_name.toLowerCase().includes(searchTag.toLowerCase())
                  )
                  .map((row, index) => (
                    <Grid item xs={12}>
                      <Grid
                        container
                        alignItems="center"
                        style={{
                          background: "#efeffd",
                          width: "100%",
                          marginBottom: "7px",
                        }}
                      >
                        <Grid
                          item
                          xs={8}
                          className="mt-2"
                          style={{
                            padding: "7px 12px",
                            borderLeft: `4px solid ${
                              switchColors[index % switchColors.length]
                            }`,
                          }}
                        >
                          <h6>{row.tag_name}</h6>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          textAlign="right"
                          className="grid-switch-unit"
                        >
                          <Switch
                            checked={
                              snartTagSelectTag.find((f) => f.tagId == row.id)
                                ? true
                                : false
                            }
                            style={{ float: "right" }}
                            name={switchColors[index]}
                            // disabled={isCheckBoxTagDisabled(
                            //   activeClass,
                            //   activeTabClass,
                            //   row.id
                            // )}
                            onClick={(e) => handleSelectTag(row, e.target.name)}
                            sx={{
                              "& .MuiSwitch-thumb": {
                                bgcolor: "#828282",
                              },
                              "& .MuiSwitch-track": {
                                bgcolor: "#D9D9E5 !important",
                              },
                              "& .Mui-checked": {
                                "& .MuiSwitch-thumb": {
                                  bgcolor:
                                    switchColors[index % switchColors.length],
                                },
                                "& .MuiSwitch-track": {
                                  bgcolor: "#CEE6E1 !important",
                                },
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))
              ) : (
                <DeviceListShimmer itemCount={7} />
              )}
            </Box>
          </div>

          {activeClassConsumer == "Consumer" ? (
            <div>
              <button
                className="export-btn"
                style={{ marginTop: "46px" }}
                onClick={handleExportAnalyticsCOCExcel}
              >
                Export SmartTag Data
              </button>
            </div>
          ) : (
            <div>
              <button
                className="export-btn"
                style={{ marginTop: "46px" }}
                onClick={handleExportAnalyticsPOPExcel}
              >
                Export SmartTag Data
              </button>
            </div>
          )}
        </Box>
      </Grid>
    </>
  );
};
export default SmartTagList;
