import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Switch } from "@mui/material";
import { images } from "../../../../config/images";
import "./AddAccessManagement.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAccessModule,
  getDepartment,
  editAccessManagement,
  getAccessManagementById,
  getCompanyByDetails,
  getAccessModuleByCompany,
  getCompanyList,
  getPlantList,
  getcompanyListPlantManegement,
  getPlantByCompany,
} from "../../service";
import Loader from "../../../../components/Loader/Loader";
import { FormControlLabel, FormGroup } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";

function AddAccesssManagementByID() {
  const navigate = useNavigate();
  const [openAddDepartmentModal, setOpenAddDepartmentModal] =
    React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [department, setDepartment] = useState([]);
  const [selectDepartment, setSelectDepartment] = useState("none");
  const [accessModule, setAccessModule] = useState([]);
  const [subModule, setSubModule] = useState([]);
  const [accessModuleId, setAccessModuleId] = useState("");
  const [selectAccessManagement, setSelectAccessManagement] = useState([]);
  const [selectRole, setSelectRole] = useState("none");
  const [isLoading, setLoading] = useState(false);
  const [companyIdName, setCompanyName] = useState("");
  const [companyIdData, setCompanyId] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [plantList, setPlantList] = useState({});
  const [plantListDropDown, setPlantListDropDown] = useState({});
  const [companyAccess, setCompanyAccess] = useState([]);
  const [plantAccess, setPlantAccess] = useState([]);

  const { departmentId } = useParams();
  const param = useParams();

  console.log("selectAccessManagement", plantListDropDown);
  const {
    companyId,
    user_access,
    companyName,
    is_super_admin,
    is_created_by_super_admin_user_company,
  } = useSelector((state) => state.userState);

  const superAdminAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Admin Dashboard"
      )
    : null;
  const accessManagementUser = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
          (f) => f.sub_module_name == "Access Management"
        )
      : null
    : null;

  const handleOpenaddDepartmentModal = () => {
    setOpenAddDepartmentModal(!openAddDepartmentModal);
  };

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  useEffect(() => {
    // Find the specific plant that matches the plantId
    const matchedAccessModule = accessModule.find(
      (e) => e.id === accessModuleId
    );

    if (matchedAccessModule) {
      // If the matchedPlant exists, check if its device array is empty
      setSubModule(
        matchedAccessModule.sub_module.length
          ? matchedAccessModule.sub_module
          : []
      );
    }
  }, [accessModule, accessModuleId]);

  // handleSelectDepartment
  const handleSelectDepartment = (e) => {
    if (accessManagementUser?.is_editor) {
      setSelectRole("none");
      setSelectDepartment(e.target.value);
    } else {
      handleTostMsg();
    }
  };
  // Delete Confirmation Modal
  const handleConfirmationModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const getDepartmentList = async () => {
    const paramReq = {
      company: param.companyId ? param.companyId : companyId,
    };

    const response = await getDepartment(paramReq);
    console.log("departmentList", response);
    if (response.status == 200 || response.status == 201) {
      setDepartment(response.data.payload);
    }
  };

  const getAccessmoduleByCompany = async () => {
    const resp = await getAccessModuleByCompany(param.companyId);
    if (resp.status == 200 || resp.status == 201) {
      setAccessModule(resp.data.payload[0]?.access_module);
      // setAccessModuleId(resp.data.payload[0].access_module[0].id);
    }
  };
  const getAccessmodule = async () => {
    const resp = await getAccessModule();
    if (resp.status == 200 || resp.status == 201) {
      setAccessModule(resp.data.payload[0].access_module);
      // setAccessModuleId(resp.data.payload[0].access_module[0].id);
    }
  };

  // company deatils
  const getCompanyByDetailsApi = async () => {
    const paramReq = {
      company_id: param.companyId,
    };
    const resp = await getCompanyByDetails(paramReq);
    console.log("resprespresp", resp);
    if (resp.status == 200) {
      setCompanyName(resp.data.payload.company_name);
      setCompanyId(resp.data.payload.id);
    }
  };

  //comapny list
  const getCompanyDetails = async () => {
    const params = {
      dashboard: 1,
    };
    try {
      const response = await getcompanyListPlantManegement(params);
      if (response.status == 200 || response.status == 201) {
        setCompanyList(response.data.payload);
        console.log("responseCompanyList...", response.data.payload);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  // update plant list
  const updatePlantList = (companyId, newItem) => {
    setPlantList((prevData) => ({
      ...prevData,
      [companyId]: newItem,
    }));
  };

  // update plant list dropdown arrow
  const updatePlantListDropDown = (companyId, isOpen) => {
    setPlantListDropDown((prevData) => ({
      ...prevData,
      [companyId]: isOpen,
    }));
  };

  // plant list
  const getPlantDetails = async (companyId) => {
    const params = {
      company: companyId,
    };
    console.log("plantParams", params);
    try {
      const response = await getPlantByCompany(params);
      if (response.status == 200 || response.status == 201) {
        updatePlantList(companyId, response.data.payload);
        if (
          response.data.payload &&
          Array.isArray(response.data.payload) &&
          response.data.payload.length > 0
        ) {
          // to automatically open the dropdown of plants comment if not needed
          updatePlantListDropDown(companyId, true);
        }
        console.log("responsePlantList...", response.data.payload);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  // Function to add multiple companies to the company access if they are not already present
  const addCompanyAccess = (elementsToAdd) => {
    setCompanyAccess((prevList) => {
      const updatedList = [...prevList];
      elementsToAdd.forEach((element) => {
        if (!updatedList.includes(element)) {
          updatedList.push(element); // Add element if it's not in the list
        }
      });
      return updatedList;
    });
  };

  // Function to remove multiple companies from the company access if they exist
  const removeCompanyAccess = (elementsToRemove) => {
    setCompanyAccess((prevList) => {
      return prevList.filter((item) => !elementsToRemove.includes(item)); // Remove elements
    });
  };

  // Function to add multiple plants to the plant access if they are not already present
  const addPlantAccess = (elementsToAdd) => {
    setPlantAccess((prevList) => {
      const updatedList = [...prevList];
      elementsToAdd.forEach((element) => {
        if (!updatedList.includes(element)) {
          updatedList.push(element); // Add element if it's not in the list
        }
      });
      return updatedList;
    });
  };

  // Function to remove multiple plants from the plant access if they exist
  const removePlantAccess = (elementsToRemove) => {
    setPlantAccess((prevList) => {
      return prevList.filter((item) => !elementsToRemove.includes(item)); // Remove elements
    });
  };

  const handleCheckboxChange = async (event, companyId) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      await getPlantDetails(companyId);

      // to add access of company
      addCompanyAccess([companyId]);
    } else {
      /// remove all the plants related to that company
      // removePlantAccess(plantList[companyId]??[]);
      updatePlantList(companyId, []);
      updatePlantListDropDown(companyId, false);

      // to remove access of company
      removeCompanyAccess([companyId]);
    }
    console.log(`Company ID ${companyId}, Checked ${isChecked}`);
  };

  const handlePlantListCheckBoxChange = (event, plantId) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      // to add access of plant
      addPlantAccess([plantId]);
    } else {
      // to remove access of plant
      removePlantAccess([plantId]);
    }
  };

  useEffect(() => {
    getCompanyDetails();
    getPlantDetails();
  }, []);

  useEffect(() => {
    if (param.companyId) {
      getCompanyByDetailsApi();
    }
  }, []);

  const getAccessManagementByIdData = async () => {
    const resp = await getAccessManagementById(departmentId);
    console.log("resp", resp.data);
    if (resp.status == 200) {
      setSelectDepartment(resp.data.department_id);
      setSelectRole(resp.data.role_id);

      const selectedData = resp.data.access_module_management.map((e) => {
        return {
          access_module: e.access_module.id,
          sub_module: e.sub_module.map((e) => e.id),
          is_viewer: e.is_viewer,
          is_editor: e.is_editor,
        };
      });
      setSelectAccessManagement(selectedData);
      if (
        resp.data.access_module_management[0] &&
        resp.data.access_module_management[0].access_module
      ) {
        setAccessModuleId(
          resp.data.access_module_management[0].access_module.id
        );
      }

      // Extract company access IDs
      if (resp.data.company_access && resp.data.company_access.length > 0) {
        const companyIds = resp.data.company_access.map((company) => {
          getPlantDetails(company.id);
          return company.id;
        });
        console.log("SELECTED COMPANY IDS:- ", companyIds);
        addCompanyAccess(companyIds);
      }

      // Extract plant access IDs
      if (resp.data.plant_access && resp.data.plant_access.length > 0) {
        const plantIds = resp.data.plant_access.map((plant) => plant.id);
        console.log("SELECTED Plant IDS:- ", plantIds);
        addPlantAccess(plantIds);
      }
    }
  };

  // Check if a company ID is present in the company access list
  const companyAccessExists = (id) => {
    const result =
      companyAccess && companyAccess.some((companyId) => companyId === id);
    console.log("IS RESULT : ", result);
    return result;
  };

  // Check if a plant ID is present in the plant access list
  const plantAccessExists = (id) => {
    return plantAccess && plantAccess.some((plantId) => plantId === id);
  };

  useEffect(() => {
    console.log("plantAccess", plantAccess);
    console.log("companyAccess", companyAccess);
  }, [plantAccess, companyAccess]);

  useEffect(() => {
    getDepartmentList();
    if (param.companyId) {
      getAccessmoduleByCompany();
    } else {
      getAccessmodule();
    }

    getAccessManagementByIdData();
  }, []);

  const handleSelectAccessManagement = (accessModuleId) => {
    setSelectAccessManagement((prevState) => {
      // Check if an object with the same access_module already exists
      const existingObjectIndex = prevState.findIndex(
        (item) => item.access_module === accessModuleId
      );

      // If it exists, remove the object by filtering out the matching item
      if (existingObjectIndex !== -1) {
        return prevState.filter((item, index) => index !== existingObjectIndex);
      }

      // If it doesn't exist, add a new object with the given accessModuleId
      const newAccessManagement = {
        access_module: accessModuleId,
        sub_module: [],
        is_viewer: false,
        is_editor: false,
      };

      return [...prevState, newAccessManagement];
    });
  };

  const handleSetAccessViewer = (accessModuleId, is_viewer) => {
    // Extract all the id's from the selectAccessManagement array
    const subModuleIds = subModule.length
      ? subModule.map((item) => item.id)
      : [];

    setSelectAccessManagement((prevState) =>
      prevState.map((item) => {
        // Check if the access_module matches the current accessModuleId
        if (item.access_module === accessModuleId) {
          if (is_viewer) {
            return {
              ...item,
              is_viewer,
              sub_module: is_viewer ? subModuleIds : [],
            };
          } else {
            return {
              ...item,
              is_viewer,
              is_editor: false,
              sub_module: is_viewer ? subModuleIds : [],
            };
          }
          // Update the is_viewer value based on the provided parameter
        }
        return item; // Return the unchanged item if access_module doesn't match
      })
    );
  };

  const handleSetAccessEditer = (accessModuleId, is_editor) => {
    const subModuleIds = subModule.length
      ? subModule.map((item) => item.id)
      : [];

    setSelectAccessManagement((prevState) =>
      prevState.map((item) => {
        // Check if the access_module matches the current accessModuleId
        if (item.access_module === accessModuleId) {
          // Update the is_viewer value based on the provided parameter
          return {
            ...item,
            is_editor,
            is_viewer: true,
            sub_module: subModuleIds,
          };
        }
        return item; // Return the unchanged item if access_module doesn't match
      })
    );
  };

  const handleSubModuleSetId = (accessModuleId, id) => {
    setSelectAccessManagement((prevState) =>
      prevState.map((item) => {
        // Check if the access_module matches the current accessModuleId
        if (item.access_module === accessModuleId) {
          // Check if the id is already in the sub_module array
          const subModuleIndex = item.sub_module.indexOf(id);
          if (subModuleIndex !== -1) {
            // Remove the id from the sub_module array
            const newSubModule = [...item.sub_module];
            newSubModule.splice(subModuleIndex, 1);
            return { ...item, sub_module: newSubModule };
          } else {
            // Add the id to the sub_module array
            return {
              ...item,
              is_viewer: true,
              is_editor: true,
              sub_module: [...item.sub_module, id],
            };
          }
        }
        return item; // Return the unchanged item if access_module doesn't match
      })
    );
  };

  const isViewerChecked = selectAccessManagement.length
    ? selectAccessManagement.find((f) => f.access_module == accessModuleId)
      ? selectAccessManagement.find((f) => f.access_module == accessModuleId)
          .is_viewer
      : false
    : false;
  const isEditorChecked = selectAccessManagement.length
    ? selectAccessManagement.find((f) => f.access_module == accessModuleId)
      ? selectAccessManagement.find((f) => f.access_module == accessModuleId)
          .is_editor
      : false
    : false;

  const submitAddAccessManagement = async () => {
    if (selectDepartment == "none") {
      notify("Please Select Department");
      return true;
    }

    if (selectRole == "none") {
      notify("Please Select Role");
      return true;
    }

    const params = {
      department: selectDepartment,
      role: selectRole,
      access_management: selectAccessManagement.map((item) => {
        // If sub_module contains -1, filter it out
        if (item.sub_module.includes(-1)) {
          item.sub_module = item.sub_module.filter((value) => value !== -1);
        }
        return item;
      }),
      company: param.companyId ? param.companyId : companyId,
      company_access: companyAccess,
      plant_access: plantAccess,
    };

    try {
      console.log("PARMAS PARAMS:- ", params);
      setLoading(true);
      const resp = await editAccessManagement(departmentId, params);

      if (resp.data.success == true) {
        setLoading(false);
        if (param.companyId) {
          navigate(`/accessManagement/company/${param.companyId}`);
          return true;
        }
        if (param.view) {
          navigate(`/usermanagement`);
          return true;
        }
        navigate("/accessManagement");
      }
    } catch (error) {
      setLoading(false);
      notify(error.response.data.message);
    }
  };

  const handleTostMsg = () => {
    notify("You don't have access");
  };

  console.log("paramparam", param);
  return (
    <>
      {/* <div>ManagementDepartment</div> */}
      <div></div>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="deviceManagementHeader-wrap">
            <div className="deviceManagementTitle-wrap">
              <KeyboardBackspaceIcon
                onClick={() => {
                  if (param.companyId) {
                    if (param.view) {
                      navigate(`/usermanagement/company/${param.companyId}`);
                      return true;
                    }
                    navigate(`/accessManagement/company/${param.companyId}`);
                    return true;
                  }

                  if (param.view) {
                    navigate(`/usermanagement`);
                    return true;
                  }
                  navigate("/accessManagement");
                }}
                className="backArrow"
              />{" "}
              <Typography
                variant="h5"
                className="deviceManagementTitle"
                gutterBottom
                style={{ margin: "0" }}
                onClick={() => {
                  if (param.companyId) {
                    if (param.view) {
                      navigate(`/usermanagement/company/${param.companyId}`);
                      return true;
                    }
                    navigate(`/accessManagement/company/${param.companyId}`);
                    return true;
                  }
                  if (param.view) {
                    navigate(`/usermanagement`);
                    return true;
                  }
                  navigate("/accessManagement");
                }}
              >
                {param.companyId
                  ? `${companyIdName} - Access Management`
                  : "Access Management"}
              </Typography>
            </div>
            <div className="addDevice-buttonWrap">
              <button
                className="manageDepartment-button"
                onClick={() => {
                  if (accessManagementUser?.is_editor) {
                    navigate("/managedepartment");
                  } else {
                    handleTostMsg();
                  }
                }}
              >
                Manage Department
              </button>
              <img src={images.excelLogo} alt="" className="cloudActiveImg" />
            </div>
          </div>
          {/* =============== Add Department Modal Start =============== */}
          {openAddDepartmentModal && (
            <Card className="addDepartment-card">
              <Box>
                {/* <Stack direction="row" spacing={{ sm: 14, md: 20, lg: 25 }}>
                    </Stack> */}
                <Box>
                  <Stack direction="row" spacing={{ sm: 42, md: 42, lg: 38 }}>
                    <Typography gutterBottom style={{ display: "none" }}>
                      ..
                    </Typography>
                    <img
                      src={images.closeIcon}
                      alt=""
                      style={{ width: "30px", cursor: "pointer" }}
                      onClick={handleOpenaddDepartmentModal}
                    />
                  </Stack>
                  <Typography
                    gutterBottom
                    style={{
                      color: "#1C407B",
                      fontWeight: "600",
                      fontSize: "19px",
                      textAlign: "center",
                    }}
                  >
                    Add Department
                  </Typography>
                </Box>
                <div className="departmentWrap">
                  <label for="exampleInputEmail1" class="form-label">
                    Department <span>*</span>
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Department"
                  ></input>
                </div>
                <button className="addDepartmentButton">Add Department</button>
              </Box>
            </Card>
          )}
          {/* =============== Add Department Modal End =============== */}
          {/* =============== Delete Confirmation Modal Start =============== */}
          {openDeleteModal && (
            <Card className="deleteConfirmation-Modal">
              <Typography
                variant="h6"
                gutterBottom
                className="deleteConfirmation-title"
              >
                Are You Sure<br></br> You Want To Delete?
              </Typography>
              <Box className="deleteConfirmation-btnWrap">
                <button
                  className="deleteConfirmation-Yesbtn"
                  onClick={handleConfirmationModal}
                >
                  Yes
                </button>
                <button
                  className="deleteConfirmation-Nobtn"
                  onClick={handleConfirmationModal}
                >
                  No
                </button>
              </Box>
            </Card>
          )}
          {/* =============== Delete Confirmation Modal End =============== */}
          <Grid container style={{ paddingTop: "20px" }}>
            <Grid item xs={12}>
              <Card className="manageDepartment-card">
                <Grid
                  container
                  style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                  }}
                >
                  <Grid item sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Department</b>{" "}
                      <span style={{ color: "#E31E24" }}>*</span>
                    </Typography>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      className="addAccessManagement selectDropDown"
                      value={selectDepartment}
                      onChange={(e) => handleSelectDepartment(e)}
                    >
                      <option selected disabled hidden value="none">
                        Select Department
                      </option>
                      {department.map((e, i) => {
                        return (
                          <option value={e.id}>{e.department_name}</option>
                        );
                      })}
                    </select>
                    <Box className="accessDepartment-textWrap">
                      <div>
                        <Typography variant="subtitle2" gutterBottom>
                          <b>Select Access Module</b>{" "}
                          <span style={{ color: "#E31E24" }}>*</span>
                        </Typography>
                      </div>
                      {/* <div>
                                                <img
                                                    src={images.addIcon}
                                                    alt=""
                                                    className="addDeviceImg"
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </div> */}
                    </Box>
                    <div className="manageDepartment-wrap">
                      {accessModule.length
                        ? accessModule.map((e1, i) => {
                            const isChecked = selectAccessManagement.find(
                              (f) => f.access_module == e1.id
                            )
                              ? true
                              : false;
                            return (
                              <div
                                className="addAccessManagement-fieldWrap"
                                onClick={() => setAccessModuleId(e1.id)}
                              >
                                {accessModuleId == e1.id && (
                                  <img
                                    src={images.activeDeviceimg}
                                    alt=""
                                    className="activeAccessDepartmentImg"
                                  />
                                )}
                                <div className="manageDepartment-field">
                                  <Typography
                                    variant="body2"
                                    gutterBottom
                                    style={{ margin: "0" }}
                                  >
                                    <Checkbox
                                      checked={isChecked}
                                      onClick={() => {
                                        if (accessManagementUser?.is_editor) {
                                          handleSelectAccessManagement(e1.id);
                                        } else {
                                          handleTostMsg();
                                        }
                                      }}
                                    ></Checkbox>{" "}
                                    {e1.access_module_name}
                                  </Typography>
                                </div>
                              </div>
                            );
                          })
                        : []}
                    </div>
                  </Grid>
                  <Grid item sm={12} md={8} lg={8} xl={8}>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Role</b> <span style={{ color: "#E31E24" }}>*</span>
                    </Typography>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      className="addAccessManagement selectDropDown Roles"
                      value={selectRole}
                      onChange={(e) => {
                        if (accessManagementUser?.is_editor) {
                          setSelectRole(e.target.value);
                        } else {
                          handleTostMsg();
                        }
                      }}
                    >
                      <option selected disabled hidden value="none">
                        Select Role
                      </option>
                      {department.length
                        ? department.map((e, i) => {
                            if (selectDepartment == e.id) {
                              return e.company_roles.map((e1) => {
                                return (
                                  <option value={e1.id}>{e1.role_name}</option>
                                );
                              });
                            }
                          })
                        : []}
                    </select>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Select Sub Module</b>{" "}
                      <span style={{ color: "#E31E24" }}>*</span>
                    </Typography>
                    <div className="managementDepartment-card">
                      <div
                        className="accessManagement switchWrap"
                        style={{ marginLeft: "20px" }}
                      >
                        <Grid container spacing={2} pt={4}>
                          <Grid item xs={4} sm={6}>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{ margin: "0" }}
                            >
                              Viewer
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Switch
                              onClick={(e) => {
                                if (accessManagementUser?.is_editor) {
                                  handleSetAccessViewer(
                                    accessModuleId,
                                    e.target.checked
                                  );
                                } else {
                                  handleTostMsg();
                                }
                              }}
                              // defaultChecked
                              checked={isViewerChecked}
                              size="small"
                              color="success"
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={4} sm={6}>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{ margin: "0" }}
                            >
                              Editor
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Switch
                              onClick={(e) => {
                                if (accessManagementUser?.is_editor) {
                                  handleSetAccessEditer(
                                    accessModuleId,
                                    e.target.checked
                                  );
                                } else {
                                  handleTostMsg();
                                }
                              }}
                              checked={isEditorChecked}
                              size="small"
                              color="success"
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <Grid container className="pt-3">
                        {accessModule.length
                          ? accessModule.map((e, i) => {
                              if (accessModuleId === e.id) {
                                const getAccessManagement =
                                  selectAccessManagement.find(
                                    (f) => f.access_module === accessModuleId
                                  );

                                const subModules = e.sub_module.map((e1) => {
                                  const isChecked = getAccessManagement
                                    ? getAccessManagement.sub_module.includes(
                                        e1.id
                                      )
                                    : false;

                                  return (
                                    <Grid
                                      item
                                      xs={6}
                                      sm={3}
                                      md={3}
                                      lg={3}
                                      xl={3}
                                      key={e1.id}
                                    >
                                      <Box className="managementDepartment roles ">
                                        <Typography variant="body2">
                                          <Checkbox
                                            onClick={() => {
                                              if (
                                                accessManagementUser?.is_editor
                                              ) {
                                                handleSubModuleSetId(
                                                  accessModuleId,
                                                  e1.id
                                                );
                                              } else {
                                                handleTostMsg();
                                              }
                                            }}
                                            checked={isChecked}
                                          />
                                          {e1.sub_module_name}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  );
                                });

                                return (
                                  <Grid container spacing={2}>
                                    {subModules}
                                  </Grid>
                                );
                              }
                            })
                          : []}
                      </Grid>
                      <hr></hr>
                      <div>
                        <Typography variant="subtitle2" gutterBottom>
                          <b>Choose company and plant:</b>
                        </Typography>
                        <div style={{height : "439px", overflow:"auto"}}>
                        <FormGroup>
                          {param.companyId ? (
                            <>
                              <div>
                                <div>
                                  <FormControlLabel
                                    key={companyIdData}
                                    control={
                                      <Checkbox
                                        onChange={(event) =>
                                          handleCheckboxChange(
                                            event,
                                            companyIdData
                                          )
                                        }
                                        checked={companyAccessExists(
                                          companyIdData
                                        )}
                                      />
                                    }
                                    label={companyIdName}
                                    sx={{
                                      "& .MuiFormControlLabel-label": {
                                        fontSize: "16px",
                                        fontWeight: "600",
                                      },
                                    }}
                                  />

                                  {plantListDropDown[companyIdData] ?? false ? (
                                    <KeyboardArrowUpIcon
                                      className="company-up-arrow"
                                      onClick={() => {
                                        if (
                                          plantList[companyIdData] &&
                                          Array.isArray(
                                            plantList[companyIdData]
                                          ) &&
                                          plantList[companyIdData].length > 0
                                        ) {
                                          updatePlantListDropDown(
                                            companyIdData,
                                            false
                                          );
                                        }
                                      }}
                                    />
                                  ) : (
                                    <KeyboardArrowDownIcon
                                      className="company-down-arrow"
                                      onClick={() => {
                                        if (
                                          plantList[companyIdData] &&
                                          Array.isArray(
                                            plantList[companyIdData]
                                          ) &&
                                          plantList[companyIdData].length > 0
                                        ) {
                                          updatePlantListDropDown(
                                            companyIdData,
                                            true
                                          );
                                        }
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="selected-plant-wrap">
                                  {(plantListDropDown[companyIdData] ??
                                    false) &&
                                    plantList[companyIdData].map((plant) => (
                                      <FormControlLabel
                                        key={plant.id}
                                        control={
                                          <Checkbox
                                            onChange={(event) => {
                                              handlePlantListCheckBoxChange(
                                                event,
                                                plant.id
                                              );
                                            }}
                                          />
                                        }
                                        checked={plantAccessExists(plant.id)}
                                        label={plant.plant_name}
                                      />
                                    ))}
                                </div>
                              </div>
                            </>
                          ) : is_super_admin ||
                            is_created_by_super_admin_user_company ? (
                              <>
                              <div key={companyId}>
                                      <div className="company-plant-box-wrap">
                                        <div>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                onChange={(event) =>
                                                  handleCheckboxChange(
                                                    event,
                                                    companyId
                                                  )
                                                }
                                                checked={companyAccessExists(
                                                  companyId
                                                )}
                                              />
                                            }
                                            label={companyName}
                                            sx={{
                                              "& .MuiFormControlLabel-label": {
                                                fontSize: "16px",
                                                fontWeight: "600",
                                              },
                                            }}
                                          />
                                        </div>

                                        <div>
                                          {plantListDropDown[companyId] ? (
                                            <KeyboardArrowUpIcon
                                              className="company-up-arrow"
                                              onClick={() => {
                                                if (
                                                  plantList[companyId] &&
                                                  Array.isArray(
                                                    plantList[companyId]
                                                  ) &&
                                                  plantList[companyId].length >
                                                    0
                                                ) {
                                                  updatePlantListDropDown(
                                                    companyId,
                                                    false
                                                  );
                                                }
                                              }}
                                            />
                                          ) : (
                                            <KeyboardArrowDownIcon
                                              className="company-down-arrow"
                                              onClick={() => {
                                                if (
                                                  plantList[companyId] &&
                                                  Array.isArray(
                                                    plantList[companyId]
                                                  ) &&
                                                  plantList[companyId].length >
                                                    0
                                                ) {
                                                  updatePlantListDropDown(
                                                    companyId,
                                                    true
                                                  );
                                                }
                                              }}
                                            />
                                          )}
                                        </div>
                                      </div>

                                      <div className="selected-plant-wrap">
                                        {plantListDropDown[companyId] &&
                                          plantList[companyId].map((plant) => (
                                            <FormControlLabel
                                              key={plant.id}
                                              control={
                                                <Checkbox
                                                  onChange={(event) =>
                                                    handlePlantListCheckBoxChange(
                                                      event,
                                                      plant.id
                                                    )
                                                  }
                                                  checked={plantAccessExists(plant.id)}
                                                />
                                              }
                                              label={plant.plant_name}
                                            />
                                          ))}
                                      </div>
                                    </div>
                            {companyList.map((company) => (

                              <>
                              <div>
                                <div
                                  key={company.id}
                                  className="company-plant-box-wrap"
                                >
                                  <div>
                                    <FormControlLabel
                                      key={company.id}
                                      control={
                                        <Checkbox
                                          onChange={(event) =>
                                            handleCheckboxChange(
                                              event,
                                              company.id
                                            )
                                          }
                                          checked={companyAccessExists(
                                            company.id
                                          )}
                                        />
                                      }
                                      label={company.company_name}
                                      sx={{
                                        "& .MuiFormControlLabel-label": {
                                          fontSize: "16px",
                                          fontWeight: "600",
                                        },
                                      }}
                                    />
                                  </div>

                                  <div>
                                    {plantListDropDown[company.id] ?? false ? (
                                      <KeyboardArrowUpIcon
                                        className="company-up-arrow"
                                        onClick={() => {
                                          if (
                                            plantList[company.id] &&
                                            Array.isArray(
                                              plantList[company.id]
                                            ) &&
                                            plantList[company.id].length > 0
                                          ) {
                                            updatePlantListDropDown(
                                              company.id,
                                              false
                                            );
                                          }
                                        }}
                                      />
                                    ) : (
                                      <KeyboardArrowDownIcon
                                        className="company-down-arrow"
                                        onClick={() => {
                                          if (
                                            plantList[company.id] &&
                                            Array.isArray(
                                              plantList[company.id]
                                            ) &&
                                            plantList[company.id].length > 0
                                          ) {
                                            updatePlantListDropDown(
                                              company.id,
                                              true
                                            );
                                          }
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>

                                <div className="selected-plant-wrap">
                                  {(plantListDropDown[company.id] ?? false) &&
                                    plantList[company.id].map((plant) => (
                                      <FormControlLabel
                                        key={plant.id}
                                        control={
                                          <Checkbox
                                            onChange={(event) => {
                                              handlePlantListCheckBoxChange(
                                                event,
                                                plant.id
                                              );
                                            }}
                                            checked={plantAccessExists(plant.id)}
                                          />
                                        }
                                        checked={plantAccessExists(plant.id)}
                                        label={plant.plant_name}
                                      />
                                    ))}
                                </div>
                              </div>
                              </>
                            ))}
                            </>
                          ) : (
                            <div>
                            <div className="d-flex">
                              <div>
                                <FormControlLabel
                                  key={companyId}
                                  control={
                                    <Checkbox
                                      onChange={(event) =>
                                        handleCheckboxChange(
                                          event,
                                          companyId
                                        )
                                      }
                                      checked={companyAccessExists(
                                        companyId
                                      )}
                                    />
                                  }
                                  label={companyName}
                                  sx={{
                                    "& .MuiFormControlLabel-label": {
                                      fontSize: "16px",
                                      fontWeight: "600",
                                    },
                                  }}
                                />
                              </div>
                              <div  style={{marginTop:"7px"}}>
                                {plantListDropDown[companyId] ? (
                                  <KeyboardArrowUpIcon
                                    className="company-up-arrow"
                                    onClick={() => {
                                      if (
                                        plantList[companyId] &&
                                        Array.isArray(
                                          plantList[companyId]
                                        ) &&
                                        plantList[companyId].length > 0
                                      ) {
                                        updatePlantListDropDown(
                                          companyId,
                                          false
                                        );
                                      }
                                    }}
                                  />
                                ) : (
                                  <KeyboardArrowDownIcon
                                    className="company-down-arrow"
                                    onClick={() => {
                                      if (
                                        plantList[companyId] &&
                                        Array.isArray(
                                          plantList[companyId]
                                        ) &&
                                        plantList[companyId].length > 0
                                      ) {
                                        updatePlantListDropDown(
                                          companyId,
                                          true
                                        );
                                      }
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="selected-plant-wrap">
                              {plantListDropDown[companyId] &&
                                plantList[companyId].map((plant) => (
                                  <FormControlLabel
                                    key={plant.id}
                                    control={
                                      <Checkbox
                                        onChange={(event) =>
                                          handlePlantListCheckBoxChange(
                                            event,
                                            plant.id
                                          )
                                        }
                                        checked={plantAccessExists(plant.id)}
                                      />
                                    }
                                    label={plant.plant_name}
                                  />
                                ))}
                            </div>
                          </div>
                          )}
                        </FormGroup>
                        </div>
                      </div>
                    </div>
                    <button
                      className="Plantmanagemet submit"
                      onClick={() => {
                        if (accessManagementUser?.is_editor) {
                          submitAddAccessManagement();
                        } else {
                          handleTostMsg();
                        }
                      }}
                    >
                      Submit
                    </button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>
        {isLoading && <Loader />}
      </div>
    </>
  );
}
export default AddAccesssManagementByID;
