import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";



const Every15minutesRemoveGraphdata = ({ combineChartData, setCombineChartData, setAverageValue, selectTagData }) => {

    const timeStringToMinutes = (timeStr) => {
        const [hours, minutes] = timeStr?.split(':').map(Number);
        return hours * 60 + minutes;
    }
    const { selectTimeZoneProfile } = useSelector(state => state.userState);
    const getTagTime = () => {
        return new Date().toLocaleTimeString('en-US', {
            timeZone: selectTimeZoneProfile,
            hour12: false,
        });
    };

    useEffect(() => {
        const updateChartData = () => {

            setCombineChartData((prev) => {

                const zeroIndx = [...prev];
                const innerArrayCopy = [...zeroIndx[0]];
                const innerArrayCopy1 = [...zeroIndx[1]];
                const innerArrayCopy2 = [...zeroIndx[2]];
                const innerArrayCopy3 = [...zeroIndx[3]];

                const currentTimeStr = getTagTime();
                const currentTimeInMinutes = timeStringToMinutes(currentTimeStr);

                const filteredData = innerArrayCopy.filter(item => {
                    const itemTimeInMinutes = timeStringToMinutes(item.tagTime);
                    const timeDifferenceInMinutes = currentTimeInMinutes - itemTimeInMinutes;
                    return Math.abs(timeDifferenceInMinutes) !== 15;
                });

                const filteredData1 = innerArrayCopy1.filter(item => {
                    const itemTimeInMinutes = timeStringToMinutes(item.tagTime);
                    const timeDifferenceInMinutes = currentTimeInMinutes - itemTimeInMinutes;
                    return Math.abs(timeDifferenceInMinutes) !== 15;
                });

                const filteredData2 = innerArrayCopy2.filter(item => {
                    const itemTimeInMinutes = timeStringToMinutes(item.tagTime);
                    const timeDifferenceInMinutes = currentTimeInMinutes - itemTimeInMinutes;
                    return Math.abs(timeDifferenceInMinutes) !== 15;
                });

                const filteredData3 = innerArrayCopy3.filter(item => {
                    const itemTimeInMinutes = timeStringToMinutes(item.tagTime);
                    const timeDifferenceInMinutes = currentTimeInMinutes - itemTimeInMinutes;
                    return Math.abs(timeDifferenceInMinutes) !== 15;
                });

                zeroIndx[0] = filteredData;
                zeroIndx[1] = filteredData1;
                zeroIndx[2] = filteredData2;
                zeroIndx[3] = filteredData3;

                return zeroIndx;
            })

        };

        updateChartData();
        const intervalId = setInterval(updateChartData, 60000); 

        return () => clearInterval(intervalId);
    }, [selectTagData, selectTimeZoneProfile]);

    useEffect(() => {
        setAverageValue((prev) => {
            const result = prev.slice(-combineChartData[0].length);
            return result;
        });
    }, [combineChartData])

}

export default Every15minutesRemoveGraphdata;