import {
  Box,
  Grid,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useMemo, useState } from "react";
import { InputAdornment, Paper, Popper } from "@material-ui/core";
import {
  exportSmarTagLiveExcel,
  getDeviceByDashboard,
  getTagList,
} from "../services";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import "../MachineDashboard/Analytics/MachineAnalytics.css";
import { setSmartTagReportData } from "../MachineDashboard/Slice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { getDeviceUnitData } from "../../ProcessDashboard/OverView/services";
import { getDeviceUnits } from "../../VMS/VmsCompanyDashboard/VmsMachine/Reports/service";
import { getTagID } from "../../ProcessDashboard/Source/services";
import CubeQueryComponent from "../../ProcessDashboard/Consumption/ConsumptionGraphData";
import {
  CompactTagUnit,
  CompactTagValue,
} from "../../../components/CompactValue/CompactValue";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { cubejsApi } from "../../Cubejs/CubejsApiWrapper";
import OverViewGraphData from "../../VMS/VmsCompanyDashboard/VmsMachine/OverView/OverViewGraphData";
import RealTimeMqttData from "./RealTimeMqttData";
import HighchartsGraphData from "../../VMS/VmsCompanyDashboard/VmsMachine/OverView/graph/LiveGraph";
import { toast } from "react-toastify";
import { SoftTagFormula } from "../softTag/SoftTagFormula";

const SmartTagAnalyticsList = ({}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [deviceId, setDeviceId] = useState([]);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [searchTag, setSearchTag] = useState("");
  const [selectedUnit, setSelectedUnit] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [selectDeviceData, setSelectDeviceData] = useState([]);
  const [selectUnitData, setSelectUnitData] = useState([]);
  const [chartCubeData, setChartCubeData] = useState([]);
  const [selectTagData, setSelectTagData] = useState([]);
  const [selectColorData, setSelectColorData] = useState([]);
  const [tagListData, setTagListData] = useState([]);
  const [getDevice, setGetDevice] = useState([]);
  const [selectTimeRange, setSelectTimeRange] = useState("live");
  const [chartLineData, setChartLineData] = useState([]);
  const [minMax, setMinMax] = useState([]);
  const [realTimevalue, setRealTimevalue] = useState([]);
  const [chartLineData1, setChartLineData1] = useState([]);
  const [minMax1, setMinMax1] = useState([]);
  const [realTimevalue1, setRealTimevalue1] = useState([]);
  const [chartLineData2, setChartLineData2] = useState([]);
  const [minMax2, setMinMax2] = useState([]);
  const [realTimevalue2, setRealTimevalue2] = useState([]);
  const [chartLineData3, setChartLineData3] = useState([]);
  const [minMax3, setMinMax3] = useState([]);
  const [realTimevalue3, setRealTimevalue3] = useState([]);
  const [combineChartData, setCombineChartData] = useState([[], [], [], []]);
  const [tagMinMaxValue, setTagMinMaxValue] = useState([]);
  const [combineRealTimeData, setCombinerealTimeData] = useState([]);
  const [combineMinMax, setCombineminMax] = useState([]);
  const [averageValue, setAverageValue] = useState([]);
  const [percentageValue, setPercentageValue] = useState([]);
  const [average, setAverage] = useState(false);
  const [tagStatus, setTagStatus] = useState([]);
  const [tagVMSData, setTagVMSData] = useState([]);
  const [mqttTagPreviousvalue, setMqttTagPreviousvalue] = useState([]);
  const navigate = useNavigate();
  const [colors, setColors] = useState(null); // State to hold color
  const [mqttTagData, setMqttTagData] = useState();
  const [previousValues, setPreviousValues] = useState({});
  const [selectedPreFixData, setSelectedPrefixData] = useState("");

  console.log("combineRealTimeData", combineChartData);

  console.log("getDevice", combineChartData);
  const { smartTagPOPSelectUnit1, activeClassConsumer } = useSelector(
    (state) => state.smartTagReportSlice
  );
  const { selectUnit } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );
  const { deviceID, smarTagselectedDevice, smarTagselectedUnit, tagUNIT } =
    useSelector((state) => state.smartTagReportSlice);
  const { selectTimeZoneProfile } = useSelector((state) => state.userState);

  const dispatch = useDispatch();

  const switchColors = [
    "#FEBB22",
    "#002B2A",
    "#C99C00",
    "#EB9E79",
    "#69927A",
    "#872B81",
    "#F06292",
    "#CD4D4A",
    "#123782",
    "#544632",
    "#94655B",
    "#0F4C81",
    "#8B0000",
    "#C60BC8",
    "#7D2264",
    "#BC2F00",
    "#556830",
    "#FCA311",
    "#021116",
    "#808080",
    "#300338",
    "#634C23",
    "#841E62",
    "#F34424",
    "#003333",
    "#020000",
    "#305355",
    "#5C3A31",
    "#D9750C",
    "#0C4562",
    "#001A30",
    "#00838F",
    "#785049",
    "#FF8800",
    "#0B7C8E",
    "#08535E",
    "#0E3504",
    "#C48200",
    "#846748",
    "#075571",
    "#9C27B0",
    "#A97E07",
    "#CE286D",
    "#8409DF",
    "#0B648D",
    "#29445A",
    "#1D733F",
    "#6E02B1",
  ];

  const param = useParams();

  const { overViewTagClickebleData } = useSelector(
    (state) => state.smartTagReportSlice
  );

  console.log("overViewTagClickebleData", overViewTagClickebleData);
  useEffect(() => {
    setCombinerealTimeData([
      realTimevalue,
      realTimevalue1,
      realTimevalue2,
      realTimevalue3,
    ]);
    // setCombineminMax([minMax, minMax1, minMax2, minMax3]);
  }, [
    chartLineData,
    chartLineData1,
    chartLineData2,
    chartLineData3,
    realTimevalue,
    realTimevalue1,
    realTimevalue2,
    realTimevalue3,
    minMax,
    minMax1,
    minMax2,
    minMax3,
  ]);

  useEffect(() => {
    if (combineChartData.length) {
      const resultArray = combineChartData.map((subArray) => {
        const lastValue = subArray[subArray.length - 1]?.tagValue;
        const secondLastValue = subArray[subArray.length - 2]?.tagValue;

        const diff = lastValue - secondLastValue;
        const percentage = (diff / secondLastValue) * 100;
        return parseFloat(percentage.toExponential(2).replace(/e-6$/, "")); // round to 2 decimal places
      });

      setPercentageValue(resultArray);
    }
  }, [combineChartData]);

  useMemo(() => {
    if (selectTagData.length == 1 && chartLineData.length >= 2) {
      const lastValue = parseFloat(
        chartLineData[chartLineData.length - 1].tagValue
      );
      const secondLastValue = parseFloat(
        chartLineData[chartLineData.length - 2].tagValue
      );
      const chartLineDataAverage = (lastValue + secondLastValue) / 2;

      console.log("chartLineDataAverage", chartLineDataAverage);
      // setAverageValue((prev) => [...prev, chartLineDataAverage]);
      setAverageValue((prev) => {
        // Create a shallow copy of the previous state
        const indexCopy = [...prev, chartLineDataAverage];
        //==========================new add code==============================
        if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        return indexCopy;
      });
    }
  }, [chartLineData]);

  useMemo(() => {
    if (
      selectTagData.length == 2 &&
      chartLineData.length != 0 &&
      chartLineData.length == chartLineData1.length
    ) {
      const device1 = chartLineData.length
        ? [chartLineData[chartLineData.length - 1]]
        : [];
      const device2 = chartLineData1.length
        ? [chartLineData1[chartLineData1.length - 1]]
        : [];
      // const device3 = lineGraphData3.length ? [lineGraphData3[lineGraphData3.length - 1]] : [];
      // const device4 = lineGraphData4.length ? [lineGraphData4[lineGraphData4.length - 1]] : [];

      const nonEmptyArrays = [device1, device2].filter((arr) => arr.length > 0);
      const sum = nonEmptyArrays.reduce(
        (acc, arr) => acc + (parseFloat(arr[0]?.tagValue) || 0),
        0
      ); // Safely access tagValue

      // Calculate the average
      const average =
        nonEmptyArrays.length > 0 ? sum / nonEmptyArrays.length : 0;

      // Format the average to two decimal places using toFixed(2)
      const formattedAverage = average;
      console.log("formattedAverage", formattedAverage);

      // setAverageValue((prev) => [...prev, formattedAverage]);
      if (chartLineData.length > 2) {
      }
      setAverageValue((prev) => {
        // Create a shallow copy of the previous state
        const indexCopy = [...prev, formattedAverage];
        //==========================new add code==============================
        if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        return indexCopy;
      });
    }
  }, [chartLineData1, selectUnit]);

  useMemo(() => {
    if (
      selectTagData.length == 3 &&
      chartLineData.length != 0 &&
      chartLineData.length == chartLineData1.length &&
      chartLineData1.length == chartLineData2.length
    ) {
      const device1 = chartLineData.length
        ? [chartLineData[chartLineData.length - 1]]
        : [];
      const device2 = chartLineData1.length
        ? [chartLineData1[chartLineData1.length - 1]]
        : [];
      const device3 = chartLineData2.length
        ? [chartLineData2[chartLineData2.length - 1]]
        : [];
      // const device4 = lineGraphData4.length ? [lineGraphData4[lineGraphData4.length - 1]] : [];

      const nonEmptyArrays = [device1, device2, device3].filter(
        (arr) => arr.length > 0
      );
      const sum = nonEmptyArrays.reduce(
        (acc, arr) => acc + (parseFloat(arr[0]?.tagValue) || 0),
        0
      ); // Safely access tagValue

      // Calculate the average
      const average =
        nonEmptyArrays.length > 0 ? sum / nonEmptyArrays.length : 0;

      // Format the average to two decimal places using toFixed(2)
      const formattedAverage = average;

      // setAverageValue((prev) => [...prev, formattedAverage]);
      setAverageValue((prev) => {
        // Create a shallow copy of the previous state
        const indexCopy = [...prev, formattedAverage];
        //==========================new add code==============================
        if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        return indexCopy;
      });
    }
  }, [chartLineData1, chartLineData2, selectUnit]);

  useMemo(() => {
    if (
      selectTagData.length == 4 &&
      chartLineData.length != 0 &&
      chartLineData.length == chartLineData1.length &&
      chartLineData1.length == chartLineData2.length &&
      chartLineData2.length == chartLineData3.length
    ) {
      const device1 = chartLineData.length
        ? [chartLineData[chartLineData.length - 1]]
        : [];
      const device2 = chartLineData1.length
        ? [chartLineData1[chartLineData1.length - 1]]
        : [];
      const device3 = chartLineData2.length
        ? [chartLineData2[chartLineData2.length - 1]]
        : [];
      const device4 = chartLineData3.length
        ? [chartLineData3[chartLineData3.length - 1]]
        : [];

      const nonEmptyArrays = [device1, device2, device3, device4].filter(
        (arr) => arr.length > 0
      );
      const sum = nonEmptyArrays.reduce(
        (acc, arr) => acc + (parseFloat(arr[0]?.tagValue) || 0),
        0
      ); // Safely access tagValue

      // Calculate the average
      const average =
        nonEmptyArrays.length > 0 ? sum / nonEmptyArrays.length : 0;

      // Format the average to two decimal places using toFixed(2)
      const formattedAverage = average;

      // setAverageValue((prev) => [...prev, formattedAverage]);
      setAverageValue((prev) => {
        // Create a shallow copy of the previous state
        const indexCopy = [...prev, formattedAverage];
        //==========================new add code==============================
        if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        return indexCopy;
      });
    }
  }, [chartLineData1, chartLineData2, chartLineData3, selectUnit]);

  const foundIndex = tagListData?.findIndex(
    (row) => row.tag_name === overViewTagClickebleData[0].tagName
  );

  const color = switchColors[foundIndex % switchColors.length];

  useEffect(() => {
    const foundData = overViewTagClickebleData?.find(
      (f) => f.plantId == param.plantId
    );

    if (foundData) {
      const {
        machineId,
        tagUnit,
        tag_id,
        tagName,
        device__uid,
        device_tag,
        softTag,
        softTagFormula,
      } = foundData;

      setColors(color);

      let myP = [
        {
          tag_id: tag_id || null,
          tagName: tagName || null,
          tagUnit: tagUnit || null,
          machineId: machineId || null,
          plantId: param.plantId || null,
          device_tag: device_tag,
          device__uid: device__uid,
          softTag: softTag,
          softTagFormula: softTagFormula,
          color: color, // Set the correct color
        },
      ];
      setSelectColorData(myP);
    }
  }, [foundIndex]);

  useEffect(() => {
    const foundData = overViewTagClickebleData?.find(
      (f) => f.plantId == param.plantId
    );

    if (foundData) {
      const {
        machineId,
        tagUnit,
        tag_id,
        tagName,
        device__uid,
        device_tag,
        softTag,
        softTagFormula,
      } = foundData;

      setColors(color);
      console.log("foundIndex", foundIndex, color);

      setSelectDeviceData([
        {
          machineId: machineId || null,
          plantId: param.plantId,
        },
      ]);

      setSelectUnitData([
        {
          tagUnit: tagUnit || null,
          machineId: machineId || null,
          plantId: param.plantId,
        },
      ]);

      let myP = [
        {
          tag_id: tag_id || null,
          tagName: tagName || null,
          tagUnit: tagUnit || null,
          machineId: machineId || null,
          plantId: param.plantId || null,
          device_tag: device_tag,
          device__uid: device__uid,
          softTag: softTag,
          softTagFormula: softTagFormula,
          color: color, // Set the correct color
        },
      ];
      console.log("reached", myP);

      setSelectTagData(myP);
    }
  }, []);

  useEffect(() => {
    const hasKwUnit = selectUnitData.some((f) =>
      f.tagUnit.toLowerCase().startsWith("k")
    );
    setSelectedPrefixData(hasKwUnit);
  }, [selectUnitData]);

  const handleSelectMachine = (selectedMachine) => {
    const existingIndex = selectDeviceData.findIndex(
      (color) =>
        color.machineId === selectedMachine.device &&
        color.plantId === param.plantId
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...selectDeviceData];
      updateMachineId.splice(existingIndex, 1);
      setSelectDeviceData(updateMachineId);
    } else {
      setSelectDeviceData((prev) => {
        return [
          ...prev,
          {
            machineId: selectedMachine.device,
            plantId: param.plantId,
          },
        ];
      });
    }
  };

  const handleSelectReportsTagUnit = (selectedUnit) => {
    // Check if the unit is already selected

    const existingIndex = selectUnitData.findIndex(
      (color) =>
        color.tagUnit?.toLowerCase() == selectedUnit.tag_unit?.toLowerCase() &&
        color.plantId === param.plantId
    );

    if (existingIndex !== -1) {
      console.log("selectUnitDataselectUnitData", selectUnitData, selectedUnit);
      // If the selected unit is already in the list, remove it (unselect)
      setSelectUnitData([]);
      setSelectTagData([]);
      setTagListData([]);
      setSelectColorData([]);
    } else {
      // If no unit is selected or a different one is selected, replace the unit
      setSelectUnitData([
        {
          tagUnit: selectedUnit.tag_unit,
          machineId: selectedUnit.device,
          plantId: param.plantId,
        },
      ]);
      console.log("selectUnitData", selectUnitData);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setAnchorEl2(null);
  };

  const handleClickUnit = (event) => {
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
    setAnchorEl(null);
  };

  const openMachine = Boolean(anchorEl);
  const openUnit = Boolean(anchorEl2);

  useEffect(() => {
    setAnchorEl(null);
    setAnchorEl2(null);
  }, []);

  const handlePropagation = (event) => {
    event.stopPropagation();
  };

  const isCheckboxTagUnitDisabled = (tagUnit) => {
    return smartTagPOPSelectUnit1
      ? smartTagPOPSelectUnit1.length >= 1 &&
          !smartTagPOPSelectUnit1.some((device) => device.tagUnit === tagUnit)
      : false;
  };
  useEffect(() => {
    if (selectTagData.length && selectUnitData.length) {
      setChartLineData([]);
      setChartLineData1([]);
      setChartLineData2([]);
      setChartLineData3([]);
      setMinMax([]);
      setMinMax1([]);
      setMinMax2([]);
      setMinMax3([]);
      setRealTimevalue([]);
      setRealTimevalue1([]);
      setRealTimevalue2([]);
      setRealTimevalue3([]);
      setCombineChartData([[], [], [], []]);
      setCombineminMax([]);
      setCombinerealTimeData([]);
      setGetDevice([]);
      setAverageValue([]);
      getTagIDApi();
    } else {
      setChartLineData([]);
      setChartLineData1([]);
      setChartLineData2([]);
      setChartLineData3([]);
      setMinMax([]);
      setMinMax1([]);
      setMinMax2([]);
      setMinMax3([]);
      setRealTimevalue([]);
      setRealTimevalue1([]);
      setRealTimevalue2([]);
      setRealTimevalue3([]);
      setCombineChartData([[], [], [], []]);
      setCombineminMax([]);
      setCombinerealTimeData([]);
      setGetDevice([]);
      setAverageValue([]);
    }
  }, [selectUnitData.length, selectTimeRange, selectTagData.length]);

  const getTagIDApi = async () => {
    try {
      const params = {
        device_id: selectDeviceData.map((e) => e.machineId),
        tag_unit: selectUnitData.length ? selectUnitData[0].tagUnit : [],
      };
      const resp = await getTagID(params);

      if (resp.status === 200 && resp.data.payload.length) {
        setGetDevice(
          selectDeviceData.map((id) =>
            resp.data.payload.find((item) => item.device == id.machineId)
          )
        );
        console.log("resp.data.payload", resp.data.payload);
        const tagObj = resp.data.payload.map((row) => {
          let curValue = 0;
          let prevValue = 0;

          // Evaluate formula for soft tags
          if (row.is_soft_tag && row.soft_tag_id__formula) {
            curValue = evaluateSoftTagValue(
              row.soft_tag_id__formula,
              mqttTagPreviousvalue
            );
            prevValue = curValue; // Adjust if there are different prevValue needs
          }

          const obj = {
            dvName: row.device_tag ? row.device_tag || "-" : "-",
            prevValue,
            curValue,
            dateAndTime: getTagDateAndTime(),
            is_soft_tag: row.is_soft_tag || false,
            soft_tag_id__formula: row.soft_tag_id__formula,
          };
          console.log("obj", obj);
          return obj;
        });
        setMqttTagPreviousvalue(tagObj);
      }
    } catch (error) {
      // Handle the error here
      console.error("An error occurred while fetching tag IDs:", error);
    }
  };
  useEffect(() => {
    getDeviceData();
  }, []);

  const getDeviceData = async () => {
    const params = {
      dashboard: 3,
      plant: param.plantId,
    };
    try {
      const resp = await getDeviceByDashboard(params);
      if (resp.status == 200 || resp.status == 201) {
        setDeviceData(resp.data.payload.device);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  const getTagListData = async (selectedMachines, selectedTagUnits) => {
    try {
      const params = {
        device_id: selectedMachines,
        tag_unit: selectedTagUnits,
      };

      const resp = await getTagList(params);

      if (resp.status === 200 || resp.status === 201) {
        setTagListData(resp.data.payload);
        const tagObj = resp.data.payload.map((row) => {
          let curValue = 0;
          let prevValue = 0;

          // Evaluate formula for soft tags
          if (row.is_soft_tag && row.soft_tag_id__formula) {
            curValue = evaluateSoftTagValue(
              row.soft_tag_id__formula,
              mqttTagPreviousvalue
            );
            prevValue = curValue; // Adjust if there are different prevValue needs
          }

          const obj = {
            dvName: row.device_tag ? row.device_tag || "-" : "-",
            prevValue,
            curValue,
            dateAndTime: getTagDateAndTime(),
            is_soft_tag: row.is_soft_tag || false,
            soft_tag_id__formula: row.soft_tag_id__formula,
          };
          console.log("objsadss", obj);
          return obj;
        });
        setMqttTagPreviousvalue(tagObj);
      }
    } catch (error) {
      // Handle API call error if needed

      console.error("Error fetching tag list:", error);
    }
  };

  useEffect(() => {
    if (selectUnitData?.length > 0) {
      getTagListData(
        selectDeviceData.map((e) => e.machineId),
        selectUnitData.map((e) => e.tagUnit)
      );
    }

    console.log("selectUnitData", selectUnitData);

    if (selectDeviceData.length > 0) {
      handleGetUnitsData(selectDeviceData.map((e) => e.machineId));
    } else {
      setUnitData([]);
    }
  }, [selectDeviceData, selectUnitData]);

  const handleGetUnitsData = async (id) => {
    const params = {
      device: id,
    };
    try {
      const resp = await getDeviceUnits(params);
      if (resp.status == 200 || resp.status == 201) {
        setUnitData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const handelCubeChartLineData = (value) => {
    setChartLineData((prev) => {
      // Create a shallow copy of the previous state
      const indexCopy = [...prev];
      //==========================new add code==============================
      if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
        indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
      }

      return [...indexCopy, value];
    });
    // setChartLineData((prev) => [...prev, value]);

    setRealTimevalue([value]);
  };

  // Line-2 Chart data
  const handelCubeChartLineData1 = (value) => {
    setChartLineData1((prev) => {
      // Create a shallow copy of the previous state
      const indexCopy = [...prev];
      //==========================new add code==============================
      if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
        indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
      }

      return [...indexCopy, value];
    });
    // setChartLineData1((prev) => [...prev, value]);
    setRealTimevalue1([value]);
    // setMinMax1([minmax]);
  };

  // Line-3 Chart data
  const handelCubeChartLineData2 = (value) => {
    setChartLineData2((prev) => {
      // Create a shallow copy of the previous state
      const indexCopy = [...prev];
      //==========================new add code==============================
      if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
        indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
      }

      return [...indexCopy, value];
    });
    // setChartLineData2((prev) => [...prev, value]);
    setRealTimevalue2([value]);
    // setMinMax2([minmax]);
  };

  // Line-4 Chart data
  const handelCubeChartLineData3 = (value) => {
    setChartLineData3((prev) => {
      // Create a shallow copy of the previous state
      const indexCopy = [...prev];
      //==========================new add code==============================
      if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
        indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
      }

      return [...indexCopy, value];
    });
    // setChartLineData3((prev) => [...prev, value]);
    setRealTimevalue3([value]);
    // setMinMax3([minmax]);
  };

  const getTagTime = () => {
    return new Date().toLocaleTimeString("en-US", {
      timeZone: selectTimeZoneProfile,
      hour12: false,
    });
  };

  const executeCubeQuery = async () => {
    try {
      var currentTime;

      if (selectTimeRange == "1min") {
        console.log(
          "selectTimeRange.........................",
          selectTimeRange
        );
        currentTime = new Date().toLocaleTimeString("en-US", {
          timeZone: selectTimeZoneProfile,
          hour12: false,
        });
      }

      if (selectTimeRange == "5min") {
        console.log(
          "selectTimeRange.........................",
          selectTimeRange
        );
        currentTime = new Date().toLocaleTimeString("en-US", {
          timeZone: selectTimeZoneProfile,
          hour12: false,
        });
      }

      if (selectTimeRange == "15min") {
        currentTime = new Date().toLocaleTimeString("en-US", {
          timeZone: selectTimeZoneProfile,
          hour12: false,
        });
      }

      if (selectTimeRange == "30min") {
        currentTime = new Date().toLocaleTimeString("en-US", {
          timeZone: selectTimeZoneProfile,
          hour12: false,
        });
      }
      const chartGraph = await cubejsApi.load({
        dimensions: [
          "DeviceConfigurationTagconfiguration.latestValue",
          "DeviceConfigurationTagconfiguration.tagName",
          "DeviceConfigurationTagconfiguration.deviceId",
        ],
        filters: [
          {
            member: "DeviceConfigurationTagconfiguration.tagName",
            operator: "equals",
            values: selectTagData.length
              ? selectTagData.map((row) => row.tagName)
              : [],
          },
        ],
        limit: 4,
      });

      console.log("selectTagData", selectTagData);

      const findPlantviseDeviceId = selectTagData
        .filter((f) => f.plantId == param.plantId)
        .map((e) => e.tagName);

      console.log("findPlantviseDeviceId", findPlantviseDeviceId);
      const sortingSelectViseDeiveId = findPlantviseDeviceId.map((row) => {
        return chartGraph
          .tablePivot()
          ?.find(
            (id) => id["DeviceConfigurationTagconfiguration.tagName"] == row
          );
      });

      console.log("sortingSelectViseDeiveId", sortingSelectViseDeiveId);
      setCombineChartData((prev) => {
        const zeroIndx = [...prev];

        // Create a shallow copy of the inner array you want to modify (assuming it's an array)
        let innerArrayCopy = [...zeroIndx[0]];
        let innerArrayCopy1 = [...zeroIndx[1]];
        let innerArrayCopy2 = [...zeroIndx[2]];
        let innerArrayCopy3 = [...zeroIndx[3]];

        // Push the new object into the inner array copy
        if (selectTagData[0]) {
          if (
            innerArrayCopy[innerArrayCopy.length - 1]?.tagTime != getTagTime()
          ) {
            innerArrayCopy.push({
              tagValue:
                sortingSelectViseDeiveId?.[0]?.[
                  "DeviceConfigurationTagconfiguration.latestValue"
                ],
              tagTime: getTagTime(),
            });
            handelCubeChartLineData(
              {
                tagValue:
                  sortingSelectViseDeiveId?.[0]?.[
                    "DeviceConfigurationTagconfiguration.latestValue"
                  ],
                tagTime: getTagTime(),
              },
              null
            );
          }
        }

        if (selectTagData[1]) {
          if (
            innerArrayCopy1[innerArrayCopy1.length - 1]?.tagTime != getTagTime()
          ) {
            innerArrayCopy1.push({
              tagValue:
                sortingSelectViseDeiveId?.[1]?.[
                  "DeviceConfigurationTagconfiguration.latestValue"
                ],
              tagTime: getTagTime(),
            });
            handelCubeChartLineData1(
              {
                tagValue:
                  sortingSelectViseDeiveId?.[1]?.[
                    "DeviceConfigurationTagconfiguration.latestValue"
                  ],
                tagTime: getTagTime(),
              },
              null
            );
          }
        }

        if (selectTagData[2]) {
          if (
            innerArrayCopy2[innerArrayCopy2.length - 1]?.tagTime != getTagTime()
          ) {
            innerArrayCopy2.push({
              tagValue:
                sortingSelectViseDeiveId?.[2]?.[
                  "DeviceConfigurationTagconfiguration.latestValue"
                ],
              tagTime: getTagTime(),
            });
            handelCubeChartLineData2(
              {
                tagValue:
                  sortingSelectViseDeiveId?.[2]?.[
                    "DeviceConfigurationTagconfiguration.latestValue"
                  ],
                tagTime: getTagTime(),
              },
              null
            );
          }
        }

        if (selectTagData[3]) {
          if (
            innerArrayCopy3[innerArrayCopy3.length - 1]?.tagTime != getTagTime()
          ) {
            innerArrayCopy3.push({
              tagValue:
                sortingSelectViseDeiveId?.[3]?.[
                  "DeviceConfigurationTagconfiguration.latestValue"
                ],
              tagTime: getTagTime(),
            });
            handelCubeChartLineData3(
              {
                tagValue:
                  sortingSelectViseDeiveId?.[3]?.[
                    "DeviceConfigurationTagconfiguration.latestValue"
                  ],
                tagTime: getTagTime(),
              },
              null
            );
          }
        }

        //==========================new add code==============================
        if (innerArrayCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          innerArrayCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        if (innerArrayCopy1.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          innerArrayCopy1.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        if (innerArrayCopy2.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          innerArrayCopy2.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        if (innerArrayCopy3.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          innerArrayCopy3.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        zeroIndx[0] = innerArrayCopy;
        zeroIndx[1] = innerArrayCopy1;
        zeroIndx[2] = innerArrayCopy2;
        zeroIndx[3] = innerArrayCopy3;

        return zeroIndx;
      });

      console.log("chartGraphchartGraphchartGraph", sortingSelectViseDeiveId);
    } catch (error) {
      // Handle any errors that occur during the cube query
      console.error("Cube query error:", error);
    }
  };

  useEffect(() => {
    if (selectTimeRange != "live" && getDevice.length) {
      console.log("kpkpkpkpkpkpkkpk");
      // Function to execute the cube query

      // Set interval to execute the cube query based on selectTimeRange
      let interval = null;
      switch (selectTimeRange) {
        case "1min":
          interval = setInterval(executeCubeQuery, 60000);
          break;
        case "5min":
          interval = setInterval(executeCubeQuery, 300000);
          break;
        case "15min":
          interval = setInterval(executeCubeQuery, 900000);
          break;
        default:
          clearInterval(interval); // Clear interval if selectTimeRange is not supported
      }

      // Execute the cube query immediately
      executeCubeQuery();

      // Clean up the interval when the component unmounts or when selectTimeRange changes
      return () => {
        clearInterval(interval);
      };
    }
  }, [selectTagData, selectTimeRange]);
  console.log("selectColorDataselectColorData",selectColorData)

  const handleSelectTagName = (deviceId, index, event, row, selectUnit) => {
    console.log("row", row);
  
    // Find the index for tag data (from selectTagData)
    const existingIndex = selectTagData.findIndex(
      (color) => color.tagName === row.tag_name && color.plantId == param.plantId
    );
  
    // Find the index for color data (from selectColorData)
    const existingIndex1 = selectColorData.findIndex(
      (color) => color.tagName === row.tag_name && color.plantId == param.plantId
    );
  
    // Check if the color already exists in selectColorData
    if (existingIndex1 !== -1) {
      const updatedColors1 = [...selectColorData];
      updatedColors1.splice(existingIndex1, 1); // Remove the specific switch
      setSelectColorData(updatedColors1); // Update state with removed switch's color data
    } else {
      // Add the new color data if it doesn't exist
      setSelectColorData((prev) => {
        return [
          ...prev,
          {
            tag_id: row.id,
            tagName: row.tag_name,
            tagUnit: row.tag_unit,
            machineId: row.device,
            plantId: param.plantId,
            color: event.target.name, // Assign the switch color
            device__uid: row.device__machine__uid,
            device_tag: row.device_tag,
            softTag: row.is_soft_tag,
            softTagFormula: row.soft_tag_id__formula,
          },
        ];
      });
    }
  
    // Manage selectTagData similarly
    if (existingIndex !== -1) {
      const updatedTags = [...selectTagData];
      updatedTags.splice(existingIndex, 1); // Remove the specific switch's tag data
      setSelectTagData(updatedTags); // Update state with removed switch's tag data
    } else {
      // Add the new tag data if it doesn't exist
      setSelectTagData((prev) => {
        return [
          ...prev,
          {
            tag_id: row.id,
            tagName: row.tag_name,
            tagUnit: row.tag_unit,
            machineId: row.device,
            plantId: param.plantId,
            color: event.target.name, // Assign the switch color
            device__uid: row.device__machine__uid,
            device_tag: row.device_tag,
            softTag: row.is_soft_tag,
            softTagFormula: row.soft_tag_id__formula,
          },
        ];
      });
    }
  };
  

  const isCheckboxDisabled = (tagName) => {
    const selectedDevices = selectTagData.filter(
      (tag) => tag.plantId === param.plantId
    );
    return (
      selectedDevices.length >= 4 &&
      !selectedDevices.some((tag) => tag.tagName === tagName)
    );
  };

  useEffect(() => {
    setChartLineData([]);
    setChartLineData1([]);
    setChartLineData2([]);
    setChartLineData3([]);
    setCombineChartData([[], [], [], []]);
    setTagMinMaxValue([]);
    setAverageValue([]);
  }, [selectTagData.filter((tag) => tag.plantId === param.plantId).length]);

  useEffect(() => {
    if (mqttTagPreviousvalue.length) {
      // Function to evaluate the formula
      // const evaluateFormula = (formula, tags) => {
      //   console.log("formula", formula);
      //   try {
      //     // Match any tag-like pattern (e.g., words/numbers/underscores)
      //     const tagPattern = /[A-Za-z0-9_]+/g;
      //     const tagMatches = formula.match(tagPattern) || [];

      //     console.log("tagMatches", tagMatches);

      //     // Replace each tag with its current value from the tags array
      //     let evaluatedFormula = formula;
      //     tagMatches.forEach((tag) => {
      //       const foundTag = tags.find((t) => t.dvName === tag);
      //       if (!foundTag) {
      //         console.warn(`Tag "${tag}" not found in tags array.`);
      //       }
      //       const tagValue = foundTag ? parseFloat(foundTag.curValue) : 0;
      //       // Replace tag in formula with the corresponding value
      //       evaluatedFormula = evaluatedFormula.replace(tag, tagValue);
      //     });

      //     console.log("Evaluated Formula", evaluatedFormula);

      //     // Use eval to compute the formula result
      //     const result = eval(evaluatedFormula);
      //     console.log("Result", result);
      //     return result;
      //   } catch (error) {
      //     console.error("Error evaluating formula:", error);
      //     return 0; // Return 0 in case of an error during evaluation
      //   }
      // };

      const averagCalTagvalue = mqttTagPreviousvalue.map((row) => {
        console.log("dfgfdgfsdsd", row);
        // Handle soft tags
        if (row.is_soft_tag && row.soft_tag_id__formula) {
          // Compute current value using the formula
          const evaluatedCurrentValue = SoftTagFormula(
            row.soft_tag_id__formula,
            mqttDataReduce
          )?.value;
          console.log("Evaluated current value:", evaluatedCurrentValue);

          // Set the evaluated value as the new previous value
          setPreviousValues((prev) => ({
            ...prev,
            [row.soft_tag_id__formula]: evaluatedCurrentValue,
          }));

          // Use the previous value from state
          const evaluatedPreviousValue =
            previousValues[row.soft_tag_id__formula] || 0;
          console.log("Evaluated previous value:", evaluatedPreviousValue);

          // Calculate statusValue ensuring no division by zero
          const statusValue =
            evaluatedPreviousValue !== 0
              ? ((evaluatedCurrentValue - evaluatedPreviousValue) /
                  evaluatedPreviousValue) *
                100
              : 0;

          return {
            dvName: row.dvName,
            statusValue: statusValue,
            formula: row.soft_tag_id__formula,
          };
        } else {
          const avgCal = ((row.curValue - row.prevValue) / row.prevValue) * 100;

          return {
            dvName: row.dvName,
            statusValue: isFinite(avgCal) ? avgCal : 0.0,
          };
        }
      });

      console.log("averagCalTagvalue", averagCalTagvalue);

      setTagStatus(averagCalTagvalue);
    }
  }, [mqttTagPreviousvalue]);

  const getTagDateAndTime = () => {
    return new Date().toLocaleString("en-US", {
      timeZone: selectTimeZoneProfile,
      hour12: false,
    });
  };

  const evaluateSoftTagValue = (formula, tags) => {
    try {
      // Dynamic tag matching using tag pattern extraction
      const tagPattern = /[A-Za-z0-9_]+/g;
      const tagMatches = formula.match(tagPattern) || [];
      let evaluatedFormula = formula;

      // Replace tags in formula with current values from tags list
      tagMatches.forEach((tag) => {
        const foundTag = tags.find((t) => t.dvName === tag);
        const tagValue = foundTag ? parseFloat(foundTag.curValue) : 0;
        evaluatedFormula = evaluatedFormula.replace(tag, tagValue);
      });

      // Calculate and return the evaluated value
      return eval(evaluatedFormula);
    } catch {
      return 0; // Default to 0 on error
    }
  };
  // useEffect(() => {
  //   const createObject = selectTagData.length
  //     ? selectTagData.filter((tag) => tag.plantId === param.plantId).length
  //       ? selectTagData
  //           .filter((tag) => tag.deviceId === param.deviceId)
  //           .map((row) => {
  //             const obj = {
  //               tagName: row.device_tag ? row.device_tag : "-",
  //               prevValue: 0,
  //               curValue: 0,
  //             };

  //             return obj;
  //           })
  //       : []
  //     : [];

  //   setMqttTagPreviousvalue(createObject);
  // }, [selectTagData.filter((tag) => tag.plantId === param.plantId).length]);

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notify = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleExcelData = async () => {
    const params = {
      company: param.companyId,
      plant: param.plantId,
      time: combineChartData.length
        ? combineChartData[0].map((e) => e.tagTime)
        : [],
      tag: selectTagData
        .filter((f) => f.plantId == param.plantId)
        .map((e) => e.tag_id),
      tag_value: combineChartData.length
        ? combineChartData.flatMap((array) => array.map((obj) => obj.tagValue))
        : [],
    };
    try {
      const resp = await exportSmarTagLiveExcel(params);
      if (resp.status == 200 || resp.status == 201) {
        notify(resp.data.message);
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };

  const mergedArray = mqttTagData
    ? [].concat(...Object.values(mqttTagData))
    : [];

  const mqttDataReduce = mergedArray.reduce((map, item) => {
    map[item.device_tag] = Number(item.tagValue);
    return map;
  }, {});

  return (
    <>
      {selectTagData.length &&
      selectTagData.filter((f) => f.plantId == param.plantId).length ? (
        <OverViewGraphData
          selectTagData={selectTagData.filter(
            (f) => f.plantId == param.plantId
          )}
          setTagMinMaxValue={setTagMinMaxValue}
          vmsTagSelect={selectTagData}
        />
      ) : null}

      {getDevice.length ? (
        <RealTimeMqttData
          getDevice={selectTagData}
          handelCubeChartLineData={handelCubeChartLineData}
          handelCubeChartLineData1={handelCubeChartLineData1}
          handelCubeChartLineData2={handelCubeChartLineData2}
          handelCubeChartLineData3={handelCubeChartLineData3}
          selectTimeRange={selectTimeRange}
          setCombineChartData={setCombineChartData}
          setCombineminMax={setCombineminMax}
          setCombinerealTimeData={setCombinerealTimeData}
          combineRealTimeData={combineRealTimeData}
          setTagVMSData={setTagVMSData}
          setMqttTagPreviousvalue={setMqttTagPreviousvalue}
          setMqttTagData={setMqttTagData}
          mqttTagData={mqttTagData}
        />
      ) : null}
      <div className="page-wraper pb-5 page-wraper-overview">
        <div className="page-header page-header-analytics mb-4">
          <div onClick={() => navigate(-1)} className="page-back-btn">
            <ArrowBackIcon />
            <span>Live Dashboard</span>
          </div>
        </div>
        <div className="pt-2 consumption-grid-card">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Box className="smartTag-boxes-report">
                <Box className="consumer-text-disc">
                  <h6 className="Title">Title</h6>
                  <h6>Data Charts</h6>
                </Box>

                <Box>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "13px 8px",
                      borderRadius: "4px",
                      width: "100%",
                      background: "#f5f5f5",
                      cursor: "pointer",
                      marginTop: "4px",
                    }}
                    onClick={handleClick}
                  >
                    Select Device <ArrowDropDownIcon />
                  </div>
                </Box>

                <Popper
                  open={openMachine}
                  anchorEl={anchorEl}
                  placement="bottom-start"
                  style={{ width: "23%", maxHeight: "300px", overflow: "auto" }}
                  className="machine-dropdown"
                >
                  <Paper>
                    {deviceData?.length ? (
                      deviceData.map((row) => (
                        <MenuItem
                          className="vms-coc-menuItem"
                          onClick={handlePropagation}
                        >
                          {" "}
                          {row.device__device_name}{" "}
                          <Switch
                            style={{ float: "right" }}
                            checked={
                              selectDeviceData &&
                              selectDeviceData.some(
                                (item) => item.machineId === row.device
                              )
                            }
                            onClick={() => handleSelectMachine(row)}
                          />
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem
                        className="vms-coc-menuItem"
                        onClick={handlePropagation}
                      >
                        No Machine Found
                      </MenuItem>
                    )}
                  </Paper>
                </Popper>

                <Box>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "13px 8px",
                      borderRadius: "4px",
                      width: "100%",
                      background: "#f5f5f5",
                      cursor: "pointer",
                      marginTop: "4px",
                    }}
                    onClick={handleClickUnit}
                  >
                    Select Unit <ArrowDropDownIcon />
                  </div>

                  <Popper
                    open={openUnit}
                    anchorEl={anchorEl2}
                    placement="bottom-start"
                    style={{
                      width: "23%",
                      maxHeight: "300px",
                      overflow: "auto",
                    }}
                  >
                    <Paper>
                      {unitData?.length ? (
                        unitData.map((row) => (
                          <MenuItem
                            key={row.tag_unit}
                            className="vms-coc-menuItem"
                            onClick={handlePropagation}
                          >
                            {" "}
                            {row.tag_unit}{" "}
                            <Switch
                              style={{ float: "right" }}
                              checked={
                                selectUnitData &&
                                selectUnitData.some(
                                  (item) => item.tagUnit === row.tag_unit
                                )
                              }
                              disabled={
                                selectUnitData.length > 0 &&
                                !selectUnitData.some(
                                  (item) => item.tagUnit === row.tag_unit
                                )
                              }
                              onClick={() => handleSelectReportsTagUnit(row)}
                            />
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem
                          className="vms-coc-menuItem"
                          onClick={handlePropagation}
                        >
                          No Units Found
                        </MenuItem>
                      )}
                    </Paper>
                  </Popper>
                </Box>

                <div style={{ marginTop: "14px", fontWeight: "bold" }}>
                  Tag List
                </div>

                <TextField
                  variant="outlined"
                  className="search-textfield Smarttag-search"
                  placeholder="Search Unit here"
                  value={searchTag}
                  onChange={(e) => setSearchTag(e.target.value)}
                  InputProps={{
                    startAdornment: <SearchIcon />,
                    inputProps: {
                      style: { padding: "9px" },
                    },
                  }}
                />

                <div className="smartTag-report-taglist-unit-data">
                  <Box className="Grid-text1 mt-2">
                    {tagListData
                      ?.filter(
                        (e) =>
                          e.tag_name &&
                          e.tag_name
                            .toLowerCase()
                            .includes(searchTag.toLowerCase())
                      )
                      .map((row, index) => (
                        <Grid item xs={12}>
                          <Grid
                            container
                            alignItems="center"
                            style={{
                              background: "#efeffd",
                              width: "100%",
                              marginBottom: "7px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              style={{
                                padding: "7px 12px",
                                borderLeft: `4px solid ${
                                  switchColors[index % switchColors.length]
                                }`,
                              }}
                            >
                              <h6 className="mt-2">{row.tag_name}</h6>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              textAlign="right"
                              className="grid-switch-unit"
                            >
                              <Switch
                                style={{ float: "right" }}
                                onClick={(event) =>
                                  handleSelectTagName(
                                    param.plantId,
                                    index,
                                    event,
                                    row,
                                    selectUnit
                                  )
                                }
                                name={switchColors[index]}
                                disabled={isCheckboxDisabled(row.tag_name)}
                                checked={
                                  selectTagData.find(
                                    (f) => f.tagName == row.tag_name
                                  )
                                    ? true
                                    : false
                                }
                                sx={{
                                  "& .MuiSwitch-thumb": {
                                    bgcolor: "#828282",
                                  },
                                  "& .MuiSwitch-track": {
                                    bgcolor: "#D9D9E5 !important",
                                  },
                                  "& .Mui-checked": {
                                    "& .MuiSwitch-thumb": {
                                      bgcolor:
                                        switchColors[
                                          index % switchColors.length
                                        ],
                                    },
                                    "& .MuiSwitch-track": {
                                      bgcolor: "#E2D9F2 !important",
                                    },
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                  </Box>
                </div>

                <div>
                  <button
                    className="export-btn"
                    style={{ marginTop: "46px" }}
                    onClick={handleExcelData}
                  >
                    Export SmartTag Data
                  </button>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <Box className="source-table-box">
                <div style={{ paddingTop: "20px" }}>
                  <div className="d-flex justify-content-end">
                    <div className="d-flex avg-switch">
                      <p className="average-toggle consumption-avg-toggle">
                        Average
                      </p>

                      <Switch
                        checked={average}
                        onChange={(e) => setAverage(e.target.checked)}
                        className="average-toggle-switch"
                        sx={{
                          "& .MuiSwitch-thumb": {
                            bgcolor: "#828282",
                          },
                          "& .MuiSwitch-track": {
                            bgcolor: "#D9D9E5 !important",
                          },
                          "& .Mui-checked": {
                            "& .MuiSwitch-thumb": {
                              bgcolor: "#50C878",
                            },
                            "& .MuiSwitch-track": {
                              bgcolor: "#CEE6E1 !important",
                            },
                          },
                        }}
                      />
                    </div>

                    <div className="d-flex avg-switch">
                      <h6 className="time-desc">Interval</h6>

                      <Box style={{ float: "right", marginRight: "20px" }}>
                        <select
                          className="form-input-class consumption-interval-toggle"
                          style={{ padding: "6px 8px", borderRadius: "4px" }}
                          id="selectedClass"
                          defaultValue="all"
                          value={selectTimeRange}
                          onChange={(event) =>
                            setSelectTimeRange(event.target.value)
                          }
                          required
                        >
                          <option value="live" selected>
                            Live
                          </option>
                          <option value="1min">1 Min</option>
                          <option value="5min">5 Min</option>
                          <option value="15min">15 Min</option>
                        </select>
                      </Box>
                    </div>
                  </div>
                </div>

                <div
                  style={{ height: "421px", width: "100%", padding: "20px" }}
                >
                  <HighchartsGraphData
                    combineChartData={combineChartData}
                    selectTagData={selectTagData.filter(
                      (f) => f.plantId == param.plantId
                    )}
                    lineGraphData={chartLineData}
                    selectUnitData={CompactTagUnit(
                      selectUnit,
                      selectTagData.length
                        ? selectTagData.filter(
                            (f) => f.plantId == param.plantId
                          )[0]?.["tagUnit"] || ""
                        : ""
                    )}
                    average={average}
                    averageValue={averageValue}
                    selectColorData={selectColorData.filter(
                      (f) => f.plantId == param.plantId
                    )}
                    selectedPreFixData={selectedPreFixData}
                  />
                </div>
                <div>
                  <Table className="source-table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            fontWeight: "600",
                            width: "18%",
                            fontSize: "18px",
                            fontWeight: "800",
                          }}
                        >
                          {CompactTagUnit(
                            selectUnit,
                            selectUnitData.length
                              ? selectUnitData.filter(
                                  (f) => f.plantId == param.plantId
                                )[0]?.["tagUnit"] || ""
                              : ""
                          )}
                        </TableCell>
                        {selectTagData.length
                          ? selectTagData.filter(
                              (f) => f.plantId == param.plantId
                            ).length
                            ? selectTagData
                                .filter((f) => f.plantId == param.plantId)
                                .map((e, index) => {
                                  const findStatus = tagStatus.length
                                    ? tagStatus.find(
                                        (f) => f.dvName == e.device_tag
                                      )
                                    : 0;

                                  return (
                                    <TableCell>
                                      <div className="d-flex justify-content-between">
                                        <div>{e.tagName}</div>
                                        <div>
                                          {e.softTag && e.softTagFormula ? (
                                            tagStatus.find(
                                              (status) =>
                                                status.formula ===
                                                e.softTagFormula
                                            )?.statusValue < 0 ? (
                                              <SouthIcon
                                                style={{ color: e.color }}
                                              />
                                            ) : (
                                              <NorthIcon
                                                style={{ color: e.color }}
                                              />
                                            )
                                          ) : findStatus ? (
                                            findStatus.statusValue < 0 ? (
                                              <SouthIcon
                                                style={{ color: e.color }}
                                              />
                                            ) : (
                                              <NorthIcon
                                                style={{ color: e.color }}
                                              />
                                            )
                                          ) : (
                                            <NorthIcon
                                              style={{ color: e.color }}
                                            />
                                          )}
                                          {e.softTag && e.softTagFormula
                                            ? (
                                                tagStatus.find(
                                                  (status) =>
                                                    status.formula ==
                                                    e.softTagFormula
                                                )?.statusValue || 0.0
                                              ).toFixed(2)
                                            : findStatus
                                            ? (
                                                findStatus.statusValue || 0.0
                                              ).toFixed(2)
                                            : (0.0).toFixed(2)}
                                          %
                                        </div>
                                      </div>
                                    </TableCell>
                                  );
                                })
                            : []
                          : []}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ fontWeight: "500" }}>
                          Real Time
                        </TableCell>
                        {selectTagData.length
                          ? selectTagData.filter(
                              (f) => f.plantId == param.plantId
                            ).length
                            ? selectTagData
                                .filter((f) => f.plantId == param.plantId)
                                .map((row) => {
                                  const findTagName = tagVMSData.length
                                    ? tagVMSData.find(
                                        (f) => f.device_tag == row.device_tag
                                      )
                                    : "";

                                  const hasKwUnit = selectTagData.length
                                    ? selectTagData.some((f) =>
                                        f.tagUnit.toLowerCase().startsWith("k")
                                      )
                                    : false;

                                  return (
                                    <TableCell>
                                      {selectedPreFixData
                                        ? row.softTag && row.softTagFormula
                                          ? SoftTagFormula(
                                              row.softTagFormula,
                                              mqttDataReduce
                                            )?.softTagNotGetTag
                                            ? CompactTagValue(
                                                selectUnit,
                                                SoftTagFormula(
                                                  row.softTagFormula,
                                                  mqttDataReduce
                                                )?.value
                                              )
                                            : findTagName?.tagValue
                                            ? CompactTagValue(
                                                selectUnit,
                                                findTagName.tagValue
                                              )
                                            : "-"
                                          : findTagName?.tagValue
                                          ? CompactTagValue(
                                              selectUnit,
                                              findTagName.tagValue
                                            )
                                          : "-"
                                        : row.softTag && row.softTagFormula
                                        ? SoftTagFormula(
                                            row.softTagFormula,
                                            mqttDataReduce
                                          )?.softTagNotGetTag
                                          ? SoftTagFormula(
                                              row.softTagFormula,
                                              mqttDataReduce
                                            )?.value
                                          : findTagName?.tagValue
                                          ? findTagName.tagValue
                                          : "-"
                                        : findTagName?.tagValue
                                        ? findTagName.tagValue
                                        : "-"}
                                    </TableCell>
                                  );
                                })
                            : null
                          : null}
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: "500" }}>
                          All Time Min.
                        </TableCell>
                        {selectTagData.length
                          ? selectTagData.filter(
                              (f) => f.plantId == param.plantId
                            ).length
                            ? selectTagData
                                .filter((f) => f.plantId == param.plantId)
                                .map((row) => {
                                  const findMinValue = tagMinMaxValue.length
                                    ? tagMinMaxValue.find(
                                        (f) => row.tag_id == f.tag_id
                                      )
                                    : 0;
                                  return (
                                    <TableCell>
                                      {findMinValue
                                        ? findMinValue.all_time_min_value
                                        : "-"}
                                    </TableCell>
                                  );
                                })
                            : null
                          : null}
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: "500" }}>
                          All Time Max.
                        </TableCell>
                        {selectTagData.length
                          ? selectTagData.filter(
                              (f) => f.plantId == param.plantId
                            ).length
                            ? selectTagData
                                .filter((f) => f.plantId == param.plantId)
                                .map((row) => {
                                  const findMaxValue = tagMinMaxValue.length
                                    ? tagMinMaxValue.find(
                                        (f) => row.tag_id == f.tag_id
                                      )
                                    : "";
                                  return (
                                    <TableCell>
                                      {findMaxValue
                                        ? findMaxValue.all_time_max_value
                                        : "-"}
                                    </TableCell>
                                  );
                                })
                            : null
                          : null}
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
export default SmartTagAnalyticsList;
