import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import EnergyIdealActual from "./EnergyIdealVsActual";
import CostProduction from "./CostVsProduction";
import deviceData from "../Json/AnalyticsData.json";
import { useDispatch, useSelector } from "react-redux";
import { setAnalyticsData } from "./Slice/AdvanceAnalyticsSlice";
import { EnergyCostCOC } from "./Graph/EnergyCostCOC";
import { EnergyPiechartCOC } from "./Graph/EnergyCostPieChartCOC";
import { EnergyPOPChart } from "./Graph/EnergyPOP";
import { EnergyPiechartPOP } from "./Graph/EnergyPieChartPOP";
import dayjs from "dayjs";
import {
  analyticsEnergyCostCOCExcel,
  analyticsEnergyCostPOPExcel,
  getEnergyCostDevice,
  getEnergyCostSummaryCOCBarChart,
  getEnergyCostSummaryCOCBarChartReceipe,
} from "./services";
import { images } from "../../../config";
import COCDatePicker from "../../../components/COCDatepicker/COCDatePicker";
import POPDatePicker from "../../../components/POPDatePicker/POPDatePicker";
import { ToastContainer, toast } from "react-toastify";
import DeviceListShimmer from "../../../components/Shimmer/DeviceListShimmer/DeviceListShimmer";
import { setProcessDashboarDateSet } from "../sliceProcessDashboard/dateSliceprocessDashboard";
import { getDeviceReceipe } from "../OverView/services";

const EnergyCost = () => {
  const [activeClass, setActiveClass] = useState("Analytics");
  const [active, setActive] = useState("Consumer");
  const [value, setValue] = React.useState("1");
  const [searchQuery, setSearchQuery] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [getEnergyCostDeviceData, setEnergyCostDeviceData] = useState([]);
  const [getEnergyCostData, setEnergyCostData] = useState([]);
  const [energyCostPOPData, setEnergyCostPOPData] = useState([]);
  const [energyCostPOP1Data, setEnergyCostPOP1Data] = useState([]);
  const [energyCostSum, setEnergyCostSum] = useState(0);
  const [energyCostPOPSum, setEnergyCostPOPSum] = useState(0);
  const [energyCostPOP1Sum, setEnergyCostPOP1Sum] = useState(0);
  const [isDeviceSelected, setIsDeviceSelected] = useState(false);
  const [isShimmerdevicelist, setisShimmerdevicelist] = useState(false);
  const [openEnergyModal, setOpenEnergyModal] = useState(false);
  // const [checkedItems, setCheckedItems] = useState([]);
  // const [checkedPOPItems, setCheckedPOPItems] = useState([]);
  // const [allChecked, setAllChecked] = useState(false);
  // const [allPOPChecked, setAllPOPChecked] = useState(false);

  const {
    energyCostCOCId,
    energyCostDeviceColors,
    energyCostPOPId,
    energyCostDevicePOPColors,
    energyCostStartDateCOC,
    energyCostEndDateCOC,
    energyCostStartDatePOP,
    energyCostEndDatePOP,
    energyCostStartDatePOP1,
    energyCostEndDatePOP1,
    checkedItems,
    checkedPOPItems,
    allChecked,
    allPOPChecked,
  } = useSelector((state) => state.analyticsDataState);
  const [isLoading1, setLoading1] = useState(false);
  const [isLoadingPOP, setLoadingPOP] = useState(false);
  const [emptyDevice, setEmptyDevice] = useState(false);
  const [isShimmerCOC, setShimmerCOC] = useState(false);
  const [isShimmerPOP, setShimmerPOP] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [receipeData, setReceipeData] = useState([]);

  useEffect(() => {
    dispatch(
      setAnalyticsData({
        allChecked: checkedItems.length === receipeData.length,
      })
    );
    // setAllChecked(checkedItems.length === receipeData.length);
  }, [checkedItems, receipeData.length]);

  useEffect(() => {
    dispatch(
      setAnalyticsData({
        allPOPChecked: checkedPOPItems.length === receipeData.length,
      })
    );
    // setAllPOPChecked(checkedPOPItems.length === receipeData.length);
  }, [checkedPOPItems, receipeData.length]);

  const handleAllToggle = () => {
    if (allChecked) {
      dispatch(setAnalyticsData({ checkedItems: [] }));
      // setCheckedItems([]);
    } else {
      dispatch(setAnalyticsData({ checkedItems: receipeData }));
      // setCheckedItems(receipeData);
    }
    dispatch(setAnalyticsData({ allChecked: !allChecked }));
    // setAllChecked(!allChecked);
  };

  const handleAllPOPToggle = () => {
    if (allPOPChecked) {
      dispatch(setAnalyticsData({ checkedPOPItems: [] }));
      // setCheckedPOPItems([]);
    } else {
      dispatch(setAnalyticsData({ checkedPOPItems: receipeData }));
      // setCheckedPOPItems(receipeData);
    }
    dispatch(setAnalyticsData({ allPOPChecked: !allPOPChecked }));
    // setAllPOPChecked(!allPOPChecked);
  };

  const { user_access } = useSelector((state) => state.userState);
  const { selectCostUnit } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );
  const modalRef = useRef();
  console.log("energyCostPOPId", energyCostPOPId[0]?.deviceID);
  const param = useParams();
  const MAX_DATE_RANGE_DAYS = 365; // Maximum duration of one year in days

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Check if any device is selected
  useEffect(() => {
    const selectedDevices = energyCostDevicePOPColors.filter(
      (stack) => stack.plantId === param.id
    );
    setIsDeviceSelected(selectedDevices.length > 0);
  }, [energyCostDevicePOPColors, param.id]);

  const dateFormat = "YYYY-MM-DD";
  const startDateReFormat = energyCostStartDateCOC
    ? dayjs(energyCostStartDateCOC)
    : null;
  const endDateReFormat = energyCostEndDateCOC
    ? dayjs(energyCostEndDateCOC)
    : null;

  const startDatePOPFormat = energyCostStartDatePOP
    ? dayjs(energyCostStartDatePOP)
    : null;
  const endDatePOPFormat = energyCostEndDatePOP
    ? dayjs(energyCostEndDatePOP)
    : null;

  const startDatePOP1Format = energyCostStartDatePOP1
    ? dayjs(energyCostStartDatePOP1)
    : null;
  const endDatePOP1Format = energyCostEndDatePOP1
    ? dayjs(energyCostEndDatePOP1)
    : null;

  const formatStartDate = energyCostStartDateCOC
    ? dayjs(energyCostStartDateCOC).format(dateFormat)
    : "";
  const formatEndDate = energyCostEndDateCOC
    ? dayjs(energyCostEndDateCOC).format(dateFormat)
    : "";

  const formatstartDatePOP = energyCostStartDatePOP
    ? dayjs(energyCostStartDatePOP).format(dateFormat)
    : "";
  const formatEndDatePOP = energyCostEndDatePOP
    ? dayjs(energyCostEndDatePOP).format(dateFormat)
    : "";

  const formatstartDatePOP1 = energyCostStartDatePOP1
    ? dayjs(energyCostStartDatePOP1).format(dateFormat)
    : "";
  const formatEndDatePOP1 = energyCostEndDatePOP1
    ? dayjs(energyCostEndDatePOP1).format(dateFormat)
    : "";

  useEffect(() => {
    handleEnergyCostDevice();
  }, []);

  useEffect(() => {
    if (formatStartDate && formatEndDate) {
      handleEnergyCostCOCBarChart();
    }
  }, [
    formatStartDate,
    formatEndDate,
    energyCostCOCId,
    selectCostUnit,
    checkedItems,
  ]);

  useEffect(() => {
    const findPlantByDevice = energyCostPOPId.length
      ? energyCostPOPId.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantByDevice.length) {
      handleEnergyCostPOPBarChart();
    } else {
      setEnergyCostPOPData([]);
    }
  }, [
    formatstartDatePOP,
    formatEndDatePOP,
    energyCostPOPId,
    selectCostUnit,
    checkedPOPItems,
  ]);

  useEffect(() => {
    const findPlantByDevice = energyCostPOPId.length
      ? energyCostPOPId.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantByDevice.length) {
      handleEnergyCostPOP1BarChart();
    } else {
      setEnergyCostPOP1Data([]);
    }
  }, [
    formatstartDatePOP1,
    formatEndDatePOP1,
    energyCostPOPId,
    selectCostUnit,
    checkedPOPItems,
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();

  const deviceName = deviceData.device;

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleToggle = (option) => {
    dispatch(
      setAnalyticsData({
        checkedItems: checkedItems.includes(option)
          ? checkedItems.filter((item) => item !== option)
          : [...checkedItems, option],
      })
    );
  };

  const handlePOPToggle = (option) => {
    dispatch(
      setAnalyticsData({
        checkedPOPItems: checkedPOPItems.includes(option)
          ? checkedPOPItems.filter((item) => item !== option)
          : [...checkedPOPItems, option],
      })
    );
    // setCheckedPOPItems((prevChecked) =>
    //   prevChecked.includes(option)
    //     ? prevChecked.filter((item) => item !== option)
    //     : [...prevChecked, option]
    // );
  };

  useEffect(() => {
    handleReceipeClick();
  }, [energyCostCOCId, energyCostPOPId, active]);

  const handleReceipeClick = async () => {
    const params = {
      device:
        active == "Consumer" ? energyCostCOCId : [energyCostPOPId[0]?.deviceID],
    };
    try {
      const resp = await getDeviceReceipe(params);
      setReceipeData(resp.data.payload);
    } catch (error) {
      setReceipeData([]);
      console.log("handleSubmit", error);
    }
  };

  console.log("receipe", receipeData);

  const handleEnergyCostCOCExcel = async () => {
    const params = {
      company: param.companyId,
      plant: param.id,
      device: energyCostCOCId,
      start_date: `${formatStartDate} 00:00:00`,
      end_date: `${formatEndDate} 23:59:59`,
    };
    if (checkedItems) {
      params["recipe_names"] = checkedItems;
    }
    try {
      const resp = await analyticsEnergyCostCOCExcel(params);
      if (resp.data.success == true) {
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };
  const handleEnergyCostPOPExcel = async () => {
    // const params = {
    //   company: Number(param.companyId),
    //   plant: Number(param.id),
    //   device: [energyCostPOPId[0]?.deviceID],
    //   first_start_date: `${formatstartDatePOP} 00:00:00`,
    //   first_end_date: `${formatEndDatePOP} 23:59:59`,
    //   second_start_date: `${formatstartDatePOP1} 00:00:00`,
    //   second_end_date: `${formatEndDatePOP1} 23:59:59`,
    // };
    const params = {
      company: param.companyId,
      plant: param.id,
      device: [energyCostPOPId[0]?.deviceID],
      first_start_date: `${formatstartDatePOP} 00:00:00`,
      first_end_date: `${formatEndDatePOP} 23:59:59`,
      second_start_date: `${formatstartDatePOP1} 00:00:00`,
      second_end_date: `${formatEndDatePOP1} 23:59:59`,
    };
    if (checkedPOPItems) {
      params["recipe_names"] = checkedPOPItems;
    }
    try {
      const resp = await analyticsEnergyCostPOPExcel(params);
      if (resp.data.success == true) {
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };

  const dispatch = useDispatch();
  const switchColors = [
    "#FEBB22",
    "#002B2A",
    "#C99C00",
    "#EB9E79",
    "#69927A",
    "#872B81",
    "#F06292",
    "#0EB520",
    "#CD4D4A",
    "#123782",
    "#544632",
    "#94655B",
    "#0F4C81",
    "#8B0000",
    "#C60BC8",
    "#7D2264",
    "#BC2F00",
    "#556830",
    "#FCA311",
    "#021116",
    "#808080",
    "#300338",
    "#634C23",
    "#841E62",
    "#F34424",
    "#003333",
    "#020000",
    "#305355",
    "#5C3A31",
    "#D9750C",
    "#0C4562",
    "#001A30",
    "#00838F",
    "#785049",
    "#FF8800",
    "#0B7C8E",
    "#08535E",
    "#0E3504",
    "#C48200",
    "#846748",
    "#075571",
    "#9C27B0",
    "#A97E07",
    "#CE286D",
    "#8409DF",
    "#0B648D",
    "#29445A",
    "#1D733F",
    "#6E02B1",
  ];

  useEffect(() => {
    // Find the specific plant that matches the plantId
    const matchedPlant = getEnergyCostData.find((e) =>
      e.data.length ? true : false
    );

    if (matchedPlant) {
      // If the matchedPlant exists, check if its device array is empty
      setEmptyDevice(true);
    }
  }, [getEnergyCostData]);

  useEffect(() => {
    // Find the specific plant that matches the plantId
    const matchedPlant = energyCostPOPData.find((e) =>
      e.data.length ? true : false
    );

    if (matchedPlant) {
      // If the matchedPlant exists, check if its device array is empty
      setEmptyDevice(true);
    }
  }, [energyCostPOPData]);

  useEffect(() => {
    // Find the specific plant that matches the plantId
    const matchedPlant = energyCostPOP1Data.find((e) =>
      e.data.length ? true : false
    );

    if (matchedPlant) {
      // If the matchedPlant exists, check if its device array is empty
      setEmptyDevice(true);
    }
  }, [energyCostPOP1Data]);

  const handleSelectDeviceId = (id, index, event, row) => {
    const existingIndex = energyCostDeviceColors.findIndex(
      (color) => color.deviceId === row.id
    );

    if (existingIndex !== -1) {
      const updatedColors = [...energyCostDeviceColors];
      updatedColors.splice(existingIndex, 1);
      dispatch(
        setAnalyticsData({
          energyCostDeviceColors: updatedColors,
        })
      );
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setAnalyticsData({
          energyCostDeviceColors: [
            ...energyCostDeviceColors,
            {
              deviceId: row.id,
              dvName: row.device_name,
              color: event.target.name,
              plantId: param.id,
            },
          ],
        })
      );
    }

    if (!Array.isArray(energyCostCOCId)) {
      setDeviceId([]);
      dispatch(setAnalyticsData({ energyCostCOCId: [] }));
    }
    if (energyCostCOCId.includes(id)) {
      // setDeviceId(energyCostCOCId.filter((element) => element !== id));
      dispatch(
        setAnalyticsData({
          energyCostCOCId: energyCostCOCId.filter((element) => element !== id),
        })
      );
    } else {
      // setDeviceId([...energyCostCOCId, id]);
      dispatch(setAnalyticsData({ energyCostCOCId: [...energyCostCOCId, id] }));
    }
  };

  const handleSelectPOPDeviceId = (id, index, event, row) => {
    const existingIndex = energyCostDevicePOPColors.findIndex(
      (color) => color.deviceId === row.id && color.plantId == param.id
    );

    const existingIndexDevice = energyCostPOPId.findIndex(
      (color) => color.deviceID === row.id && color.plantId == param.id
    );

    if (existingIndex !== -1 && existingIndexDevice !== -1) {
      // If the selected device already exists, remove it
      const updatedColors = [...energyCostDevicePOPColors];
      updatedColors.splice(existingIndex, 1);
      dispatch(
        setAnalyticsData({
          energyCostDevicePOPColors: updatedColors,
        })
      );

      // Unselect the device
      const updateDevice = [...energyCostPOPId];
      updateDevice.splice(existingIndexDevice, 1);
      dispatch(setAnalyticsData({ energyCostPOPId: updateDevice }));
    } else {
      // Remove any previously selected devices
      // dispatch(setAnalyticsData({ energyCostDevicePOPColors: [] }));
      const existingId = energyCostPOPId.length
        ? energyCostPOPId.find((f) => f.plantId == param.id)
          ? true
          : false
        : false;

      if (existingId) {
        return true;
      }

      // Add the selected device to the state array
      dispatch(
        setAnalyticsData({
          energyCostDevicePOPColors: [
            {
              deviceId: row.id,
              dvName: row.device_name,
              color: event.target.name,
              plantId: param.id,
            },
          ],
        })
      );

      // Set the selected device as the only device in the array
      dispatch(
        setAnalyticsData({
          energyCostPOPId: [
            ...energyCostPOPId,
            { plantId: param.id, deviceID: id },
          ],
        })
      );
    }
  };

  const handleEnergyCostDevice = async () => {
    setisShimmerdevicelist(true);
    const params = {
      plant: param.id,
      company: param.companyId,
    };
    try {
      const resp = await getEnergyCostDevice(params);
      if (resp.status == 200 || resp.status == 201) {
        setTimeout(() => {
          setisShimmerdevicelist(false);
        }, 1000);

        setEnergyCostDeviceData(resp.data.payload);
      }
    } catch (error) {
      setisShimmerdevicelist(false);

      console.log("handleSubmit", error);
    }
  };

  const handleEnergyCostCOCBarChart = async () => {
    const params = {
      company: param.companyId,
      plant: param.id,
      device: energyCostCOCId,
      start_date: formatStartDate,
      end_date: formatEndDate,
      // unit: selectCostUnit,
      recipe_names: checkedItems,
    };

    if (selectCostUnit != "") {
      params["unit"] = selectCostUnit;
    }

    try {
      setShimmerCOC(true);
      const resp = await getEnergyCostSummaryCOCBarChartReceipe(params);
      if (resp.status == 200 || resp.status == 201) {
        setTimeout(() => {
          setShimmerCOC(false);
        }, 2000);

        setEnergyCostData(resp.data.payload);
        setEnergyCostSum(resp.data.sub_total);
      }
    } catch (error) {
      setShimmerCOC(false);
      console.log("handleSubmit", error);
    }
  };

  const handleEnergyCostPOPBarChart = async () => {
    const findPlantByDevice = energyCostPOPId.length
      ? energyCostPOPId.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantByDevice.length && formatstartDatePOP && formatEndDatePOP) {
      const params = {
        company: param.companyId,
        plant: param.id,
        device: findPlantByDevice.map((row) => row.deviceID),
        start_date: formatstartDatePOP,
        end_date: formatEndDatePOP,
        recipe_names: checkedPOPItems,
        // unit: selectCostUnit,
      };
      if (selectCostUnit != "") {
        params["unit"] = selectCostUnit;
      }
      try {
        setShimmerPOP(true);
        const resp = await getEnergyCostSummaryCOCBarChartReceipe(params);
        if (resp.status == 200 || resp.status == 201) {
          setTimeout(() => {
            setShimmerPOP(false);
          }, 2000);

          setEnergyCostPOPData(resp.data.payload);
          setEnergyCostPOPSum(resp.data.sub_total);
        }
      } catch (error) {
        setShimmerPOP(false);
        console.log("handleSubmit", error);
      }
    }
  };

  const handleEnergyCostPOP1BarChart = async () => {
    const findPlantByDevice = energyCostPOPId.length
      ? energyCostPOPId.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantByDevice.length && formatstartDatePOP1 && formatEndDatePOP1) {
      const params = {
        company: param.companyId,
        plant: param.id,
        device: findPlantByDevice.map((row) => row.deviceID),
        start_date: formatstartDatePOP1,
        end_date: formatEndDatePOP1,
        recipe_names: checkedPOPItems,
        // unit: selectCostUnit,
      };

      if (selectCostUnit != "") {
        params["unit"] = selectCostUnit;
      }

      try {
        setShimmerPOP(true);
        const resp = await getEnergyCostSummaryCOCBarChartReceipe(params);
        if (resp.status == 200 || resp.status == 201) {
          setTimeout(() => {
            setShimmerPOP(false);
          }, 2000);

          setEnergyCostPOP1Data(resp.data.payload);
          setEnergyCostPOP1Sum(resp.data.sub_total);
        }
      } catch (error) {
        setShimmerPOP(false);
        console.log("handleSubmit", error);
      }
    }
  };

  const handleStartDate = (newValue) => {
    const durationInDays = endDateReFormat
      ? endDateReFormat.diff(newValue, "days")
        ? endDateReFormat.diff(newValue, "days") > 0
          ? endDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      // Valid date range (within one year)
      dispatch(
        setAnalyticsData({
          energyCostStartDateCOC: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReFormat && newValue.isBefore(endDateReFormat)) {
      // Start date is before end date
      dispatch(
        setAnalyticsData({
          energyCostStartDateCOC: newValue,
        })
      );
    } else if (!endDateReFormat) {
      // End date is not set yet
      dispatch(
        setAnalyticsData({
          energyCostStartDateCOC: newValue,
        })
      );
    } else if (endDateReFormat && newValue.isSame(endDateReFormat, "day")) {
      // End date is not set yet
      dispatch(
        setAnalyticsData({
          energyCostStartDateCOC: newValue,
        })
      );
    } else {
      // Start date is after end date
      dispatch(
        setAnalyticsData({
          energyCostStartDateCOC: null,
        })
      );
      notifyError("End date must be greater than start date");
    }

    // dispatch(
    //   setAnalyticsData({
    //     energyCostStartDateCOC: newValue,
    //   })
    // );
  };

  const handleEndDate = (newValue) => {
    const durationInDays = startDateReFormat
      ? startDateReFormat.diff(newValue, "days")
        ? startDateReFormat.diff(newValue, "days") > 0
          ? startDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(
        setAnalyticsData({
          energyCostEndDateCOC: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReFormat && newValue.isAfter(startDateReFormat)) {
      dispatch(
        setAnalyticsData({
          energyCostEndDateCOC: newValue,
        })
      );
    } else if (!startDateReFormat) {
      dispatch(
        setAnalyticsData({
          energyCostEndDateCOC: newValue,
        })
      );
    } else if (startDateReFormat && newValue.isSame(startDateReFormat, "day")) {
      // End date is not set yet
      dispatch(
        setAnalyticsData({
          energyCostEndDateCOC: newValue,
        })
      );
    } else {
      // Start date is after end date
      dispatch(
        setAnalyticsData({
          energyCostEndDateCOC: null,
        })
      );
      notifyError(" startDate must less than endDate");
    }

    // dispatch(
    //   setAnalyticsData({
    //     energyCostEndDateCOC: newValue,
    //   })
    // );
  };

  const handlePOPStartDate = (newValue) => {
    const durationInDays = endDatePOPFormat
      ? endDatePOPFormat.diff(newValue, "days")
        ? endDatePOPFormat.diff(newValue, "days") > 0
          ? endDatePOPFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      // Valid date range (within one year)
      dispatch(
        setAnalyticsData({
          energyCostStartDatePOP: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDatePOPFormat && newValue.isBefore(endDatePOPFormat)) {
      // Start date is before end date
      dispatch(
        setAnalyticsData({
          energyCostStartDatePOP: newValue,
        })
      );
    } else if (!endDatePOPFormat) {
      // End date is not set yet
      dispatch(
        setAnalyticsData({
          energyCostStartDatePOP: newValue,
        })
      );
    } else if (endDatePOPFormat && newValue.isSame(endDatePOPFormat, "day")) {
      // End date is not set yet
      dispatch(
        setAnalyticsData({
          energyCostStartDatePOP: newValue,
        })
      );
    } else {
      // Start date is after end date
      dispatch(
        setAnalyticsData({
          energyCostStartDatePOP: null,
        })
      );
      notifyError("End date must be greater than start date");
    }

    // dispatch(
    //   setAnalyticsData({
    //     energyCostStartDatePOP: newValue,
    //   })
    // )
  };

  const handlePOPEndDate = (newValue) => {
    const durationInDays = startDatePOPFormat
      ? startDatePOPFormat.diff(newValue, "days")
        ? startDatePOPFormat.diff(newValue, "days") > 0
          ? startDatePOPFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(
        setAnalyticsData({
          energyCostEndDatePOP: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDatePOPFormat && newValue.isAfter(startDatePOPFormat)) {
      dispatch(
        setAnalyticsData({
          energyCostEndDatePOP: newValue,
        })
      );
    } else if (!startDatePOPFormat) {
      dispatch(
        setAnalyticsData({
          energyCostEndDatePOP: newValue,
        })
      );
    } else if (
      startDatePOPFormat &&
      newValue.isSame(startDatePOPFormat, "day")
    ) {
      // End date is not set yet
      dispatch(
        setAnalyticsData({
          energyCostEndDatePOP: newValue,
        })
      );
    } else {
      // Start date is after end date
      dispatch(
        setAnalyticsData({
          energyCostEndDatePOP: null,
        })
      );
      notifyError(" startDate must less than endDate");
    }

    // dispatch(
    //   setAnalyticsData({
    //     energyCostEndDatePOP: newValue,
    //   })
    // )
  };

  const handlePOP1StartDate = (newValue) => {
    const durationInDays = endDatePOP1Format
      ? endDatePOP1Format.diff(newValue, "days")
        ? endDatePOP1Format.diff(newValue, "days") > 0
          ? endDatePOP1Format.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      // Valid date range (within one year)
      dispatch(
        setAnalyticsData({
          energyCostStartDatePOP1: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDatePOP1Format && newValue.isBefore(endDatePOP1Format)) {
      // Start date is before end date
      dispatch(
        setAnalyticsData({
          energyCostStartDatePOP1: newValue,
        })
      );
    } else if (!endDatePOP1Format) {
      // End date is not set yet
      dispatch(
        setAnalyticsData({
          energyCostStartDatePOP1: newValue,
        })
      );
    } else if (endDatePOP1Format && newValue.isSame(endDatePOP1Format, "day")) {
      // End date is not set yet
      dispatch(
        setAnalyticsData({
          energyCostStartDatePOP1: newValue,
        })
      );
    } else {
      // Start date is after end date
      dispatch(
        setAnalyticsData({
          energyCostStartDatePOP1: null,
        })
      );
      notifyError("End date must be greater than start date");
    }

    // dispatch(
    //   setAnalyticsData({
    //     energyCostStartDatePOP1: newValue,
    //   })
    // )
  };

  const handlePOP1EndDate = (newValue) => {
    const durationInDays = startDatePOP1Format
      ? startDatePOP1Format.diff(newValue, "days")
        ? startDatePOP1Format.diff(newValue, "days") > 0
          ? startDatePOP1Format.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(
        setAnalyticsData({
          energyCostEndDatePOP1: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDatePOP1Format && newValue.isAfter(startDatePOP1Format)) {
      dispatch(
        setAnalyticsData({
          energyCostEndDatePOP1: newValue,
        })
      );
    } else if (!startDatePOP1Format) {
      dispatch(
        setAnalyticsData({
          energyCostEndDatePOP1: newValue,
        })
      );
    } else if (
      startDatePOP1Format &&
      newValue.isSame(startDatePOP1Format, "day")
    ) {
      // End date is not set yet
      dispatch(
        setAnalyticsData({
          energyCostEndDatePOP1: newValue,
        })
      );
    } else {
      // Start date is after end date
      dispatch(
        setAnalyticsData({
          energyCostEndDatePOP1: null,
        })
      );
      notifyError(" startDate must less than endDate");
    }

    // dispatch(
    //   setAnalyticsData({
    //     energyCostEndDatePOP1: newValue,
    //   })
    // )
  };

  const plantDashboardAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Plant Dashboard"
      )
    : null;

  const analyticsAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Advance Analytics"
        )
      : null
    : null;

  const handleTostMsg = () => {
    notifyError("You don't have access");
  };
  const handleOpenEnergyModal = () => {
    setOpenEnergyModal((prev) => {
      const newState = !prev;
      console.log("openEnergy", newState);
      return newState;
    });
  };

  const handleClickOutside = (event) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target) &&
      !event.target.closest(".filter-img-class")
    ) {
      setOpenEnergyModal(false);
    }
  };

  useEffect(() => {
    if (openEnergyModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openEnergyModal]);

  const { selectUnit } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );

  return (
    <>
      {/*  */}
      <div className="page-wraper">
        <div className="page-header page-header-analytics">
          {activeClass == "Analytics" ? (
            <>
              <div
                onClick={() =>
                  navigate(
                    `/analytics-configration/company/${param.companyId}/plant/${param.id}`
                  )
                }
                className="page-back-btn"
              >
                <ArrowBackIcon />
                <span>Energy Cost</span>
              </div>

              <div>
                <select
                  className="compact-dropdown confi-btn"
                  style={{ borderRadius: "4px" }}
                  value={selectCostUnit}
                  onChange={(e) =>
                    dispatch(
                      setProcessDashboarDateSet({
                        selectCostUnit: e.target.value,
                      })
                    )
                  }
                >
                  <option value="" selected>
                    Select Unit
                  </option>
                  <option value="k">Thousand</option>
                  <option value="m">Million</option>
                  <option value="b">Billion</option>
                </select>
              </div>
            </>
          ) : activeClass == "Reports" ? (
            <div
              onClick={() =>
                navigate(
                  `/analytics-configration/company/${param.companyId}/plant/${param.id}`
                )
              }
              className="page-back-btn"
              style={{ pointerEvents: "none" }}
            >
              <ArrowBackIcon />
              <span>Energy Cost ~ Cost Vs Production</span>
            </div>
          ) : (
            <div
              onClick={() =>
                navigate(
                  `/analytics-configration/company/${param.companyId}/plant/${param.id}`
                )
              }
              className="page-back-btn"
              style={{ pointerEvents: "none" }}
            >
              <ArrowBackIcon />
              <span>Energy Cost ~ Ideal Vs Actual</span>
            </div>
          )}
          <div className="d-flex">
            <button
              className={`${
                activeClass == "Analytics" ? "Analytics-tabs" : "Reports-tabs"
              }`}
              onClick={() => setActiveClass("Analytics")}
            >
              Summary
            </button>
            <button
              className={`${
                activeClass == "Reports" ? "Analytics-tabs" : "Reports-tabs"
              }`}
              onClick={() => setActiveClass("Reports")}
              style={{ pointerEvents: "none" }}
            >
              Cost Vs Production
            </button>
            <button
              className={`${
                activeClass == "Ideal" ? "Analytics-tabs" : "Reports-tabs"
              }`}
              onClick={() => setActiveClass("Ideal")}
              style={{ pointerEvents: "none" }}
            >
              Ideal Vs Actual
            </button>
          </div>
        </div>
        {activeClass == "Analytics" ? (
          <>
            <div className="analysis-tabs-btn analysis-tabs-btn-analytics pt-3">
              {/* <div className="analytics-tabs-btn">
                <div className="d-flex">
                  <button
                    className={`${
                      active == "Consumer"
                        ? "Analytics-tabs-overview"
                        : "Reports-tabs-overview"
                    }`}
                    onClick={() => setActive("Consumer")}
                    style={{ width: "210px", height: "50px" }}
                  >
                    Consumer over Consumer
                  </button>
                  <button
                    className={`${
                      active == "Period"
                        ? "Analytics-tabs-overview"
                        : "Reports-tabs-overview"
                    }`}
                    onClick={() => setActive("Period")}
                    style={{ width: "210px", height: "50px" }}
                  >
                    Period over Period
                  </button>
                </div>
              </div> */}
              <div className="analytics-tabs-btn">
                <div className="d-flex Overview-btns">
                  <button
                    className={`${
                      active == "Consumer"
                        ? "Analytics-tabs-overview"
                        : "Reports-tabs-overview"
                    }`}
                    onClick={() => setActive("Consumer")}
                    style={{ width: "210px", height: "50px" }}
                  >
                    Consumer over Consumer
                  </button>
                  <button
                    className={`${
                      active == "Period"
                        ? "Analytics-tabs-overview"
                        : "Reports-tabs-overview"
                    }`}
                    onClick={() => setActive("Period")}
                    style={{ width: "210px", height: "50px" }}
                  >
                    Period over Period
                  </button>
                </div>
                {active == "Consumer" ? (
                  <div
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the event from propagating to the document
                      handleOpenEnergyModal();
                    }}
                    className="filter-img-class"
                  >
                    <img
                      src={images.filterImg}
                      alt=""
                      style={{ height: "40px" }}
                    />
                  </div>
                ) : (
                  <div
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the event from propagating to the document
                      handleOpenEnergyModal();
                    }}
                    className="filter-img-class"
                  >
                    <img
                      src={images.filterImg}
                      alt=""
                      style={{ height: "40px" }}
                    />
                  </div>
                )}
              </div>
              <div
                className="analytics-aggregation analytics-datepicker gap-2"
                style={{ justifyContent: "flex-end", width: "100%" }}
              >
                {active == "Consumer" ? (
                  <>
                    <div>
                      <Button
                        variant="contained"
                        onClick={handleClick}
                        className="product-drop"
                      >
                        Select Product
                      </Button>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        className="select-pro-dropdown"
                      >
                        {receipeData.length > 0 && (
                          <MenuItem>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={allChecked}
                                  onChange={handleAllToggle}
                                />
                              }
                              label="All"
                            />
                          </MenuItem>
                        )}

                        {receipeData.length ? (
                          receipeData.map((option) => (
                            <MenuItem key={option}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={checkedItems.includes(option)}
                                    onChange={() => handleToggle(option)}
                                  />
                                }
                                label={option}
                              />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No receipe</MenuItem>
                        )}
                      </Menu>
                    </div>
                    <COCDatePicker
                      startDateReFormat={startDateReFormat}
                      endDateReFormat={endDateReFormat}
                      handleStartDate={handleStartDate}
                      handleEndDate={handleEndDate}
                      access={analyticsAccess}
                      handleTostMsg={handleTostMsg}
                    />
                  </>
                ) : (
                  <>
                    <div>
                      <Button
                        variant="contained"
                        onClick={handleClick}
                        className="product-drop"
                      >
                        Select Product
                      </Button>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        className="select-pro-dropdown"
                      >
                        {receipeData.length > 0 && (
                          <MenuItem>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={allPOPChecked}
                                  onChange={handleAllPOPToggle}
                                />
                              }
                              label="All"
                            />
                          </MenuItem>
                        )}

                        {receipeData.length ? (
                          receipeData.map((option) => (
                            <MenuItem key={option}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={checkedPOPItems?.includes(option)}
                                    onChange={() => handlePOPToggle(option)}
                                  />
                                }
                                label={option}
                              />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No receipe</MenuItem>
                        )}
                      </Menu>
                    </div>
                    <POPDatePicker
                      startDateReFormat={startDatePOPFormat}
                      endDateReFormat={endDatePOPFormat}
                      startDate1ReFormat={startDatePOP1Format}
                      endDate1ReFormat={endDatePOP1Format}
                      handleStartDate={handlePOPStartDate}
                      handleEndDate={handlePOPEndDate}
                      handlePOP1StartDate={handlePOP1StartDate}
                      handlePOP1EndDate={handlePOP1EndDate}
                      access={analyticsAccess}
                      handleTostMsg={handleTostMsg}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="pt-2 d-flex gap-3 consumption-grid-card">
              <Grid container spacing={2}>
                {active == "Consumer" ? (
                  <Grid item xs={12} sm={12} md={3}>
                    <Box
                      className="consumerData-box consumer-box"
                      style={{ minHeight: "712px" }}
                    >
                      <Box className="consumer-text-disc mb-2">
                        <h6 className="Title">Title</h6>
                        <h6>Availability Charts</h6>
                      </Box>
                      <Box className="consumer-text-disc">
                        <h6 className="Title">Description</h6>
                        <h6>Energy Cost Charts - Consumer over Consumer</h6>
                      </Box>

                      <TextField
                        variant="outlined"
                        className="search-textfield"
                        placeholder="Search Device here"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        InputProps={{
                          startAdornment: <SearchIcon />,
                          inputProps: {
                            style: { padding: "9px" },
                          },
                        }}
                      />
                      <div
                        className="furnace-grid"
                        style={{ height: "400px", overflow: "auto" }}
                      >
                        <Box className="Grid-text mt-2">
                          {!isShimmerdevicelist ? (
                            getEnergyCostDeviceData.length ? (
                              getEnergyCostDeviceData
                                .filter((e) =>
                                  e.device_name
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                                )
                                .map((e, index) => (
                                  <Grid item xs={12} key={e.device_name}>
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        style={{
                                          padding: "7px 12px",
                                          borderLeft: `4px solid ${
                                            switchColors[
                                              index % switchColors.length
                                            ]
                                          }`,
                                        }}
                                      >
                                        <h6 className="mt-2">
                                          {e.device_name}
                                        </h6>
                                      </Grid>
                                      {(analyticsAccess?.is_viewer &&
                                        analyticsAccess?.is_editor) ||
                                      (!analyticsAccess?.is_viewer &&
                                        analyticsAccess?.is_editor) ||
                                      (analyticsAccess?.is_viewer &&
                                        !analyticsAccess?.is_editor) ? (
                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            style={{ float: "right" }}
                                            onClick={(event) =>
                                              handleSelectDeviceId(
                                                e.id,
                                                index,
                                                event,
                                                e
                                              )
                                            }
                                            name={switchColors[index]}
                                            checked={energyCostCOCId.includes(
                                              e.id
                                            )}
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor:
                                                    switchColors[
                                                      index %
                                                        switchColors.length
                                                    ],
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#E2D9F2 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      ) : (
                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            style={{ float: "right" }}
                                            onClick={handleTostMsg}
                                            name={switchColors[index]}
                                            checked={energyCostCOCId.includes(
                                              e.id
                                            )}
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor:
                                                    switchColors[
                                                      index %
                                                        switchColors.length
                                                    ],
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#E2D9F2 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                ))
                            ) : (
                              []
                            )
                          ) : (
                            <DeviceListShimmer itemCount={3} />
                          )}
                        </Box>
                      </div>

                      <div className="mt-3">
                        <button
                          className="export-btn"
                          style={{ marginTop: "30px" }}
                          onClick={handleEnergyCostCOCExcel}
                        >
                          EXPORT ENERGY DATA
                        </button>
                      </div>
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={3}>
                    <Box className="consumerData-box consumer-box">
                      <Box className="consumer-text-disc mb-2">
                        <h6 className="Title">Title</h6>
                        <h6>Availability Charts</h6>
                      </Box>

                      <Box className="consumer-text-disc">
                        <h6 className="Title">Description</h6>
                        <h6>Energy Cost Charts - Period over Period</h6>
                      </Box>

                      <TextField
                        variant="outlined"
                        className="search-textfield"
                        placeholder="Search Device here"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        InputProps={{
                          startAdornment: <SearchIcon />,
                          inputProps: {
                            style: { padding: "9px" },
                          },
                        }}
                      />
                      <div
                        className="furnace-grid"
                        style={{ height: "400px", overflow: "auto" }}
                      >
                        <Box className="Grid-text mt-2">
                          {!isShimmerdevicelist ? (
                            getEnergyCostDeviceData.length ? (
                              getEnergyCostDeviceData
                                .filter((e) =>
                                  e.device_name
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                                )
                                .map((e, index) => (
                                  <Grid item xs={12} key={e.device_name}>
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        style={{
                                          padding: "7px 12px",
                                          borderLeft: `4px solid ${
                                            switchColors[
                                              index % switchColors.length
                                            ]
                                          }`,
                                        }}
                                      >
                                        <h6 className="mt-2">
                                          {e.device_name}
                                        </h6>
                                      </Grid>
                                      {(analyticsAccess?.is_viewer &&
                                        analyticsAccess?.is_editor) ||
                                      (!analyticsAccess?.is_viewer &&
                                        analyticsAccess?.is_editor) ||
                                      (analyticsAccess?.is_viewer &&
                                        !analyticsAccess?.is_editor) ? (
                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            style={{ float: "right" }}
                                            onClick={(event) =>
                                              handleSelectPOPDeviceId(
                                                e.id,
                                                index,
                                                event,
                                                e
                                              )
                                            }
                                            name={switchColors[index]}
                                            // checked={energyCostPOPId.includes(
                                            //   e.device
                                            // )}
                                            checked={
                                              energyCostPOPId.length
                                                ? energyCostPOPId.find(
                                                    (f) =>
                                                      f.deviceID == e.id &&
                                                      f.plantId == param.id
                                                  )
                                                  ? true
                                                  : false
                                                : false
                                            }
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor:
                                                    switchColors[
                                                      index %
                                                        switchColors.length
                                                    ],
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#E2D9F2 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      ) : (
                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            style={{ float: "right" }}
                                            onClick={handleTostMsg}
                                            name={switchColors[index]}
                                            // checked={energyCostPOPId.includes(
                                            //   e.device
                                            // )}
                                            checked={
                                              energyCostPOPId.length
                                                ? energyCostPOPId.find(
                                                    (f) =>
                                                      f.deviceID == e.id &&
                                                      f.plantId == param.id
                                                  )
                                                  ? true
                                                  : false
                                                : false
                                            }
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor:
                                                    switchColors[
                                                      index %
                                                        switchColors.length
                                                    ],
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#E2D9F2 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                ))
                            ) : (
                              []
                            )
                          ) : (
                            <DeviceListShimmer itemCount={3} />
                          )}
                        </Box>
                      </div>

                      <div className="mt-3">
                        <button
                          className="export-btn"
                          style={{ marginTop: "30px" }}
                          onClick={handleEnergyCostPOPExcel}
                        >
                          EXPORT ENERGY DATA
                        </button>
                      </div>
                    </Box>
                  </Grid>
                )}
                {active == "Consumer" ? (
                  <Grid item xs={12} sm={12} md={9}>
                    <Box className="consumerEnergyData-box">
                      <div style={{ minHeight: "322px" }}>
                        <div>
                          <h6>
                            <b>Pareto Chart - Consumer over Consumer</b>
                          </h6>
                        </div>
                        <div className="spinner-container">
                          <EnergyCostCOC
                            energyCostDeviceColors={energyCostDeviceColors}
                            analyticsOverviewData={getEnergyCostData}
                            emptyDevice={emptyDevice}
                            shimmer={isShimmerCOC}
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <h6>
                          <b>Pie Chart - Consumer over Consumer</b>
                        </h6>
                        <div className="spinner-container">
                          <EnergyPiechartCOC
                            energyCostDeviceColors={energyCostDeviceColors}
                            analyticsOverviewData={getEnergyCostData}
                            energyCostSum={energyCostSum}
                            emptyDevice={emptyDevice}
                            shimmer={isShimmerCOC}
                          />
                        </div>
                      </div>
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={9} lg={9}>
                    <Box className="consumerEnergyData-box">
                      <div style={{ minHeight: "322px" }}>
                        <h6>
                          <b>Bar Chart - Period over Period</b>
                        </h6>

                        {isDeviceSelected ? (
                          <EnergyPOPChart
                            energyCostPOPData={energyCostPOPData}
                            energyCostPOP1Data={energyCostPOP1Data}
                            emptyDevice={emptyDevice}
                            shimmer={isShimmerPOP}
                          />
                        ) : (
                          <img
                            src={images.noDataFoundImg}
                            className="notFoundDataImg"
                            alt=""
                          />
                        )}
                      </div>
                      <div style={{ position: "relative" }}>
                        <h6>
                          <b>Pie Chart - Period over Period</b>
                        </h6>

                        <div className="spinner-container">
                          {isDeviceSelected ? (
                            <EnergyPiechartPOP
                              energyCostPOPData={energyCostPOPData}
                              energyCostPOP1Data={energyCostPOP1Data}
                              energyCostPOPSum={energyCostPOPSum}
                              energyCostPOP1Sum={energyCostPOP1Sum}
                              energyCostDeviceColors={energyCostDeviceColors}
                              emptyDevice={emptyDevice}
                              shimmer={isShimmerPOP}
                            />
                          ) : (
                            <img
                              src={images.noDataFoundImg}
                              className="notFoundDataImg"
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </div>
          </>
        ) : activeClass == "Reports" ? (
          <CostProduction />
        ) : (
          <EnergyIdealActual />
        )}
      </div>
      {openEnergyModal &&
        (active == "Consumer" ? (
          <>
            <div className="consumerCard" ref={modalRef}>
              <Box
                className="consumerData-box consumer-card-box"
                style={{ minHeight: "712px" }}
              >
                <Box className="consumer-text-disc mb-2">
                  <h6 className="Title">Title</h6>
                  <h6>Availability Charts</h6>
                </Box>
                <Box className="consumer-text-disc">
                  <h6 className="Title">Description</h6>
                  <h6>Energy Cost Charts - Consumer over Consumer</h6>
                </Box>

                <TextField
                  variant="outlined"
                  className="search-textfield"
                  placeholder="Search Device here"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: <SearchIcon />,
                    inputProps: {
                      style: { padding: "9px" },
                    },
                  }}
                />
                <div
                  className="furnace-grid"
                  style={{ height: "400px", overflow: "auto" }}
                >
                  <Box className="Grid-text mt-2">
                    {!isShimmerdevicelist ? (
                      getEnergyCostDeviceData.length ? (
                        getEnergyCostDeviceData
                          .filter((e) =>
                            e.device_name
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          )
                          .map((e, index) => (
                            <Grid item xs={12} key={e.device_name}>
                              <Grid
                                container
                                alignItems="center"
                                style={{
                                  background: "#efeffd",
                                  width: "100%",
                                  marginBottom: "7px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={8}
                                  style={{
                                    padding: "7px 12px",
                                    borderLeft: `4px solid ${
                                      switchColors[index % switchColors.length]
                                    }`,
                                  }}
                                >
                                  <h6 className="mt-2">{e.device_name}</h6>
                                </Grid>
                                {(analyticsAccess?.is_viewer &&
                                  analyticsAccess?.is_editor) ||
                                (!analyticsAccess?.is_viewer &&
                                  analyticsAccess?.is_editor) ||
                                (analyticsAccess?.is_viewer &&
                                  !analyticsAccess?.is_editor) ? (
                                  <Grid
                                    item
                                    xs={4}
                                    textAlign="right"
                                    className="grid-switch-unit"
                                  >
                                    <Switch
                                      style={{ float: "right" }}
                                      onClick={(event) =>
                                        handleSelectDeviceId(
                                          e.id,
                                          index,
                                          event,
                                          e
                                        )
                                      }
                                      name={switchColors[index]}
                                      checked={energyCostCOCId.includes(e.id)}
                                      sx={{
                                        "& .MuiSwitch-thumb": {
                                          bgcolor: "#828282",
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#D9D9E5 !important",
                                        },
                                        "& .Mui-checked": {
                                          "& .MuiSwitch-thumb": {
                                            bgcolor:
                                              switchColors[
                                                index % switchColors.length
                                              ],
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#E2D9F2 !important",
                                          },
                                        },
                                      }}
                                    />
                                  </Grid>
                                ) : (
                                  <Grid
                                    item
                                    xs={4}
                                    textAlign="right"
                                    className="grid-switch-unit"
                                  >
                                    <Switch
                                      style={{ float: "right" }}
                                      onClick={handleTostMsg}
                                      name={switchColors[index]}
                                      checked={energyCostCOCId.includes(e.id)}
                                      sx={{
                                        "& .MuiSwitch-thumb": {
                                          bgcolor: "#828282",
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#D9D9E5 !important",
                                        },
                                        "& .Mui-checked": {
                                          "& .MuiSwitch-thumb": {
                                            bgcolor:
                                              switchColors[
                                                index % switchColors.length
                                              ],
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#E2D9F2 !important",
                                          },
                                        },
                                      }}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          ))
                      ) : (
                        []
                      )
                    ) : (
                      <DeviceListShimmer itemCount={3} />
                    )}
                  </Box>
                </div>

                <div className="mt-3">
                  <button
                    className="export-btn"
                    style={{ marginTop: "30px" }}
                    onClick={handleEnergyCostCOCExcel}
                  >
                    EXPORT ENERGY DATA
                  </button>
                </div>
              </Box>
            </div>
          </>
        ) : (
          <div className="consumerCard " ref={modalRef}>
            <Box className="consumerData-box consumer-card-box">
              <Box className="consumer-text-disc mb-2">
                <h6 className="Title">Title</h6>
                <h6>Availability Charts</h6>
              </Box>

              <Box className="consumer-text-disc">
                <h6 className="Title">Description</h6>
                <h6>Energy Cost Charts - Period over Period</h6>
              </Box>

              <TextField
                variant="outlined"
                className="search-textfield"
                placeholder="Search Device here"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon />,
                  inputProps: {
                    style: { padding: "9px" },
                  },
                }}
              />
              <div
                className="furnace-grid"
                style={{ height: "400px", overflow: "auto" }}
              >
                <Box className="Grid-text mt-2">
                  {!isShimmerdevicelist ? (
                    getEnergyCostDeviceData.length ? (
                      getEnergyCostDeviceData
                        .filter((e) =>
                          e.device_name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        )
                        .map((e, index) => (
                          <Grid item xs={12} key={e.device_name}>
                            <Grid
                              container
                              alignItems="center"
                              style={{
                                background: "#efeffd",
                                width: "100%",
                                marginBottom: "7px",
                              }}
                            >
                              <Grid
                                item
                                xs={8}
                                style={{
                                  padding: "7px 12px",
                                  borderLeft: `4px solid ${
                                    switchColors[index % switchColors.length]
                                  }`,
                                }}
                              >
                                <h6 className="mt-2">{e.device_name}</h6>
                              </Grid>
                              {(analyticsAccess?.is_viewer &&
                                analyticsAccess?.is_editor) ||
                              (!analyticsAccess?.is_viewer &&
                                analyticsAccess?.is_editor) ||
                              (analyticsAccess?.is_viewer &&
                                !analyticsAccess?.is_editor) ? (
                                <Grid
                                  item
                                  xs={4}
                                  textAlign="right"
                                  className="grid-switch-unit"
                                >
                                  <Switch
                                    style={{ float: "right" }}
                                    onClick={(event) =>
                                      handleSelectPOPDeviceId(
                                        e.id,
                                        index,
                                        event,
                                        e
                                      )
                                    }
                                    name={switchColors[index]}
                                    // checked={energyCostPOPId.includes(
                                    //   e.device
                                    // )}
                                    checked={
                                      energyCostPOPId.length
                                        ? energyCostPOPId.find(
                                            (f) =>
                                              f.deviceID == e.id &&
                                              f.plantId == param.id
                                          )
                                          ? true
                                          : false
                                        : false
                                    }
                                    sx={{
                                      "& .MuiSwitch-thumb": {
                                        bgcolor: "#828282",
                                      },
                                      "& .MuiSwitch-track": {
                                        bgcolor: "#D9D9E5 !important",
                                      },
                                      "& .Mui-checked": {
                                        "& .MuiSwitch-thumb": {
                                          bgcolor:
                                            switchColors[
                                              index % switchColors.length
                                            ],
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#E2D9F2 !important",
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                              ) : (
                                <Grid
                                  item
                                  xs={4}
                                  textAlign="right"
                                  className="grid-switch-unit"
                                >
                                  <Switch
                                    style={{ float: "right" }}
                                    onClick={handleTostMsg}
                                    name={switchColors[index]}
                                    // checked={energyCostPOPId.includes(
                                    //   e.device
                                    // )}
                                    checked={
                                      energyCostPOPId.length
                                        ? energyCostPOPId.find(
                                            (f) =>
                                              f.deviceID == e.id &&
                                              f.plantId == param.id
                                          )
                                          ? true
                                          : false
                                        : false
                                    }
                                    sx={{
                                      "& .MuiSwitch-thumb": {
                                        bgcolor: "#828282",
                                      },
                                      "& .MuiSwitch-track": {
                                        bgcolor: "#D9D9E5 !important",
                                      },
                                      "& .Mui-checked": {
                                        "& .MuiSwitch-thumb": {
                                          bgcolor:
                                            switchColors[
                                              index % switchColors.length
                                            ],
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#E2D9F2 !important",
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        ))
                    ) : (
                      []
                    )
                  ) : (
                    <DeviceListShimmer itemCount={3} />
                  )}
                </Box>
              </div>

              <div className="mt-3">
                <button
                  className="export-btn"
                  style={{ marginTop: "30px" }}
                  onClick={handleEnergyCostPOPExcel}
                >
                  EXPORT ENERGY DATA
                </button>
              </div>
            </Box>
          </div>
        ))}
    </>
  );
};
export default EnergyCost;
