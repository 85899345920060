import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
// import TextField from '@mui/material/TextField';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import SearchIcon from "@mui/icons-material/Search";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { images } from "../../../config/images";
// import FooterText from '../../../components/FooterText/FooterText';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAccessManagement,
  deleteAccessManagement,
  exportDepartmentExcel,
  exportAccessManagement,
  getDepartmentBycompany,
  getCompanyByDetails,
} from "../service";
import "./AccessManagement.css";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import { RedirectAdminDashboard } from "../../../components/CommonFunction/RedirectAdminDashboard";
import AccessManagementTableShimmer from "./AccessManagementShimmer";
import { setHeaderDetails } from "../../Header/headerSlice";

function AccessManagement() {
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [accessManagementData, setAccessManagementData] = React.useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [search, setSearch] = useState({
    department__department_name: "",
    role__role_name: "",
  });
  const [isLoading, setLoading] = useState(false);
  const [selectUserId, setSelectUserId] = useState([]);
  const [companyNameById, setCompanyName] = useState("");
  const[companyIdData,setCompanyId] = useState("")

  const [isShimmerAccessManagementData,setisShimmerAccessManagementData] = useState(false)


  const navigate = useNavigate();
  const param = useParams();
  const dispatch = useDispatch();

  const { companyName, companyId, user_access, is_super_admin } = useSelector(
    (state) => state.userState
  );
  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });
  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const superAdminAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Admin Dashboard"
      )
    : null;
  const accessManagementAccess = superAdminAccess ? superAdminAccess.sub_module.length
    ? superAdminAccess.sub_module.find(
      (f) => f.sub_module_name == "Access Management"
    )
    : null : null;

  // Delete Confirmation Modal
  const handleConfirmationModal = () => {
    if (!selectedRows.length) {
      notify("Please Select Department");
      return true;
    }
    setOpenDeleteModal(!openDeleteModal);
  };

  // company deatils
  const getCompanyByDetailsApi = async () => {
    const paramReq = {
      company_id: param.companyId,
    };
    const resp = await getCompanyByDetails(paramReq);
    console.log("resprespresp", resp);
    if (resp.status == 200) {
      setCompanyName(resp.data.payload.company_name);
      setCompanyId(resp.data.payload.id)
    }
  };

  useEffect(() => {
    if (param.companyId) {
      getCompanyByDetailsApi();
    }
  }, []);

  const getAccessManagementdata = async () => {
    try{
      setisShimmerAccessManagementData(true)
      const paramReq = {
        company: companyId,
      };
      const resp = await getDepartmentBycompany(paramReq, search);
      if (resp.data.success == true) {
        setTimeout(()=>{setisShimmerAccessManagementData(false)},1000)
        setAccessManagementData(resp.data.payload);
        setSelectUserId(resp.data.payload.map((e) => e.id));
      }
    }catch(error){
      setisShimmerAccessManagementData(false)
      console.log("Error from getAccessManagementdata",error)
    }
    
  };

  const getAccessManagementByCompanyId = async () => {
    try{
      setisShimmerAccessManagementData(true)
      const paramReq = {
        company: param.companyId,
      };
      const resp = await getDepartmentBycompany(paramReq, search);
      if (resp.data.success == true) {
        setTimeout(()=>{setisShimmerAccessManagementData(false)},1000)
        setAccessManagementData(resp.data.payload);
        setSelectUserId(resp.data.payload.map((e) => e.id));
      }
    }catch(Error){
      setisShimmerAccessManagementData(false)
      console.log("Error from getAccessManagementByCompanyId", Error);
    }
   
  };

  useEffect(() => {
    if (param.companyId) {
      getAccessManagementByCompanyId();
    } else {
      getAccessManagementdata();
    }
  }, [search]);

  const handleSelectAll = (checked) => {
    if (openDeleteModal) {
      return true;
    }
    if (checked) {
      const allRowIds = accessManagementData.map((e) => e.id);
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows([]);
    }
    setSelectAll(checked);
  };

  const handleSelectRow = (rowId) => {
    if (openDeleteModal) {
      return true;
    }
    const selectedIndex = selectedRows.indexOf(rowId);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, rowId];
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== rowId);
    }

    setSelectedRows(newSelectedRows);
  };

  // delete Access Management

  const deleteAccessManagementdata = async () => {
    if (!selectedRows.length) {
      notify("Please Select Department");
      return true;
    }

    if (selectedRows.length > 1) {
      notify("Please Select only one Department");
      return true;
    }
    try {
      setLoading(true);
      const resp = await deleteAccessManagement(selectedRows[0]);
      if (resp.data.success == true) {

        setLoading(false);
        window.location.reload(false)
        getAccessManagementdata();
        handleConfirmationModal();
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log("handleSubmit", error);
    }
  };

  // handle Search
  const handleChangeSearch = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  const handleDepartmentExcel = async () => {
    if (openDeleteModal) {
      return true;
    }
    const params = {
      id: selectedRows.length ? selectedRows : selectUserId,
    };
    const resp = await exportAccessManagement(params);
    if (resp.status == 200 || resp.status == 201) {
      notifySuccess("Export Access Management Report Successfully");
    }
  };
  const handleTostMsg = () => {
    notifyError("You don't have access");
  };
  return (
    <>
      {/* <div>
        
      </div> */}
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="deviceManagementHeader-wrap">
            <div className="deviceManagementTitle-wrap">
              <KeyboardBackspaceIcon
                onClick={() => {
                  if (openDeleteModal) {
                    return true;
                  }
                  RedirectAdminDashboard(is_super_admin, navigate);
                }}
                className="backArrow"
              />{" "}
              <Typography
                variant="h5"
                className="deviceManagementTitle"
                gutterBottom
                style={{ margin: "0" }}
                onClick={() => {
                  if (openDeleteModal) {
                    return true;
                  }

                  if (param.companyId) {
                    navigate(`/managedepartment/${param.companyId}`);
                    return true;
                  }
                  RedirectAdminDashboard(is_super_admin, navigate);
                }}
              >
                {param.companyId
                  ? `${companyNameById} - Access Management`
                  : `${companyName} - Access Management`}
              </Typography>
            </div>
            <div className="addDevice-buttonWrap">
            
                <button
                  className="manageDepartment-button"
                  onClick={() => {
                    if (openDeleteModal) {
                      return true;
                    }
                    if (param.companyId) {
                      navigate(`/managedepartment/${param.companyId}`);
                      return true;
                    }
                    navigate("/acsessmanagement/managedepartment");
                  }}
                >
                  Manage Department
                </button>
           
              {(accessManagementAccess?.is_viewer &&
                accessManagementAccess?.is_editor) ||
              (!accessManagementAccess?.is_viewer &&
                accessManagementAccess?.is_editor) ? (
                <img
                  src={images.addImage}
                  alt=""
                  className="cloudActiveImg"
                  onClick={() => {
                    if (openDeleteModal) {
                      return true;
                    }
                    if (param.companyId) {
                      navigate(
                        `/addaccessmanagement/company/${param.companyId}`
                      );
                      return true;
                    }
                    navigate("/addaccessManagement");
                  }}
                />
              ) : (
                <img
                  src={images.addImage}
                  alt=""
                  className="cloudActiveImg"
                  onClick={handleTostMsg}
                />
              )}

              {(accessManagementAccess?.is_viewer &&
                accessManagementAccess?.is_editor) ||
              (!accessManagementAccess?.is_viewer &&
                accessManagementAccess?.is_editor) ? (
                <>
                  <img
                    src={images.deleteIcon}
                    alt=""
                    className="cloudActiveImg"
                    onClick={handleConfirmationModal}
                  />
                  <div onClick={handleDepartmentExcel}>
                    <img
                      src={images.excelLogo}
                      alt=""
                      className="cloudActiveImg"
                    />
                  </div>
                </>
              ) : (
                <>
                  <img
                    src={images.deleteIcon}
                    alt=""
                    className="cloudActiveImg"
                    onClick={handleTostMsg}
                  />
                  <div onClick={handleTostMsg}>
                    <img
                      src={images.excelLogo}
                      alt=""
                      className="cloudActiveImg"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {/* =============== Delete Confirmation Modal Start =============== */}
          {openDeleteModal && (
            <Card className="deleteConfirmation-Modal">
              <Typography
                variant="h6"
                gutterBottom
                className="deleteConfirmation-title"
              >
                Are You Sure<br></br> You Want To Delete?
              </Typography>
              <Box className="deleteConfirmation-btnWrap">
                <button
                  className="deleteConfirmation-Yesbtn"
                  onClick={deleteAccessManagementdata}
                >
                  Yes
                </button>
                <button
                  className="deleteConfirmation-Nobtn"
                  onClick={handleConfirmationModal}
                >
                  No
                </button>
              </Box>
            </Card>
          )}
          {/* =============== Delete Confirmation Modal End =============== */}
          <div className="tableResponsive accessManagement">
            <Table aria-label="" className="deviceManagementTable">
              <TableHead>
                <TableRow className="deviceManagementTable-title">
                  <TableCell colSpan={2}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectAll}
                              onChange={(e) =>
                                handleSelectAll(e.target.checked)
                              }
                            />
                          }
                        />
                      </FormGroup>{" "}
                      Department
                    </div>
                  </TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell className="accessManagement-text">
                    Access
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Department"
                      name="department__department_name"
                      onChange={handleChangeSearch}
                      value={search.department__department_name}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Role"
                      name="role__role_name"
                      onChange={handleChangeSearch}
                      value={search.role__role_name}
                    />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>

                {!isShimmerAccessManagementData ? accessManagementData.length
                  ? accessManagementData.map((e) => {
                      return (
                        <TableRow className="deviceManagementTable-data">
                          <TableCell className="accessManagement-checkbox">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={selectedRows.includes(e.id)}
                                    onChange={() => handleSelectRow(e.id)}
                                  />
                                }
                              />
                            </FormGroup>
                          </TableCell>
                          <TableCell>{e.department}</TableCell>
                          <TableCell>{e.role}</TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {(accessManagementAccess?.is_viewer &&
                              accessManagementAccess?.is_editor) ||
                            (!accessManagementAccess?.is_viewer &&
                              accessManagementAccess?.is_editor) ? (
                              <img
                                src={images.editImg}
                                alt=""
                                className="cloudActiveImg"
                                onClick={() => {
                                  if (openDeleteModal) {
                                    return true;
                                  }
                                  if (param.companyId) {
                                    navigate(
                                      `/accessManagement/company/${param.companyId}/department/${e.id}`
                                    );
                                  
                                    return true;
                                  }
                                  navigate(`/accessManagement/${e.id}`);
                             
                                }}
                              />
                            ) : (
                              <img
                                src={images.editImg}
                                alt=""
                                className="cloudActiveImg"
                                onClick={handleTostMsg}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : <TableRow  className="deviceManagementTable-data">
                  <TableCell style={{ backgroundColor:"white", textAlign:"center"}} colSpan={4}>
                    No Data Found
                  </TableCell></TableRow>:<AccessManagementTableShimmer itemCount={7}/>}
              </TableHead>
              
            </Table>
          </div>
          {/* <FooterText className='accessManagement-footer' /> */}
        </Container>
        {isLoading && <Loader />}
      </div>
    </>
  );
}

export default AccessManagement;
