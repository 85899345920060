import React, { useEffect, useState } from "react";
import { images } from "../../../../config";
import Slider from "react-slick";
import { Card, Grid, TextField } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchIcon from "@material-ui/icons/Search";
import TagGroup from "./TagGroup";
import TagGroupDetail from "./TagGroupDetail";
import { useParams } from "react-router-dom";
import {
  getDeviceAlarmStatus,
  getTagDevice,
  getTagGroups,
} from "../../services";
import Mqqtdata from "../../../ProcessDashboard/Mqqtdata";
import OverViewMachineMqttData from "../mqttDataMachineDashboard/OverViewMachineMqttData";
import { useDispatch, useSelector } from "react-redux";
import { setSmartTagReportData } from "../Slice";
import Loader from "../../../../components/Loader/Loader";
import { SoftTagFormula } from "../../softTag/SoftTagFormula";

const CompactDeviceTag = () => {

  const [deviceData, setDeviceData] = useState([]);
  const [deviceId, setDeviceId] = useState("");
  const [getTag, setTag] = useState({});
  const [getTagData, setTagData] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [topics, setTopics] = useState([]);
  const [mqttTagPreviousvalue, setMqttTagPreviousvalue] = useState([]);
  const [tagStatus, setTagStatus] = useState([]);
  const [sliderRealTimeTagData, setSliderRealTimeTagData] = useState([]);
  const [tagListDataGroupAndList, setTagListDataGroupAndList] = useState([]);
  const { activeClassTab } = useSelector((state) => state.smartTagReportSlice);
  const [isLoading, setLoading] = useState(false);
  const [deviceAlarmStatus, setDeviceAlarmStatus] = useState([]);
  const [previousValues, setPreviousValues] = useState({}); 

  console.log("dsfdsfds",tagStatus)
  
  const param = useParams();
  const dispatch = useDispatch();

  const { selectTimeZoneProfile } = useSelector(state => state.userState);
  const mqttDataReduce = sliderRealTimeTagData.reduce((map, item) => {
    map[item.device_tag] = Number(item.tagValue);
    return map;
  }, {});

  useEffect(() => {
    if (mqttTagPreviousvalue.length) {
      // Function to evaluate the formula
      // const evaluateFormula = (formula, tags) => {
        
      //   try {
      //     // Match any tag-like pattern (e.g., words/numbers/underscores)
      //     const tagPattern = /[A-Za-z0-9_]+/g;
      //     const tagMatches = formula.match(tagPattern) || [];
  
      //     console.log("tagMatches", tagMatches);
  
      //     // Replace each tag with its current value from the tags array
      //     let evaluatedFormula = formula;
      //     tagMatches.forEach(tag => {
      //       const foundTag = tags.find(t => t.dvName === tag);
      //       if (!foundTag) {
      //         console.warn(`Tag "${tag}" not found in tags array.`);
      //       }
      //       const tagValue = foundTag ? parseFloat(foundTag.curValue) : 0;
      //       // Replace tag in formula with the corresponding value
      //       evaluatedFormula = evaluatedFormula.replace(tag, tagValue);
      //     });
  
      //     console.log("Evaluated Formula", evaluatedFormula);
  
      //     // Use eval to compute the formula result
      //     const result = eval(evaluatedFormula);
      //     console.log("Result", result);
      //     return result;
      //   } catch (error) {
      //     console.error("Error evaluating formula:", error);
      //     return 0; // Return 0 in case of an error during evaluation
      //   }
      // };
  
      const averagCalTagvalue = mqttTagPreviousvalue.map((row) => {

  
        // Handle soft tags
        if (row.is_soft_tag && row.soft_tag_id__formula) {
          // Compute current value using the formula
          const evaluatedCurrentValue = SoftTagFormula(row.soft_tag_id__formula, mqttDataReduce)?.value;
          console.log("Evaluated current value:", evaluatedCurrentValue);
  
          // Set the evaluated value as the new previous value
          setPreviousValues(prev => ({
            ...prev,
            [row.soft_tag_id__formula]: evaluatedCurrentValue
          }));
          
          // Use the previous value from state
          const evaluatedPreviousValue = previousValues[row.soft_tag_id__formula] || 0;
          console.log("Evaluated previous value:", evaluatedPreviousValue);
          console.log("evaluatedCurrentValue", evaluatedCurrentValue, evaluatedPreviousValue);

          const statusValue = evaluatedPreviousValue !== 0
            ? (((evaluatedCurrentValue) - (evaluatedPreviousValue)) / (evaluatedPreviousValue)) * 100
            : 0;

            console.log("evaluatedCurrentValue", evaluatedCurrentValue, evaluatedPreviousValue,statusValue);
  
          return {
            dvName: row.dvName,
            statusValue: statusValue,
            formula: row.soft_tag_id__formula
          };
        }
        else{
          const avgCal = ((row.curValue - row.prevValue) / row.prevValue) * 100;
  
          return {
            dvName: row.dvName,
            statusValue: isFinite(avgCal) ? avgCal : 0.00,
          };
        }
        
      });
  
      setTagStatus(averagCalTagvalue);
    }
  }, [mqttTagPreviousvalue]);
  
  
  
  
  

  useEffect(() => {
    handleImageClick();
    handleImageDetailClick();
  }, []);

  const handleImageClick = () => {
    dispatch(setSmartTagReportData({ activeClassTab: "Compact" }));
  };

  const handleImageDetailClick = () => {
    dispatch(setSmartTagReportData({ activeClassTab: "Detail" }));
  };

  useEffect(() => {
    if (deviceId) {
      getDeviceTagGroup();
    }
  }, [deviceId]);

  const getTagDateAndTime = () => {
    return new Date().toLocaleString("en-US", {
      timeZone: selectTimeZoneProfile,
      hour12: false,
    });
  };

  const getNotificationIconColor = (priority) => {
    const lowerCasePriority = priority?.toLowerCase();
    switch (lowerCasePriority) {
      case "high":
        return "#e31e24"; // Red for high priority
      case "medium":
        return "#ffa500"; // Orange for medium priority
      case "low":
        return "#ebeb27"; // Yellow for low priority
      default:
        return "gray";
    }
  };

  const getDeviceTagGroup = async () => {
    const params = {
      plant_id: param.plantid,
      device_id: deviceId,
    };
    try {
      setLoading(true);
      const resp = await getTagGroups(params);
      if (resp.status === 200 || (resp.status === 201 && resp.data.payload)) {
        setLoading(false);
        setTag(resp.data.payload.group_data);
        setTagData(resp.data.payload.tags_data);
  
        // Create structure change in API
        const tagListData1 = Object.keys(resp.data.payload.group_data).map((groupKey) => {
          const groupData = resp.data.payload.group_data[groupKey];
          const tags = groupData[Object.keys(groupData)];
  
          const tagDataList = tags.tags.map((e) => {
            const obj = {
              dvName: e.device_tag ? e.device_tag || "-" : "-",
              tagName: e.tag_name,
              machineUID: e.device__machine__uid,
              is_soft_tag: e.is_soft_tag || false,
              soft_tag_id__formula: e.soft_tag_id__formula,
            };
            return obj;
          });
          return tagDataList;
        });
  
        const tagListDataCombinedArray1 = tagListData1.reduce((acc, curr) => {
          return acc.concat(curr);
        }, []);
  
        const defaultTagListData = resp.data.payload.tags_data[0]?.tags?.map((e) => {
          const obj = {
            dvName: e.device_tag ? e.device_tag || "-" : "-",
            tagName: e.tag_name,
            machineUID: e.device__machine__uid,
            is_soft_tag: e.is_soft_tag || false,
            soft_tag_id__formula: e.soft_tag_id__formula,
          };
  
          return obj;
        });
  
        setTagListDataGroupAndList(tagListDataCombinedArray1.concat(defaultTagListData));
  
        const evaluateSoftTagValue = (formula, tags) => {
          try {
            // Dynamic tag matching using tag pattern extraction
            const tagPattern = /[A-Za-z0-9_]+/g;
            const tagMatches = formula.match(tagPattern) || [];
            let evaluatedFormula = formula;
  
            // Replace tags in formula with current values from tags list
            tagMatches.forEach(tag => {
              const foundTag = tags.find(t => t.dvName === tag);
              const tagValue = foundTag ? parseFloat(foundTag.curValue) : 0;
              evaluatedFormula = evaluatedFormula.replace(tag, tagValue);
            });
  
            // Calculate and return the evaluated value
            return eval(evaluatedFormula);
          } catch {
            return 0; // Default to 0 on error
          }
        };
  
        const tagObj = Object.keys(resp.data.payload.group_data).map((groupKey) => {
          const groupData = resp.data.payload.group_data[groupKey];
          const tags = groupData[Object.keys(groupData)];
  
          const tagDataList = tags.tags.map((e) => {
            let curValue = 0;
            let prevValue = 0;
  
            // Evaluate formula for soft tags
            if (e.is_soft_tag && e.soft_tag_id__formula) {
              curValue = evaluateSoftTagValue(e.soft_tag_id__formula, mqttTagPreviousvalue);
              prevValue = curValue; // Adjust if there are different prevValue needs
            }
  
            const obj = {
              is_soft_tag: e.is_soft_tag,
              dvName: e.device_tag ? e.device_tag || "-" : "-",
              prevValue,
              curValue,
              dateAndTime: getTagDateAndTime(),
              soft_tag_id__formula: e.soft_tag_id__formula,
            };
            return obj;
          });
  
          return tagDataList;
        });
  
        const combinedArray = tagObj.reduce((acc, curr) => {
          return acc.concat(curr);
        }, []);
  
        const defaultTagData = resp.data.payload.tags_data[0]?.tags?.map((e) => {
          const obj = {
            is_soft_tag: e.is_soft_tag,
            dvName: e.device_tag ? e.device_tag || "-" : "-",
            prevValue: 0,
            curValue: 0,
            dateAndTime: getTagDateAndTime(),
            soft_tag_id__formula: e.soft_tag_id__formula,
          };
          return obj;
        });
  
        setMqttTagPreviousvalue(combinedArray.concat(defaultTagData));
      }
    } catch (error) {
      setLoading(false);
      console.log("Error:", error);
    }
  };
  
  useEffect(() => {
    handleDeviceAlarmStatus();
  }, []);

  const handleDeviceAlarmStatus = async () => {
    const params = {
      machine: param.machineid,
    };
    try {
      const resp = await getDeviceAlarmStatus(params);
      if (resp.status == 200 || resp.status == 201) {
        setDeviceAlarmStatus(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,

    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    getTagDeviceData();
  }, []);

  const getTagDeviceData = async () => {
    const params = {
      plant_id: param.plantid,
      machine: param.machineid,
    };
    try {
      setLoading(true);
      const resp = await getTagDevice(params);
      if (resp.status == 200 || resp.status == 201) {
        setLoading(false);
        setDeviceData(resp.data.payload);
      }
    } catch (error) {
      setLoading(false);
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    if (deviceData.length > 0) {
      setDeviceId(deviceData[0].id);
      setTopics(["Hikar/DeviceUID/" + deviceData[0].machine_uid])
      
    }
  }, [deviceData]);

  const handleDeviceClick = (id, topic) => {
    setDeviceId(id);
    setTopics(["Hikar/DeviceUID/" + topic])
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  console.log("activeClassTabactiveClassTab", activeClassTab);
  return (
    <>
      <div className="page-wraper">
        {topics.length ? (
          <OverViewMachineMqttData
            topics={topics}
            tagListDataGroupAndList={tagListDataGroupAndList}
            setMqttTagPreviousvalue={setMqttTagPreviousvalue}
            mqttTagPreviousvalue={mqttTagPreviousvalue}
            setSliderRealTimeTagData={setSliderRealTimeTagData}
          />
        ) : null}
        <Slider {...sliderSettings} className="my-slider-class">
          {deviceData.map((e) => {
            const matchedNotification = deviceAlarmStatus.find(
              (notif) => notif.id === e.id
            );
            return (
              <Card
                className="machineoverView-title"
                onClick={() => handleDeviceClick(e.id, e.machine_uid)}
              >
                <div className="d-flex justify-content-between">
                  <div>
                    <h6 style={{ fontSize: "18px" }}>
                      <b>{e.device_name}</b>
                    </h6>
                  </div>
                  <div className="d-flex gap-1">
                    <NotificationsIcon
                      style={{
                        color: getNotificationIconColor(
                          matchedNotification?.alarm_priority
                        ),
                      }}
                    />
                    {e.is_sub ? (
                      <img
                        src={images.cloudActiveImg}
                        alt=""
                        className="triangle-image"
                      />
                    ) : (
                      <img
                        src={images.cloudImg}
                        alt=""
                        className="triangle-image"
                      />
                    )}
                  </div>
                </div>
                <div>
                  <div>
                    <p style={{ fontSize: "14px" }} className="mb-1">
                      {e.device_description}
                    </p>
                  </div>
                </div>
              </Card>
            );
          })}
        </Slider>
        <div className="pt-3">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={10} md={10} lg={11} xl={11}>
              <TextField
                variant="outlined"
                style={{ width: "100%", backgroundColor: "white" }}
                // className="search-textfield"
                placeholder="Search tag name"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon />,
                  inputProps: {
                    style: { padding: "9px" },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={1} xl={1}>
              <div className="d-flex gap-2">
                {activeClassTab === "Compact" ? (
                  <>
                    <img
                      src={images.compactViewIcon}
                      alt=""
                      onClick={handleImageClick}
                      style={{ cursor: "pointer" }}
                    />
                    <img
                      src={images.detailViewIcon}
                      alt=""
                      onClick={handleImageDetailClick}
                      style={{ cursor: "pointer" }}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={images.compact}
                      alt=""
                      onClick={handleImageClick}
                      style={{ cursor: "pointer" }}
                    />
                    <img
                      src={images.detail}
                      alt=""
                      onClick={handleImageDetailClick}
                      style={{ cursor: "pointer" }}
                    />
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="pt-3">
          {activeClassTab === "Compact" ? (
            <TagGroup
              deviceId={deviceId}
              getTag={getTag}
              searchQuery={searchQuery}
              mqttTagData={sliderRealTimeTagData}
              getTagData={getTagData}
              getDeviceTagGroup={getDeviceTagGroup}
            />
          ) : (
            <TagGroupDetail
              getTag={getTag}
              searchQuery={searchQuery}
              getTagData={getTagData}
              getDeviceTagGroup={getDeviceTagGroup}
              setMqttTagPreviousvalue={setMqttTagPreviousvalue}
              mqttTagPreviousvalue={mqttTagPreviousvalue}
              tagStatus1={tagStatus}
              sliderRealTimeTagData={sliderRealTimeTagData}
            />
          )}
        </div>
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default CompactDeviceTag;