import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";
import { images } from "../../../../config";
import { PieChartShimmer } from "../../../../components/Shimmer/PieChartShimmer/PieChartShimmer";
import '../../../ProcessDashboard/ProcessDashboard.css';

export function PieChartPOP({
    piechartData,
    piechartDataPOP1,
    shimmer,
    tagID,

}) {

    console.log("piechartDataPOP",piechartData,piechartDataPOP1)
  const chartRef = useRef(null);
  const { user_country_currency_symbol } = useSelector(
    (state) => state.userState
  );

  // Prepare data with absolute values for chart display and keep original values for tooltip
  const dataForChart = [
    {
      name: piechartData.length ? piechartData[0].tag_name: '',
      y: Math.abs(piechartData.length ? piechartData[0].tag_value : 0), // Use absolute value for chart display
      color: '#C0255F',
      originalY: piechartData.length ? piechartData[0].tag_value : 0, // Keep original value for tooltip
    },
    {
      name: piechartDataPOP1.length ? piechartDataPOP1[0].tag_name : '',
      y: Math.abs(piechartDataPOP1.length ? piechartDataPOP1[0].tag_value : 0), // Use absolute value for chart display
      color: '#E66595',
      originalY: piechartDataPOP1.length ? piechartDataPOP1[0].tag_value : 0, // Keep original value for tooltip
    },
  ];

  const options = {
    chart: {
      type: 'pie',
      height: 250,
      width: 250,
      backgroundColor: 'transparent',
    },
    title: {
      text: ''
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function () {
        const total = this.series.data.reduce((acc, point) => acc + Math.abs(point.originalY), 0); // Use absolute values for total
        const percentage = ((Math.abs(this.point.originalY) / total) * 100).toFixed(2) + '%';
        return `<div style ="font-size : 12px">
                  <b>${this.point.name}</b><br/>
                  <span style="color:${this.point.color}">\u25A0</span> ${this.point.originalY} (${percentage})
                </div>`;
      }
    },
    plotOptions: {
      pie: {
        innerSize: '70%',
        dataLabels: {
          enabled: false,
        }
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'Values',
      data: dataForChart,
    }]
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.update(options);
    }
  }, [piechartData, piechartDataPOP1]);

  return (
    // <></>
    <div style={{ width: "100%", height: "195px", display: 'flex', justifyContent: 'center', alignItems: 'center',position:"relative"}} className="doughnut-chart1">
      {/* {(piechartData.length || piechartDataPOP1.length) &&
   
        <>
          {!shimmer ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={chartRef}
            />
          ) : (
            <PieChartShimmer size="195px" position="relative"/>
          )}

<p className="doughnut-chartText">
          {(piechartData.length ? piechartData[0].tag_value : 0) + (piechartDataPOP1.length ? piechartDataPOP1[0].tag_value : 0) }
            
          </p>
        </>
     } */}

{!shimmer ? piechartData.length || piechartDataPOP1.length ? (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
        <p className="doughnut-chartText">
        {(piechartData.length ? piechartData[0].tag_value : 0) + (piechartDataPOP1.length ? piechartDataPOP1[0].tag_value : 0) }
        </p>
        <p className="doughnut-chartText-unit">{piechartData[0]?.tag_unit || piechartDataPOP1[0]?.tag_unit}</p>
        
      </>
    ) : (
      <img src={images.noDataFoundImg} className="notFoundDataImg" alt="No DataFound Img" />
    ) : <PieChartShimmer size="150px" position="relative" />}
    </div>
  );
}
