import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { images } from "../../../../config/images";
import { useNavigate, useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import { getDepartment } from "../../service";
import { ToastContainer, toast } from "react-toastify";
import {
  addGroupConfiguration,
  getDepartmentForManagement,
  getDepartmentRole,
  updateGroupConfiguration,
} from "../services";
import { useSelector } from "react-redux";

const AlarmManagementUserList = ({
  selectedDevices,
  getConfigurationData,
  activeClass,
}) => {
  const [departmentList, setdepartmentList] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const [selectedRoles, setSelectedRoles] = useState("");
  const [useDetailData, setUserDetailData] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [isPreviousUsernameChecked, setIsPreviousUsernameChecked] =
    useState(false);
  const [selectUser, setSelectUser] = useState([]);
  const [allHighSelect, setAllHighSelect] = useState(false);
  const [allMediumSelect, setAllMediumSelect] = useState(false);
  const [allLowSelect, setAllLowSelect] = useState(false);
  const [allSmsSelect, setAllSmsSelect] = useState(false);
   const [allalertSelect, setAllalertSelect] = useState(false);
  const [smsAlertMsg, setSmsAlertMsg] = useState(false);
  const [isConfigure, setConfigure] = useState(false);

  console.log("selectUser", selectUser);
  const param = useParams();
  const {
    is_super_admin,
    is_created_by_super_admin_user_company,
    user_access,
  } = useSelector((state) => state.userState);

  useEffect(() => {
    setDepartmentId(
      getConfigurationData.user.length
        ? getConfigurationData.user[0].department
        : ""
    );
    setSelectedRoles(
      getConfigurationData.user.length ? getConfigurationData.user[0].role : ""
    );

    // selected user
    const user = getConfigurationData.user.length
      ? getConfigurationData.user.map((e) => {
          return {
            department: e.department,
            role: e.role,
            user: e?.user?.id,
            is_high: e.is_high,
            is_medium: e.is_medium,
            is_low: e.is_low,
            is_sms: e.is_sms,
            is_sys_alert : e.is_sys_alert
          };
        })
      : [];

    setSelectUser(user);
    setConfigure(getConfigurationData.user.length ? true : false);
  }, [activeClass]);

  const handleSelectRole = (id) => {
    if (smsAlertMsg) {
      return true;
    }

    setSelectUser((prevState) => {
      if (prevState.some((user) => user.role === id)) {
        // Role already exists, remove it from the array
        const updatedUsers = prevState.filter((user) => user.role !== id);
        return updatedUsers;
      } else {
        // Role doesn't exist, add it to the array
        const updatedUsers = prevState.filter(
          (user) => user.department !== departmentId
        );
        const newUser = {
          department: departmentId,
          role: id,
          users: [],
        };
        return [...updatedUsers, newUser];
      }
    });
  };


  const handleSelectUsers = (userID) => {
   
    // Find the index of the user with the specified userID in the selectUser array
    const userIndex = selectUser.findIndex((user) => user.user === userID);

    if (userIndex !== -1) {
      // If the user exists (index is not -1), remove the user from the array
      const updatedUsers = [...selectUser];
      updatedUsers.splice(userIndex, 1); // Remove 1 element from the array at the found index
      setSelectUser(updatedUsers);

    } else {
      // If the user does not exist, add the user to the array
      const newUser = {
        department: departmentId,
        role: selectedRoles,
        user: userID,
        is_high: false,
        is_medium: false,
        is_low: false,
        is_sys_alert : true
      };
      setSelectUser([...selectUser, newUser]);
    }
  };

  const handleUserHighCheckBoxSelect = (userId, high) => {
    if (smsAlertMsg) {
      return true;
    }

    const updatedUsers = selectUser.map((user) => {
      if (user.user === userId) {
        return {
          ...user,
          is_high: high,
          is_sms: !high ? false : user.is_sms,
        };
      }
      return user;
    });

    setSelectUser(updatedUsers);
  };

  const handleUserMediumCheckBoxSelect = (userId, medium) => {
    if (smsAlertMsg) {
      return true;
    }

    const updatedUsers = selectUser.map((user) => {
      if (user.user === userId) {
        return {
          ...user,
          is_medium: medium,
        };
      }
      return user;
    });

    setSelectUser(updatedUsers);
  };

  const handleUserLowCheckBoxSelect = (userId, low) => {
    if (smsAlertMsg) {
      return true;
    }

    const updatedUsers = selectUser.map((user) => {
      if (user.user === userId) {
        return {
          ...user,
          is_low: low,
        };
      }
      return user;
    });

    setSelectUser(updatedUsers);
  };
  

  const handleUserAlertCheckBoxSelect = (userId, alert) => {
   
    if (smsAlertMsg) {
      return true;
    }

    const updatedUsers = selectUser.map((user) => {
      if (user.user === userId) {
        return {
          ...user,
          is_sys_alert: alert,
        };
      }
      return user;
    });

    setSelectUser(updatedUsers);
  };
  const handleUserSmsCheckBoxSelect = (userId, sms) => {
    if (smsAlertMsg) {
      return true;
    }

    const updatedUsers = selectUser.map((user) => {
      if (user.user === userId) {
        if (user.is_high) {
          return {
            ...user,
            is_sms: sms,
          };
        } else {
          setSmsAlertMsg(true);
          return user;
        }
      }
      return user;
    });

    setSelectUser(updatedUsers);
  };

  const handleUserAllPriortyCheckBoxSelect = (ckd, prority) => {
    if (prority === "high") {
      if (smsAlertMsg) {
        return true;
      }

      if (ckd == true) {
        const updatedUsers = selectUser.map((user) => {
          if (user.department === departmentId) {
            return {
              ...user,
              is_high: ckd,
            };
          }
          return user;
        });

        setSelectUser(updatedUsers);
        setAllHighSelect(!allHighSelect);
      } else {
        const updatedUsers = selectUser.map((user) => {
          if (user.department === departmentId) {
            return {
              ...user,
              is_high: ckd,
              is_sms: ckd,
            };
          }
          return user;
        });

        setSelectUser(updatedUsers);
        setAllHighSelect(!allHighSelect);
        setAllSmsSelect(false);
      }
    }

    if (prority == "medium") {
      if (smsAlertMsg) {
        return true;
      }

      const updatedUsers = selectUser.map((user) => {
        if (user.department === departmentId) {
          return {
            ...user,
            is_medium: ckd,
          };
        }
        return user;
      });

      setSelectUser(updatedUsers);
      setAllMediumSelect(!allMediumSelect);
    }

    if (prority == "low") {
      if (smsAlertMsg) {
        return true;
      }

      const updatedUsers = selectUser.map((user) => {
        if (user.department === departmentId) {
          return {
            ...user,
            is_low: ckd,
          };
        }
        return user;
      });

      setSelectUser(updatedUsers);
      setAllLowSelect(!allLowSelect);
    }

    if (prority == "sms") {
      if (smsAlertMsg) {
        return true;
      }

      if (ckd == true) {
        const updatedUsers = selectUser.map((user) => {
          if (user.department === departmentId) {
            if (user.is_high) {
              return {
                ...user,
                is_sms: ckd,
              };
            } else {
              setSmsAlertMsg(true);
              return user;
            }
          }
          return user;
        });

        setSelectUser(updatedUsers);
        setAllSmsSelect(!allSmsSelect);
      } else {
        const updatedUsers = selectUser.map((user) => {
          if (user.department === departmentId) {
            if (user.is_high) {
              return {
                ...user,
                is_sms: ckd,
              };
            } else {
              return user;
            }
          }
          return user;
        });

        setSelectUser(updatedUsers);
        setAllSmsSelect(!allSmsSelect);
      }
    }
    if (prority === "alert") {
      if (smsAlertMsg) {
        return true;
      }

      const updatedUsers = selectUser.map((user) => {
        if (user.department === departmentId) {
          return {
            ...user,
            is_sys_alert: ckd,
          };
        }
        return user;
      });

      setSelectUser(updatedUsers);
      setAllalertSelect(ckd);
    }
  };

  useEffect(() => {
    if (!selectUser.length) {
      setAllHighSelect(false);
      setAllMediumSelect(false);
      setAllLowSelect(false);
      setAllSmsSelect(false);
     
    }
  }, [selectUser]);

  // const handleUsernameCheckboxChange = (index) => {
  //   const updatedData = [...useDetailData];
  //   updatedData[index].isChecked = !updatedData[index].isChecked;

  //   // Clear priority for unchecked user
  //   if (!updatedData[index].isChecked) {
  //     updatedData[index].priority = null;
  //   }

  //   setUserDetailData(updatedData);

  //   const anyChecked = updatedData.some((user) => user.isChecked);
  //   setIsPreviousUsernameChecked(anyChecked);
  // };

  // const handlePriorityCheckboxChange = (priority) => {
  //   const updatedData = useDetailData.map((user) => {
  //     if (user.isChecked) {
  //       return {
  //         ...user,
  //         priority: user.priority === priority ? null : priority,
  //       };
  //     }
  //     return user;
  //   });

  //   setUserDetailData(updatedData);
  //   setSelectedPriority(priority === selectedPriority ? null : priority);
  // };

  useEffect(() => {
    if (selectedRoles) {
      handleUserDetailData();
    }
  }, [selectedRoles, departmentId]);

  const handleUserDetailData = async () => {
    const params = {
      department: departmentId,
      role: selectedRoles,
    };
    try {
      const resp = await getDepartmentRole(params);
      if (resp.status == 200 || resp.status == 201) {
        setUserDetailData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  useEffect(() => {
    getDepartmentList();
  }, []);

  // select department
  const handleSelectDepartment = (id) => {
    if (smsAlertMsg) {
      return true;
    }

    const findSelectRole = selectUser.length
      ? selectUser.filter((f) => f.department == id).length
        ? selectUser.filter((f) => f.department == id)[0]["role"]
        : ""
      : "";
    console.log("findSelectRole", findSelectRole);
    setDepartmentId(id);
    setSelectedRoles(findSelectRole);


    // same department click not remove list userData
    if (departmentId != id) {
      setUserDetailData([]);
    }
  };

  // check all HIGH, MEDIUM, LOW condition checkbox
  useEffect(() => {
    if (departmentId) {
      // high
      const highCheckBox = selectUser.length
        ? selectUser.filter(
            (f) => f.department == departmentId && f.role == selectedRoles
          ).length
          ? selectUser.filter(
              (f) =>
                f.department == departmentId &&
                f.role == selectedRoles &&
                f.is_high
            ).length ==
            selectUser.filter(
              (f) => f.department == departmentId && f.role == selectedRoles
            ).length
            ? true
            : false
          : false
        : false;

      setAllHighSelect(highCheckBox);
      

      // medium
      const mediumCheckBox = selectUser.length
        ? selectUser.filter(
            (f) => f.department == departmentId && f.role == selectedRoles
          ).length
          ? selectUser.filter(
              (f) =>
                f.department == departmentId &&
                f.role == selectedRoles &&
                f.is_medium
            ).length ==
            selectUser.filter(
              (f) => f.department == departmentId && f.role == selectedRoles
            ).length
            ? true
            : false
          : false
        : false;

      setAllMediumSelect(mediumCheckBox);

      // low
      const lowCheckBox = selectUser.length
        ? selectUser.filter(
            (f) => f.department == departmentId && f.role == selectedRoles
          ).length
          ? selectUser.filter(
              (f) =>
                f.department == departmentId &&
                f.role == selectedRoles &&
                f.is_low
            ).length ==
            selectUser.filter(
              (f) => f.department == departmentId && f.role == selectedRoles
            ).length
            ? true
            : false
          : false
        : false;

      setAllLowSelect(lowCheckBox);
      

      // sms
      const smsCheckBox = selectUser.length
        ? selectUser.filter(
            (f) => f.department == departmentId && f.role == selectedRoles
          ).length
          ? selectUser.filter(
              (f) =>
                f.department == departmentId &&
                f.role == selectedRoles &&
                f.is_sms
            ).length ==
            selectUser.filter(
              (f) => f.department == departmentId && f.role == selectedRoles
            ).length
            ? true
            : false
          : false
        : false;

      setAllSmsSelect(smsCheckBox);

      const alertCheckBox = selectUser.length
      ? selectUser.filter(
          (f) => f.department == departmentId && f.role == selectedRoles
        ).length
        ? selectUser.filter(
            (f) =>
              f.department == departmentId &&
              f.role == selectedRoles &&
              f.is_sys_alert
          ).length ==
          selectUser.filter(
            (f) => f.department == departmentId && f.role == selectedRoles
          ).length
          ? true
          : false
        : false
      : false;

    setAllalertSelect(alertCheckBox);
    }
  }, [departmentId, selectUser, selectedRoles]);

  //Get department list
  const getDepartmentList = async () => {
    try {
      const paramReq = {
        company: param.companyId,
      };
      const response = await getDepartmentForManagement(paramReq);
      console.log("departmentList", response);
      if (response.status == 200 || response.status == 201) {
        setdepartmentList(response.data.payload);
        // if (!departmentId) {
        //   setDepartmentId(response.data.payload[0].id);
        // }
      }
    } catch (error) {
      notify(error.response.data.message);
    }
  };

  // const handleUsernameCheckboxChange = (index) => {
  //   const updatedData = [...useDetailData];
  //   updatedData[index].isChecked = !updatedData[index].isChecked;
  //   setUserDetailData(updatedData);
  // };

  // const handlePriorityCheckboxChange = (priority) => {
  //   if (selectedPriority === priority) {
  //     setSelectedPriority(null);
  //   } else {
  //     setSelectedPriority(priority);
  //   }
  // };

  const handleAddGroupConfiguration = async () => {
    if (smsAlertMsg) {
      return true;
    }

    const params = {
      group: Number(param.groupId),
      device: selectedDevices,
      user: selectUser,
    };
    try {
      const resp = await addGroupConfiguration(params);
      if (resp.status == 200 || resp.status == 201) {
        navigate("/alarmmanagement");
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  const handleUpdateGroupConfiguration = async () => {
    if (smsAlertMsg) {
      return true;
    }

    const params = {
      group: Number(param.groupId),
      device: selectedDevices,
      user: selectUser,
    };
    try {
      const resp = await updateGroupConfiguration(params, param.groupId);
      if (resp.status == 200 || resp.status == 201) {
        navigate("/alarmmanagement");
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  const handleAlertModel = () => {
    setSmsAlertMsg(false);
    setAllSmsSelect(false);
  };

  return (
    <>
      
      {smsAlertMsg && (
        <div>
          <Box className="popup-box-1">
            <div className="pt-5 text-center">
              <h5>
                Required to enable High <br />
                Priority Alarm
              </h5>
            </div>
            <div className="text-center pt-3">
              <button className="vms-btn" onClick={() => handleAlertModel()}>
                OK
              </button>
            </div>
          </Box>
        </div>
      )}
      <Grid container>
        <Grid item xs={12}>
          <Card className="manageDepartment-card">
            <Grid
              container
              style={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "30px",
                paddingBottom: "30px",
              }}
            >
              <Grid item sm={12} md={12} lg={4} xl={4}>
                <Typography variant="subtitle2" gutterBottom>
                  <b>Select Department</b>
                </Typography>

                {departmentList.map((e, i) => {
                  return (
                    <div className="qqq">
                      <div
                        className="manageDepartment-wrap mb-2"
                        onClick={() => handleSelectDepartment(e.id)}
                      >
                        <div
                          className={`manageDepartment-fieldWrap ${
                            (
                              selectUser.find((f) => f.department == e.id)
                                ? true
                                : false
                            )
                              ? "alarm-department-highlight"
                              : ""
                          }`}
                        >
                          {departmentId == e.id && (
                            <img
                              src={images.activeDeviceimg}
                              alt=""
                              className="activeDepartmentimg"
                            />
                          )}
                          <div className="manageDepartment-field">
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{ margin: "0" }}
                            >
                              {is_super_admin ||
                              is_created_by_super_admin_user_company
                                ? `${e.department_name}  -  ${e.company_name}`
                                : e.department_name}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Grid>
              <Grid item sm={12} md={12} lg={4} xl={4}>
                <Typography variant="subtitle2" gutterBottom>
                  <b>Roles</b>
                </Typography>

                <div className="alarmMangementCard-useListCard1">
                  {departmentList.length
                    ? departmentList.map((e, i) => {
                        if (e.id == departmentId) {
                          return e.company_roles.map((e1) => {
                            return (
                              <div className="alarmManagementCard-Roleswrap">
                                <div>
                                  <Checkbox
                                    {...label}
                                    defaultChecked={false}
                                    style={{
                                      color: "#1C407B",
                                    }}
                                    checked={
                                      selectUser.find((f) => f.role == e1.id)
                                        ? true
                                        : false || selectedRoles == e1.id
                                    }
                                    onChange={(event) =>
                                      setSelectedRoles(e1.id)
                                    }
                                  />
                                </div>
                                <div>
                                  <Typography
                                    variant="body2"
                                    gutterBottom
                                    className="m-0"
                                  >
                                    {e1.role_name}
                                  </Typography>
                                </div>
                              </div>
                            );
                          });
                        }
                      })
                    : []}
                </div>
              </Grid>
              <Grid item sm={12} md={12} lg={4} xl={4}>
                <Typography variant="subtitle2" gutterBottom>
                  <b>User Details</b>
                </Typography>

                <div className="alarmMangementCard-useListCard">
                  <Typography
                    variant="body2"
                    gutterBottom
                    className="sms-alarm"
                  >
                  SMS: Applicable for High Priority Alarms only.
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className="sms-alarm "
                    style={{marginTop:"-16px"}}
                  >
                  SYS Alert: Applicable for receive system alert email.
                  </Typography>
                  <div className="alarmaManagementCard-UserDetailsWrap">
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={4} md={6} sm={6}>
                        <div>
                          <Typography
                            variant="body2"
                            gutterBottom
                            className="m-0"
                          >
                            User Name
                          </Typography>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={8}
                        md={6}
                        sm={6}
                        className="alarm-management-user"
                      >
                        <div className="alarmaManagementCard-alarmPriorityWrap">
                          <div className="px-1">
                            <Typography
                              variant="body2"
                              gutterBottom
                              className="m-0"
                            >
                              Alarm Priority
                            </Typography>
                          </div>
                          <div className="alarmaManagementCard-checkboxWrap">
                            <div>
                              <Checkbox
                                {...label}
                                style={{
                                  color: "#E31E24",
                                  padding: "0px",
                                }}
                                checked={allHighSelect}
                                // onChange={() => handlePriorityCheckboxChange("H")}
                                onChange={(ckd) =>
                                  handleUserAllPriortyCheckBoxSelect(
                                    ckd.target.checked,
                                    "high"
                                  )
                                }
                                // disabled={!isPreviousUsernameChecked}
                                disabled={!selectUser.length}
                              />
                            </div>
                            <Typography
                              variant="body2"
                              gutterBottom
                              className="m-0"
                            >
                              H
                            </Typography>
                          </div>
                          <div className="alarmaManagementCard-checkboxWrap">
                            <div>
                              <Checkbox
                                {...label}
                                style={{
                                  color: "#FFA500",
                                  // color:'#FFFF00',
                                  padding: "0px",
                                }}
                                onChange={(ckd) =>
                                  handleUserAllPriortyCheckBoxSelect(
                                    ckd.target.checked,
                                    "medium"
                                  )
                                }
                                checked={allMediumSelect}
                                // onChange={() => handlePriorityCheckboxChange("M")}
                                disabled={!selectUser.length}
                              />
                            </div>
                            <Typography
                              variant="body2"
                              gutterBottom
                              className="m-0"
                            >
                              M
                            </Typography>
                          </div>
                          <div className="alarmaManagementCard-checkboxWrap">
                            <div>
                              <Checkbox
                                {...label}
                                style={{
                                  color: "#EBEB27",
                                  padding: "0px",
                                }}
                                onChange={(ckd) =>
                                  handleUserAllPriortyCheckBoxSelect(
                                    ckd.target.checked,
                                    "low"
                                  )
                                }
                                checked={allLowSelect}
                                // onChange={() => handlePriorityCheckboxChange("L")}
                                // disabled={!isPreviousUsernameChecked}
                                disabled={!selectUser.length}
                              />
                            </div>
                            <Typography
                              variant="body2"
                              gutterBottom
                              className="m-0"
                            >
                              L
                            </Typography>
                          </div>
                          <div className="alarmaManagementCard-checkboxWrap">
                            <div>
                              <Checkbox
                                {...label}
                                style={{
                                  color: "#A9A9A9",
                                  padding: "0px",
                                }}
                                checked={allSmsSelect}
                                onChange={(ckd) =>
                                  handleUserAllPriortyCheckBoxSelect(
                                    ckd.target.checked,
                                    "sms"
                                  )
                                }
                                disabled={!selectUser.length}
                              />
                            </div>
                            <Typography
                              variant="body2"
                              gutterBottom
                              className="m-0"
                            >
                              SMS
                            </Typography>
                          </div>
                          <div className="alarmaManagementCard-checkboxWrap">
                            <div>
                              <Checkbox
                                {...label}
                                style={{
                                  color: "#2b48ff",
                                  padding: "0px",
                                }}
                                checked={allalertSelect}
                                onChange={(ckd) =>
                                  handleUserAllPriortyCheckBoxSelect(
                                    ckd.target.checked,
                                    "alert"
                                  )
                                }
                                disabled={!selectUser.length}
                              />
                            </div>
                            <Typography
                              variant="body2"
                              gutterBottom
                              className="m-0"
                            >
                              SYS <br/> Alert
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    {useDetailData.length
                      ? useDetailData.map((e, index) => {
                          const previousUser = useDetailData[index - 1];

                          const handlePriorityCheckboxChange = (priority) => {
                            if (e.isChecked) {
                              const updatedData = [...useDetailData];
                              updatedData[index].priority =
                                e.priority === priority ? null : priority;
                              setUserDetailData(updatedData);
                            }
                          };

                          return (
                            <div
                              className="alarmManagementCard-UserDetail-innerWrap"
                              key={index}
                            >
                              <div className="alarmManagementCard-UserDetail-ContentWrap">
                                <Grid container spacing={2}>
                                  <Grid item xs={12} lg={6} md={6} sm={6}>
                                    <div className="alarmManagementCard-UserDetail-Content-inner-Wrap ">
                                      <div>
                                        <Checkbox
                                          {...label}
                                          style={{
                                            color: "#1C407B",
                                          }}
                                          checked={
                                            selectUser.find(
                                              (f) => f.user == e.id
                                            )
                                              ? true
                                              : false
                                          }
                                          onChange={() =>
                                            handleSelectUsers(e.id)
                                          }
                                        />
                                      </div>
                                      <div>
                                        <Typography
                                          variant="body2"
                                          gutterBottom
                                          className="m-0"
                                        >
                                          {e.first_name} {e.last_name}
                                        </Typography>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    className="alarm-management-user"
                                  >
                                    <div>
                                      <Checkbox
                                        {...label}
                                        style={{
                                          color: "#E31E24",
                                        }}
                                        onChange={(ckd) =>
                                          handleUserHighCheckBoxSelect(
                                            e.id,
                                            ckd.target.checked
                                          )
                                        }
                                        checked={
                                          selectUser.find(
                                            (user) => user.user === e.id
                                          )?.is_high || false
                                        }
                                      />
                                      <Checkbox
                                        {...label}
                                        style={{
                                          color: "#FFA500",
                                        }}
                                        checked={
                                          selectUser.find(
                                            (user) => user.user === e.id
                                          )?.is_medium || false
                                        }
                                        onChange={(ckd) =>
                                          handleUserMediumCheckBoxSelect(
                                            e.id,
                                            ckd.target.checked
                                          )
                                        }
                                      />
                                      <Checkbox
                                        {...label}
                                        style={{
                                          color: "#EBEB27",
                                        }}
                                        checked={
                                          selectUser.find(
                                            (user) => user.user === e.id
                                          )?.is_low || false
                                        }
                                        onChange={(ckd) =>
                                          handleUserLowCheckBoxSelect(
                                            e.id,
                                            ckd.target.checked
                                          )
                                        }
                                      />
                                      <Checkbox
                                        {...label}
                                        style={{
                                          color: "#A9A9A9",
                                        }}
                                        checked={
                                          selectUser.find(
                                            (user) => user.user === e.id
                                          )?.is_sms || false
                                        }
                                        onChange={(ckd) =>
                                          handleUserSmsCheckBoxSelect(
                                            e.id,
                                            ckd.target.checked
                                          )
                                        }
                                      />
                                      <Checkbox
                                        {...label}
                                        style={{
                                          color: "#2b48ff",
                                        }}
                                        checked={
                                          selectUser.find(
                                            (user) => user.user === e.id
                                          )?.is_sys_alert || false
                                        }
                                        onChange={(ckd) =>
                                          handleUserAlertCheckBoxSelect(
                                            e.id,
                                            ckd.target.checked
                                          )
                                        }
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          );
                        })
                      : []}
                  </div>

                  {/* </div> */}
                </div>
                {isConfigure ? (
                  <button
                    className={
                      allHighSelect ||
                      allMediumSelect ||
                      allLowSelect ||
                      allSmsSelect || allalertSelect
                        ? "Plantmanagemet submit"
                        : "alarmManagement-submit-disable"
                    }
                    onClick={() => handleUpdateGroupConfiguration()}
                    disabled={
                      !(
                        allHighSelect ||
                        allMediumSelect ||
                        allLowSelect ||
                        allSmsSelect || allalertSelect
                      )
                    }
                  >
                    Update
                  </button>
                ) : (
                  <button
                    className={
                      allHighSelect ||
                      allMediumSelect ||
                      allLowSelect ||
                      allSmsSelect || allalertSelect
                        ? "Plantmanagemet submit"
                        : "alarmManagement-submit-disable"
                    }
                    onClick={() => handleAddGroupConfiguration()}
                    disabled={
                      !(
                        allHighSelect ||
                        allMediumSelect ||
                        allLowSelect ||
                        allSmsSelect || allalertSelect
                      )
                    }
                  >
                    Submit
                  </button>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid></Grid>
      </Grid>
    </>
  );
};
export default AlarmManagementUserList;
