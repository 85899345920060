
import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PieChartShimmer } from "../../../../components/Shimmer/PieChartShimmer/PieChartShimmer";
import '../../../ProcessDashboard/AdvanceAnalytics/Graph/Shimmer.css';
import "../../../ProcessDashboard/ProcessDashboard.css";
import { images } from "../../../../config";

export function PieChartCOC({
    piechartData,
    shimmer,
    tagID,
    pieChartSum,

  }) {
    const chartRef = useRef(null);


  
    const dataForChart = piechartData && piechartData.length 
      ? piechartData.map((row) => {
        const findTagId = tagID.find((f) => f.tagId == row.tag_id);
        return {
          name: row.tag_name,
          y: Math.abs(row.tag_value),  // Correct property name
          color: findTagId ? findTagId.color : "",
          originalY: row.tag_value, 
        };
      }) 
      : [];
  
    const options = {
      chart: {
        type: 'pie',
        height: 230,
        width: 230,
        backgroundColor: 'transparent',
        align: 'center', // Center horizontally
      verticalAlign: 'middle', // Center vertically
      },
      title: {
        text: ''
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        formatter: function () {
          const total = this.series.data.reduce((acc, point) => acc + Math.abs(point.originalY), 0);
        const percentage = ((Math.abs(this.point.originalY) / total) * 100).toFixed(2) + '%';
        return `<div style ="font-size : 12px">
                  <b>${this.point.name}</b><br/>
                  <span style="color:${this.point.color}">\u25A0</span> ${this.point.originalY.toFixed(2)} (${percentage})
                </div>`;
        }
      },
      plotOptions: {
        pie: {
          innerSize: '70%',
          dataLabels: {
            enabled: false,
          }
        }
      },
      series: [{
        name: 'Up Time',
        data: dataForChart, // Corrected data
      }],
      credits: {
        enabled: false
      }
    };
  
    useEffect(() => {
      if (chartRef.current) {
        chartRef.current.chart.update(options);
      }
    }, [piechartData]);
  
    return (
    <div style={{ width: "100%", height: "195px", display: 'flex', justifyContent: 'center', alignItems: 'center',position:"relative"}} className="doughnut-chart1">
    {!shimmer ? piechartData.length ? (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
        <p className="doughnut-chartText">
          {pieChartSum? pieChartSum.tag_value_sum : ""}
        </p>
        <p className="doughnut-chartText-unit">{piechartData[0]?.tag_unit}</p>
        
      </>
    ) : (
      <img src={images.noDataFoundImg} className="notFoundDataImg" alt="No DataFound Img" />
    ) : <PieChartShimmer size="150px" position="relative" />}
  </div>
    );
  }
  