import React, { useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TextField,
} from "@material-ui/core";
import "../AdvanceAnalytics/Analytics.css";
import { images } from "../../../config/images";
import {
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { getDeviceAPI } from "../../SuperAdminDashboard/ReportManagement/service";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import Container from "@mui/material/Container";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  advanceCreateConfiguration,
  advanceGetConfiguration,
  advancegetAdvanceAnalyticsRunningHour,
  createCostPrice,
  exportAdvanceAnalyticsSummaryReport,
  getCo2FootPrintSummary,
  getCostPrice,
  getEnergyCostSummary,
  getSummaryGraphData,
  summaryReport,
} from "./services";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setAnalyticsData } from "./Slice/AdvanceAnalyticsSlice";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import AnalyticsGraph from "./Graph/AnalyticsGraph";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { getDeviceTagList } from "../Configration/services";
import Clock from "../../../components/Clock/Clock";
import CloseIcon from "@mui/icons-material/Close";
import Marquee from "react-fast-marquee";
import { setProcessDashboarDateSet } from "../sliceProcessDashboard/dateSliceprocessDashboard";
import { ShimmerTable, ShimmerTitle } from "react-shimmer-effects";


const ConfigrationAnalytics = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [selectRange, setSelectedRange] = useState([]);
  const [editPopup, setEditPopup] = useState(false);
  const [deviceUnit, setDeviceUnit] = useState([]);
  const [deviceId, setDeviceId] = useState(null);
  const [deviceTagList, setDeviceTagList] = useState([]);
  const [analyticsDevicePopup, setAnalyticsDevicePopup] = useState(false);
  const [auxPopup, setAuxPopup] = useState(false);
  const [sourceDevicePopup, setSourceDevicePopup] = useState(false);
  const [sourceDevice, setSourceDevice] = useState([]);
  const [devicepopup, setDevicePopup] = useState(false);
  const [consumptionDevice, setConsumptionDevice] = useState("");
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedAuxDevices, setSelectedAuxDevices] = useState([]);
  const [selectAllAux, setSelectAllAux] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedSourceDevices, setSelectedSourceDevices] = useState([]);
  const [selectSourceAll, setSourceSelectAll] = useState(false);
  const [configureId, setConfigureId] = useState("");
  const [runningHour, setRunningHour] = useState({});
  const [co2SummaryData, setCo2SummaryData] = useState("");
  const [numInputs, setNumInputs] = useState(0);
  const [getCost, setGetCost] = useState([]);
  const [energycostType, setEnergyCostType] = useState("");
  const [energySummaryData, setEnergySummaryData] = useState("");
  const [selectAllUnits, setSelectAllUnits] = useState(false);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [searchTagName, setSearchTagName] = useState("");
  const [getsummaryGraphData, setSummaryGraphData] = useState([]);
  const [getDeviceSummaryData, setDeviceSummaryData] = useState([]);
  const [searchTagComment, setSearchTagComment] = useState("");
  const [searchTagUnit, setSearchTagUnit] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [productionPopup, setProductionPopup] = useState(false);
  const [sendEmailPopup, setSendEmailPopup] = useState(false);
  const [configureTagUnit, setConfigureTagUnit] = useState({
    accepted: "",
    rejected: "",
  });

  // CO2 Prod
  const [co2ProdSelectDevice, setCo2PrdSelectDevice] = useState([]);
  const [co2ProdDevicePopup, setCo2ProdDevicePopup] = useState(false);
  const [allSelectCo2, setAllSelectCo2] = useState(false);

  // co2 AUX
  const [co2AuxSelectDevice, setCo2AuxSelectDevice] = useState([]);
  const [co2AuxDevicePopup, setCo2AuxDevicePopup] = useState(false);
  const [allSelectCo2Aux, setAllSelectCo2Aux] = useState(false);

  const [energyCostData, setEnergyCostData] = useState({
    Total: "",
    production: "",
    Auxillary: "",
    GreenEnergy: "",
  });
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [unitPrice, setUnitPrice] = useState("");
  const initialTimevalue = [
    {
      startTime: null,
      endTime: null,
      unitPrice: 0,
    },
  ];
  const [costTimeObject, setCostTimeObject] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedTagWithDevice, setSelectedtagWithDevice] = useState([]);
  const [selectTagUnitDisable, setSelecttagUnitDisable] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [isShimmer, setShimmer] = useState(false);
  const [isShimmerDeviceList, setisShimmerDeviceList] = useState(false);
  const [isShimmerDeviceTagList, setisShimmerDeviceTagList] = useState(false);
  const [isHtml2CanvasLoaded, setIsHtml2CanvasLoaded] = useState(false);
  const html2canvasRef = useRef(null);

  console.log("imageURL", imageURL);

  const handledeleteClick = () => {
    setDeletePopup(true);
    setEditPopup(false);
  };

  const { user_country_currency_symbol } = useSelector(
    (state) => state.userState
  );
  const { selectUnit, selectCostUnit } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );

  console.log(
    "selectedTagWithDevice",
    selectedTagWithDevice,
    selectTagUnitDisable
  );

  const { user_access } = useSelector((state) => state.userState);
  const MAX_DATE_RANGE_DAYS = 365; // Maximum duration of one year in days

  const handleDeleteConfirmation = () => {
    if (deleteIndex !== null) {
      // Remove the row associated with the stored index from costTimeObject
      setCostTimeObject((prevCostTimeObject) =>
        prevCostTimeObject.filter((_, index) => index !== deleteIndex)
      );
    }

    // Clear the deleteIndex and close the delete confirmation popup
    setDeleteIndex(null);
    setDeletePopup(false);
  };

  const handleDeleteClick = (index) => {
    // Set the index of the row to delete and open the delete confirmation popup
    setDeleteIndex(index);
    setDeletePopup(true);
    // setEditPopup(false)
  };

  const handleSelectTagWithDevice = (deviceId, tagUnit, tagId, tagName) => {
    // Check if an object with the same tagId, deviceId, and tagUnit already exists
    const existingIndex = selectedTagWithDevice.findIndex(
      (obj) =>
        obj.tagId === tagId &&
        obj.deviceId === deviceId &&
        obj.tagUnit === tagUnit
    );

    if (existingIndex !== -1) {
      // An object with the same values already exists, remove it from the array
      const updatedSelectedTagWithDevice = selectedTagWithDevice.filter(
        (obj, index) => index !== existingIndex
      );

      setSelectedtagWithDevice(updatedSelectedTagWithDevice);
    } else {
      // No object with the same values found, add the new object to the array
      const objTagData = {
        deviceId: deviceId,
        tagId: tagId,
        tagUnit: tagUnit,
        tagName: tagName,
      };

      setSelectedtagWithDevice([...selectedTagWithDevice, objTagData]);
    }
  };

  useEffect(() => {
    if (selectedTagWithDevice.length && deviceId) {
      const isFirstAndSecondDeviceSame =
        selectedTagWithDevice.length >= 2
          ? selectedTagWithDevice[0].deviceId ===
            selectedTagWithDevice[1].deviceId
          : false;

      if (isFirstAndSecondDeviceSame) {
        setSelecttagUnitDisable(
          selectedTagWithDevice.map((row) => row.tagUnit).slice(0, 2)
        );
      } else {
        setSelecttagUnitDisable([]);
      }
    } else {
      setSelecttagUnitDisable([]);
    }
  }, [selectedTagWithDevice, deviceId]);

  // handle Left device
  const handleleftdevice = () => {
    const checkLeftDevice = selectedTagWithDevice.length
      ? selectedTagWithDevice.filter((f) => f.deviceId == deviceId).length
      : 0;

    if (checkLeftDevice == 0) {
      return 2;
    } else if (checkLeftDevice == 1) {
      return 1;
    } else {
      return 0;
    }
  };

  const handleTagSubmitClick = async () => {
    const params = {
      plant: param.id,
      company: param.companyId,
      en_production: selectedDevices,
      en_greenenergy: selectedSourceDevices,
      en_auxilary: selectedAuxDevices,
      co_production: co2ProdSelectDevice,
      co_auxilary: co2AuxSelectDevice,
      graph: selectedTagWithDevice.map((row) => row.tagId),
    };

    try {
      const resp = await advanceCreateConfiguration(params);
      if (resp.status == 200 || resp.status == 201) {
        setDevicePopup(false);
        console.log("successfully create configuration");
      }
    } catch (error) {
      notifyError(error.response.data.message);
      console.log("handlesubmit", error);
    }
  };

  const handleTimeChange = (index, field, value) => {
    const updatedTimeValues = [...costTimeObject];
    updatedTimeValues[index][field] = value;
    setCostTimeObject(updatedTimeValues);
  };

  const { configurationSummary, summaryStartDate, summaryEndDate } =
    useSelector((state) => state.analyticsDataState);

  const plantDashboardAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Plant Dashboard"
      )
    : null;

  const analyticsAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Advance Analytics"
        )
      : null
    : null;

  const configAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Configuration"
        )
      : null
    : null;

  const handleTostMsg = () => {
    notifyError("You don't have access");
  };
  const dateFormat = "YYYY-MM-DD";
  const startDateReFormat = summaryStartDate ? dayjs(summaryStartDate) : null;
  const endDateReFormat = summaryEndDate ? dayjs(summaryEndDate) : null;

  const startDateFormat = summaryStartDate
    ? dayjs(summaryStartDate).format(dateFormat)
    : "";

  const endDateFormat = summaryEndDate
    ? dayjs(summaryEndDate).format(dateFormat)
    : "";

  const dispatch = useDispatch();
  const ref = useRef();
  // Calculate the total filled time based on the selected time slots

  const param = useParams();
  useEffect(() => {
    if (startDateFormat && endDateFormat) {
      handlegetSummaryData();
    }
  }, [startDateFormat, endDateFormat, configurationSummary, selectUnit]);

  const handlegetSummaryData = async () => {
    const params = {
      plant_id: param.id,
      start_date: startDateFormat,
      end_date: endDateFormat,
      company_id: param.companyId,
      unit: selectUnit,
    };
    try {
      setShimmer(true);
      const resp = await getSummaryGraphData(params);
      if (resp.status == 200 || resp.status == 201) {
        setConfigureTagUnit({
          accepted: resp.data.payload.accepted,
          rejected: resp.data.payload.rejected,
        });
        setDeviceSummaryData(resp.data.payload);
        setSummaryGraphData(
          resp.data.payload.data.map((item) => {
            const transformedItem = {
              date: item.date,
              accepted: item[Object.keys(item)[1]],
              rejected: item[Object.keys(item)[2]],
            };

            return transformedItem;
          })
        );
        setTimeout(() => {
          setShimmer(false);
        }, 2000);
      }
    } catch (error) {
      setShimmer(false);
      console.log("handleSubmit", error);
    }
  };

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notify = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleOpenModal = () => {
    setAnalyticsDevicePopup(true);
    setEditPopup(false);
    setDevicePopup(false);
    setCo2ProdDevicePopup(false);
    setSourceDevicePopup(false);
    setCo2AuxDevicePopup(false);
    setAuxPopup(false);
    setProductionPopup(false);
  };

  const handleAuxOpenModal = () => {
    setAuxPopup(true);
    setAnalyticsDevicePopup(false);
    setEditPopup(false);
    setDevicePopup(false);
    setCo2ProdDevicePopup(false);
    setSourceDevicePopup(false);
    setCo2AuxDevicePopup(false);
    setProductionPopup(false);
  };

  const handleEnergyOpenModal = () => {
    setSourceDevicePopup(true);
    setEditPopup(false);
    setAnalyticsDevicePopup(false);
    setDevicePopup(false);
    setCo2ProdDevicePopup(false);
    setCo2AuxDevicePopup(false);
    setAuxPopup(false);
    setProductionPopup(false);
  };

  // Co2 Prd

  const handleOpenCo2Modal = () => {
    setCo2ProdDevicePopup(!co2ProdDevicePopup);
    setCo2AuxDevicePopup(false);
    setAnalyticsDevicePopup(false);
    setSourceDevicePopup(false);
    setDevicePopup(false);
    setEditPopup(false);
    setAuxPopup(false);
    setProductionPopup(false);
  };

  //Co2 Aux

  const handleOpenCo2AuxModal = () => {
    setCo2AuxDevicePopup(!co2AuxDevicePopup);
    setCo2ProdDevicePopup(false);
    setAnalyticsDevicePopup(false);
    setSourceDevicePopup(false);
    setDevicePopup(false);
    setEditPopup(false);
    setAuxPopup(false);
    setProductionPopup(false);
  };

  const handleEditClick = (type) => {
    setEditPopup(true);
    setEnergyCostType(type);
    setAnalyticsDevicePopup(false);
    setSourceDevicePopup(false);
    setCo2ProdDevicePopup(false);
    setDevicePopup(false);
    setCo2AuxDevicePopup(false);
    setAuxPopup(false);
    setProductionPopup(false);
  };

  const handleCancleClick = () => {
    setEditPopup(false);
    setDevicePopup(false);
    setCo2ProdDevicePopup(false);
    setCo2AuxDevicePopup(false);
    setAnalyticsDevicePopup(false);
    setSourceDevicePopup(false);
    setAuxPopup(false);
    setProductionPopup(false);
  };

  const handleEditCancleClick = () => {
    // window.location.reload(false);
    setEditPopup(false);
    setDevicePopup(false);
    setCo2ProdDevicePopup(false);
    setCo2AuxDevicePopup(false);
    setAnalyticsDevicePopup(false);
    setSourceDevicePopup(false);
    setAuxPopup(false);
    setProductionPopup(false);
    setCostTimeObject([]);
  };

  const handleCloseClick = () => {
    setProductionPopup(false);
    setAuxPopup(false);
    setAnalyticsDevicePopup(false);
    setSourceDevicePopup(false);
    setDevicePopup(false);
    setCo2AuxDevicePopup(false);
    setCo2ProdDevicePopup(false);
    setEditPopup(false);
  };
  useEffect(() => {
    if (
      auxPopup ||
      analyticsDevicePopup ||
      devicepopup ||
      co2ProdDevicePopup ||
      co2AuxDevicePopup
    ) {
      consumptionDeviceAPI();
    }
  }, [
    auxPopup,
    analyticsDevicePopup,
    co2ProdDevicePopup,
    co2AuxDevicePopup,
    devicepopup,
  ]);

  useEffect(() => {
    if (editPopup) {
      handlegetCostAPI();
    }
  }, [editPopup]);

  function convertToISOFormat(date, time) {
    const [year, month, day] = date.split("-");
    const [hours, minutes, seconds] = time.split(":");

    const isoDateTime = new Date(
      year,
      month - 1,
      day,
      hours,
      minutes,
      seconds
    ).toISOString();

    return isoDateTime;
  }

  const handlegetCostAPI = async () => {
    const params = {
      company: param.companyId,
      choise: energycostType,
      plant_id: param.id,
    };
    try {
      const resp = await getCostPrice(params);
      if (resp.status == 200 || resp.status == 201) {
        if (resp.data.payload.time.length) {
          const creareTime = resp.data.payload.time.map((row) => {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, "0"); // Zero-padding the month
            const day = String(today.getDate()).padStart(2, "0"); // Zero-padding the day

            const formattedDate = `${year}-${month}-${day}`;

            console.log(formattedDate);
            const convertedDateTime = convertToISOFormat(
              formattedDate,
              row.start_time
            );
            const startTimeFormat = convertedDateTime
              ? dayjs(convertedDateTime)
              : null;
            const convertedDateTimeEnd = convertToISOFormat(
              formattedDate,
              row.end_time
            );
            const endTimeFormat = convertedDateTimeEnd
              ? dayjs(convertedDateTimeEnd)
              : null;
            return {
              startTime: startTimeFormat,
              endTime: endTimeFormat,
              unitPrice: row.unit_price,
            };
          });

          // Instead of overwriting the costTimeObject state, you should merge the new data
          setCostTimeObject((prevCostTimeObject) => [
            ...prevCostTimeObject,
            ...creareTime,
          ]);

          // You can also update numInputs if needed
          setNumInputs(numInputs + creareTime.length);
        }

        setGetCost(resp.data.payload);
        console.log("getcost data successfully");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSubmitClick = async () => {
    // Duplicate time slot validation
    const timeSlotSet = new Set();
    for (const row of costTimeObject) {
      if (row.startTime && row.endTime) {
        const startTime = dayjs(row.startTime, "hh:mmA");
        const endTime = dayjs(row.endTime, "hh:mmA");
        const durationMinutes = endTime.diff(startTime, "minutes");
        // Check for duplicate time slots down to the minute level
        for (let i = 0; i < durationMinutes; i++) {
          const timeSlot = startTime.add(i, "minutes").format("hh:mmA");
          if (timeSlotSet.has(timeSlot)) {
            notifyError("Duplicate time slot:", timeSlot);
            return;
          }
          timeSlotSet.add(timeSlot);
        }
      }
    }

    // Calculate the total hours for all time slots
    const totalHours = costTimeObject.reduce((total, row) => {
      if (row.startTime && row.endTime) {
        const startTime = dayjs(row.startTime, "hh:mmA");
        const endTime = dayjs(row.endTime, "hh:mmA");

        let durationHours = Math.round(endTime.diff(startTime, "hours", true));

        // Check if the end time is before the start time, indicating a time span across two days
        if (durationHours < 0) {
          // Add 24 hours to the duration
          durationHours += 24;
        }
        return total + durationHours;
      }
      return total;
    }, 0);

    if (totalHours !== 24) {
      // Check if the total hours equal 24
      notifyError("Time slots must be 24 hours.");
      return;
    }
    const params = {
      time: costTimeObject.length
        ? costTimeObject
            .map((row) => {
              const timeFormat = "HH:mm";
              const startTimeFormat = row.startTime
                ? dayjs(row.startTime, "hh:mmA").format(timeFormat)
                : "";
              const endTimeFormat = row.endTime
                ? dayjs(row.endTime, "hh:mmA").format(timeFormat)
                : "";
              return {
                start_time: startTimeFormat,
                end_time: endTimeFormat,
                unit_price: Number(row.unitPrice),
              };
            })
            .filter((obj) => obj.start_time !== "" || obj.end_time !== "")
        : [], // This filters out empty objects from the array.
      choise: energycostType,
      plant: param.id,
      company: param.companyId,
    };

    console.log("params", params);

    try {
      if (params.time.length > 0) {
        // Only make the API call if there are non-empty time entries.
        const resp = await createCostPrice(params);
        if (resp.status === 200 || resp.status === 201) {
          console.log("Add cost price successfully");
          setEditPopup(false);
          setCostTimeObject([]);
          setNumInputs(0);
        }
      } else {
        console.log("Start time, end time, and unit price are required.");
      }
    } catch (error) {
      setCostTimeObject([]);
      setNumInputs(0);
      console.log("handleSubmitError", error);
    }
  };
  const isDeviceDisabledInAuxPopup = (device) => {
    return selectedDevices.includes(device);
  };

  const isDeviceDisabledInanalyticsPopup = (device) => {
    return selectedAuxDevices.includes(device);
  };
  const isDeviceDisabledInCO2prodPopup = (device) => {
    return co2AuxSelectDevice.includes(device);
  };

  const isDeviceDisabledInCO2AuxPopup = (device) => {
    return co2ProdSelectDevice.includes(device);
  };
  const handleCancleClick1 = () => {
    setAuxPopup(false);
    setAnalyticsDevicePopup(false);
    setSourceDevicePopup(false);
    setDevicePopup(false);
    setCo2AuxDevicePopup(false);
    setCo2ProdDevicePopup(false);
    setEditPopup(false);
    setProductionPopup(false);
    handleGetConfiguration();
  };

  const handleChartClick = () => {
    setDevicePopup(true);
    setEditPopup(false);
    setCo2ProdDevicePopup(false);
    setCo2AuxDevicePopup(false);
    setAnalyticsDevicePopup(false);
    setSourceDevicePopup(false);
    setAuxPopup(false);
    setProductionPopup(false);
  };

  const handelSelectDevice = (row, id) => {
    row.stopPropagation();

    if (
      selectedTagWithDevice.length
        ? selectedTagWithDevice.filter((f) => f.deviceId == deviceId).length
          ? selectedTagWithDevice.filter((f) => f.deviceId == deviceId)
              .length == 2
          : true
        : true
    ) {
      setDeviceId(id);
    }
  };

  const consumptionDeviceAPI = async () => {
    setisShimmerDeviceList(true);
    const params = {
      device_type: "Consumption",
      plant_id: param.id,
    };
    try {
      const resp = await getDeviceAPI(params);
      if (resp.status == 200 || resp.status == 201) {
        setisShimmerDeviceList(false);
        setConsumptionDevice(resp.data.payload);
        if (resp.data.payload.device.length) {
          const firstDeviceId = resp.data.payload.device[0].device;
          setDeviceId(firstDeviceId); // Select the first device
          setSelectAll(
            selectedDevices.length == resp.data.payload.device.length
          );
          setSelectAllAux(
            selectedAuxDevices.length == resp.data.payload.device.length
          );
          setAllSelectCo2(
            co2ProdSelectDevice.length == resp.data.payload.device.length
          );
          setAllSelectCo2Aux(
            co2AuxSelectDevice.length == resp.data.payload.device.length
          );
        }
      }
    } catch (error) {
      setisShimmerDeviceList(false);
      console.log("handleSubmit", error);
    }
  };

  useEffect(() => {
    if (sourceDevicePopup) {
      sourceDeviceAPI();
    }
  }, [sourceDevicePopup]);

  const sourceDeviceAPI = async () => {
    const params = {
      device_type: "Source",
      plant_id: param.id,
    };
    try {
      const resp = await getDeviceAPI(params);
      if (resp.status == 200 || resp.status == 201) {
        setSourceDevice(resp.data.payload);
        setSourceSelectAll(
          selectedSourceDevices.length == resp.data.payload.device.length
        );
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  const handleDeviceCheckboxChange = (device) => {
    if (selectedDevices.includes(device)) {
      setSelectedDevices((prevSelectedDevices) =>
        prevSelectedDevices.filter((id) => id !== device)
      );
    } else {
      setSelectedDevices((prevSelectedDevices) => [
        ...prevSelectedDevices,
        device,
      ]);
    }

    // Check/uncheck the "All" checkbox based on the selected devices
    setSelectAll(
      selectedDevices.length === (consumptionDevice?.device?.length || 0)
    );
  };

  const handleAuxDeviceCheckboxChange = (device) => {
    if (selectedAuxDevices.includes(device)) {
      setSelectedAuxDevices((prevSelectedDevices) =>
        prevSelectedDevices.filter((id) => id !== device)
      );
    } else {
      setSelectedAuxDevices((prevSelectedDevices) => [
        ...prevSelectedDevices,
        device,
      ]);
    }

    // Check/uncheck the "All" checkbox based on the selected devices
    setSelectAll(
      selectedAuxDevices.length === (consumptionDevice?.device?.length || 0)
    );
  };

  useEffect(() => {
    if (consumptionDevice?.device?.length || selectedAuxDevices.length) {
      if (consumptionDevice?.device?.length !== selectedAuxDevices.length) {
        setSelectAllAux(false);
      } else {
        setSelectAllAux(true);
      }
    }
  }, [selectedAuxDevices]);

  useEffect(() => {
    if (consumptionDevice?.device?.length || selectedDevices.length) {
      if (consumptionDevice?.device?.length !== selectedDevices.length) {
        setSelectAll(false);
      } else {
        setSelectAll(true);
      }
    }
  }, [selectedDevices]);

  useEffect(() => {
    if (consumptionDevice?.device?.length || co2ProdSelectDevice.length) {
      if (consumptionDevice?.device?.length !== co2ProdSelectDevice.length) {
        setAllSelectCo2(false);
      } else {
        setAllSelectCo2(true);
      }
    }
  }, [co2ProdSelectDevice]);

  useEffect(() => {
    if (consumptionDevice?.device?.length || co2AuxSelectDevice.length) {
      if (consumptionDevice?.device?.length !== co2AuxSelectDevice.length) {
        setAllSelectCo2Aux(false);
      } else {
        setAllSelectCo2Aux(true);
      }
    }
  }, [co2AuxSelectDevice]);

  useEffect(() => {
    if (sourceDevice?.device?.length || selectedSourceDevices.length) {
      if (sourceDevice?.device?.length !== selectedSourceDevices.length) {
        setSourceSelectAll(false);
      } else {
        setSourceSelectAll(true);
      }
    }
  }, [selectedSourceDevices]);

  // CO2 hande Select device
  const handleCo2DeviceCheckboxChange = (device) => {
    if (co2ProdSelectDevice.includes(device)) {
      setCo2PrdSelectDevice(co2ProdSelectDevice.filter((id) => id !== device));
    } else {
      setCo2PrdSelectDevice([...co2ProdSelectDevice, device]);
    }
  };

  const handleSelectAllCo2CheckboxChange = () => {
    if (allSelectCo2) {
      setCo2PrdSelectDevice([]);
    } else {
      const allDeviceUids =
        consumptionDevice?.device?.map((e) => e.device) || [];
      setCo2PrdSelectDevice(allDeviceUids);
    }
    setAllSelectCo2(!allSelectCo2);
  };

  // Co2 AUX
  // CO2 hande Select device
  const handleCo2AuxDeviceCheckboxChange = (device) => {
    if (co2AuxSelectDevice.includes(device)) {
      setCo2AuxSelectDevice(co2AuxSelectDevice.filter((id) => id !== device));
    } else {
      setCo2AuxSelectDevice([...co2AuxSelectDevice, device]);
    }
  };

  const handleSelectAllCo2AuxCheckboxChange = () => {
    if (allSelectCo2Aux) {
      setCo2AuxSelectDevice([]);
    } else {
      const allDeviceUids =
        consumptionDevice?.device?.map((e) => e.device) || [];
      setCo2AuxSelectDevice(allDeviceUids);
    }
    setAllSelectCo2Aux(!allSelectCo2Aux);
  };

  const handleSelectAllCheckboxChange = () => {
    if (selectAll) {
      setSelectedDevices([]);
    } else {
      const allDeviceUids =
        consumptionDevice?.device?.map((e) => e.device) || [];
      setSelectedDevices(allDeviceUids);
    }
    setSelectAll((prevSelectAll) => !prevSelectAll); // Toggle the "All" checkbox state
  };

  const handleSelectAllAuxCheckboxChange = () => {
    if (selectAllAux) {
      setSelectedAuxDevices([]);
    } else {
      const allDeviceUids =
        consumptionDevice?.device?.map((e) => e.device) || [];
      setSelectedAuxDevices(allDeviceUids);
    }
    setSelectAllAux((prevSelectAll) => !prevSelectAll); // Toggle the "All" checkbox state
  };

  const handleSourceDeviceCheckboxChange = (device) => {
    if (selectedSourceDevices.includes(device)) {
      setSelectedSourceDevices(
        selectedSourceDevices.filter((id) => id !== device)
      );
    } else {
      setSelectedSourceDevices([...selectedSourceDevices, device]);
    }
  };

  const handleSourceSelectAllCheckboxChange = () => {
    if (selectSourceAll) {
      setSelectedSourceDevices([]);
    } else {
      const allDeviceUids = sourceDevice?.device?.map((e) => e.device) || [];
      setSelectedSourceDevices(allDeviceUids);
    }
    setSourceSelectAll(!selectSourceAll);
  };

  const handleSubmit = async () => {
    const params = {
      plant: param.id,
      company: param.companyId,
      en_production: selectedDevices,
      en_greenenergy: selectedSourceDevices,
      en_auxilary: selectedAuxDevices,
      co_production: co2ProdSelectDevice,
      co_auxilary: co2AuxSelectDevice,
      graph: selectedTagWithDevice.map((row) => row.tagId),
    };

    try {
      const resp = await advanceCreateConfiguration(params);
      if (resp.status == 200 || resp.status == 201) {
        setAuxPopup(false);
        setAnalyticsDevicePopup(false);
        setSourceDevicePopup(false);
        setCo2AuxDevicePopup(false);
        setCo2ProdDevicePopup(false);
        // handleOpenCo2AuxModal();
        console.log("successfully create configuration");
      }
    } catch (error) {
      notifyError(error.response.data.message);
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    handleGetConfiguration();
  }, []);

  const handleGetConfiguration = async () => {
    const params = {
      plant: param.id,
      company: param.companyId,
    };
    try {
      const resp = await advanceGetConfiguration(params);
      if (resp.status == 200 || (resp.status == 201 && resp.data.payload)) {
        console.log("getconfiguration successfully");
        setSelectedDevices(
          resp.data.payload.en_production.length
            ? resp.data.payload.en_production.map((e) => e.id)
            : []
        );
        setSelectedAuxDevices(
          resp.data.payload.en_auxilary.length
            ? resp.data.payload.en_auxilary.map((e) => e.id)
            : []
        );
        setSelectedSourceDevices(
          resp.data.payload.en_greenenergy.length
            ? resp.data.payload.en_greenenergy.map((e) => e.id)
            : []
        );

        setSelectedIds(
          resp.data.payload.graph
            ? resp.data.payload.graph.map((e) => e.id)
            : []
        );

        const sortDeviceId = resp.data.payload.graph.length
          ? resp.data.payload.graph.sort((a, b) => a.device - b.device)
          : [];
        setSelectedtagWithDevice(
          sortDeviceId.length
            ? sortDeviceId.map((row) => {
                return {
                  deviceId: row.device,
                  tagId: row.id,
                  tagUnit: row.tag_unit,
                  tagName: row.tag_name,
                };
              })
            : []
        );

        setDeviceId(sortDeviceId.length ? sortDeviceId[0].device : null);

        setCo2PrdSelectDevice(
          resp.data.payload.co_production.length
            ? resp.data.payload.co_production.map((e) => e.id)
            : []
        );
        setCo2AuxSelectDevice(
          resp.data.payload.co_auxilary.length
            ? resp.data.payload.co_auxilary.map((e) => e.id)
            : []
        );
        setConfigureId(resp.data.payload.id);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  const handleOnchangeEnergyData = (e) => {
    setEnergyCostData({ ...energyCostData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (startDateFormat && endDateFormat) {
      handleGetRunningHours();
      handlegetEnergyCostSummary();
    }
  }, [startDateFormat, endDateFormat, selectCostUnit]);

  const handleGetRunningHours = async () => {
    const params = {
      plant: param.id,
      start_date: startDateFormat,
      end_date: endDateFormat,
    };
    try {
      const resp = await advancegetAdvanceAnalyticsRunningHour(params);
      if (
        resp.status == 200 ||
        (resp.status == 201 && resp.data.payload.length)
      ) {
        setRunningHour(resp.data.payload[0].running_hour);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  const handlegetEnergyCostSummary = async () => {
    const params = {
      plant_id: param.id,
      start_date: startDateFormat,
      end_date: endDateFormat,
      company_id: param.companyId,
    };

    if (selectCostUnit != "") {
      params["unit"] = selectCostUnit;
    }

    try {
      const resp = await getEnergyCostSummary(params);
      if (
        resp.status == 200 ||
        (resp.status == 201 && resp.data.payload.length)
      ) {
        setEnergySummaryData(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };
  useEffect(() => {
    if (startDateFormat && endDateFormat) {
      handleCO2Summary();
    }
  }, [startDateFormat, endDateFormat, selectUnit]);

  const handleCO2Summary = async () => {
    const params = {
      plant_id: param.id,
      start_date: startDateFormat,
      end_date: endDateFormat,
      company_id: param.companyId,
      unit: selectUnit,
    };
    try {
      const resp = await getCo2FootPrintSummary(params);
      if (
        resp.status == 200 ||
        (resp.status == 201 && resp.data.payload.length)
      ) {
        setCo2SummaryData(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  const handleBackClick = () => {
    navigate(
      `/company/${param.companyId}/plant/plantprocessdashboard/${param.id}`
    );
    dispatch(setAnalyticsData({ configurationSummary: false }));
  };
  const generateUniqueTimeSlotID = () => {
    const currentTime = new Date();
    return currentTime.getTime();
  };

  const addInputField = () => {
    // Increment numInputs and add a new object to costTimeObject
    setNumInputs(numInputs + 1);

    setCostTimeObject((prevCostTimeObject) => [
      ...prevCostTimeObject,
      {
        startTime: null,
        endTime: null,
        unitPrice: 0,
      },
    ]);
  };

  const renderDevices = (devices) => {
    return devices.map((device, index) => (
      <React.Fragment key={index}>
        {index > 0 && ",    "}
        {device}
      </React.Fragment>
    ));
  };

  const getTagListByDevice = async (i) => {
    setisShimmerDeviceTagList(true);
    const params = {
      device_id: deviceId,
      plant_id: param.id,
      company_id: param.companyId,
      position: 1,
    };

    try {
      const resp = await getDeviceTagList(params);
      if (resp.data.success == true) {
        setisShimmerDeviceTagList(false);
        setDeviceTagList(resp.data.payload);
      }
    } catch (error) {
      setisShimmerDeviceTagList(false);
      notify(error.response.data.message);
      setDeviceTagList([]);
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    if (devicepopup) {
      getTagListByDevice();
    }
  }, [devicepopup, deviceId]);

  const handleStartDate = (newValue) => {
    const durationInDays = endDateReFormat
      ? endDateReFormat.diff(newValue, "days")
        ? endDateReFormat.diff(newValue, "days") > 0
          ? endDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      // Valid date range (within one year)
      dispatch(setAnalyticsData({ summaryStartDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReFormat && newValue.isBefore(endDateReFormat)) {
      // Start date is before end date
      dispatch(setAnalyticsData({ summaryStartDate: newValue }));
    } else if (!endDateReFormat) {
      // End date is not set yet
      dispatch(setAnalyticsData({ summaryStartDate: newValue }));
    } else if (endDateReFormat && newValue.isSame(endDateReFormat, "day")) {
      // End date is not set yet
      dispatch(setAnalyticsData({ summaryStartDate: newValue }));
    } else {
      // Start date is after end date
      dispatch(setAnalyticsData({ summaryStartDate: null }));
      notifyError("End date must be greater than start date");
    }

    // dispatch(setAnalyticsData({ summaryStartDate: newValue }));
  };

  const handleEndDate = (newValue) => {
    const durationInDays = startDateReFormat
      ? startDateReFormat.diff(newValue, "days")
        ? startDateReFormat.diff(newValue, "days") > 0
          ? startDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setAnalyticsData({ summaryEndDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReFormat && newValue.isAfter(startDateReFormat)) {
      dispatch(setAnalyticsData({ summaryEndDate: newValue }));
    } else if (!startDateReFormat) {
      dispatch(setAnalyticsData({ summaryEndDate: newValue }));
    } else if (startDateReFormat && newValue.isSame(startDateReFormat, "day")) {
      // End date is not set yet
      dispatch(setAnalyticsData({ summaryEndDate: newValue }));
    } else {
      // Start date is after end date
      dispatch(setAnalyticsData({ summaryEndDate: null }));
      notifyError(" startDate must less than endDate");
    }

    // dispatch(setAnalyticsData({ summaryEndDate: newValue }));
  };

  // Your code for filterArrayClockAM

  const results = [];

  const mainClockArray = costTimeObject
    ? costTimeObject
        .map((row) => {
          const timeFormat = "HH:mm";
          const startTimeFormat = row.startTime
            ? dayjs(row.startTime, "hh:mmA").format(timeFormat)
            : "";
          const endTimeFormat = row.endTime
            ? dayjs(row.endTime, "hh:mmA").format(timeFormat)
            : "";

          console.log("endTimeFormat", endTimeFormat);
          return {
            start_time: startTimeFormat,
            end_time: endTimeFormat,
            unit_price: Number(row.unitPrice),
          };
        })
        .map((item) => {
          const startParts = item.start_time.split(":");
          const endParts = item.end_time.split(":");

          const startHour = startParts[0];
          const endHour = endParts[0] == "00" ? "23.9999999" : endParts[0];
          console.log("startHour", startHour, endHour);
          let Meridiem;

          if (startHour && endHour) {
            if (
              "00" <= startHour &&
              startHour <= "12" &&
              "00" <= endHour &&
              endHour <= "12" &&
              startHour < endHour
            ) {
              Meridiem = "AM";
            } else if (
              "12" <= startHour &&
              startHour <= "23" &&
              (("12" <= endHour && endHour <= "23") || endHour == "23.9999999")
            ) {
              Meridiem = "PM";
            } else {
              if (startHour < endHour) {
                results.push({
                  startHour: startHour,
                  endHour: "12",
                  Meridiem: "AM",
                });
                results.push({
                  startHour: "12",
                  endHour: endHour,
                  Meridiem: "PM",
                });
              } else {
                results.push({
                  startHour: "00",
                  endHour: endHour,
                  Meridiem: "AM",
                });
                results.push({
                  startHour: startHour,
                  endHour: "23.999999",
                  Meridiem: "PM",
                });
              }
            }
          }
          return { startHour, endHour, Meridiem };
        })
        .concat(results)
        .filter((obj) => obj.Meridiem != undefined)
        .filter((obj) => obj.start_time !== "" && obj.end_time !== "")
    : [];

  const filterArrayClockAM = mainClockArray
    ? mainClockArray.filter((obj) => obj.Meridiem === "AM")
    : [];

  // Your code for filterArrayClockPM
  const filterArrayClockPM = mainClockArray
    ? mainClockArray.filter((obj) => obj.Meridiem === "PM")
    : [];

  const handleOverlayClick = () => {
    setProductionPopup(true);
    setAuxPopup(false);
    setAnalyticsDevicePopup(false);
    setSourceDevicePopup(false);
    setDevicePopup(false);
    setCo2AuxDevicePopup(false);
    setCo2ProdDevicePopup(false);
    setEditPopup(false);
  };

  const TagListShimmer = () => {
    return (
      <>
        <TableRow>
          <TableCell
            className="ShimmerTable-configration-table-TagList"
            colSpan={2}
          >
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
          <TableCell className="ShimmerTable-configration-table-TagList">
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
          <TableCell className="ShimmerTable-configration-table-TagList">
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
        </TableRow>
        <TableRow>
          {/* <TableCell className="ShimmerTable-configration-table-TagList"> 
          <Checkbox
                  style={{ color: "#1C407B" }}
                
                />
          </TableCell> */}
          <TableCell
            className="ShimmerTable-configration-table-TagList"
            colSpan={2}
          >
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
          <TableCell className="ShimmerTable-configration-table-TagList">
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
          <TableCell className="ShimmerTable-configration-table-TagList">
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
        </TableRow>
        <TableRow>
          {/* <TableCell className="ShimmerTable-configration-table-TagList"> 
          <Checkbox
                  style={{ color: "#1C407B" }}
                
                />
          </TableCell> */}
          <TableCell
            className="ShimmerTable-configration-table-TagList"
            colSpan={2}
          >
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
          <TableCell className="ShimmerTable-configration-table-TagList">
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
          <TableCell className="ShimmerTable-configration-table-TagList">
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
        </TableRow>
        <TableRow>
          {/* <TableCell className="ShimmerTable-configration-table-TagList"> 
          <Checkbox
                  style={{ color: "#1C407B" }}
                
                />
          </TableCell> */}
          <TableCell
            className="ShimmerTable-configration-table-TagList"
            colSpan={2}
          >
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
          <TableCell className="ShimmerTable-configration-table-TagList">
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
          <TableCell className="ShimmerTable-configration-table-TagList">
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
        </TableRow>
        <TableRow>
          {/* <TableCell className="ShimmerTable-configration-table-TagList"> 
          <Checkbox
                  style={{ color: "#1C407B" }}
                
                />
          </TableCell> */}
          <TableCell
            className="ShimmerTable-configration-table-TagList"
            colSpan={2}
          >
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
          <TableCell className="ShimmerTable-configration-table-TagList">
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
          <TableCell className="ShimmerTable-configration-table-TagList">
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
        </TableRow>
        <TableRow>
          {/* <TableCell className="ShimmerTable-configration-table-TagList"> 
          <Checkbox
                  style={{ color: "#1C407B" }}
                
                />
          </TableCell> */}
          <TableCell
            className="ShimmerTable-configration-table-TagList"
            colSpan={2}
          >
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
          <TableCell className="ShimmerTable-configration-table-TagList">
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
          <TableCell className="ShimmerTable-configration-table-TagList">
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
        </TableRow>
        <TableRow>
          {/* <TableCell className="ShimmerTable-configration-table-TagList"> 
          <Checkbox
                  style={{ color: "#1C407B" }}
                
                />
          </TableCell> */}
          <TableCell
            className="ShimmerTable-configration-table-TagList"
            colSpan={2}
          >
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
          <TableCell className="ShimmerTable-configration-table-TagList">
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
          <TableCell className="ShimmerTable-configration-table-TagList">
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
        </TableRow>
        <TableRow>
          {/* <TableCell className="ShimmerTable-configration-table-TagList"> 
          <Checkbox
                  style={{ color: "#1C407B" }}
                
                />
          </TableCell> */}
          <TableCell
            className="ShimmerTable-configration-table-TagList"
            colSpan={2}
          >
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
          <TableCell className="ShimmerTable-configration-table-TagList">
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
          <TableCell className="ShimmerTable-configration-table-TagList">
            {" "}
            <ShimmerTable row={1} col={1} />
          </TableCell>
        </TableRow>
      </>
    );
  };

  const TagListNotFound = () => {
    return (
      <>
        <TableRow>
          <TableCell colSpan={4} className="Tags-DataNotFound">
            Tags are not configured
          </TableCell>
        </TableRow>
      </>
    );
  };

  const DeviceShimmer = () => {
    return (
      <>
        <Box className={`Device-caption   mb-2`}>
          <ShimmerTitle line={2} gap={10} variant="primary" />
        </Box>
        <Box className={`Device-caption   mb-2`}>
          <ShimmerTitle line={2} gap={10} variant="primary" />
        </Box>
        <Box className={`Device-caption   mb-2`}>
          <ShimmerTitle line={2} gap={10} variant="primary" />
        </Box>
        <Box className={`Device-caption   mb-2`}>
          <ShimmerTitle line={2} gap={10} variant="primary" />
        </Box>
      </>
    );
  };

  const DeviceNotFound = () => {
    return (
      <>
        <Box className={`Device-caption   mb-2`}>Device Not configured</Box>
      </>
    );
  };
  useEffect(() => {
    // Load html2canvas dynamically if needed
    if (typeof window.html2canvas === "undefined") {
      const script = document.createElement("script");
      script.src = "https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.min.js";
      script.onload = () => {
        html2canvasRef.current = window.html2canvas;
        setIsHtml2CanvasLoaded(true);
        console.log("html2canvas loaded");
      };
      document.head.appendChild(script);
    } else {
      html2canvasRef.current = window.html2canvas;
      setIsHtml2CanvasLoaded(true);
    }
  }, []);

  const captureScreenshot = async () => {
    setSendEmailPopup(true);
  };

  const handleCapture = async () => {
    if (!isHtml2CanvasLoaded) {
      console.error("html2canvas not loaded yet.");
      return;
    }

    try {
      // Capture the visible part of the screen
      const canvas = await html2canvasRef.current(document.body, {
        scrollX: 0,
        scrollY: -window.scrollY,
        backgroundColor: null,
        useCORS: true,
        scale: 1,
        ignoreElements: (element) => element.classList.contains("exclude-me"),
      });

      const imgData = canvas.toDataURL("image/png");
      setImageURL(imgData);

      const params = { image_base64: imgData };
      const resp = await summaryReport(params);

      if (resp.status === 200 || resp.status === 201) {
        setSendEmailPopup(false);
        notify(resp.data.message);
      } else {
        setSendEmailPopup(false);
        notify(resp.data.message);
      }
    } catch (error) {
      setSendEmailPopup(false);
      notifyError(error.response.data.message);
      console.error("Error capturing the screenshot:", error);
    }
  };
  return (
    <>
      <div className="page-wraper">
        <div className="page-header d-flex justify-content-between analytics-header overview-table-header">
          <div onClick={handleBackClick} className="page-back-btn">
            <ArrowBackIcon />
            <span>Advanced Analytics - Summary</span>
          </div>
          <div className="header-btn-group d-flex end-date-datepicker">
            {(analyticsAccess?.is_viewer && analyticsAccess?.is_editor) ||
            (!analyticsAccess?.is_viewer && analyticsAccess?.is_editor) ||
            (analyticsAccess?.is_viewer && !analyticsAccess?.is_editor) ? (
              <>
                <Box
                  className={`box-datepicker ${
                    productionPopup ||
                    configurationSummary ||
                    auxPopup ||
                    analyticsDevicePopup ||
                    editPopup ||
                    devicepopup ||
                    co2AuxDevicePopup ||
                    co2ProdDevicePopup ||
                    sourceDevicePopup
                      ? "popup-open"
                      : ""
                  }`}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="custom-datepicker"
                      label="Start Date"
                      value={startDateReFormat}
                      inputFormat="DD/MM/YYYY"
                      onChange={(newValue) => handleStartDate(newValue)}
                      disableFuture
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ background: "#ffff" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                <Box
                  className={`${
                    productionPopup ||
                    configurationSummary ||
                    auxPopup ||
                    analyticsDevicePopup ||
                    editPopup ||
                    devicepopup ||
                    co2AuxDevicePopup ||
                    co2ProdDevicePopup ||
                    sourceDevicePopup
                      ? "popup-open"
                      : ""
                  }`}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="custom-datepicker"
                      label="End Date"
                      value={endDateReFormat}
                      inputFormat="DD/MM/YYYY"
                      onChange={(newValue) => handleEndDate(newValue)}
                      disableFuture
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ background: "#ffff" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </>
            ) : (
              <>
                <Box
                  className={`box-datepicker ${
                    productionPopup ||
                    configurationSummary ||
                    auxPopup ||
                    analyticsDevicePopup ||
                    editPopup ||
                    devicepopup ||
                    co2AuxDevicePopup ||
                    co2ProdDevicePopup ||
                    sourceDevicePopup
                      ? "popup-open"
                      : ""
                  }`}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="custom-datepicker"
                      label="Start Date"
                      // value={startDateReFormat}
                      inputFormat="DD/MM/YYYY"
                      onChange={handleTostMsg}
                      disableFuture
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ background: "#ffff" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                <Box
                  className={`${
                    productionPopup ||
                    configurationSummary ||
                    auxPopup ||
                    analyticsDevicePopup ||
                    editPopup ||
                    devicepopup ||
                    co2AuxDevicePopup ||
                    co2ProdDevicePopup ||
                    sourceDevicePopup
                      ? "popup-open"
                      : ""
                  }`}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="custom-datepicker"
                      label="End Date"
                      // value={endDateReFormat}
                      inputFormat="DD/MM/YYYY"
                      onChange={handleTostMsg}
                      disableFuture
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ background: "#ffff" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </>
            )}

            <img
              src={images.excel}
              alt=""
              height="56px"
              className="excel-img-data exclude-me"
              onClick={captureScreenshot}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div>
          <div className="overview-card-header">
            <div className="page-header sticky-page">
              <div
                onClick={handleBackClick}
                // className={`page-back-btn ${productionPopup ||
                //   configurationSummary ||
                //   auxPopup ||
                //   analyticsDevicePopup ||
                //   editPopup ||
                //   devicepopup ||
                //   co2AuxDevicePopup ||
                //   co2ProdDevicePopup ||
                //   sourceDevicePopup
                //   ? "popup-open"
                //   : ""
                //   }`}
                className="page-back-btn"
              >
                <ArrowBackIcon />
                <span>Advanced Analytics - Summary</span>
              </div>
              <div className="d-flex gap-2 justify-content-end pt-2">
                <div>
                  <select
                    className="compact-btn"
                    value={selectUnit}
                    onChange={(e) =>
                      dispatch(
                        setProcessDashboarDateSet({
                          selectUnit: e.target.value,
                        })
                      )
                    }
                  >
                    <option value="k">Kilo</option>
                    <option value="m">Mega</option>
                    <option value="g">Giga</option>
                  </select>
                </div>
                <div>
                  {(analyticsAccess?.is_viewer && analyticsAccess?.is_editor) ||
                  (!analyticsAccess?.is_viewer && analyticsAccess?.is_editor) ||
                  (analyticsAccess?.is_viewer &&
                    !analyticsAccess?.is_editor) ? (
                    <button
                      className="configration-btn confi-btn exclude-me"
                      onClick={() =>
                        dispatch(
                          setAnalyticsData({
                            configurationSummary: !configurationSummary,
                          })
                        )
                      }
                    >
                      <img
                        src={images.configration}
                        style={{
                          height: "18px",
                          width: "18px",
                          marginRight: "8px",
                        }}
                        alt=""
                      />
                      Configuration
                    </button>
                  ) : (
                    <button
                      className="configration-btn"
                      onClick={handleTostMsg}
                    >
                      <img
                        src={images.configration}
                        style={{
                          height: "18px",
                          width: "18px",
                          marginRight: "8px",
                        }}
                        alt=""
                      />
                      Configuration
                    </button>
                  )}
                </div>
              </div>
              <div>
                <div className="header-btn-group d-flex end-date-datepicker">
                  {(analyticsAccess?.is_viewer && analyticsAccess?.is_editor) ||
                  (!analyticsAccess?.is_viewer && analyticsAccess?.is_editor) ||
                  (analyticsAccess?.is_viewer &&
                    !analyticsAccess?.is_editor) ? (
                    <>
                      <Box
                        className={`box-datepicker ${
                          productionPopup ||
                          configurationSummary ||
                          auxPopup ||
                          analyticsDevicePopup ||
                          editPopup ||
                          devicepopup ||
                          co2AuxDevicePopup ||
                          co2ProdDevicePopup ||
                          sourceDevicePopup
                            ? "popup-open"
                            : ""
                        }`}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="custom-datepicker"
                            label="Start Date"
                            value={startDateReFormat}
                            inputFormat="DD/MM/YYYY"
                            onChange={(newValue) => handleStartDate(newValue)}
                            disableFuture
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ background: "#ffff" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        className={`${
                          productionPopup ||
                          configurationSummary ||
                          auxPopup ||
                          analyticsDevicePopup ||
                          editPopup ||
                          devicepopup ||
                          co2AuxDevicePopup ||
                          co2ProdDevicePopup ||
                          sourceDevicePopup
                            ? "popup-open"
                            : ""
                        }`}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="custom-datepicker"
                            label="End Date"
                            value={endDateReFormat}
                            inputFormat="DD/MM/YYYY"
                            onChange={(newValue) => handleEndDate(newValue)}
                            disableFuture
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ background: "#ffff" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        className={`box-datepicker ${
                          productionPopup ||
                          configurationSummary ||
                          auxPopup ||
                          analyticsDevicePopup ||
                          editPopup ||
                          devicepopup ||
                          co2AuxDevicePopup ||
                          co2ProdDevicePopup ||
                          sourceDevicePopup
                            ? "popup-open"
                            : ""
                        }`}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="custom-datepicker"
                            label="Start Date"
                            // value={startDateReFormat}
                            inputFormat="DD/MM/YYYY"
                            onChange={handleTostMsg}
                            disableFuture
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ background: "#ffff" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        className={`${
                          productionPopup ||
                          configurationSummary ||
                          auxPopup ||
                          analyticsDevicePopup ||
                          editPopup ||
                          devicepopup ||
                          co2AuxDevicePopup ||
                          co2ProdDevicePopup ||
                          sourceDevicePopup
                            ? "popup-open"
                            : ""
                        }`}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="custom-datepicker"
                            label="End Date"
                            // value={endDateReFormat}
                            inputFormat="DD/MM/YYYY"
                            onChange={handleTostMsg}
                            disableFuture
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ background: "#ffff" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </>
                  )}
                  <div>
                    <img
                      src={images.excel}
                      alt=""
                      height="36px"
                      width="36px"
                      className="excel-img-data"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Grid container spacing={3} className="confi-analytics">
          <Grid item xs={12} md={7}>
            <div
              className="d-flex mt-4 production p-card"
              style={{ width: "100%" }}
            >
              {/* <h4 style={{ width: "100%", marginLeft: "-43px" }}>Production</h4> */}
            </div>

            <div className="d-flex dispatch-data" style={{ gap: "20px" }}>
              <div>
                <h4 className="production-text">Availability</h4>
                <div className="production-data-table production-data">
                  <Grid container>
                    <Grid
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      sx={{ P: 0 }}
                      className="single-data-item"
                    >
                      <Table className="no-border-table transparent-table common-table">
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <b className="pData">Running Hours</b>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div className="position-relative">
                                <input
                                  id="outlined-basic"
                                  style={{ pointerEvents: "none" }}
                                  // className="form-input-production-pairs-hours"
                                  className={`form-input-production-pairs-hours ${
                                    productionPopup ||
                                    configurationSummary ||
                                    auxPopup ||
                                    analyticsDevicePopup ||
                                    editPopup ||
                                    devicepopup ||
                                    co2AuxDevicePopup ||
                                    co2ProdDevicePopup ||
                                    sourceDevicePopup
                                      ? "popup-open"
                                      : ""
                                  }`}
                                  value={
                                    !isNaN(runningHour) &&
                                    runningHour !== null &&
                                    runningHour !== undefined
                                      ? runningHour.toFixed(2)
                                      : ""
                                  }
                                  // className=" form-input-production-pairs-hours"
                                />
                                <span className="input-label">hr</span>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div>
                <h4 className="production-text">Production</h4>
                <div className="production-data-table1 production-data position-relative">
                  <div className="overlay1" onClick={handleOverlayClick}></div>
                  <Grid container>
                    <Grid
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      sx={{ P: 0 }}
                      className="single-data-item"
                    >
                      <Table className="no-border-table transparent-table common-table">
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <b className="pData">Planned</b>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div>
                                <input
                                  id="production-planned"
                                  className={`production-input-color ${
                                    productionPopup ||
                                    configurationSummary ||
                                    auxPopup ||
                                    analyticsDevicePopup ||
                                    editPopup ||
                                    devicepopup ||
                                    co2AuxDevicePopup ||
                                    co2ProdDevicePopup ||
                                    sourceDevicePopup
                                      ? "popup-open"
                                      : ""
                                  }`}
                                  value={4000}
                                />
                                <span className="input-label-kt">kt</span>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                    <Grid
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      sx={{ P: 0 }}
                      className="single-data-item"
                    >
                      <Table className="no-border-table transparent-table common-table">
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <b className="pData">Actual</b>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div>
                                <input
                                  id="outlined-basic"
                                  className={`production-input-color ${
                                    productionPopup ||
                                    configurationSummary ||
                                    auxPopup ||
                                    analyticsDevicePopup ||
                                    editPopup ||
                                    devicepopup ||
                                    co2AuxDevicePopup ||
                                    co2ProdDevicePopup ||
                                    sourceDevicePopup
                                      ? "popup-open"
                                      : ""
                                  }`}
                                  value={3842}
                                />
                                <span className="input-label-kt">kt</span>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </div>
              </div>
              {productionPopup && (
                <>
                  <Box className="production-box position-absolute">
                    <div onClick={handleCloseClick}>
                      <CloseIcon className="closeIcon" />
                    </div>
                    <h6 className="production-title">
                      Please add Advanced Production Planning and Management to
                      Enable this Feature
                    </h6>
                  </Box>
                </>
              )}
            </div>

            <div className="d-flex justify-content-between batchNo p-card pt-4 align-items-center">
              <h4>Energy Cost</h4>
              <select
                className="compact-btn mb-2"
                value={selectCostUnit}
                onChange={(e) =>
                  dispatch(
                    setProcessDashboarDateSet({
                      selectCostUnit: e.target.value,
                    })
                  )
                }
              >
                <option selected value="">
                  Select Unit
                </option>
                <option value="k">Thousand</option>
                <option value="m">Million</option>
                <option value="b">Billion</option>
              </select>
              <select
                className="compact-dropdown mb-2"
                value={selectCostUnit}
                onChange={(e) =>
                  dispatch(
                    setProcessDashboarDateSet({
                      selectCostUnit: e.target.value,
                    })
                  )
                }
              >
                <option selected value="">
                  Select Unit
                </option>
                <option value="k">Thousand</option>
                <option value="m">Million</option>
                <option value="b">Billion</option>
              </select>
            </div>
            <div className="production-data energy-cost-data">
              <Grid container spacing={2} className="grid-container">
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={3}
                  sx={{ P: 0 }}
                  className="single-data-item"
                >
                  <Table className="no-border-table transparent-table common-table">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <b className="pData">Total</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <div className="position-relative">
                            <input
                              id="outlined-basic"
                              style={{ pointerEvents: "none" }}
                              className={`form-input-production ${
                                productionPopup ||
                                configurationSummary ||
                                auxPopup ||
                                analyticsDevicePopup ||
                                editPopup ||
                                devicepopup ||
                                co2AuxDevicePopup ||
                                co2ProdDevicePopup ||
                                sourceDevicePopup
                                  ? "popup-open"
                                  : ""
                              }`}
                              autoComplete="off"
                              value={
                                energySummaryData?.total_sum?.toFixed(2) || ""
                              }
                            />
                            <span className="input-label-rupees">
                              {user_country_currency_symbol}
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                {configurationSummary ? (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={3}
                    sx={{ P: 0 }}
                    className="single-data-item prod-table"
                  >
                    <div
                      onClick={() => handleOpenModal()}
                      style={{ cursor: "pointer" }}
                      className={`${
                        analyticsDevicePopup ||
                        auxPopup ||
                        editPopup ||
                        devicepopup ||
                        co2AuxDevicePopup ||
                        co2ProdDevicePopup ||
                        sourceDevicePopup
                          ? "popup-open"
                          : ""
                      }`}
                    >
                      <Table className="no-border-table transparent-table common-table prod-table">
                        <TableBody>
                          <TableRow>
                            <TableCell
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <b
                                className="pData pData0"
                                style={{ marginLeft: "-38px" }}
                              >
                                Production
                              </b>

                              {(analyticsAccess?.is_viewer &&
                                analyticsAccess?.is_editor) ||
                              (!analyticsAccess?.is_viewer &&
                                analyticsAccess?.is_editor) ||
                              (analyticsAccess?.is_viewer &&
                                !analyticsAccess?.is_editor) ? (
                                <img
                                  src={images.editbtn}
                                  onClick={() =>
                                    handleEditClick("EN_PRODUCTION")
                                  }
                                  // style={{ cursor: "pointer" }}
                                  alt=""
                                  className="edit-icon"
                                />
                              ) : (
                                <img
                                  src={images.editbtn}
                                  onClick={handleTostMsg}
                                  alt=""
                                  className="edit-icon"
                                />
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div
                                className="position-relative"
                                style={{ pointerEvents: "none" }}
                              >
                                <input
                                  id="outlined-basic"
                                  style={{ pointerEvents: "none" }}
                                  className={`form-input-production production-tablecell ${
                                    analyticsDevicePopup ||
                                    auxPopup ||
                                    editPopup ||
                                    devicepopup ||
                                    co2AuxDevicePopup ||
                                    co2ProdDevicePopup ||
                                    sourceDevicePopup
                                      ? "popup-open"
                                      : ""
                                  }`}
                                  autoComplete="off"
                                  name="production"
                                  value={
                                    energySummaryData?.en_production?.toFixed(
                                      2
                                    ) || ""
                                  }
                                />
                                <span className="input-label-rupees">
                                  {user_country_currency_symbol}
                                </span>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={3}
                    sx={{ P: 0 }}
                    className="single-data-item"
                  >
                    <Table className="no-border-table transparent-table common-table">
                      <TableBody>
                        <TableRow>
                          <TableCell
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <b
                              className="pData pData0"
                              style={{ marginLeft: "-38px" }}
                            >
                              Production
                            </b>
                            {(analyticsAccess?.is_viewer &&
                              analyticsAccess?.is_editor) ||
                            (!analyticsAccess?.is_viewer &&
                              analyticsAccess?.is_editor) ||
                            (analyticsAccess?.is_viewer &&
                              !analyticsAccess?.is_editor) ? (
                              <img
                                src={images.editbtn}
                                onClick={() => handleEditClick("EN_PRODUCTION")}
                                style={{ cursor: "pointer" }}
                                alt=""
                                className="edit-icon"
                              />
                            ) : (
                              <img
                                src={images.editbtn}
                                onClick={handleTostMsg}
                                style={{ cursor: "pointer" }}
                                alt=""
                                className="edit-icon"
                              />
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <div className="position-relative">
                              <input
                                id="outlined-basic"
                                style={{ pointerEvents: "none" }}
                                className={`form-input-production  ${
                                  analyticsDevicePopup ||
                                  auxPopup ||
                                  editPopup ||
                                  devicepopup ||
                                  co2AuxDevicePopup ||
                                  co2ProdDevicePopup ||
                                  sourceDevicePopup
                                    ? "popup-open"
                                    : ""
                                }`}
                                autoComplete="off"
                                name="production"
                                value={
                                  energySummaryData?.en_production?.toFixed(
                                    2
                                  ) || ""
                                }
                              />
                              <span className="input-label-rupees">
                                {user_country_currency_symbol}
                              </span>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                )}

                {configurationSummary ? (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={3}
                    sx={{ P: 0 }}
                    className="single-data-item prod-table"
                  >
                    <div
                      onClick={() => handleAuxOpenModal()}
                      style={{ cursor: "pointer" }}
                      className={`${
                        auxPopup ||
                        analyticsDevicePopup ||
                        editPopup ||
                        devicepopup ||
                        co2AuxDevicePopup ||
                        co2ProdDevicePopup ||
                        sourceDevicePopup
                          ? "popup-open"
                          : ""
                      }`}
                    >
                      <Table className="no-border-table transparent-table common-table prod-table">
                        <TableBody>
                          <TableRow>
                            <TableCell
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <b
                                className="pData pData0"
                                style={{ marginLeft: "-38px" }}
                              >
                                Auxillary
                              </b>
                              {(analyticsAccess?.is_viewer &&
                                analyticsAccess?.is_editor) ||
                              (!analyticsAccess?.is_viewer &&
                                analyticsAccess?.is_editor) ||
                              (analyticsAccess?.is_viewer &&
                                !analyticsAccess?.is_editor) ? (
                                <img
                                  src={images.editbtn}
                                  onClick={() => handleEditClick("EN_AUXILARY")}
                                  style={{ cursor: "pointer" }}
                                  alt=""
                                  className="edit-icon"
                                />
                              ) : (
                                <img
                                  src={images.editbtn}
                                  onClick={handleTostMsg}
                                  style={{ cursor: "pointer" }}
                                  alt=""
                                  className="edit-icon"
                                />
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div
                                className="position-relative"
                                style={{ pointerEvents: "none" }}
                              >
                                <input
                                  id="outlined-basic"
                                  style={{ pointerEvents: "none" }}
                                  className={`form-input-production production-tablecell ${
                                    analyticsDevicePopup ||
                                    auxPopup ||
                                    editPopup ||
                                    devicepopup ||
                                    co2AuxDevicePopup ||
                                    co2ProdDevicePopup ||
                                    sourceDevicePopup
                                      ? "popup-open"
                                      : ""
                                  }`}
                                  autoComplete="off"
                                  name="Auxillary"
                                  value={
                                    energySummaryData?.en_auxilary?.toFixed(
                                      2
                                    ) || ""
                                  }
                                />
                                <span className="input-label-rupees">
                                  {user_country_currency_symbol}
                                </span>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={3}
                    sx={{ P: 0 }}
                    className="single-data-item"
                  >
                    <Table className="no-border-table transparent-table common-table">
                      <TableBody>
                        <TableRow>
                          <TableCell
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <b
                              className="pData pData0"
                              style={{ marginLeft: "-38px" }}
                            >
                              Auxillary
                            </b>
                            {(analyticsAccess?.is_viewer &&
                              analyticsAccess?.is_editor) ||
                            (!analyticsAccess?.is_viewer &&
                              analyticsAccess?.is_editor) ||
                            (analyticsAccess?.is_viewer &&
                              !analyticsAccess?.is_editor) ? (
                              <img
                                src={images.editbtn}
                                onClick={() => handleEditClick("EN_AUXILARY")}
                                style={{ cursor: "pointer" }}
                                alt=""
                                className="edit-icon"
                              />
                            ) : (
                              <img
                                src={images.editbtn}
                                onClick={handleTostMsg}
                                style={{ cursor: "pointer" }}
                                alt=""
                                className="edit-icon"
                              />
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <div className="position-relative">
                              <input
                                id="outlined-basic"
                                style={{ pointerEvents: "none" }}
                                className={`form-input-production ${
                                  analyticsDevicePopup ||
                                  auxPopup ||
                                  editPopup ||
                                  devicepopup ||
                                  co2AuxDevicePopup ||
                                  co2ProdDevicePopup ||
                                  sourceDevicePopup
                                    ? "popup-open"
                                    : ""
                                }`}
                                autoComplete="off"
                                name="Auxillary"
                                value={
                                  energySummaryData?.en_auxilary?.toFixed(2) ||
                                  ""
                                }
                              />
                              <span className="input-label-rupees">
                                {user_country_currency_symbol}
                              </span>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                )}

                {configurationSummary ? (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={3}
                    sx={{ P: 0 }}
                    className="single-data-item prod-table"
                  >
                    <div
                      onClick={() => handleEnergyOpenModal()}
                      style={{ cursor: "pointer" }}
                      className={`${
                        analyticsDevicePopup ||
                        auxPopup ||
                        editPopup ||
                        devicepopup ||
                        co2AuxDevicePopup ||
                        co2ProdDevicePopup ||
                        sourceDevicePopup
                          ? "popup-open"
                          : ""
                      }`}
                    >
                      <Table className="no-border-table transparent-table common-table">
                        <TableBody>
                          <TableRow>
                            <TableCell
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <b
                                className="pData pData0"
                                style={{ marginLeft: "-38px" }}
                              >
                                Green Energy
                              </b>
                              {(analyticsAccess?.is_viewer &&
                                analyticsAccess?.is_editor) ||
                              (!analyticsAccess?.is_viewer &&
                                analyticsAccess?.is_editor) ||
                              (analyticsAccess?.is_viewer &&
                                !analyticsAccess?.is_editor) ? (
                                <img
                                  src={images.editbtn}
                                  onClick={() =>
                                    handleEditClick("EN_GREENENERGY")
                                  }
                                  alt=""
                                  style={{ cursor: "pointer" }}
                                  className="edit-icon"
                                />
                              ) : (
                                <img
                                  src={images.editbtn}
                                  onClick={handleTostMsg}
                                  style={{ cursor: "pointer" }}
                                  alt=""
                                  className="edit-icon"
                                />
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div
                                className="position-relative"
                                style={{ pointerEvents: "none" }}
                              >
                                <input
                                  id="outlined-basic"
                                  style={{ pointerEvents: "none" }}
                                  className={`form-input-production production-tablecell ${
                                    analyticsDevicePopup ||
                                    auxPopup ||
                                    editPopup ||
                                    devicepopup ||
                                    co2AuxDevicePopup ||
                                    co2ProdDevicePopup ||
                                    sourceDevicePopup
                                      ? "popup-open"
                                      : ""
                                  }`}
                                  autoComplete="off"
                                  name="GreenEnergy"
                                  value={
                                    energySummaryData?.en_greenenergy?.toFixed(
                                      2
                                    ) || ""
                                  }
                                />
                                <span className="input-label-rupees">
                                  {user_country_currency_symbol}
                                </span>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={3}
                    sx={{ P: 0 }}
                    className="single-data-item"
                  >
                    <Table className="no-border-table transparent-table common-table">
                      <TableBody>
                        <TableRow>
                          <TableCell
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <b
                              className="pData pData0"
                              style={{ marginLeft: "-38px" }}
                            >
                              Green Energy
                            </b>
                            {(analyticsAccess?.is_viewer &&
                              analyticsAccess?.is_editor) ||
                            (!analyticsAccess?.is_viewer &&
                              analyticsAccess?.is_editor) ||
                            (analyticsAccess?.is_viewer &&
                              !analyticsAccess?.is_editor) ? (
                              <img
                                src={images.editbtn}
                                onClick={() =>
                                  handleEditClick("EN_GREENENERGY")
                                }
                                style={{ cursor: "pointer" }}
                                alt=""
                                className="edit-icon"
                              />
                            ) : (
                              <img
                                src={images.editbtn}
                                onClick={handleTostMsg}
                                style={{ cursor: "pointer" }}
                                alt=""
                                className="edit-icon"
                              />
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <div className="position-relative">
                              <input
                                id="outlined-basic"
                                className={`form-input-production ${
                                  productionPopup ||
                                  configurationSummary ||
                                  auxPopup ||
                                  analyticsDevicePopup ||
                                  editPopup ||
                                  devicepopup ||
                                  co2AuxDevicePopup ||
                                  co2ProdDevicePopup ||
                                  sourceDevicePopup
                                    ? "popup-open"
                                    : ""
                                }`}
                                autoComplete="off"
                                style={{ pointerEvents: "none" }}
                                name="GreenEnergy"
                                value={
                                  energySummaryData?.en_greenenergy?.toFixed(
                                    2
                                  ) || ""
                                }
                              />
                              <span className="input-label-rupees">
                                {user_country_currency_symbol}
                              </span>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                )}
              </Grid>
            </div>

            <div className="d-flex justify-content-between batchNo p-card mt-4">
              <h4>CO2 Footprint</h4>
            </div>
            <div className="production-data" style={{ padding: "16px" }}>
              <Grid container className="grid-container">
                <Grid
                  lg={4}
                  md={6}
                  sm={6}
                  xs={6}
                  sx={{ P: 0 }}
                  className="single-data-item"
                >
                  <Table className="no-border-table transparent-table common-table">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <b className="pData">Total</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <div className="position-relative">
                            <input
                              id="outlined-basic"
                              className={`form-input-production-footprint ${
                                productionPopup ||
                                configurationSummary ||
                                auxPopup ||
                                analyticsDevicePopup ||
                                editPopup ||
                                devicepopup ||
                                co2AuxDevicePopup ||
                                co2ProdDevicePopup ||
                                sourceDevicePopup
                                  ? "popup-open"
                                  : ""
                              }`}
                              style={{ pointerEvents: "none" }}
                              value={co2SummaryData?.total?.toFixed(2) || ""}
                            />
                            <span className="input-label-ktt">kt</span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                {configurationSummary ? (
                  <Grid
                    lg={4}
                    md={6}
                    sm={6}
                    xs={6}
                    sx={{ P: 0 }}
                    className="single-data-item prod-table"
                  >
                    <div
                      onClick={() => handleOpenCo2Modal()}
                      style={{ cursor: "pointer" }}
                      className={`${
                        analyticsDevicePopup ||
                        auxPopup ||
                        editPopup ||
                        devicepopup ||
                        co2AuxDevicePopup ||
                        co2ProdDevicePopup ||
                        sourceDevicePopup
                          ? "popup-open"
                          : ""
                      }`}
                    >
                      <Table className="no-border-table transparent-table common-table">
                        <TableBody>
                          <TableRow>
                            <TableCell
                              style={{
                                display: "flex",
                              }}
                            >
                              <b className="pData">Production</b>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div
                                className="position-relative"
                                style={{ pointerEvents: "none" }}
                              >
                                <input
                                  id="outlined-basic"
                                  className={`form-input-production-footprint production-tablecell ${
                                    analyticsDevicePopup ||
                                    auxPopup ||
                                    editPopup ||
                                    devicepopup ||
                                    co2AuxDevicePopup ||
                                    co2ProdDevicePopup ||
                                    sourceDevicePopup
                                      ? "popup-open"
                                      : ""
                                  }`}
                                  style={{ pointerEvents: "none" }}
                                  value={
                                    co2SummaryData?.co_production?.toFixed(2) ||
                                    ""
                                  }
                                />
                                <span className="input-label-ktt">kt</span>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </Grid>
                ) : (
                  <Grid
                    lg={4}
                    md={6}
                    sm={6}
                    xs={6}
                    sx={{ P: 0 }}
                    className="single-data-item"
                  >
                    <Table className="no-border-table transparent-table common-table">
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <b className="pData">Production</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <div className="position-relative">
                              <input
                                id="outlined-basic"
                                className={`form-input-production-footprint ${
                                  analyticsDevicePopup ||
                                  auxPopup ||
                                  editPopup ||
                                  devicepopup ||
                                  co2AuxDevicePopup ||
                                  co2ProdDevicePopup ||
                                  sourceDevicePopup
                                    ? "popup-open"
                                    : ""
                                }`}
                                style={{ pointerEvents: "none" }}
                                value={
                                  co2SummaryData?.co_production?.toFixed(2) ||
                                  ""
                                }
                              />
                              <span className="input-label-ktt">kt</span>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                )}

                {configurationSummary ? (
                  <Grid
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ P: 0 }}
                    className="single-data-item prod-table"
                  >
                    <div
                      onClick={() => handleOpenCo2AuxModal()}
                      style={{ cursor: "pointer" }}
                      className={`${
                        analyticsDevicePopup ||
                        auxPopup ||
                        editPopup ||
                        devicepopup ||
                        co2AuxDevicePopup ||
                        co2ProdDevicePopup ||
                        sourceDevicePopup
                          ? "popup-open"
                          : ""
                      }`}
                    >
                      <Table className="no-border-table transparent-table common-table">
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <b className="pData">Auxillary</b>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div
                                className="position-relative"
                                style={{ pointerEvents: "none" }}
                              >
                                <input
                                  id="outlined-basic"
                                  className={`form-input-production-footprint production-tablecell ${
                                    analyticsDevicePopup ||
                                    auxPopup ||
                                    editPopup ||
                                    devicepopup ||
                                    co2AuxDevicePopup ||
                                    co2ProdDevicePopup ||
                                    sourceDevicePopup
                                      ? "popup-open"
                                      : ""
                                  }`}
                                  style={{ pointerEvents: "none" }}
                                  value={
                                    co2SummaryData?.co_auxilary?.toFixed(2) ||
                                    ""
                                  }
                                />
                                <span className="input-label-ktt">kt</span>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </Grid>
                ) : (
                  <Grid
                    lg={4}
                    md={6}
                    sm={6}
                    xs={6}
                    sx={{ P: 0 }}
                    className="single-data-item"
                  >
                    <Table className="no-border-table transparent-table common-table">
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <b className="pData">Auxillary</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <div className="position-relative">
                              <input
                                id="outlined-basic"
                                className={`form-input-production-footprint ${
                                  analyticsDevicePopup ||
                                  auxPopup ||
                                  editPopup ||
                                  devicepopup ||
                                  co2AuxDevicePopup ||
                                  co2ProdDevicePopup ||
                                  sourceDevicePopup
                                    ? "popup-open"
                                    : ""
                                }`}
                                style={{ pointerEvents: "none" }}
                                value={
                                  co2SummaryData?.co_auxilary?.toFixed(2) || ""
                                }
                              />
                              <span className="input-label-ktt">kt</span>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
          {configurationSummary ? (
            <Grid item xs={12} md={5} lg={5} sm={12}>
              <div className="d-flex justify-content-between batchNo p-card position-relative pt-4">
                <h4>Energy Consumption</h4>
              </div>
              <Card
                // className="chart-card chart-card-opacity"
                onClick={handleChartClick}
                style={{ cursor: "pointer" }}
                className={`chart-card chart-card-opacity ${
                  analyticsDevicePopup ||
                  auxPopup ||
                  editPopup ||
                  devicepopup ||
                  co2AuxDevicePopup ||
                  co2ProdDevicePopup ||
                  sourceDevicePopup
                    ? "popup-open"
                    : ""
                }`}
              ></Card>
            </Grid>
          ) : (
            <Grid item xs={12} md={5}>
              <div className="d-flex justify-content-between batchNo p-card position-relative pt-4">
                <h4>Energy Consumption</h4>
              </div>
              <Card className="chart-card">
                <div className="d-flex justify-content-end p-3">
                  {configureTagUnit.accepted ? (
                    <div className="consumption-slot"></div>
                  ) : null}
                  <div
                    style={{
                      marginLeft: "5px",
                      marginTop: "-5px",
                      color: "#1C407B",
                    }}
                  >
                    {configureTagUnit.accepted}
                  </div>
                  {configureTagUnit.rejected ? (
                    <div
                      className="Loss-slot"
                      style={{ marginLeft: "15px" }}
                    ></div>
                  ) : null}
                  <div
                    style={{
                      marginLeft: "5px",
                      marginTop: "-5px",
                      color: "#ccd7eb",
                    }}
                  >
                    {configureTagUnit.rejected}
                  </div>
                </div>

                <AnalyticsGraph
                  getsummaryGraphData={getsummaryGraphData}
                  configureTagUnit={configureTagUnit}
                  shimmer={isShimmer}
                />

                <div className="p-5 text-center">
                  {getDeviceSummaryData?.devices &&
                    (getDeviceSummaryData.devices.length > 6 ? (
                      <Marquee pauseOnHover={true}>
                        {renderDevices(getDeviceSummaryData.devices)}
                      </Marquee>
                    ) : (
                      renderDevices(getDeviceSummaryData.devices)
                    ))}
                </div>
              </Card>
            </Grid>
          )}
        </Grid>

        {configurationSummary ? (
          <div className="pt-4 d-flex gap-3">
            <Link className="production-btn" style={{ pointerEvents: "none" }}>
              PRODUCTION
            </Link>
            <Link className="production-btn" style={{ pointerEvents: "none" }}>
              ENERGY COST
            </Link>
            <Link className="production-btn" style={{ pointerEvents: "none" }}>
              CO2
            </Link>
          </div>
        ) : (
          <div className="pt-4 d-flex gap-3 sticky-page exclude-me">
            <Link
              to={`/analyticsproduction/company/${param.companyId}/plant/${param.id}`}
              className={`production-btn pro-btn${
                productionPopup ||
                configurationSummary ||
                auxPopup ||
                analyticsDevicePopup ||
                editPopup ||
                devicepopup ||
                co2AuxDevicePopup ||
                co2ProdDevicePopup ||
                sourceDevicePopup
                  ? "popup-open"
                  : ""
              }`}
            >
              PRODUCTION
            </Link>
            <Link
              to={`/energycost/company/${param.companyId}/plant/${param.id}`}
              className={`production-btn energy-btn${
                productionPopup ||
                configurationSummary ||
                auxPopup ||
                analyticsDevicePopup ||
                editPopup ||
                devicepopup ||
                co2AuxDevicePopup ||
                co2ProdDevicePopup ||
                sourceDevicePopup
                  ? "popup-open"
                  : ""
              }`}
              // style={{pointerEvents:"none"}}
            >
              ENERGY COST
            </Link>
            <Link
              to={`/CO2/company/${param.companyId}/plant/${param.id}`}
              className={`production-btn co2-btn${
                productionPopup ||
                configurationSummary ||
                auxPopup ||
                analyticsDevicePopup ||
                editPopup ||
                devicepopup ||
                co2AuxDevicePopup ||
                co2ProdDevicePopup ||
                sourceDevicePopup
                  ? "popup-open"
                  : ""
              }`}
              style={{ pointerEvents: "none" }}
            >
              CO2
            </Link>
          </div>
        )}

        {sendEmailPopup && (
          <Card className="deleteConfirmation-Modal exclude-me">
            <Typography
              variant="h6"
              gutterBottom
              className="deleteConfirmation-title"
            >
              Are You Sure<br></br> You Want To Capture screenshot?
            </Typography>
            <Box className="deleteConfirmation-btnWrap">
              <button
                className="deleteConfirmation-Yesbtn"
                onClick={handleCapture}
              >
                Yes
              </button>
              <button
                className="deleteConfirmation-Nobtn"
                onClick={() => setSendEmailPopup(false)}
                // onClick={handleConfirmationModal}
              >
                No
              </button>
            </Box>
          </Card>
        )}
        {deletePopup && (
          <Card className="deleteConfirmation-Modal">
            <Typography
              variant="h6"
              gutterBottom
              className="deleteConfirmation-title"
            >
              Are You Sure<br></br> You Want To Delete?
            </Typography>
            <Box className="deleteConfirmation-btnWrap">
              <button
                className="deleteConfirmation-Yesbtn"
                onClick={handleDeleteConfirmation}
              >
                Yes
              </button>
              <button
                className="deleteConfirmation-Nobtn"
                onClick={() => setDeletePopup(false)}
                // onClick={handleConfirmationModal}
              >
                No
              </button>
            </Box>
          </Card>
        )}

        {editPopup && (
          <>
            <div className="editPopupContainer">
              <div className="editData">
                <Card className="editCard">
                  <>
                    <div className="editdata-title">
                      <div>
                        <h6 style={{ fontWeight: "800" }}>Slot</h6>
                      </div>
                      <div>
                        <h6 style={{ fontWeight: "800" }}>Unit Price</h6>
                      </div>
                      <div>
                        <button className="addbtn" onClick={addInputField}>
                          Add
                        </button>
                      </div>
                    </div>
                    <div className="slotBox-wrap">
                      <div className="slotBox">
                        <div className="slotBox-data">
                          {costTimeObject.map((_, index) => (
                            <div
                              className="mb-2"
                              style={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "baseline",
                              }}
                            >
                              <div
                                onClick={() => handleDeleteClick(index)}
                                style={{ cursor: "pointer" }}
                              >
                                <CancelIcon className="cancel-icon" />
                              </div>
                              <div className="editpopup-input">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["TimePicker"]}>
                                    <TimePicker
                                      label="Start Time"
                                      className="customTimePicker"
                                      value={costTimeObject[index].startTime}
                                      onChange={(newValue) =>
                                        handleTimeChange(
                                          index,
                                          "startTime",
                                          newValue
                                        )
                                      }
                                      viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          className="customTimePicker"
                                        />
                                      )}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </div>
                              <div className="editpopup-input">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["TimePicker"]}>
                                    <TimePicker
                                      label="End Time"
                                      className="customTimePicker"
                                      value={costTimeObject[index].endTime}
                                      onChange={(newValue) =>
                                        handleTimeChange(
                                          index,
                                          "endTime",
                                          newValue
                                        )
                                      }
                                      viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          className="customTimePicker"
                                        />
                                      )}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </div>
                              <input
                                key={index}
                                type="number"
                                className="slotbox-input"
                                value={costTimeObject[index].unitPrice}
                                onChange={(newValue) =>
                                  handleTimeChange(
                                    index,
                                    "unitPrice",
                                    newValue.target.value
                                  )
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      <div>
                        <div className="slotbox-timer">
                          <div>
                            <h6>AM</h6>
                            <Clock clockArray={filterArrayClockAM} />
                          </div>
                          <div>
                            <h6>PM</h6>
                            <Clock clockArray={filterArrayClockPM} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="analytics-btns">
                      <div className="analytics-btn-wrap">
                        <button
                          className="analytics-cancle-btn"
                          onClick={handleEditCancleClick}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="analytics-btn-wrap">
                        <button
                          className="analytics-submit-btn"
                          onClick={handleSubmitClick}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </>
                </Card>
              </div>
            </div>
          </>
        )}

        {analyticsDevicePopup && (
          <>
            <div className="overlay123">
              <div className="deviceModal-popup ana-popup">
                <div style={{ padding: "25px 20px" }}>
                  <h6>
                    <b>Devices</b>
                  </h6>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box className="devicemodal-box">
                      {(configAccess?.is_viewer && configAccess?.is_editor) ||
                      (!configAccess?.is_viewer && configAccess?.is_editor) ||
                      (configAccess?.is_viewer && !configAccess?.is_editor) ? (
                        <FormGroup>
                          <FormControlLabel
                            style={{ display: "flex", justifyContent: "end" }}
                            control={
                              <Checkbox
                                style={{
                                  color: "#1C407B",
                                  padding: "0px",
                                  marginRight: "3px",
                                }}
                                checked={selectAll}
                                onChange={handleSelectAllCheckboxChange}
                              />
                            }
                            label="All"
                          />
                        </FormGroup>
                      ) : (
                        <FormGroup>
                          <FormControlLabel
                            style={{ display: "flex", justifyContent: "end" }}
                            control={
                              <Checkbox
                                style={{
                                  color: "#1C407B",
                                  padding: "0px",
                                  marginRight: "3px",
                                }}
                                checked={selectAll}
                                onChange={handleTostMsg}
                              />
                            }
                            label="All"
                          />
                        </FormGroup>
                      )}
                      <div style={{ maxHeight: "420px", overflow: "auto" }}>
                        {consumptionDevice?.device?.map((e) => {
                          return (
                            <Box className="Device-caption d-flex mt-2">
                              <>
                                <div>
                                  {(configAccess?.is_viewer &&
                                    configAccess?.is_editor) ||
                                  (!configAccess?.is_viewer &&
                                    configAccess?.is_editor) ||
                                  (configAccess?.is_viewer &&
                                    !configAccess?.is_editor) ? (
                                    <Checkbox
                                      checked={
                                        isDeviceDisabledInanalyticsPopup(
                                          e.device
                                        )
                                          ? true
                                          : selectedDevices.includes(e.device)
                                      }
                                      onChange={() =>
                                        handleDeviceCheckboxChange(e.device)
                                      }
                                      disabled={isDeviceDisabledInanalyticsPopup(
                                        e.device
                                      )}
                                      className={
                                        isDeviceDisabledInanalyticsPopup(
                                          e.device
                                        )
                                          ? "grayed-checkbox"
                                          : "enabled-checkbox"
                                      }
                                    />
                                  ) : (
                                    <Checkbox
                                      checked={
                                        isDeviceDisabledInanalyticsPopup(
                                          e.device
                                        )
                                          ? true
                                          : selectedDevices.includes(e.device)
                                      }
                                      onChange={handleTostMsg}
                                      disabled={isDeviceDisabledInanalyticsPopup(
                                        e.device
                                      )}
                                      className={
                                        isDeviceDisabledInanalyticsPopup(
                                          e.device
                                        )
                                          ? "grayed-checkbox"
                                          : "enabled-checkbox"
                                      }
                                    />
                                  )}
                                </div>
                                <div>
                                  <h6>{e.device__device_name}</h6>
                                  <p>UID - {e.device__machine__uid}</p>
                                  <p>{e.device__device_description}</p>
                                </div>
                              </>
                            </Box>
                          );
                        })}
                      </div>
                    </Box>
                  </Grid>
                </div>
                <div className="text-center mb-4 analytics-btns">
                  <button className="canclebtn" onClick={handleCancleClick1}>
                    Cancel
                  </button>
                  {(configAccess?.is_viewer && configAccess?.is_editor) ||
                  (!configAccess?.is_viewer && configAccess?.is_editor) ||
                  (configAccess?.is_viewer && !configAccess?.is_editor) ? (
                    <button className="submitbtn" onClick={handleSubmit}>
                      Submit
                    </button>
                  ) : (
                    <button className="submitbtn" onClick={handleTostMsg}>
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {auxPopup && (
          <>
            <div className="overlay123">
              <div className="deviceModal-popup ana-popup">
                <div style={{ padding: "25px 20px" }}>
                  <h6>
                    <b>Devices</b>
                  </h6>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box className="devicemodal-box">
                      {(configAccess?.is_viewer && configAccess?.is_editor) ||
                      (!configAccess?.is_viewer && configAccess?.is_editor) ||
                      (configAccess?.is_viewer && !configAccess?.is_editor) ? (
                        <FormGroup>
                          <FormControlLabel
                            style={{ display: "flex", justifyContent: "end" }}
                            control={
                              <Checkbox
                                style={{
                                  color: "#1C407B",
                                  padding: "0px",
                                  marginRight: "3px",
                                }}
                                checked={selectAllAux}
                                onChange={handleSelectAllAuxCheckboxChange}
                              />
                            }
                            label="All"
                          />
                        </FormGroup>
                      ) : (
                        <FormGroup>
                          <FormControlLabel
                            style={{ display: "flex", justifyContent: "end" }}
                            control={
                              <Checkbox
                                style={{
                                  color: "#1C407B",
                                  padding: "0px",
                                  marginRight: "3px",
                                }}
                                checked={selectAllAux}
                                onChange={handleTostMsg}
                              />
                            }
                            label="All"
                          />
                        </FormGroup>
                      )}
                      <div style={{ maxHeight: "420px", overflow: "auto" }}>
                        {consumptionDevice?.device?.map((e) => {
                          return (
                            <Box className="Device-caption d-flex mt-2">
                              <>
                                <div>
                                  {(configAccess?.is_viewer &&
                                    configAccess?.is_editor) ||
                                  (!configAccess?.is_viewer &&
                                    configAccess?.is_editor) ||
                                  (configAccess?.is_viewer &&
                                    !configAccess?.is_editor) ? (
                                    <Checkbox
                                      checked={
                                        isDeviceDisabledInAuxPopup(e.device)
                                          ? true
                                          : selectedAuxDevices.includes(
                                              e.device
                                            )
                                      }
                                      onChange={() =>
                                        handleAuxDeviceCheckboxChange(e.device)
                                      }
                                      className={
                                        isDeviceDisabledInAuxPopup(e.device)
                                          ? "grayed-checkbox"
                                          : "enabled-checkbox"
                                      }
                                      disabled={isDeviceDisabledInAuxPopup(
                                        e.device
                                      )}
                                    />
                                  ) : (
                                    <Checkbox
                                      checked={
                                        isDeviceDisabledInAuxPopup(e.device)
                                          ? true
                                          : selectedAuxDevices.includes(
                                              e.device
                                            )
                                      }
                                      onChange={handleTostMsg}
                                      className={
                                        isDeviceDisabledInAuxPopup(e.device)
                                          ? "grayed-checkbox"
                                          : "enabled-checkbox"
                                      }
                                      disabled={isDeviceDisabledInAuxPopup(
                                        e.device
                                      )}
                                    />
                                  )}
                                </div>
                                <div>
                                  <h6>{e.device__device_name}</h6>
                                  <p>UID - {e.device__machine__uid}</p>
                                  <p>{e.device__device_description}</p>
                                </div>
                              </>
                            </Box>
                          );
                        })}
                      </div>
                    </Box>
                  </Grid>
                </div>
                <div className="text-center analytics-btns mb-4">
                  <button className="canclebtn" onClick={handleCancleClick1}>
                    Cancel
                  </button>
                  {(configAccess?.is_viewer && configAccess?.is_editor) ||
                  (!configAccess?.is_viewer && configAccess?.is_editor) ||
                  (configAccess?.is_viewer && !configAccess?.is_editor) ? (
                    <button className="submitbtn" onClick={handleSubmit}>
                      Submit
                    </button>
                  ) : (
                    <button className="submitbtn" onClick={handleTostMsg}>
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {sourceDevicePopup && (
          <>
            <div className="overlay123">
              <div className="deviceModal-popup ana-popup">
                <div style={{ padding: "25px 20px" }}>
                  <h6>
                    <b>Devices</b>
                  </h6>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box className="devicemodal-box">
                      {(configAccess?.is_viewer && configAccess?.is_editor) ||
                      (!configAccess?.is_viewer && configAccess?.is_editor) ||
                      (configAccess?.is_viewer && !configAccess?.is_editor) ? (
                        <FormGroup>
                          <FormControlLabel
                            style={{ display: "flex", justifyContent: "end" }}
                            control={
                              <Checkbox
                                style={{
                                  color: "#1C407B",
                                  padding: "0px",
                                  marginRight: "3px",
                                }}
                                checked={selectSourceAll}
                                onChange={handleSourceSelectAllCheckboxChange}
                              />
                            }
                            label="All"
                          />
                        </FormGroup>
                      ) : (
                        <FormGroup>
                          <FormControlLabel
                            style={{ display: "flex", justifyContent: "end" }}
                            control={
                              <Checkbox
                                style={{
                                  color: "#1C407B",
                                  padding: "0px",
                                  marginRight: "3px",
                                }}
                                checked={selectSourceAll}
                                onChange={handleTostMsg}
                              />
                            }
                            label="All"
                          />
                        </FormGroup>
                      )}
                      <div style={{ maxHeight: "420px", overflow: "auto" }}>
                        {sourceDevice?.device?.map((e) => {
                          return (
                            <Box className="Device-caption d-flex mt-2">
                              <>
                                <div>
                                  {(configAccess?.is_viewer &&
                                    configAccess?.is_editor) ||
                                  (!configAccess?.is_viewer &&
                                    configAccess?.is_editor) ||
                                  (configAccess?.is_viewer &&
                                    !configAccess?.is_editor) ? (
                                    <Checkbox
                                      style={{
                                        color: "#1C407B",
                                        marginTop: "8px",
                                        marginLeft: "-10px",
                                      }}
                                      checked={selectedSourceDevices.includes(
                                        e.device
                                      )}
                                      onChange={() =>
                                        handleSourceDeviceCheckboxChange(
                                          e.device
                                        )
                                      }
                                    />
                                  ) : (
                                    <Checkbox
                                      style={{
                                        color: "#1C407B",
                                        marginTop: "8px",
                                        marginLeft: "-10px",
                                      }}
                                      checked={selectedSourceDevices.includes(
                                        e.device
                                      )}
                                      onChange={handleTostMsg}
                                    />
                                  )}
                                </div>
                                <div>
                                  <h6>{e.device__device_name}</h6>
                                  <p>UID - {e.device__machine__uid}</p>
                                  <p>{e.device__device_description}</p>
                                </div>
                              </>
                            </Box>
                          );
                        })}
                      </div>
                    </Box>
                  </Grid>
                </div>
                <div className="text-center  mb-4 analytics-btns">
                  <button className="canclebtn" onClick={handleCancleClick1}>
                    Cancel
                  </button>
                  {(configAccess?.is_viewer && configAccess?.is_editor) ||
                  (!configAccess?.is_viewer && configAccess?.is_editor) ||
                  (configAccess?.is_viewer && !configAccess?.is_editor) ? (
                    <button className="submitbtn" onClick={handleSubmit}>
                      Submit
                    </button>
                  ) : (
                    <button className="submitbtn" onClick={handleTostMsg}>
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {devicepopup && (
          <>
            <div className="overlay123">
              <div className="deviceModal-popup">
                <div className="d-flex devices-text">
                  <h6 style={{ marginTop: "7px" }}>Devices</h6>
                  {/* <h6 style={{ marginTop: "7px" }} className="device-tag-list">
                  Tag List
                </h6>
                <div className="selectionText">
                  {handleleftdevice()} Left For Selection
                </div> */}
                  <div className="selectionText">
                    {handleleftdevice()} Left For Selection
                  </div>
                </div>
                <Grid container spacing={2} className="pt-2">
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <Box className="devicemodal-box">
                      <div className="configration-details">
                        <div style={{ maxHeight: "425px", overflow: "auto" }}>
                          {!isShimmerDeviceList
                            ? consumptionDevice
                              ? consumptionDevice.device?.map((e) => {
                                  return (
                                    <Box
                                      key={e.device}
                                      className={`Device-caption d-flex ${
                                        deviceId === e.device
                                          ? "Device-caption1"
                                          : ""
                                      }  mb-2`}
                                      onClick={(row) =>
                                        handelSelectDevice(row, e.device)
                                      }
                                    >
                                      <>
                                        <div>
                                          <h6>{e.device__device_name}</h6>
                                          <p>UID - {e.device__machine__uid}</p>
                                          <p>{e.device__device_description}</p>
                                        </div>
                                      </>
                                    </Box>
                                  );
                                })
                              : DeviceNotFound()
                            : DeviceShimmer()}
                        </div>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={7} lg={7}>
                    <h6 className="device-tag-list">Tag List</h6>
                    <Box className="devicemodal-box1">
                      <div style={{ maxHeight: "454px", overflow: "auto" }}>
                        <Table className="device-table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{ background: "white" }}
                              ></TableCell>
                              <TableCell style={{ background: "white" }}>
                                Tag Name
                              </TableCell>
                              <TableCell style={{ background: "white" }}>
                                Tag Comment
                              </TableCell>
                              <TableCell
                                style={{ background: "white", width: "0%" }}
                              >
                                Unit
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                className="search-table-row"
                                style={{ background: "#F1F1FF" }}
                              ></TableCell>
                              <TableCell
                                className="search-table-row"
                                style={{ background: "#F1F1FF" }}
                              >
                                <SearchIcon className="search-icon" />
                                <input
                                  type="search"
                                  placeholder="Tag Name"
                                  style={{ fontSize: "13px" }}
                                  name="Date"
                                  value={searchTagName}
                                  onChange={(e) =>
                                    setSearchTagName(e.target.value)
                                  }
                                />
                              </TableCell>

                              <TableCell
                                className="search-table-row"
                                style={{ background: "#F1F1FF" }}
                              >
                                <SearchIcon className="search-icon" />
                                <input
                                  type="search"
                                  placeholder="Tag Comment"
                                  style={{ fontSize: "13px" }}
                                  name="Date"
                                  value={searchTagComment}
                                  onChange={(e) =>
                                    setSearchTagComment(e.target.value)
                                  }
                                />
                              </TableCell>

                              <TableCell
                                className="search-table-row"
                                style={{ background: "#F1F1FF" }}
                              >
                                <SearchIcon className="search-icon" />
                                <input
                                  type="search"
                                  placeholder="Unit"
                                  style={{ fontSize: "13px" }}
                                  name="Date"
                                  value={searchTagUnit}
                                  onChange={(e) =>
                                    setSearchTagUnit(e.target.value)
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {!isShimmerDeviceTagList
                              ? deviceTagList
                              ? deviceTagList
                                    .filter(
                                      (e) =>
                                        e.tag_name &&
                                        e.tag_name
                                          .toLowerCase()
                                          .includes(searchTagName.toLowerCase())
                                    )
                                    .filter(
                                      (e) =>
                                        e.tag_comment &&
                                        e.tag_comment
                                          .toLowerCase()
                                          .includes(
                                            searchTagComment.toLowerCase()
                                          )
                                    )
                                    .filter(
                                      (e) =>
                                        e.tag_unit &&
                                        e.tag_unit
                                          .toLowerCase()
                                          .includes(searchTagUnit.toLowerCase())
                                    )
                                    .map((e) => {
                                      const isSelectable = [
                                        "kVAH",
                                        "kVARH",
                                        "kWH",
                                      ].includes(e.tag_unit);

                                      const tagSelected =
                                        selectedTagWithDevice.find(
                                          (f) =>
                                            f.tagId == e.id &&
                                            f.deviceId == deviceId
                                        )
                                          ? true
                                          : false;
                                      const canSelectUnit =
                                        selectTagUnitDisable.length < 2;
                                      const selectedTagunit =
                                        selectTagUnitDisable.includes(
                                          e.tag_unit
                                        );

                                      return (
                                        <TableRow key={e.tag_name}>
                                          <TableCell style={{ padding: "0px" }}>
                                            {(configAccess?.is_viewer &&
                                              configAccess?.is_editor) ||
                                            (!configAccess?.is_viewer &&
                                              configAccess?.is_editor) ||
                                            (configAccess?.is_viewer &&
                                              !configAccess?.is_editor) ? (
                                              <Checkbox
                                                className={
                                                  isSelectable
                                                    ? selectedTagunit
                                                      ? "enabled-checkbox"
                                                      : ""
                                                    : "disabled-checkbox"
                                                }
                                                disabled={
                                                  !isSelectable ||
                                                  (!canSelectUnit &&
                                                    !selectedTagunit)
                                                }
                                                // checked={isUnitSelected}
                                                checked={tagSelected}
                                                onChange={() =>
                                                  handleSelectTagWithDevice(
                                                    deviceId,
                                                    e.tag_unit,
                                                    e.id,
                                                    e.tag_name
                                                  )
                                                }
                                              >
                                                {e.tag_name}
                                              </Checkbox>
                                            ) : (
                                              <Checkbox
                                                className={
                                                  isSelectable
                                                    ? selectedTagunit
                                                      ? "enabled-checkbox"
                                                      : ""
                                                    : "disabled-checkbox"
                                                }
                                                disabled={
                                                  !isSelectable ||
                                                  (!canSelectUnit &&
                                                    !selectedTagunit)
                                                }
                                                // checked={isUnitSelected}
                                                checked={tagSelected}
                                                onChange={handleTostMsg}
                                              >
                                                {e.tag_name}
                                              </Checkbox>
                                            )}
                                          </TableCell>
                                          <TableCell>{e.tag_name}</TableCell>
                                          <TableCell>{e.tag_comment}</TableCell>
                                          <TableCell>{e.tag_unit}</TableCell>
                                        </TableRow>
                                      );
                                    })
                                : TagListNotFound()
                              : TagListShimmer()}
                          </TableBody>
                        </Table>
                      </div>
                    </Box>
                  </Grid>
                </Grid>

                <div className="pt-3 d-flex selectTagDesc">
                  <h6>Selected Tag: </h6>
                  {selectedTagWithDevice.length
                    ? selectedTagWithDevice.map((tag, index, array) => (
                        <React.Fragment key={tag}>
                          <p>{tag.tagName}</p>
                          {index < array.length - 1 && <p>,</p>}
                        </React.Fragment>
                      ))
                    : ""}
                </div>
                <div className="configration-details-btn">
                  <button className="canclebtn" onClick={handleCancleClick}>
                    Cancel
                  </button>
                  {(configAccess?.is_viewer && configAccess?.is_editor) ||
                  (!configAccess?.is_viewer && configAccess?.is_editor) ||
                  (configAccess?.is_viewer && !configAccess?.is_editor) ? (
                    <button
                      className={
                        selectTagUnitDisable.length == 2
                          ? "submitbtn"
                          : "submit-btn-disable"
                      }
                      onClick={handleTagSubmitClick}
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      className={
                        selectTagUnitDisable.length == 2
                          ? "submitbtn"
                          : "submit-btn-disable"
                      }
                      onClick={handleTostMsg}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {/* Co2 popup */}
        {co2ProdDevicePopup && (
          <>
            <div className="overlay123">
              <div className="deviceModal-popup ana-popup">
                <div style={{ padding: "25px 20px" }}>
                  <h6>
                    <b>Devices</b>
                  </h6>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box className="devicemodal-box">
                      {(configAccess?.is_viewer && configAccess?.is_editor) ||
                      (!configAccess?.is_viewer && configAccess?.is_editor) ||
                      (configAccess?.is_viewer && !configAccess?.is_editor) ? (
                        <FormGroup>
                          <FormControlLabel
                            style={{ display: "flex", justifyContent: "end" }}
                            control={
                              <Checkbox
                                style={{
                                  color: "#1C407B",
                                  padding: "0px",
                                  marginRight: "3px",
                                }}
                                checked={allSelectCo2}
                                onChange={handleSelectAllCo2CheckboxChange}
                              />
                            }
                            label="All"
                          />
                        </FormGroup>
                      ) : (
                        <FormGroup>
                          <FormControlLabel
                            style={{ display: "flex", justifyContent: "end" }}
                            control={
                              <Checkbox
                                style={{
                                  color: "#1C407B",
                                  padding: "0px",
                                  marginRight: "3px",
                                }}
                                checked={allSelectCo2}
                                onChange={handleTostMsg}
                              />
                            }
                            label="All"
                          />
                        </FormGroup>
                      )}
                      <div style={{ maxHeight: "420px", overflow: "auto" }}>
                        {consumptionDevice?.device?.map((e) => {
                          return (
                            <Box className="Device-caption d-flex mt-2">
                              <>
                                <div>
                                  {(configAccess?.is_viewer &&
                                    configAccess?.is_editor) ||
                                  (!configAccess?.is_viewer &&
                                    configAccess?.is_editor) ||
                                  (configAccess?.is_viewer &&
                                    !configAccess?.is_editor) ? (
                                    <Checkbox
                                      checked={
                                        isDeviceDisabledInCO2prodPopup(e.device)
                                          ? true
                                          : co2ProdSelectDevice.includes(
                                              e.device
                                            )
                                      }
                                      onChange={() =>
                                        handleCo2DeviceCheckboxChange(e.device)
                                      }
                                      className={
                                        isDeviceDisabledInCO2prodPopup(e.device)
                                          ? "grayed-checkbox"
                                          : "enabled-checkbox"
                                      }
                                      disabled={isDeviceDisabledInCO2prodPopup(
                                        e.device
                                      )}
                                    />
                                  ) : (
                                    <Checkbox
                                      checked={
                                        isDeviceDisabledInCO2prodPopup(e.device)
                                          ? true
                                          : co2ProdSelectDevice.includes(
                                              e.device
                                            )
                                      }
                                      onChange={handleTostMsg}
                                      className={
                                        isDeviceDisabledInCO2prodPopup(e.device)
                                          ? "grayed-checkbox"
                                          : "enabled-checkbox"
                                      }
                                      disabled={isDeviceDisabledInCO2prodPopup(
                                        e.device
                                      )}
                                    />
                                  )}
                                </div>
                                <div>
                                  <h6>{e.device__device_name}</h6>
                                  <p>UID - {e.device__machine__uid}</p>
                                  <p>{e.device__device_description}</p>
                                </div>
                              </>
                            </Box>
                          );
                        })}
                      </div>
                    </Box>
                  </Grid>
                </div>
                <div className="text-center  mb-4 analytics-btns">
                  <button className="canclebtn" onClick={handleOpenCo2Modal}>
                    Cancel
                  </button>
                  {(configAccess?.is_viewer && configAccess?.is_editor) ||
                  (!configAccess?.is_viewer && configAccess?.is_editor) ||
                  (configAccess?.is_viewer && !configAccess?.is_editor) ? (
                    <button className="submitbtn" onClick={handleSubmit}>
                      Submit
                    </button>
                  ) : (
                    <button className="submitbtn" onClick={handleTostMsg}>
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {co2AuxDevicePopup && (
          <>
            <div className="overlay123">
              <div className="deviceModal-popup ana-popup">
                <div style={{ padding: "25px 20px" }}>
                  <h6>
                    <b>Devices</b>
                  </h6>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box className="devicemodal-box">
                      {(configAccess?.is_viewer && configAccess?.is_editor) ||
                      (!configAccess?.is_viewer && configAccess?.is_editor) ||
                      (configAccess?.is_viewer && !configAccess?.is_editor) ? (
                        <FormGroup>
                          <FormControlLabel
                            style={{ display: "flex", justifyContent: "end" }}
                            control={
                              <Checkbox
                                style={{
                                  color: "#1C407B",
                                  padding: "0px",
                                  marginRight: "3px",
                                }}
                                checked={allSelectCo2Aux}
                                onChange={handleSelectAllCo2AuxCheckboxChange}
                              />
                            }
                            label="All"
                          />
                        </FormGroup>
                      ) : (
                        <FormGroup>
                          <FormControlLabel
                            style={{ display: "flex", justifyContent: "end" }}
                            control={
                              <Checkbox
                                style={{
                                  color: "#1C407B",
                                  padding: "0px",
                                  marginRight: "3px",
                                }}
                                checked={allSelectCo2Aux}
                                onChange={handleTostMsg}
                              />
                            }
                            label="All"
                          />
                        </FormGroup>
                      )}
                      <div style={{ maxHeight: "420px", overflow: "auto" }}>
                        {consumptionDevice?.device?.map((e) => {
                          return (
                            <Box className="Device-caption d-flex mt-2">
                              <>
                                <div>
                                  {(configAccess?.is_viewer &&
                                    configAccess?.is_editor) ||
                                  (!configAccess?.is_viewer &&
                                    configAccess?.is_editor) ||
                                  (configAccess?.is_viewer &&
                                    !configAccess?.is_editor) ? (
                                    <Checkbox
                                      checked={
                                        isDeviceDisabledInCO2AuxPopup(e.device)
                                          ? true
                                          : co2AuxSelectDevice.includes(
                                              e.device
                                            )
                                      }
                                      onChange={() =>
                                        handleCo2AuxDeviceCheckboxChange(
                                          e.device
                                        )
                                      }
                                      className={
                                        isDeviceDisabledInCO2AuxPopup(e.device)
                                          ? "grayed-checkbox"
                                          : "enabled-checkbox"
                                      }
                                      disabled={isDeviceDisabledInCO2AuxPopup(
                                        e.device
                                      )}
                                    />
                                  ) : (
                                    <Checkbox
                                      checked={
                                        isDeviceDisabledInCO2AuxPopup(e.device)
                                          ? true
                                          : co2AuxSelectDevice.includes(
                                              e.device
                                            )
                                      }
                                      onChange={handleTostMsg}
                                      className={
                                        isDeviceDisabledInCO2AuxPopup(e.device)
                                          ? "grayed-checkbox"
                                          : "enabled-checkbox"
                                      }
                                      disabled={isDeviceDisabledInCO2AuxPopup(
                                        e.device
                                      )}
                                    />
                                  )}
                                </div>
                                <div>
                                  <h6>{e.device__device_name}</h6>
                                  <p>UID - {e.device__machine__uid}</p>
                                  <p>{e.device__device_description}</p>
                                </div>
                              </>
                            </Box>
                          );
                        })}
                      </div>
                    </Box>
                  </Grid>
                </div>
                <div className="text-center analytics-btns mb-4">
                  <button className="canclebtn" onClick={handleOpenCo2AuxModal}>
                    Cancel
                  </button>
                  {(configAccess?.is_viewer && configAccess?.is_editor) ||
                  (!configAccess?.is_viewer && configAccess?.is_editor) ||
                  (configAccess?.is_viewer && !configAccess?.is_editor) ? (
                    <button className="submitbtn" onClick={handleSubmit}>
                      Submit
                    </button>
                  ) : (
                    <button className="submitbtn" onClick={handleTostMsg}>
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ConfigrationAnalytics;
