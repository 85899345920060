import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import exporting from 'highcharts/modules/exporting';
import { useSelector } from 'react-redux';
import { images } from '../../../config';
import { useParams } from 'react-router-dom';
import { ShimmerText } from "react-shimmer-effects";
import ShimmerEffectLineChart from '../../../components/ShimmerLineChart/ShimmerLinechart';


HC_more(Highcharts);
exporting(Highcharts);

export function MultiAxisLineChart({ fisrtLineChartReportData, secondeLineChartReportData, selectFirstUnit, selectSecondUnit,selectFirst,selectSecond,Shimmer}) { 

    const {
    unitColorsReportRedux,
    } = useSelector((state) => state.overViewState);



    const categories = [];
    
    if (fisrtLineChartReportData.length || secondeLineChartReportData.length) {
        const popDataLength = fisrtLineChartReportData[0]?.data?.length || 0;
        const pop1DataLength = secondeLineChartReportData[0]?.data?.length || 0;
        const maxLength = Math.max(popDataLength, pop1DataLength);

        for (let i = 0; i < maxLength; i++) {
            const popData = fisrtLineChartReportData[0]?.data[i] || {};
            const pop1Data = secondeLineChartReportData[0]?.data[i] || {};
            const minKey = popData?.MIN_KEY || "";
            const pop1MinKey = pop1Data?.MIN_KEY || "";
            if (minKey !== pop1MinKey) {
                categories.push(`${minKey} ${pop1MinKey}`);
            } else if (!categories.includes(minKey)) { 
                categories.push(minKey); 
            }
        }
    }

    const param = useParams();
    // const categories = fisrtLineChartReportData.length ? fisrtLineChartReportData[0] && fisrtLineChartReportData[0].data.map((row) => row.MIN_KEY) : [] 

    const findUnitColor1 = unitColorsReportRedux.find((f) => f.tagUnit == selectFirstUnit && f.plantId == param.id);
    const findUnitColor2 = unitColorsReportRedux.find((f) => f.tagUnit == selectSecondUnit && f.plantId == param.id);

    const options = {
        chart: {
            type: "spline",
            scrollablePlotArea: {
                minWidth: 700,
                scrollPositionX: categories.length - 1, // Set scroll position to the rightmost end
            },
        },
        title: {
            text: null
        },
        legend: {
            enabled: false // Disable legend
        },
        xAxis: {
            categories: categories,
            scrollbar: {
                enabled: true,
            },
        },
        series: [
            {
                name: selectFirst,
                data: fisrtLineChartReportData.length ? fisrtLineChartReportData[0] && fisrtLineChartReportData[0].data.map((row) => parseFloat(row.tag_value)) : [],
                color: findUnitColor1 ? findUnitColor1.color : "",
                yAxis: 0 // Set the series to use the first y-axis
            },
            {
                name: selectSecond,
                data: secondeLineChartReportData.length ? secondeLineChartReportData[0] && secondeLineChartReportData[0].data.map((row) => parseFloat(row.tag_value)) : [],
                color: findUnitColor2 ? findUnitColor2.color : "",
                yAxis: 1 // Set the series to use the second y-axis
            }
        ],
        yAxis: [
            {
                title: {
                    text: selectFirstUnit == "none" ? "" : selectFirstUnit // First y-axis title
                }
            },
            {
                title: {
                    text: selectSecondUnit == "none" ? "" : selectSecondUnit// Second y-axis title
                },
                opposite: true // Display the second y-axis on the opposite side
            }
        ],
        // colors: unitColorsReportRedux.length ? unitColorsReportRedux.map((e) => e.color) : [],
        exporting: {
            enabled: true, // Disable exporting options
        },
        credits: {
            enabled: false, // Disable credits
        },
        tooltip: {
            shared: true,
            formatter: function () {
                let s = `${this.x}<br/>`;
                this.points.forEach(point => {
                    s += `<br/><span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: <b>${point.y}</b>`;
                });
                return s;
            }
        },
    };

    return (
        <>
           
            {!Shimmer ? fisrtLineChartReportData.length || secondeLineChartReportData.length ? <HighchartsReact highcharts={Highcharts} options={options} /> :<img src={images.noDataFoundImg} className="notFoundDataImg" alt='No DataFound Img' style={{paddingTop: "100px"}}/>:<ShimmerEffectLineChart />}
        </>
    )
}