import React from "react";
import { useEffect } from "react";
import Mqqtdata from "../../../../../ProcessDashboard/Mqqtdata";
import { useState } from "react";
import { timeStampCheckStatus } from "../../../../../../components/TimeStampCheckStatus/timeStampCheckStatus";
import { toast } from "react-toastify";

const OverViewSelectTagData = ({
    deviceData,
    unitySelectTag,
    setSelectTagRealTimeData,
    setSelectMqttTagPreviousvalue,
    tagListDataForDevice
}) => {

    const [topics, setTopics] = useState([]);
    const [mqttTagData, setMqttTagData] = useState([]);
    const [isNotified, setIsNotified] = useState(false);
    const [MqttTime, setMqttTime] = useState("");

    //topic 
    useEffect(() => {
        const vmsTopic = ['Hikar/DeviceUID/' + deviceData.machine_uid];
        setTopics(vmsTopic);
        setSelectTagRealTimeData([]);
    }, [unitySelectTag]);


    useEffect(() => {
        // Use the filter method to get the desired data
        const filteredData = Object.values(mqttTagData).flatMap(deviceData =>
            deviceData.filter(item => item.tagName === unitySelectTag)
        );

        console.log("filteredDatafilteredData", filteredData);
        if (filteredData.length) {
            // setSelectTagRealTimeData((prev) => [...prev, filteredData[0]]);
            setSelectTagRealTimeData((prev) => {
                // Create a shallow copy of the previous state
                const indexCopy = [...prev];
                //==========================new add code==============================
                if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
                  indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
                }
          
                return [...indexCopy, filteredData[0]]
              });
            setSelectMqttTagPreviousvalue((prev) => {
                return {
                    tagName: unitySelectTag,
                    prevValue: prev.curValue,
                    curValue: filteredData[0].tagValue
                }
            })
        } 
       
    }, [mqttTagData])
    const convertTimeStampFormat = mqttTagData ? Object.keys(mqttTagData).map((row) =>{
        const findTimeStamp = mqttTagData[row].find((f) => f.device_tag == "TIMESTAMP");
        return ({
          ...findTimeStamp,
          TIMESTAMP: findTimeStamp?.TIMESTAMP || "",
        });
      }) : []
      useEffect(() => {
        const getData = timeStampCheckStatus(convertTimeStampFormat);
    
        if (!isNotified && Array.isArray(getData) && getData.length > 0) {
          const { device_tag, tagDateAndTimeFormat, tagValue } = getData[0];
          setMqttTime(tagValue);
          const notifyError = (message) =>
            toast.error(message, {
              theme: 'colored',
              position: toast.POSITION.TOP_RIGHT,
              toastClassName: 'custom-toast',
            });
    
            if (device_tag && tagDateAndTimeFormat && tagValue) {
          
              notifyError(
                <div>
                  <p>Device Tag: {device_tag}</p>
                  <p>Current Time: {tagDateAndTimeFormat}</p>
                  <p>Mqtt Time: {tagValue}</p>
                </div>
              );
              setIsNotified(true); 
            }
        }
      }, [mqttTagData, isNotified]); 
    
  
    return (
        <>
            {topics.length ? <Mqqtdata topics={topics} setMqttTagData={setMqttTagData} overViewTagData={tagListDataForDevice} /> : null}
        </>
    )
}

export default OverViewSelectTagData;