import { getAnalyticsGraphDataCOC } from "../../../../SmartTag/services";
import { getReportParatoChat } from "../service"

export const getReportAPIComp = async (param, setData,setLoading) => {

    try {
        setLoading(true)
        const resp = await getReportParatoChat(param);
        if (resp.status == 200 || resp.status == 201) {
            setLoading(false)
            setData(resp.data.payload)
        }
        else{
            setData([])
        }
    }
    catch (error) {
        setData([])
        setLoading(false);
        console.log("error", error);
    }
   
}


export const getReportAPIAnalyticsData = async (param, setData,setLoading) => {

    try {
        setLoading(true)
        const resp = await getAnalyticsGraphDataCOC(param);
        if (resp.status == 200 || resp.status == 201) {
            setLoading(false)
            setData(resp.data.payload)
        }
        else{
            setData([])
        }
    }
    catch (error) {
        setData([])
        setLoading(false);
        console.log("error", error);
    }
   
}